export const links = {
  home: "/",
  profileSettings: "/admin/main/profile/settings",
  profileOverview: "/admin/profile/overview",
  allUserEvents: "/admin/profile/all-events",
  signInCentered: "/auth/sign-in/",
  updatePasswordCentered: "/auth/update-password/",
  allAppsPage: "/admin/marketplace",
  singleAppPage: "/admin/subscription/profile",
  createNewSubscription: "/admin/create",
  editSubscription: "/admin/subscription/settings",
  subscriptionsTables: "/admin/subscription/subscribers",
  failedFix: "/admin/learn/failed-event",
  docsLink: "/",
  verifyEmailCentered: "/auth/verify-email/",
  setEmailCentered: "/auth/set-email/",
  supportEmail: "",
  termsOfUse: "/",
  blog: "/",
  license: "/",
  allEvents: "/admin/profile/all-events",
  eventDetails: "/admin/event",
  mainDashboard: "/admin/subscription/dashboard",
  docsApprove: "/",
  allUpcomingEvents: "/admin/profile/upcoming-events",
  allEventsStats: "/admin/subscription/events",
  adminDashboard: "/admin/subscription/admin",
  subscriptionApiKeys: "/admin/subscription/admin/api-keys",
  subscriptionPaymentLinks: "/admin/subscription/admin/payment-links",
  subscriptionWebhooks: "/admin/subscription/admin/webhooks",
  subscribersOverview: "/admin/subscription/admin/subscribers",
  subscriberDetails: "/admin/subscription/admin/subscribers/details",
  linkDetails: "/admin/subscription/admin/payment-links/details",
  widgetDocs: "/",
  reactPluginDocs: "/",
  webhooksDocs: "/",
  sdkAndApiDocs: "/",
  widget: "https://bananapay.tijan.dev/widget/#/v1",
  profileDashboard: "/admin/home",
  learn: "/admin/learn",
  aboutDocs: "/",
  support: "/",
  discord: "/",
  twitter: "",
  call: "/",
  alertText: "This is a DEMO version of BananaPay on Polygon Mumbai. There may be bugs! For any questions please contact tijan@bananapay.co",
  help: {
    tokenAllowance: ""
  }
}