import { Box, Button, Flex, Icon, Image, Link, SimpleGrid, Stack, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import Card from 'components/card/Card';
import { v4 as uuidv4 } from "uuid";
import { createPaymentLinkFromId, createWidgetLink, displayTime, secondesToDate } from "helpers/formatters";
import QRCode from "components/QRCode/QRCode";
import PricingPlan from "components/Pricing/PricingPlan";
import { useEvents } from "contexts/EventsContext";
import { BiBellOff } from "react-icons/bi";
import { RiRestartLine } from "react-icons/ri";
import SubscriptionStatusTag from "components/other/SubscriptionStatusTag";
import PricingPlanCard from "components/Pricing/PricingPlanCard";
import ChainTag from "components/chain/ChainTag";
import { BananaPay } from "bananapay-types";

const PricingPanel = (props: { app: BananaPay.App }) => {
  const { app } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { getSubscriptionPlan, getEventsForUserAndApp } = useEvents();

	const getIsCanceled = () => {
		return getEventsForUserAndApp(app.subscription_id)[getEventsForUserAndApp(app.subscription_id)?.length-1]?.name === "SubscriptionCanceled" ? true : false;
	}

	const displayAmount = (paymentOption: BananaPay.App.PaymentOption): string => {
		if(paymentOption.useUsdValue) return `$${paymentOption.usdValue}`;
    const tickerAmounts = new Map();
    paymentOption.paymentTokens.map(paymentToken => {
      const tokenTicker = paymentToken?.token?.ticker;
      const amount = paymentToken.amount;
      const existingAmount = tickerAmounts.get(tokenTicker) ?? 0;
      tickerAmounts.set(tokenTicker, existingAmount + amount);
    });
    return [...tickerAmounts.entries()]
      .map(([ticker, amount]) => `${amount} ${ticker}`)
      .join(', ');
	}


	const displayTokens = (paymentOption: BananaPay.App.PaymentOption) => {
		return paymentOption.paymentTokens?.map((paymentToken) => {
			return {
				img: paymentToken?.token?.logo,
				ticker: paymentToken?.token?.ticker,
				name: paymentToken?.token?.name
			}
		});
	}

	const displayPrice = () => {
		const { paymentToken, plan } = getSubscriptionPlan(app);
		const amount = plan?.useUsdValue ? `$${plan?.usdValue}` : `${paymentToken?.amount}`;
		const interval = plan?.paymentInterval;
		const token = paymentToken?.token?.ticker;
		return `${amount}${token}/${displayTime(interval)}`;
	}

  return(
    <Box px="8" mx="2" pb="8">
      <Card>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          {app.name}' Pricing Plans:
        </Text>
        <Text color={textColorSecondary} fontSize='md' me='26px' mb='25px'>
          All {app.name}'s pricing plans, take a look, subscribed and enjoy without worrying with BananaPay!
        </Text>
      </Card>

			<Card mt="8">
				<Flex justifyContent={"space-between"}>
        	<Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px' mb='4px'>
          	Supported Chains:
        	</Text>
					<Flex>
						{Object.entries(app.receivers).map(([index, receiver]) => {
							return(
								<Box key={index} ml="2">
									<ChainTag chain={receiver.chain} />
								</Box>
							)
						})}
					</Flex>
				</Flex>
      </Card>

      <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px' mt="8">
        {app.paymentOptions.map((paymentOption, index) => {
					if(!paymentOption.isHidden) {
						return(
							<PricingPlan
								subscriptionId={app.subscription_id}
								name={app.name}
								plan={paymentOption}
								key={uuidv4()}
								title={paymentOption.name}
								desc={paymentOption.description}
								button='Subscribe'
								planId={index}
								subscribeLink={createPaymentLinkFromId(app.defaultPaymentLinks.subscribe[index])}
								price={<PricingPlanCard paymentOption={paymentOption} />}
								details='(Per subscriber per month)'
								benefits={[
									'Sell on your own terms',
									'Website, marketing tools & automations',
									'Bandwidth & storage is included',
									"We'll get you onboarded"
								]}
							/>
						)
					}
					else return null
        })}
      </SimpleGrid>
    </Box>
  )
};

export default PricingPanel;