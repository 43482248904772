import React, { useState, createContext, useContext, useEffect } from "react";
import { signInWithMoralis } from "@moralisweb3/client-firebase-evm-auth";
import { auth, functions, moralisAuth } from "../helpers/firebase";
import { onAuthStateChanged, updateEmail, updatePassword, signInWithEmailAndPassword, sendEmailVerification } from "@firebase/auth";
import { User } from "@firebase/auth";
import { useToast } from '@chakra-ui/react'
import { getToast } from "helpers/formatters";
import { NotificationSettings } from "helpers/types";
import { httpsCallable } from "firebase/functions";
import { BananaPay } from "bananapay-types";

interface UserProviderProps {
  children: React.ReactNode
}

interface UserContextProps {
  isSignedIn: boolean,
  user: User | null,
  notifications: NotificationSettings | null,
  signIn: () => void,
  signOut: () => void,
  saveEmail: (newEmail: string) => void,
  changePassword: (newPassword: string) => void,
  signInWithPassword: (email: string, password: string) => void,
  verifyEmail: () => void,
  getIsVerified: () => Promise<boolean>,
  refreshUser: () => Promise<User | null>,
  updateNotificationSettings: (newNotifications: NotificationSettings) => Promise<void>,
  createApiKey: (name: string, subscriptionId: string) => Promise<string>,
  deleteApiKey: (name: string) => Promise<void>,
  changeApiKeyState: (name: string, newState: boolean) => Promise<void>,
  getAllApiKeys: () => Promise<BananaPay.ExtendedUser.ApiKeys>,
  getAllApiKeysForSubscription: (subscriptionId: string, ownerUid: string) => Promise<BananaPay.ExtendedUser.ApiKeys>,
  apiKeys: BananaPay.ExtendedUser.ApiKeys | null,
  subscriptionApiKeys: BananaPay.ExtendedUser.ApiKeys | null
}

const UserContext = createContext<UserContextProps>({
  isSignedIn: false,
  user: null,
  notifications: null,
  signIn: () => { },
  signOut: () => { },
  saveEmail: (newEmail: string) => { },
  changePassword: (newPassword: string) => { },
  signInWithPassword: (email: string, password: string) => { },
  verifyEmail: () => { },
  getIsVerified: () => new Promise(() => false),
  refreshUser: () => new Promise(() => null),
  updateNotificationSettings: (newNotifications) => new Promise(() => {}),
  createApiKey: (name, subscriptionId) => new Promise(() => ""),
  deleteApiKey: (name) => new Promise(() => {}),
  changeApiKeyState: (name, newState) => new Promise(() => {}),
  getAllApiKeys: () => new Promise(() => []),
  getAllApiKeysForSubscription: (subscriptionId, ownerUid) => new Promise(() => []),
  apiKeys: null,
  subscriptionApiKeys: null
});


export const UserProvider = ({ children }: UserProviderProps) => {

  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [notifications, setNotifications] = useState<NotificationSettings | null>(null);
  const [apiKeys, setApiKeys] = useState<BananaPay.ExtendedUser.ApiKeys | null>(null);
  const [subscriptionApiKeys, setSubscriptionApiKeys] = useState<BananaPay.ExtendedUser.ApiKeys | null>(null);

  const toast = useToast();

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, async(user) => {
      setUser(user);
      if(user) setIsSignedIn(true);
      else setIsSignedIn(false);
    });
    return subscriber; // unsubscribe on unmount
  }, []);

  useEffect(() => {
    if(user) {
      (async function () {
        await getUserNotifications();
        await getAllApiKeys();
      })();
    }
  }, [user]);

  // TODO: update analytics and growthbook user ids, client ids


  const signIn = async() => {
    try{
      toast(getToast("info", "Loading!", "Signing in . . ."));
      await signInWithMoralis(moralisAuth);
      toast.closeAll();
      toast(getToast("success", "Signed In!", "You have signed in successfully!"));
    }catch(error: any){
      toast(getToast("error", "Error!", error.message))
      throw new Error(error);
    }
  }

  const signOut = async() => {
    try{
      await auth.signOut();
      toast(getToast("success", "Signed Out!", "You have signed out successfully!"));
    }catch(error: any){
      toast(getToast("error", "Error!", "Something went wrong while signing you out."))
      throw new Error(error);
    }
  }

  const saveEmail = async(newEmail: string) => {
    if(auth.currentUser && newEmail) {
      await updateEmail(auth.currentUser, newEmail).then(() => {
        toast(getToast("success", "Email Updated!", "Your email was updated successfully!"));
        const _user = auth.currentUser;
        setUser(_user);
      }).catch((error: any) => {
        toast(getToast("error", "Error!", error.message));
        throw new Error(error);
      });
    }
  }

  const changePassword = async(newPassword: string) => {
    try{
      await updatePassword(user, newPassword);
      toast(getToast("success", "Password Updated!", "Your password has been updated successfully!"));
    }catch(error: any){
      toast(getToast("error", "Something went wrong!", error.message))
      throw new Error(error);
    }
  }

  const signInWithPassword = async(email: string, password: string) => {
    try{
      toast(getToast("info", "Loading!", "Signing in . . ."));
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("SIGNED IN");
      toast(getToast("success", "Signed In!", "You have signed in successfully!"));
      setUser(user);
      console.log(user);
    }catch(err){
      const error: any = err;
      const errorCode = error.code;
      const errorMessage = error.message;
      toast(getToast("error", "Something went wrong!", errorMessage))
      console.log("ERROR")
      console.log(errorCode, errorMessage);
      throw new Error(error)
    }
  }

  
  const verifyEmail = () => {
    if(user) {
      sendEmailVerification(user)
      .then(() => {
        toast(getToast("success", "Email Sent!", "Follow instructions in that email!"));
      });
    }
  }

  const getIsVerified = async() => {
    await user?.reload();
    const _user = await auth.currentUser;
    setUser(_user);
    if(_user){
      var status = _user.emailVerified;
      if(status) return true;
      else return false;
    }else{
      return false
    } 
  }

  const getUserNotifications = async() => {
    if(user) {
      try{
        const getNotificationSettingsF = httpsCallable(functions, "getUserNotifications");
        const result = await getNotificationSettingsF()
        const data: any = result.data;
        const _notifications: NotificationSettings = data.notifications;
        console.log(_notifications);
        setNotifications(_notifications);
      }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error while getting your notification settings!", message));
      }
    }else{
      // wrong input data
      toast(getToast("error", "Log in!", "You must be logged in to update this data!"));
    }
  }

  const updateNotificationSettings = async(newNotifications: NotificationSettings) => {
    if(user) {
      if(newNotifications !== notifications) {
        try{
          toast(getToast("info", "Saving!", "Saving your notification settings . . ."));
          const updateNotificationSettingsF = httpsCallable(functions, "updateUserNotifications");
          const result = await updateNotificationSettingsF({
            notifications: newNotifications
          })
          const data: any = result.data;
          setNotifications(newNotifications);
          toast(getToast("success", "Saved!", "Your notification settings have been updated successfully!"));
        }catch(err){
          // Getting the Error details.
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while getting your notification settings!", message));
        }
      }else{
        // wrong input data
        toast(getToast("info", "No changes!", "No changes to save!"));
      }
    }else{
      // wrong input data
      toast(getToast("error", "Log in!", "You must be logged in to update this data!"));
    }
  }

  const createApiKey = async(name: string, subscriptionId: string) => {
    if(user) {
      if(!Object.values(apiKeys).some(item => item.name === name)) {
        try{
          toast(getToast("info", "Creating!", "Creating your API Key . . ."));
          const createApiKeyF = httpsCallable(functions, "createApiKey");
          const result = await createApiKeyF({
            name: name,
            subscriptionId: subscriptionId
          })
          const data: any = result.data;
          const hashedKey = data.hashedApiKey;
          toast(getToast("success", "Created!", "Your API Key has been created successfully!"));
          if(data.length>0) {
            const _keys = {...apiKeys};
            _keys[hashedKey] = {
              active: true,
              hashed_key: "",
              name: name,
              subscription_id: subscriptionId
            }
            setApiKeys(_keys);
          }
          return data.apiKey;
        }catch(err){
          // Getting the Error details.
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error creating your API Key!", message));
          return "";
        }
      }else{
        // wrong input data
        toast(getToast("info", "Already Created!", "API Key with this name is already created."));
        return "";
      }
    }else{
      // wrong input data
      toast(getToast("error", "Log in!", "You must be logged in to create your API Key!"));
      return "";
    }
  };

  const deleteApiKey = async(hashedKey: string) => {
    if(user) {
      if(apiKeys[hashedKey]) {
        try{
          toast(getToast("info", "Deleting", "Deleting your API Key . . ."));
          const deleteApiKeyF = httpsCallable(functions, "deleteApiKey");
          const result = await deleteApiKeyF({
            hashedKey: hashedKey
          })
          const data: any = result.data;
          const _keys = {...apiKeys};
          delete _keys[hashedKey];
          setApiKeys(_keys);
          toast(getToast("success", "Deleted!", "Your API Key has been deleted successfully!"));
        }catch(err){
          // Getting the Error details.
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while deleting your API key!", message));
        }
      }else{
        // wrong input data
        toast(getToast("info", "Already Deleted!", "API Key with this name doesn't exist."));
      }
    }else{
      // wrong input data
      toast(getToast("error", "Log in!", "You must be logged in to create your API Key!"));
    }
  };

  const changeApiKeyState = async(hashedApiKey: string, newState: boolean) => {
    if(user) {
      if(apiKeys[hashedApiKey]) {
        try{
          toast(getToast("info", "Changing!", "Changing your API Key State . . ."));
          const changeApiKeyStateF = httpsCallable(functions, "changeApiKeyState");
          const result = await changeApiKeyStateF({
            hashedApiKey: hashedApiKey,
            newState: newState
          })
          const data: any = result.data;
          const _keys = {...apiKeys};
          _keys[hashedApiKey].active = newState;
          setApiKeys(_keys);
          toast(getToast("success", "Changed!", "Your API Key state has been changed successfully!"));
        }catch(err){
          // Getting the Error details.
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while changing your API Key state!", message));
        }
      }else{
        // wrong input data
        toast(getToast("info", "Not found!", "API Key with this name doesn't exist."));
      }
    }else{
      // wrong input data
      toast(getToast("error", "Log in!", "You must be logged in to change your API Key state!"));
    }
  };

  const getAllApiKeys = async(): Promise<BananaPay.ExtendedUser.ApiKeys> => {
    if(user) {
      try{
        const getAllApiKeysF = httpsCallable(functions, "getAllApiKeys");
        const result = await getAllApiKeysF()
        const data: any = result.data;
        console.log("Api keys");
        console.log(data);
        setApiKeys(data as BananaPay.ExtendedUser.ApiKeys);
        return data as BananaPay.ExtendedUser.ApiKeys;
      }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error while getting your API Keys.", message));
        return {};
      }
    }else{
      // wrong input data
      toast(getToast("error", "Log in!", "You must be logged in to get your API Keys!"));
      return {};
    }
  };

  const getAllApiKeysForSubscription = async(subscriptionId: string, ownerUid: string): Promise<BananaPay.ExtendedUser.ApiKeys> => {
    if(user) {
      try{
        const getAllApiKeysForSubscriptionF = httpsCallable(functions, "getAllApiKeysForSubscription");
        const result = await getAllApiKeysForSubscriptionF({
          ownerUid: ownerUid,
          subscriptionId: subscriptionId
        })
        const data: any = result.data;
        console.log("Api keys");
        console.log(data);
        setSubscriptionApiKeys(data as BananaPay.ExtendedUser.ApiKeys);
        return data as BananaPay.ExtendedUser.ApiKeys;
      }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error while getting your API Keys.", message));
        return {};
      }
    }else{
      // wrong input data
      toast(getToast("error", "Log in!", "You must be logged in to get your API Keys!"));
      return {};
    }
  };

  const refreshUser = async() => {
    await user?.reload();
    const _user = await auth.currentUser;
    setUser(_user);
    return _user;
  };


  return (
    <UserContext.Provider
      value={{
        isSignedIn,
        user,
        notifications,
        signIn,
        signOut,
        saveEmail,
        changePassword,
        signInWithPassword,
        verifyEmail,
        getIsVerified,
        refreshUser,
        updateNotificationSettings,
        createApiKey,
        deleteApiKey,
        changeApiKeyState,
        getAllApiKeys,
        getAllApiKeysForSubscription,
        apiKeys,
        subscriptionApiKeys
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
}