/*!
""

*/

// Chakra imports
import { Avatar, Box, Button, Center, Flex, FormLabel, Grid, Icon, Image, Select, SimpleGrid, Text, useColorModeValue, useToast } from '@chakra-ui/react';
// Custom components
import Balance from 'views/admin/dashboards/default/components/Balance';
import DailyTraffic from 'views/admin/dashboards/default/components/DailyTraffic';
import MostVisitedTable from 'views/admin/dashboards/default/components/MostVisitedTable';
import OverallRevenue from 'views/admin/dashboards/default/components/OverallRevenue';
import ProfitEstimation from 'views/admin/dashboards/default/components/ProfitEstimation';
import YourTransfers from 'views/admin/dashboards/default/components/YourTransfers';
import tableDataMostVisited from 'views/admin/dashboards/default/variables/tableDataMostVisited';
import { useState, useEffect, useMemo } from 'react';
import { useStats } from 'contexts/StatsContext';
import { Balances, ChannelData } from 'helpers/types';
import IconBox from 'components/icons/IconBox';
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPayments } from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import ChainLogo from 'components/chain/ChainLogo';
import { allChains, getChain } from 'helpers/chains';
import { v4 as uuidv4 } from "uuid";
import { useHistory } from 'react-router-dom';
import { createLinkForDashboard, getToast, useQuery } from 'helpers/formatters';
import MaticLogo from 'assets/img/chain/polygon';
import ETHLogo from 'assets/img/chain/ethereum';
import DailyEvents from './components/DailyEvents';
import LatestEventsTable from './components/LatestEventsTable';
import Events from './components/Events';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'helpers/firebase';
import { BsBellFill, BsClock, BsClipboardData } from 'react-icons/bs';
import { BananaPay } from 'bananapay-types';
import { useApps } from 'contexts/AppsContext';
import { links } from 'helpers/links';
import { useUser } from 'contexts/UserContext';
import Loading from 'components/loading/Loading';
import BreadCrumpStrip from 'components/breadcrumb/Breadcrumb';


export default function Default() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	
	const [activeData, setActiveData] = useState<{name: string, data: {timestamp: string, subscribers: number}[]}[] | null>(null);
	const [totalData, setTotalData] = useState<{name: string, data: {timestamp: string, subscribers: number}[]}[] | null>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [stats, setStats] = useState<ChannelData | null>(null);

	const { getStats, isLoading, subscriptionId } = useStats();
	const { apps, getAppDetails } = useApps();
	const { user } = useUser();
	const history = useHistory();
	const query = useQuery();
	const toast = useToast();

	/*const calculateNewSubscribers = () => {
		if(stats?.totalSubscribers?.length>1) {
			return stats?.totalSubscribers[stats.totalSubscribers.length-1]?.subscribers?.length - stats?.totalSubscribers[stats.totalSubscribers.length-2]?.subscribers?.length
		}else{
			return stats?.totalSubscribers[stats.totalSubscribers.length-1]?.subscribers?.length;
		}
	}*/

	const getActiveSubscribers = () => {
		let subscribers = 0;
		if(stats) {
			Object.keys(stats.active_subscribers).forEach(chain => {
				subscribers += stats.active_subscribers[chain as BananaPay.Analytics.ChainType][stats.active_subscribers[chain as BananaPay.Analytics.ChainType].length-1].subscribers.length;
			});
		}
		return subscribers;
	}

	const getTotalSalesPerDay = () => {
		let salesPerDay = 0;
		if(stats) {
			Object.keys(stats.sales_per_day).forEach(chain => {
				salesPerDay += stats.sales_per_day[chain as BananaPay.Analytics.ChainType][stats.sales_per_day[chain as BananaPay.Analytics.ChainType].length-1].sales.totalSalesUsd;
			});
		}
		return salesPerDay;
	}

	const getTotalSales = () => {
		let totalSales = 0;
		if(stats) {
			Object.keys(stats.total_sales).forEach(chain => {
				totalSales += stats.total_sales[chain as BananaPay.Analytics.ChainType][stats.total_sales[chain as BananaPay.Analytics.ChainType].length-1].sales.totalSalesUsd;
			});
		}
		return totalSales;
	}

	useEffect(() => {
		if(stats) {
			const activeDataArr = [].concat(...Object.values(stats.active_subscribers)).map((_data: any) => {
				return {
					subscribers: Number(_data?.subscribers?.length) || 0,
					timestamp: _data?.timestamp
				}
			});
			const totalDataArr = [].concat(...Object.values(stats.total_subscribers)).map((_data: any) => {
				return {
					subscribers: Number(_data?.subscribers?.length) || 0,
					timestamp: _data?.timestamp
				}
			});
			setActiveData([{
				name: "Active Subscribers",
				data: activeDataArr
			}]);
			setTotalData([{
				name: "Total Subscribers",
				data: totalDataArr
			}]);
		}
	}, [stats]);

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

  useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
        (async function () {
          const _stats = await getStats(app.subscription_id);
          setStats(_stats);
					setIsLoaded(true);
        })();
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.mainDashboard}_subscriptionId=${subscriptionId}` : `${links.signInCentered}?to=${links.mainDashboard}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user, apps]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<BreadCrumpStrip
				additional={{mb: "4"}}
				links={[{
					href: `/#${createLinkForDashboard(app.subscription_id)}`,
					name: "Dashboard"
				}, {
					href: `/`,
					name: "Analytics"
				}]}
			/>
			<Flex direction={{ base: 'column', xl: 'row' }}>
				{stats?.allEvents?.length>0 && !isLoading ? (
					<Flex direction='column' width='stretch'>
						<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
									/>
								}
								name='Total Earnings'
								value={`$${Math.round((getTotalSales() + Number.EPSILON) * 100) / 100 || "-"}`}
							/>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
									/>
								}
								name='Earnings today'
								value={getTotalSalesPerDay()}
							/>
							<MiniStatistics
								growth='+23%'
								name='New subscribers'
								value={"calculateNewSubscribers()"}
							/>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={BsBellFill} color={brandColor} />}
									/>
								}
								name='Active Subscribers'
								value={getActiveSubscribers()}
							/>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={<Icon w='32px' h='32px' as={MdPayments} color={brandColor} />}
									/>
								}
								name='Total Events'
								value={stats?.allEvents?.length || "-"}
							/>
						</SimpleGrid>
						<Grid
							mb='20px'
							gap='20px'
							display={{ base: 'block', lg: 'grid' }}>
							<Flex>
								<OverallRevenue stats={stats} />
							</Flex>
							{/*<Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
								<Balance data={balancesData} />
							</Flex>*/}
						</Grid>
						<Grid
							gap='20px'
							gridTemplateColumns={{
								md: 'repeat(2, 1fr)',
								'2xl': 'repeat(3, 1fr)'
							}}
							gridTemplateRows={{
								md: 'repeat(2, 1fr)',
								'2xl': '1fr'
							}}
							mb='20px'>
							<Flex gridArea={{ md: '1 / 1 / 2 / 2', '2xl': '1 / 1 / 2 / 2' }}>
								<DailyTraffic data={totalData} type="total" />
							</Flex>
							<Flex gridArea={{ md: '1 / 2 / 2 / 3', '2xl': '1 / 2 / 2 / 3' }}>
								<DailyTraffic data={activeData} type="active" />
							</Flex>
							<Flex gridArea={{ md: ' 2 / 1 / 3 / 3', '2xl': '1 / 3 / 2 / 4' }}>
								<ProfitEstimation data={stats} />
							</Flex>
						</Grid>
						<Grid
							templateColumns={{ base: 'repeat(2, 1fr)', '2xl': '350fr 720fr' }}
							gap='20px'
							display={{ base: 'block', lg: 'grid' }}
							mb='20px'
						>
							<Flex gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
								<YourTransfers
									subscriptionId={subscriptionId}
								/>
							</Flex>
							<Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
								<MostVisitedTable stats={stats} />
							</Flex>
						</Grid>
						<Grid
							templateColumns={{ base: 'repeat(1, 2fr)', '2xl': '620fr 450fr' }}
							gap='20px'
							display={{ base: 'block', lg: 'grid' }}
						>
							<Flex gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
								<DailyEvents stats={stats} />
							</Flex>
							<Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
								<Events
									subscriptionId={subscriptionId}
								/>
							</Flex>
						</Grid>
					</Flex>
				):null}
				{isLoading || !isLoaded ? (
					<Loading text='Loading Analytics Data...' />
				):(
					stats?.allEvents?.length<=0 ? (
						<Box mb="10" mt="6" mx="auto">
							<Center mb="3">
								<Icon as={BsClipboardData} color={textColorPrimary} fontSize={"9xl"} mx="auto" w="min-content" textAlign={"center"} />
							</Center>
							<Text fontSize={"3xl"} fontWeight="900" color={textColorPrimary} textAlign="center">Not Enough Data</Text>
							<Text fontSize={"lg"} fontWeight="300" color={textColorPrimary} textAlign="center">Your subscription does not have enough data to be displayed. Try getting your first subscriber!</Text>
						</Box>
					):null
				)}
			</Flex>
		</Box>
	);
}
