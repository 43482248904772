import { useState, useEffect } from "react";
// Chakra imports
import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';
import { VSeparator } from 'components/separator/Separator';
import PieChart from 'components/charts/PieChart';
// Custom components
import Card from 'components/card/Card';
import { ChannelData, PlanData } from 'helpers/types';
import { BananaPay } from "bananapay-types";

const OPTIONS: any = {
	labels: [ 'Your files', 'System', 'Empty', "Cloud" ],
	colors: [ '#4318FF', '#6AD2FF', '#EFF4FB', "#11047A" ],
	chart: {
		width: "200px"
	},
	states: {
		hover: {
			filter: {
				type: 'none'
			}
		}
	},
	legend: {
		show: false
	},
	dataLabels: {
		enabled: true
	},
	hover: { mode: null },
	plotOptions: {
		donut: {
			expandOnClick: false,
			donut: {
				labels: {
					show: false
				}
			}
		}
	},
	fill: {
		colors: [ '#4318FF', '#6AD2FF', '#EFF4FB', "#11047A" ]
	},
	tooltip: {
		enabled: true,
		theme: 'dark'
	}
}
const COLORS = [ '#4318FF', '#6AD2FF', '#EFF4FB', "#11047A" ];

export default function ProfitEstimation(props: { data: ChannelData | null; [x: string]: any }) {
	const { data, ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const cardColor = useColorModeValue('white', 'navy.700');

	const [mostPopular, setMostPopular] = useState<PlanData>(null);
	const [chartData, setChartData] = useState<number[]|null>(null);
	const [chartOptions, setChartOptions] = useState<any>(OPTIONS);

	const getPercentage = () => {
		let total = 0;
		Object.keys(data.active_subscribers).forEach((chain, index) => {
			total += data?.active_subscribers[chain as BananaPay.Analytics.ChainType][data.active_subscribers[chain as BananaPay.Analytics.ChainType].length-1]?.subscribers.length;
		});
		const popular = mostPopular?.activeSubscribers?.length;
		if(total && popular) return `${Math.round((Number(popular/total) + Number.EPSILON) * 1000) / 1000 * 100}%`;
		else return "-";
	}

	useEffect(() => {
		if(data) {
			// set the data of the most popular plan - box below chart
			const _mostPopular = [].concat(...Object.values(data.payment_plans)).sort((a, b) => b.activeSubscribers.length - a.activeSubscribers.length); // a-b for reverse sort
			console.log(_mostPopular);
			setMostPopular(_mostPopular[0]);
			
			// set data - pie chart
			const _data = [].concat(...Object.values(data.payment_plans)).map((planData) => planData.activeSubscribers.length+1);
			setChartData(_data);

			// update chart options - pie chart
			const _options = OPTIONS;
			const _colors = [].concat(...Object.values(data.payment_plans)).map((x, i) => COLORS[Math.abs(i+1-COLORS.length)]);
			_options.colors = _colors;
			_options.labels = [].concat(...Object.values(data.payment_plans)).map((planData) => planData?.plan?.name || "No Plan");
			_options.fill.colors = _colors;
			setChartOptions(_options);
		}else{
			setChartData(null);
			setChartOptions(null);
		}
	}, [data])

	return (
		<Card p='20px' alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Text color={textColor} fontSize='lg' fontWeight='700' mb='10px' mx='auto'>
				Active Subscribers' Plans
			</Text>
			<Text color='secondaryGray.600' align={"center"} fontSize='sm' fontWeight='500' maxW='300px' mx='auto' mb='10px'>
				Discover your subscribers preferences, and learn more about your business!
			</Text>
			<Flex justifyContent='center' alignItems='center' w='100%' px='10px' mb='8px' />
			
			<Box w='200px' mx='auto' mb='10px' mt='10px'>
				{/**ADD PIE CHART INSTEAD */}
				{chartData && chartOptions ? (
					<PieChart h='100%' w='100%' chartData={chartData} chartOptions={chartOptions} />
				):(
					<Box h="100%" w="100%">
						<Text>No Active Subscribers</Text>
					</Box>
				)}
			</Box>

			<Card bg={cardColor} flexDirection='row' p='15px' mt='2' mx="auto" px="auto" textAlign={"center"}>
				<Flex direction='column' w={"33%"}>
					<Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
						Most Popular Plan
					</Text>
					<Text fontSize='lg' color={textColor} fontWeight='700'>
						{mostPopular?.plan?.name || "No Plan"}
					</Text>
				</Flex>
				<VSeparator />
				<Flex direction='column' w="33%">
					<Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
						Subscribers
					</Text>
					<Text fontSize='lg' color={textColor} fontWeight='700'>
						{mostPopular?.activeSubscribers?.length || "-"}
					</Text>
				</Flex>
				<VSeparator />
				<Flex direction='column' w="33%">
					<Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
						Percentage
					</Text>
					<Text fontSize='lg' color={textColor} fontWeight='700'>
						{getPercentage()}
					</Text>
				</Flex>
			</Card>
		</Card>
	);
}
