import { UseToastOptions } from "@chakra-ui/react"
import { BananaPay } from "bananapay-types";
import { useMemo } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { getChain } from "./chains";
import { links } from "./links";
import { EventLinkType, OptionalParamProps, RedirectUrlProps, WidgetType } from "./types";
import { getChainContractAddress } from "./web3";

export const drawerSizes = {
  sm: '350px',
  md: "1150px",
  lg: "1300px"
};

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const stringToUrlLocationtType = (val: string) => {
  switch (val) {
    case "_self": return "_self";
    case "_blank": return "_blank";
    default: return "_self";
  }
};

export const urlLocationTypes = () => {
  return [{
    value: "_self",
    name: "Current Tab"
  }, {
    value: "_blank",
    name: "New Tab"
  }];
};

export const stringToWidgetType = (val: string) => {
  switch (val) {
    case "subscribe": return "subscribe";
    case "cancel": return "cancel";
    case "restore": return "restore";
    default: return "subscribe";
  }
};

export const widgetTypes = () => {
  return [{
    value: "subscribe",
    name: "Subscribe"
  }, {
    value: "cancel",
    name: "Cancel"
  }, {
    value: "restore",
    name: "Restore"
  }];
};

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const createLinkForExplorer = (chain: string, type: "address"|"tx", data: string) => {
  switch(chain) {
    case "0x13881":
      return `${getChain(chain).blockExplorer}${type}/${data}`;
    default:
      return `${getChain(chainToHex(chainToNumber(chain))).blockExplorer}${type}/${data}`;
  }
};

export const strToColor = (str: string) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const encodeUriObject = (obj: any) => {
  const str = JSON.stringify(obj);
  return encodeUriString(str);
};

export const encodeUriString = (str: string) => {
  return  encodeURIComponent(btoa(str));
};

export const decodeUriToObj = (uri: string) => {
  return JSON.parse(decodeUriData(uri));
};

export const decodeUriData = (uri: string) => {
  return atob(decodeURIComponent(uri));
};

export const getEventName = (str: any) => {
  switch(str) {
    case "Subscribed": return str;
    case "Unsubscribed": return str;
    case "PaymentExecuted": return "Payment Successful";
    case "PaymentFailed": return "Payment Failed";
    case "SubscriptionCanceled": return "Subscription Canceled";
    case "SubscriptionRestored": return "Subscription Restored";
    case "IntervalsGiven": return "Given Intervals";
    default: return str;
  }
};

export const getShortEventName = (str: any) => {
  switch(str) {
    case "Subscribed": return str;
    case "Unsubscribed": return str;
    case "PaymentExecuted": return "Successful";
    case "PaymentFailed": return "Failed";
    case "SubscriptionCanceled": return "Canceled";
    case "SubscriptionRestored": return "Restored";
    case "IntervalsGiven": return "Given Intervals";
    default: return str;
  }
};

export const eventLinkToEventName = (str: EventLinkType, eventName: string) => {
  switch(str) {
    case "all": return eventName;
    case "unsubscribed": return "Unsubscribed";
    case "successful": return "PaymentExecuted";
    case "failed": return "PaymentFailed";
    case "canceled": return "SubscriptionCanceled";
    case "restored": return "SubscriptionRestored";
    case "subscribed": return "Subscribed";
    case "intervals-given": return "IntervalsGiven";
  }
};

export const defaultChain = "0x13881";

export const getExplorer = (chain: string) => {
  switch(chain) {
    case "mumbai": return "https://mumbai.polygonscan.com/";
    case "tbnb": return "https://testnet.bscscan.com/";
    case "tftm": return "https://testnet.ftmscan.com/";
    case "0x13881": return "https://mumbai.polygonscan.com/";
    case "0x61": return "https://testnet.bscscan.com/";
    case "0xfa2": return "https://testnet.ftmscan.com/";
  }
};

export const chainToNumber = (chain: string) => {
  switch(chain) {
    case "mumbai": return 80001;
    case "tbnb": return 97;
    case "tftm": return 4002;
    case "0x13881": return 80001;
    case "0x61": return 97;
    case "0xfa2": return 4002;
    default: return 80001;
  }
};

export const chainToHex = (chain: number) => {
  switch(chain) {
    case 80001: return "0x13881";
    case 97: return "0x61";
    case 4002: return "0xfa2";
    default: return "0x13881";
  }
};

export const numToChain = (chain: number) => {
  switch(chain) {
    case 80001: return "mumbai";
    case 97: return "tbnb";
    case 4002: return "tftm";
  }
};

export const hexToChain = (chain: string) => {
  switch(chain) {
    case "0x13881": return "mumbai";
    case "0x61": return "tbnb";
    case "0xfa2": return "tftm";
  }
};

export const chainToName = (chain: string) => {
  switch(chain) {
    case "0x13881": return "polygon";
    case "0x61": return "binance";
    case "0xfa2": return "fantom";
  }
}

export const stringToChain = (chain: string) => {
  switch(chain) {
    case "0x13881": return "0x13881";
    case "0x61": return "0x61";
    case "0xfa2": return "0xfa2";
  }
};

export const getPaymentToken = (paymentOption: BananaPay.App.PaymentOption, tokenAddress: string): BananaPay.App.PaymentToken | null => {
  const tokenIndex = paymentOption?.paymentTokens?.findIndex(e => Object.values(e?.token?.address).includes(tokenAddress?.toLowerCase()));
  if(tokenIndex > -1) {
    return paymentOption.paymentTokens[tokenIndex];
  }else{
    return null;
  }
}

export const formatNumber = (num: number, places?: number) => {
  const _num = num || 0;
  return _num.toLocaleString(undefined, {
    maximumSignificantDigits: places || 8,
  });
}

export const createWidgetLink = (
  subscriptionId: string,
  type: WidgetType,
  planId?: number,
  redirectUrl?: RedirectUrlProps,
  optionalParam?: OptionalParamProps[]
) => {
  if(planId>=0) {
    const dataObj = {
      subscriptionId: subscriptionId,
      planId: planId,
      ...(redirectUrl && { redirectUrl }),
      ...(optionalParam && { optionalParam }),
    }
    const subscriptionHash = encodeUriObject(dataObj);
    return `${links.widget}/${type}?subscription=${subscriptionHash}`;
  }else{
    if(type==="subscribe") {
      throw new Error("Missing parameter, planId!");
    }else{
      const dataObj = {
        subscriptionId: subscriptionId,
        ...(redirectUrl && { redirectUrl }),
      }
      const subscriptionHash = encodeUriObject(dataObj);
      return `${links.widget}/${type}?subscription=${subscriptionHash}`;
    }
  }
};

export const createLinkForApp = (subscriptionId: string) => {
  return `${links.singleAppPage}?subscriptionId=${subscriptionId}`;
};

export const createLinkForEditApp = (subscriptionId: string) => {
  return `${links.editSubscription}?subscriptionId=${subscriptionId}`;
};

export const createLinkForDashboard = (subscriptionId: string) => {
  return `${links.adminDashboard}?subscriptionId=${subscriptionId}`;
};

export const createLinkForSubscribersOverview = (subscriptionId: string) => {
  return `${links.subscribersOverview}?subscriptionId=${subscriptionId}`;
};

export const createLinkForAllEventsStats = (subscriptionId: string) => {
  return `${links.allEventsStats}?subscriptionId=${subscriptionId}`;
};

export const createLinkForPaymentLinkOverview = (subscriptionId: string, linkId: string) => {
  return `${links.linkDetails}?subscriptionId=${subscriptionId}&linkId=${linkId}`;
};

export const createLinkForCustomerOverview = (subscriptionId: string, customerId: string) => {
  return `${links.subscriberDetails}?subscriptionId=${subscriptionId}&customerId=${customerId}`;
};

export const createLinkForSubscriptionApiKeys = (subscriptionId: string) => {
  return `${links.subscriptionApiKeys}?subscriptionId=${subscriptionId}`;
};

export const createLinkForSubscriptionPaymentLinks = (subscriptionId: string) => {
  return `${links.subscriptionPaymentLinks}?subscriptionId=${subscriptionId}`;
};

export const createLinkForSubscriptionWebhooks = (subscriptionId: string) => {
  return `${links.subscriptionWebhooks}?subscriptionId=${subscriptionId}`;
};

export const createLinkForAnalytics = (subscriptionId: string) => {
  return `${links.mainDashboard}?subscriptionId=${subscriptionId}`;
};

export const createLinkForEvent = (event: any, isUpcoming: boolean) => {
  if(!isUpcoming) {
    return `${links.eventDetails}?eventId=${event.id}`;
  }else{
    return `${links.eventDetails}?eventId=${encodeUriObject(event)}&type=u`;
  }
};

export const isValidUUID = (uuidString: string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuidString);
};

export const getEllipsisTxt = (str: string, n = 6) => {
  if (str) {
    return `${str.substr(0, n)}...${str.substr(str.length - n, str.length)}`;
  }
  return "";
};

export const roundNumber = (n: number, decimals: number) => {
  if(Number(n.toFixed(decimals)) % 1 === 0){
    return n.toFixed(0);
  }else{
    return n.toFixed(decimals);
  }
};

export const displayTime = (n: number, space: boolean = true) => {
  if(n < 60) return `${roundNumber(n, 2)}${space ? " ": ""}sec`;
  if(n/60 < 60) return `${roundNumber(n/60, 2)}${space ? " ": ""}min`;
  if(n/60/60 < 24) return `${roundNumber(n/60/60, 2)}${space ? " ": ""}hrs`;
  if(n/60/60/24 < 30) return `${roundNumber(n/60/60/24, 2)}${space ? " ": ""}days`;
  if(n/60/60/24/30 < 12) return `${roundNumber(n/60/60/24/30, 2)}${space ? " ": ""}mo`;
  if(n/60/60/24/30/12 < 1) return `${roundNumber(n/60/60/24/30/12, 2)}${space ? " ": ""}yrs`;
  else return `${roundNumber(n/60/60/24/30/12, 2)} yrs`;
};

export const getEventsForDate = (events: any, date: Date) => {
  let eventsToReturn: any[] = [];
  events.map((event: any) => {
    let eventDate = new Date(event.blockTimestamp*1000);
    if (
      eventDate.getFullYear() === date.getFullYear() &&
      eventDate.getMonth() === date.getMonth() &&
      eventDate.getDate() === date.getDate()
    ) {
      eventsToReturn.push(event);
    }
  });
  return sortEvents(eventsToReturn);
};

export const sortEvents = (events: any[]) => {
  return events.sort((a: any, b: any) => {
    if (Number(a.blockTimestamp) - Number(b.blockTimestamp) === 0) {
      // emitted in same block - find true sequence
      if(a.name === "Unsubscribed" || b.name === "Subscribed") {
        // return b first
        return 1;
      }else{
        // return a first
        return -1;
      }
    }
    // emitted in different blocks - OK
    return Number(a.blockTimestamp) - Number(b.blockTimestamp);
  });
};

export const secondesToDate = (s: number) => {
  const date = new Date(s * 1000);
  const minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()} (${date.getHours()}:${minutes})`;
};

export const generateTxLink = (contractAddress: string, chain: string, functionName: string, params: {name: string, val: any}[]) => {
  let p = params.length === 0 ? "" : "?";
  params.map((x, i) => {
    const y = i === params.length-1 ? "" : "&";
    p = p.concat(`${x.name}=${x.val}${y}`)
  });
  return `ethereum:${contractAddress}@${chainToNumber(chain)}/${functionName}${p}`;
};

export const generateAllowanceTxLink = (chain: string, tokenAddress: string, spenderAddress: string, value: string) => {
  const params = [
    {
      name: "spender",
      val: spenderAddress
    },
    {
      name: "amount",
      val: value
    }
  ];
  return generateTxLink(tokenAddress, chain, "unsubscribe", params);
};

export const generateUnsubscribeTxLink = (chain: string, address: string) => {
  const params = [
    {
      name: "channel",
      val: address
    }
  ];
  return generateTxLink(getChainContractAddress(chain), chain, "unsubscribe", params);
};

/*export const generateSubscribeTxLink = (chain: string, plan: PaymentOption, appAddress: string) => {
  const params = [
    {
      name: "channel",
      val: appAddress
    },
    {
      name: "amount",
      val: plan.amount * (10**plan.token.decimals)
    },
    {
      name: "tokenAddress",
      val: plan.token.address
    },
    {
      name: "interval",
      val: plan.paymentInterval
    }
  ];
  return generateTxLink(getChainContractAddress(chain), chain, "subscribe", params);
};*/

export const getToast = (
  type: "success"|"error"|"info"|"warning",
  title: string,
  message: string
): UseToastOptions => {
  return {
    duration: type==="success" ? 2000 : 4000,
    status: type,
    title: title,
    description: message,
    isClosable: true,
    position: "bottom-right"
  }
};

export const checkIfAppOk = (app: BananaPay.App) => {
  let ok = true;
  //if(!app.address.startsWith("0x") || app.address.length !== 42) ok = false;
  return ok;
};

export const getCurrency = (chain: string) => {
  switch(chain) {
    case "0x13881": return "MATIC";
    case "0x61": return "tBNB";
    case "0xfa2": return "FTM";
    default: return "MATIC"
  }
};

export const isObjectInArray = (arr: any, searchObject: any) => {
  return arr.some((obj: any) => {
    // Convert both the search object's values and the current object's values to lowercase
    const searchValues = Object.values(searchObject).map((val: any) => val?.toLowerCase());
    const objValues = Object.values(obj).map((val: any) => val?.toLowerCase());

    // Check if the current object's values match the search object's values
    return searchValues.every((value, index) => value === objValues[index]);
  });
};

export const hasTokenAddress =(obj: any, searchString: string) => {
  const lowerSearchString = searchString.toLowerCase();
  
  return Object.values(obj).some((value: any) => {
    const lowerValue = value.toLowerCase();
    return lowerValue.includes(lowerSearchString);
  });
};

export const isEnglishAlphabet = (str: string): boolean => {
  const pattern = /^[A-Za-z]+$/;
  return pattern.test(str);
};

export const createPaymentLinkFromId = (linkId: string) => {
  return `${links.widget}?id=${linkId}`;
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const createDateString = (seconds: number) => {
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const d = new Date(seconds*1000);
  const m = month[d.getMonth()];
  const y = d.getFullYear();
  return `${d.getDay()} ${m} ${y}`;
};

const STUCK_THRESHOLD_SECONDS = 600;

export const isEventStuck = (event: BananaPay.Event.BaseEvent) => {
  if(event.confirmed) return false;
  else if(event.updatedAt.seconds + STUCK_THRESHOLD_SECONDS < new Date().getTime()/1000) return true;
  else return false;
};

export const getLastFiveMondays = () => {
  const dates = [];
  const timestamps: number[] = [];

  const currentDate = new Date();
  let currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  // Adjust currentDayOfWeek to Monday (1) by going back in time if needed
  while (currentDayOfWeek !== 1) {
    currentDate.setTime(currentDate.getTime() - millisecondsPerDay);
    currentDayOfWeek = currentDate.getDay();
  }

  // Set the time to midnight (0 hours, 0 minutes, 0 seconds, 0 milliseconds)
  currentDate.setHours(0, 0, 0, 0);

  // Get the last 5 Mondays
  for (let i = 0; i < 5; i++) {
    const date = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    const formattedDate = `${date} ${month}`;
    const timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

    dates.push(formattedDate);
    timestamps.push(timestampInSeconds);

    currentDate.setTime(currentDate.getTime() - 7 * millisecondsPerDay); // Go back 7 days (1 week)
  }

  return {
    dates: dates.reverse(),
    timestamps: timestamps.reverse()
  };
};