// Chakra imports
import { Flex, FormControl, Link, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useUser } from 'contexts/UserContext';
import { links } from 'helpers/links';
import { Notifications } from 'helpers/types';
import NotificationsPanel from '../../newPlan/components/NotificationsPanel';
import SwitchField from 'components/fields/SwitchField';


export default function Marketplace(props: { displayInLibrary: boolean, setDisplayInLibrary: (newStatus: boolean) => void, [x: string]: any }) {
	const { displayInLibrary, setDisplayInLibrary, ...rest } = props;
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	const { user } = useUser();

	return (
		<FormControl>
			<Card mb='20px' {...rest}>
				<Flex direction='column' mb='30px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Marketplace
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Do you want to publicly display this subscription in BananaPay Marketplace - anyone will see it and be able to subscribe. You will still see it since you are the owner.
					</Text>
				</Flex>
				<SwitchField
          isChecked={displayInLibrary}
          onChange={() => setDisplayInLibrary(!displayInLibrary)}
          reversed={true}
          fontSize='sm'
          mb='10px'
          id='marketplace'
          label='Display my subscription in BananaPay Marketplace'
        />
			</Card>
		</FormControl>
	);
}
