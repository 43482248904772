// Chakra imports
import { Flex, Box, Icon, Select, Text, useColorModeValue } from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';

// Custom components
import Card from 'components/card/Card';
import { lineChartDataOverallRevenue, lineChartOptionsOverallRevenue } from 'variables/charts';

// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { useStats } from 'contexts/StatsContext';
import { ChannelData } from 'helpers/types';

export default function OverallRevenue(props: { stats: ChannelData, [x: string]: any }) {
	const { stats, ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const [chartData, setChartData] = useState<any>(null);
	const [chartOptions, setChartOptions] = useState<any>(null);
	const [length, setLength] = useState<"all"|"week"|"month">("all");
	
	const getChange = () => {
		if(stats){
			const today = [].concat(...Object.values(stats.total_sales))[[].concat(...Object.values(stats.total_sales)).length-1].sales?.total_sales_usd?.toFixed(2);;
			const yesterday = [].concat(...Object.values(stats.total_sales))[[].concat(...Object.values(stats.total_sales)).length-2].sales?.total_sales_usd?.toFixed(2);;
			const change  = Math.round(((today-yesterday)/yesterday)*100);
			return `${change || "-"}%`;
		}
		return "-%";
	}

	const getTotalRevenue = () => {
		if(stats){
			const today = [].concat(...Object.values(stats.total_sales))[[].concat(...Object.values(stats.total_sales)).length-1].sales?.total_sales_usd?.toFixed(2);
			return `${today}`;
		}
		return "-";
	}

	const getChartData = (obj: any) => {
		const result: any = {};

		Object.keys(obj).forEach((prop) => {
			obj[prop].forEach((item: any) => {
				const { timestamp, sales } = item;
				if (!result[timestamp]) {
					result[timestamp] = 0;
				}
				result[timestamp] += sales.totalSalesUsd;
			});
		});
		
		return Object.keys(result)
			.sort((a, b) => parseInt(a) - parseInt(b))
			.map((timestamp) => result[timestamp]);
	}

	const getXaxsis = () => {
		let longestArray: any = null;
		let maxLength = 0;

		Object.values(stats.sales_per_day).forEach((arr) => {
			if (arr.length > maxLength) {
				maxLength = arr.length;
				longestArray = arr;
			}
		});

		return {longestArray, maxLength};
	}

	useEffect(() => {
		if(stats) {
			let l = -Math.abs(getXaxsis().maxLength);
			if(length === "week" && l>=7) {
				l = -Math.abs(7);
			}else if(length === "month" && l>=30) {
				l = -Math.abs(30);
			}

			const data = [{
				name: "Total Revenue",
				data: [0]
			}, {
				name: "Revenue per Day",
				data: [0]
			}];

			const options = {
				chart: {
					toolbar: {
						show: false
					},
					dropShadow: {
						enabled: true,
						top: 13,
						left: 0,
						blur: 10,
						opacity: 0.1,
						color: '#4318FF'
					}
				},
				colors: [ '#4318FF', '#39B8FF' ],
				markers: {
					size: 0,
					colors: 'white',
					strokeColors: '#7551FF',
					strokeWidth: 3,
					strokeOpacity: 0.9,
					strokeDashArray: 0,
					fillOpacity: 1,
					// discrete: [],
					shape: 'circle',
					radius: 2,
					offsetX: 0,
					offsetY: 0,
					showNullDataPoints: true
				},
				tooltip: {
					theme: 'dark'
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth',
					type: 'line'
				},
				xaxis: {
					type: 'numeric',
					categories: [ 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB' ],
					labels: {
						style: {
							colors: '#A3AED0',
							fontSize: '12px',
							fontWeight: '500'
						}
					},
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false
					}
				},
				yaxis: {
					show: false
				},
				legend: {
					show: false
				},
				grid: {
					show: false,
					column: {
						color: [ '#7551FF', '#39B8FF' ],
						opacity: 0.5
					}
				},
				color: [ '#7551FF', '#39B8FF' ]
			};

			data[0].data = getChartData(stats.total_sales);
			data[1].data = getChartData(stats.sales_per_day);
			options.xaxis.categories = getXaxsis().longestArray.map((x: any) => {
				const d = new Date(x.timestamp);
				return `${d.getDate()}/${d.getMonth()+1}`;
			});

			const x = data[0].data.splice(l);
			const y = data[1].data.splice(l);
			const z = options.xaxis.categories.splice(l);
			data[0].data = x;
			data[1].data = y;
			options.xaxis.categories = z;

			console.log(l, length)

			setChartData(data);
			setChartOptions(options);
		}
	}, [stats, length]);

	useEffect(() => {
		console.log(chartData);
	}, [chartData])

	return (
		<Card
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
			w='100%'
			mb={{ base: '20px', lg: '0px' }}
			{...rest}>
			<Flex justify='space-between' px='20px' pt='5px' w='100%'>
				<Flex align='center' w='100%'>
					<Flex flexDirection='column' me='20px'>
						<Text color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
							${getTotalRevenue()}
						</Text>
						<Text color='secondaryGray.600' fontSize='sm' fontWeight='500' mt='4px'>
							Overall Revenue
						</Text>
					</Flex>
					<Flex align='center'>
						<Icon as={RiArrowUpSFill} color='green.500' me='2px' />
						<Text color='green.500' fontSize='sm' fontWeight='700'>
							{getChange()}
						</Text>
					</Flex>
				</Flex>
				<Select
					onChange={e => setLength(e.target.value==="week" ? "week" : (e.target.value==="month" ? "month" : "all"))}
					value={length}
					fontSize='sm'
					variant='subtle'
					width='min-content'
					fontWeight='700'
				>
					<option value='week'>Last Week</option>
					<option value='month'>Last Month</option>
					<option value='all'>All</option>
				</Select>
			</Flex>
			<Box minH='260px' mt='auto' w='100%'>
				{chartData && chartOptions ? (
					<LineChart chartData={chartData} chartOptions={chartOptions} />
				):null}
			</Box>
		</Card>
	);
}
