import { useState, useMemo, useEffect } from "react";
import { Box, Center, Flex, Select, Spinner, Text, useColorModeValue, useToast } from "@chakra-ui/react"
import { capitalizeFirstLetter, createLinkForDashboard, getToast, useQuery } from "helpers/formatters";
import { useHistory } from "react-router-dom";
import { useApps } from "contexts/AppsContext";
import { useUser } from "contexts/UserContext";
import { BananaPay } from "bananapay-types";
import { links } from "helpers/links";
import Card from 'components/card/Card';
import { useStats } from "contexts/StatsContext";
import LinksTable from "./components/LinksTable";
import DefaultLinks from "./components/DefaultLinks";
import GetById from "./components/GetById";
import Loading from "components/loading/Loading";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";

type RowObj = {
	type: string;
	layout: string
	status: string;
	plan: string;
	views: number;
	url: string;
	id: string;
	phone: string;
	email: string;
	after: string;
	customFields: number;
	customText: string;
	tosCollection: string;
	promotionsCollection: string;
	actions: [string, string];
};

const PaymentLinksPage = () => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

  const query = useQuery();
	const history = useHistory();
	const { user } = useUser();
	const { getAppDetails, apps } = useApps();
	const toast = useToast();
	const { isLoading, getPaymentLinks } = useStats();

	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [paymentLinks, setPaymentLinks] = useState<BananaPay.PaymentLink[]>([]);
	const [tableData, setTableData] = useState<RowObj[]>([]);

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	useEffect(() => {
		if(paymentLinks && app) {
			const data: RowObj[] = paymentLinks.map((x) => {
				return{
					type: capitalizeFirstLetter(x.type),
					layout: capitalizeFirstLetter(x.layout),
					status: x.active ? "Active" : "Inactive",
					plan: app?.paymentOptions[x.line_items[0].id]?.name,
					url: x.url,
					id: x.id,
					views: x.views,
					phone: x.phone_number_collection.enabled ? "Required" : "None",
					email: capitalizeFirstLetter(x.email_collection.enabled),
					after: x.after_completion.type === "hosted_confirmation" ? "Hosted" : "Redirect",
					customFields: x.custom_fields.length,
					customText: x.custom_text.submit?.message,
					tosCollection: x.consent_collection?.terms_of_service?.required ? "Required" : "None",
					promotionsCollection: capitalizeFirstLetter(x.consent_collection?.promotions || "none"),
					actions: [app.subscription_id, x.id],
				}
			});
			setTableData(data);
		}
	}, [paymentLinks, app]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;
	
	const fetchLinks = async(startAfter: string) => {
		const res = await getPaymentLinks(app.subscription_id, startAfter);
		const _paymentLinks = res[app.subscription_id];
		console.log(_paymentLinks);
		setPaymentLinks(_paymentLinks.paymentLinks);
		setHasMore(_paymentLinks.hasMore);
		setIsLoaded(true);
	}

	const getActiveNumber = (_paymentLinks: BananaPay.PaymentLink[]) => {
		let active = 0;

		_paymentLinks.map(x => {
			if(x.active) {
				active += 1;
			}
		});

		return active;
	}

  useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
        (async function () {
          // get payment links
					await fetchLinks("");
        })();
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.subscriptionPaymentLinks}_subscriptionId=${subscriptionId}` : `${links.signInCentered}?to=${links.subscriptionPaymentLinks}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user]);

  return(
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }} px="2">
			<BreadCrumpStrip
				additional={{mb: "4"}}
				links={[{
					href: `/#${createLinkForDashboard(app?.subscription_id)}`,
					name: "Dashboard"
				},{
					href: `/`,
					name: "All Payment Links"
				}]}
			/>

			{app?.displayInMarketplace ? (
				<Box mb="8">
					<DefaultLinks />
				</Box>
			):null}

			<Card>
				<Box px="4">
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
						<b>All </b> Payment Links
					</Text>
					<Text color={textColorSecondary} fontSize='md' me='26px' mb='18px'>
						All your payment links (create with API or not) in one place!
					</Text>
				</Box>
				{isLoaded && paymentLinks.length>0 ? (
					<LinksTable
						tableData={tableData}
						activeLinks={getActiveNumber(paymentLinks).toString()}
						hasMore={hasMore}
						fetchMore={() => fetchLinks(paymentLinks[paymentLinks.length-1].id)}
						placeholder="Find in the table"
						search={false}
					/>
				):null}
				{paymentLinks.length===0 && isLoaded ? (
					<Text>No Payment Links have been created yet.</Text>
				):null}
				{!isLoaded ? (
					<Loading text="Loading Payment Links ..." />
				):null}
			</Card>

			<Box mt="8">
				<GetById subscriptionId={app?.subscription_id} />
			</Box>
		</Box>
  );
};

export default PaymentLinksPage;