import { Box, Button, Icon, Link, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import Card from 'components/card/Card';
import { MdEdit } from "react-icons/md";
import { links } from "helpers/links";
import { createLinkForAllEventsStats, createLinkForAnalytics, createLinkForEditApp, createLinkForSubscribersOverview, createLinkForSubscriptionPaymentLinks, hexToChain } from "helpers/formatters";
import { IoAnalytics } from "react-icons/io5";
import { BsEyeFill, BsFillBellFill, BsFillPersonFill } from "react-icons/bs";
import { createLinkForApp } from "helpers/formatters";
import { TbBooks } from "react-icons/tb";
import { BananaPay } from "bananapay-types";
import { FiLink } from "react-icons/fi";


const General = (props: { app: BananaPay.App }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.500', 'gray.300');

  const { app } = props;

  return (
    <Box mt="4">
      <Text mb="2" fontSize={"2xl"} fontWeight={"extrabold"} color={textColorSecondary}>General</Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            Subscription Page:
          </Text>
          <Link href={`/#${createLinkForApp(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={BsEyeFill} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              View
            </Button>
          </Link>
        </Card>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            Edit Subscription:
          </Text>
          <Link href={`/#${createLinkForEditApp(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={MdEdit} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              Edit
            </Button>
          </Link>
        </Card>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            See Analytics:
          </Text>
          <Link href={`/#${createLinkForAnalytics(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={IoAnalytics} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              Analytics
            </Button>
          </Link>
        </Card>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            See Subscribers:
          </Text>
          <Link href={`/#${createLinkForSubscribersOverview(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={BsFillPersonFill} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              Subscribers
            </Button>
          </Link>
        </Card>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            See All Events:
          </Text>
          <Link href={`/#${createLinkForAllEventsStats(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={BsFillBellFill} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              All Events
            </Button>
          </Link>
        </Card>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            Payment Links
          </Text>
          <Link href={`/#${createLinkForSubscriptionPaymentLinks(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={FiLink} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              Manage
            </Button>
          </Link>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default General;