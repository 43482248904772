import { useState } from "react";
import { Box, Button, Center, Flex, Icon, SimpleGrid, Spinner, Tag, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { BananaPay } from "bananapay-types";
import Card from 'components/card/Card';
import { capitalizeFirstLetter, createDateString, getToast } from "helpers/formatters";
import { useApps } from "contexts/AppsContext";
import { FaCopy } from "react-icons/fa";
import { BiLink } from "react-icons/bi";


const EndpointDetails = (props: { webhook: BananaPay.Webhook, close: () => void }) => {

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.600';

  const { webhook, close } = props;

  const toast = useToast();
  const { changeWebhookState, deleteWebhook } = useApps();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeState = async() => {
    setIsLoading(true);
    const newWebhook = {...webhook};
    if(webhook.status === "enabled") {
      newWebhook.status = "disabled";
    }else{
      newWebhook.status = "enabled";
    }
    await changeWebhookState(newWebhook);
    setIsLoading(false);
  };

  const handleDelete = async() => {
    setIsLoading(true);
    await deleteWebhook(webhook);
    setIsLoading(false);
  };


  return(
    <Card mt="8">
      {!isLoading ? (
        <Box>
          <Flex>
            <Text fontSize='2xl' fontWeight='900' color={textColorPrimary}>
              {webhook.name}
            </Text>
            <Tag ml="4" mt="1.5" size={"md"} h="fit-content" variant='solid' colorScheme={webhook.status === "enabled" ? "green": "red"}>
              {capitalizeFirstLetter(webhook.status)}
            </Tag>
          </Flex>
          <Text fontSize='md' color={textColorPrimary}>
            {webhook.description}
          </Text>

          <Text fontSize='sm' mt="3" fontWeight={"bold"} color={textColorSecondary}>
            Created: {createDateString(webhook.created)}
          </Text>
          <Text fontSize='sm' fontWeight={"bold"} color={textColorSecondary}>
            Webhook Endpoint ID: {webhook.id}
          </Text>
          <Text fontSize='sm' fontWeight={"bold"} color={textColorSecondary}>
            API Version: {webhook.api_version}
          </Text>

          <Box>
            <Text fontSize='lg' mt="6" fontWeight={"bold"} color={textColorPrimary}>
              Enabled Events:
            </Text>
            <SimpleGrid columns={{ base: 2, md: 3, lg: 5, '2xl': 6 }} gap="10px" mb='20px'>
              {webhook.enabled_events.map((event) => {
                return(
                  <Tag size="lg" variant={"solid"}>{event}</Tag>
                );
              })}
            </SimpleGrid>
          </Box>

          <Box>
            <Text fontSize='lg' mt="6" fontWeight={"bold"} color={textColorPrimary}>
              Endpoint URL:
            </Text>
            <Flex>
              <Icon as={BiLink} h='22px' w='22px' mr="5px" color={textColorSecondary} />
              <Text fontSize='md' fontWeight={"bold"} color={textColorSecondary}>
                URL:
              </Text>
              <Text fontSize='md' ml="2" fontWeight={"bold"} color={textColorSecondary}>
                {webhook.url}
              </Text>
            </Flex>
          </Box>

          <Text fontSize='lg' mt="6" fontWeight={"bold"} color={textColorPrimary}>
            Actions:
          </Text>
          <Flex>
            <Button
              mr="2"
              onClick={() => toast(getToast("info", "Info", "At the moment webhook can only be updated via BananaPay API - look into the docs for instructions."))}
            >
              Update
            </Button>
            <Button mr="2" variant="brand" onClick={handleChangeState}>
              {webhook.status === "enabled" ? "Disable" : "Enable"}
            </Button>
            <Button variant="darkBrand" onClick={handleDelete}>
              Delete
            </Button>
          </Flex>

          <Box>
            <Text fontSize='lg' mt="6" fontWeight={"bold"} color={textColorPrimary}>
              Deliveries:
            </Text>
            <Text fontSize='md' fontWeight={"bold"} color={textColorSecondary}>
              Successful: {webhook.deliveries.success}
            </Text>
            <Text fontSize='md' fontWeight={"bold"} color={textColorSecondary}>
              Failed: {webhook.deliveries.fail}
            </Text>
            <Text fontSize='md' fontWeight={"bold"} color={textColorSecondary}>
              Fail Rate: {((webhook.deliveries.fail / webhook.deliveries.success * 100).toFixed(2)) || "0"}%
            </Text>
          </Box>

          <Box>
            <Text fontSize='lg' mt="6" fontWeight={"bold"} color={textColorPrimary}>
              Signing Secret
            </Text>
            <Text fontSize='md' color={textColorPrimary}>
              To prevent processing illegitimate data use this signing secret to verify webhook payloads on your server. To learn how to do this please take a look into BananaPay Docs and the code example below.
            </Text>
            <Button mt="2" mb="1" onClick={() => {
              navigator.clipboard.writeText(webhook.secret)
              toast(getToast("success", "Copied!", "Copied Signing Secret."));
            }}>
              <Icon as={FaCopy} h='18px' w='18px' mr="10px" />
              Copy Signing Secret
            </Button>
            <Text fontSize='sm' fontWeight={"bold"} color={textColorSecondary}>
              *Keep this secret PRIVATE and stored securely
            </Text>
          </Box>

          <Flex mt="4" justifyContent={"end"}>
            <Button
              variant={"brand"}
              onClick={close}
            >
              Close
            </Button>
          </Flex>
        </Box>
      ):(
        <Center mx="auto" flexDirection={"column"} my="8">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='brand.500'
            size="xl"
            mx="auto"
          />
          <Text textAlign={"center"}>Loading, please wait . . .</Text>
        </Center>
      )}
    </Card>
  );
};

export default EndpointDetails;