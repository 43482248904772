import { Box, Flex, Icon, Link, SimpleGrid, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import ChainTag from "components/chain/ChainTag";
import Card from 'components/card/Card';
import SubscriptionStatusTag from "components/other/SubscriptionStatusTag";
import { HSeparator } from "components/separator/Separator";
import { getEllipsisTxt } from "helpers/formatters";
import { getChain } from "helpers/chains";
import { MdOutlineContentCopy } from "react-icons/md";
import { BananaPay } from "bananapay-types";


const ContactPanel = (props: { app: BananaPay.App }) => {
  const { app } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';
	const textColorLink = useColorModeValue('blue.500', 'white');

  const toast = useToast();

  return(
    <Box px="8" mx="2" pb="8">
      <Card>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          {app.name}'s Details
        </Text>
        <Text color={textColorSecondary} fontSize='md' me='26px' mb='25px'>
          All {app.name}'s details you will need:
        </Text>
      </Card>

      <SimpleGrid gap="20px" mt="8" columns={{ base: 1, md: 2 }}>
        <Card mb="15px">
          <Text color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            ADDRESSES:
          </Text>
          {Object.entries(app.receivers).map(([index, receiver]) => {
            return(
              <Box key={index}>
                <Flex
                  align='center'
                  mb='14px'
                  cursor='pointer'
                  onClick={function() {
                    navigator.clipboard.writeText(receiver.address);
                    toast({
                      title: `Address copied!`,
                      position: 'top',
                      status: 'success',
                      isClosable: true
                    });
                  }}>
                  <Text color='secondaryGray.600' fontSize='lg' fontWeight='400' mx='4px'>
                    {getEllipsisTxt(receiver.address, 4)}
                  </Text>
                  <Icon as={MdOutlineContentCopy} h='14px' w='14px' color='secondaryGray.600' mt='6px' />
                </Flex>
                <Box h="14px" w="14px">
                  {getChain(receiver.chain).logo}
                </Box>
              </Box>
            )
          })}
        </Card>

        <Card mb="15px">
          <Text color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            EMAIL:
          </Text>
          <Text color={textColorPrimary}>
            {app.appEmail}
          </Text>
        </Card>

        <Card mb="15px">
          <Text color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            WEBSITE:
          </Text>
          <Link href={app.website} target={"_blank"}>
            <Text color={textColorLink} fontWeight='500'>
              {app.website}
            </Text>
          </Link>
        </Card>

        <Card mb="15px">
          <Text color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            YOUR STATUS:
          </Text>
          <Box mt="5px" ml="-5px">
            <SubscriptionStatusTag isSubscribed={false} />
          </Box>
        </Card>
      </SimpleGrid>
      
    </Box>
  )
};

export default ContactPanel;