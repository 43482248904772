import { Box, Text } from "@chakra-ui/react";
import SwitchField from 'components/fields/SwitchField';

const PublishPanel = (props: { isPublic: boolean, setIsPublic: (newState: boolean) => void }) => {
  const { isPublic, setIsPublic } = props;

  return(
    <Box textAlign={"center"}>
      <Text fontSize={"3xl"} fontWeight="extrabold">Wohooo! All Done!</Text>
      <Text fontSize={"lg"}>Click Create! to create your subscription!</Text>
      <SwitchField
        mx="auto"
        isChecked={isPublic}
        onChange={() => setIsPublic(!isPublic)}
        reversed={true}
        fontSize='sm'
        mt="35px"
        mb='5px'
        id='4'
        label="Publish this subscription in BananaPay Subscriptions marketplace (can be changed later)"
      />
      <Text fontSize={"sm"} color={"secondaryGray.600"} textAlign={"left"} mb="15px">*it is recommended to make it public if your service for which users would be paying is public, for testing purposes make it private.</Text>
    </Box>
  );
};

export default PublishPanel;