import { Box, Icon, Image, Input, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { MdOutlineCloudUpload } from "react-icons/md";
import Dropzone from "./Dropzone";

const MediaPanel = (props: {
  logo: string;
  setLogo: (newLogo: string) => void;
  banner: string;
  setBanner: (newBanner: string) => void;
}) => {
  const { logo, setLogo, banner, setBanner } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brand = useColorModeValue('brand.500', 'brand.400');
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return(
    <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
      <Stack direction='column' gap='20px'>
        <Box>
          <Text fontSize='sm' fontWeight='bold' pb="2">
            Subscription Logo:
          </Text>
          <Dropzone
            file={logo || ""}
            onChange={(e: string) => setLogo(e)}
            content={
              <Box>
                {logo ? (
                  <Image
                    src={logo}
                    h='80px'
                    alt="Uploaded logo"
                    mb="2"
                    mx="auto"
                  />
                ):(
                  <Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
                )}                <Text
                  mx='auto'
                  mb='12px'
                  fontSize='lg'
                  fontWeight='700'
                  whiteSpace='pre-wrap'
                  color={textColor}>
                  Drop your files here, or{' '}
                  <Text as='span' fontSize='lg' fontWeight='700' color={brand}>
                    browse
                  </Text>
                </Text>
                <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                  PNG, JPG and GIF files are allowed
                </Text>
              </Box>
            }
          />
        </Box>
      </Stack>
      <Stack direction='column' gap='20px'>
        <Box>
          <Text fontSize='sm' fontWeight='bold' pb="2">
            Subscription Banner:
          </Text>
          <Dropzone
            file={banner || ""}
            onChange={(e: string) => setBanner(e)}
            content={
              <Box>
                {banner ? (
                  <Image
                    src={banner}
                    h='80px'
                    alt="Uploaded banner"
                    mb="2"
                    mx="auto"
                  />
                ):(
                  <Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
                )}
                <Text
                  mx='auto'
                  mb='12px'
                  fontSize='lg'
                  fontWeight='700'
                  whiteSpace='pre-wrap'
                  color={textColor}>
                  Drop your files here, or{' '}
                  <Text as='span' fontSize='lg' fontWeight='700' color={brand}>
                    browse
                  </Text>
                </Text>
                <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                  PNG, JPG and GIF files are allowed
                </Text>
              </Box>
            }
          />
        </Box>
      </Stack>
    </SimpleGrid>
  );
};

export default MediaPanel;