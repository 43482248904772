import { FaBell, FaBellSlash, FaExclamation, FaHandHoldingUsd } from "react-icons/fa";
import { HiOutlineGift } from "react-icons/hi";
import { TiCancel } from "react-icons/ti";
import { VscDebugRestart } from "react-icons/vsc";

export const EventIcon = (props: { eventName: string, size?: string }) => {
  const { eventName, size="2rem" } = props;
  switch(eventName) {
    case "Subscribed":
      return <FaBell fontSize={size} color={"white"} />
    case "PaymentExecuted":
      return <FaHandHoldingUsd fontSize={size} color={"white"} />
    case "PaymentFailed":
      return <FaExclamation fontSize={size} color={"white"} />
    case "SubscriptionCanceled":
      return <TiCancel fontSize={size} color={"white"} />
    case "SubscriptionRestored":
      return <VscDebugRestart fontSize={size} color={"white"} />
    case "IntervalsGiven":
      return <HiOutlineGift fontSize={size} color={"white"} />
    default:
      return <FaBellSlash fontSize={size} color={"white"} />
  }
};

export const getIconColor = (eventName: any) => {
  switch(eventName) {
    case "Subscribed":
      return "blue.600"
    case "PaymentExecuted":
      return "green.700"
    case "PaymentFailed":
      return "red.600"
    case "SubscriptionCanceled":
      return "blue.600"
    case "SubscriptionRestored":
      return "blue.600"
    case "IntervalsGiven":
      return "purple.500"
    default:
      return "blue.600"
  }
};