// Chakra imports
import { Flex, FormControl, Link, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { links } from 'helpers/links';
import AdvancedPanel from '../../newPlan/components/AdvancedPanel';


export default function Advanced(props: {
  isEnabled: boolean;
  setIsEnabled: (newStatsu: boolean) => void;
}) {
	const { isEnabled, setIsEnabled } = props;
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	return (
		<FormControl>
			<Card mb='20px'>
        <Flex direction='column' mb='30px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Advanced
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
          	BananaPay allows you to get real-time data about users subscriptions to your server and database - BananaPay webhooks. Read more about this in <Link isExternal href={links.docsLink}>docs</Link>.
					</Text>
				</Flex>
				<AdvancedPanel
          showText={false}
          isEnabled={isEnabled}
          setIsEnabled={setIsEnabled}
        />
			</Card>
		</FormControl>
	);
}
