import { useEffect, useState } from "react";
import { Box, Button, Center, Flex, Grid, Icon, Link, Select, Spinner, Text, toast, useColorModeValue, useToast } from "@chakra-ui/react";
import { useUser } from "contexts/UserContext";
import { chainToHex, chainToNumber, createLinkForEvent, getToast, hexToChain, useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { useHistory } from "react-router-dom";
import Card from 'components/card/Card';
import { BiLogOut } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import NoUser from "./components/NoUser";
import { BsClockHistory } from "react-icons/bs";
import EventsTable from "./components/EventsTable";
import IconBox from "components/icons/IconBox";
import { useApps } from "contexts/AppsContext";
import { useStats } from "contexts/StatsContext";
import { EventTypes } from "helpers/types";
import { allChains } from "helpers/chains";
import ChainLogo from "components/chain/ChainLogo";
import { v4 as uuidv4 } from "uuid";
import { useEvents } from "contexts/EventsContext";
import EventsCalendar from "components/event/Calendar";
import { BananaPay } from "bananapay-types";


type RowObj = {
  type: EventTypes;
  chain: string;
  date: string; 
  appName: string;
  amount: string;
  plan: string;
  actions: string;
};


const AllUpcomingEventsPage = () => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

  const { user } = useUser();
  const { getAllUpcomingEvents, events, getSubscriptionPlan } = useEvents();
	const { apps, getAppDetailsFromAddressAndChain } = useApps();
	const history = useHistory();
	const query = useQuery();

	const [tableData, setTableData] = useState<RowObj[] | null>(null);
	const [chain, setChain] = useState<"tbnb"|"ftm"|"mumbai">("mumbai");

  const getJoinDate = (d: Date) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()} - ${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`;
	}

	const updateChainUrl = (chain: string) => {
    history.push({
      search: `?chain=${chain}`
    });
	}

	useEffect(() => {
		if(events && apps) {
      (async () => {
				const allUpcomingEvents = await getAllUpcomingEvents();
        const data : RowObj[] = [];

				for(const chain in allUpcomingEvents) {
					for(const appAddress in allUpcomingEvents[chain]) {
						const upcomingEvents = allUpcomingEvents[chain][appAddress];
						const app = getAppDetailsFromAddressAndChain(appAddress, chainToHex(chainToNumber(chain)));

						upcomingEvents.map((x) => {
              let amountString = "-";
              let pricingPlan: BananaPay.App.PaymentOption | null = null;

              if(x.name !== "Unsubscribed") {
                const data = getSubscriptionPlan(app);
                const amount = Number(x?.paymentAmount) / (10**(data?.paymentToken?.token?.decimals || 18));
                amountString = `${amount || "-"} ${data?.paymentToken?.token?.ticker || "tokens"}`;
                pricingPlan = data?.plan;
              }
            
              data.push({
                type: x.name,
                appName: app?.name || "Subscription",
                chain: hexToChain(chainToHex(x.chainId)).charAt(0).toUpperCase() + hexToChain(chainToHex(x.chainId)).slice(1),
                plan: pricingPlan?.name || "-", 
                date: getJoinDate(new Date(x.updatedAt.seconds*1000)),
                actions: `/#${createLinkForEvent(x, true)}`,
                amount: amountString
              });
            });
          }
        }
        setTableData(data);
      })();
		}
	}, [events, apps]);

	useEffect(() => {
		updateChainUrl(chain);
	}, [chain]);

	useEffect(() => {
		const CHAIN_URL = query.get("chain");
		if(CHAIN_URL) {
			const _chain = CHAIN_URL === "mumbai" ? "mumbai" : (CHAIN_URL === "ftm" ? "ftm" : "tbnb");
			setChain(_chain);
		}else{
			updateChainUrl(allChains[0].ticker);
		}
	}, [query]);

  return(
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }} px="2">
      {user ? (
        <>
          <Card>
            <Flex justifyContent={"space-between"} flexDirection={{ sm: "column", "2sm": "column", lg: "row", base: "row" }}>
              <Box>
                <Text textAlign={{ sm: "center", "2sm": "center", lg: "left", base: "left" }} fontSize={{ sm: "xl", "2sm": "xl", lg: "2xl", base: "2xl" }}  fontWeight={"normal"} color={textColorPrimary}>Looking for <b>past events</b> instead ...</Text>
              </Box>
              <Box mx={{ sm: "auto", "2sm": "auto", lg: "0", base: "0" }} mt={{ sm: "3", "2sm": "3", lg: "3", base: "-4" }}>
                <Link href={`/#${links.allUserEvents}`}>
                  <Button
                    mr="2"
                    mt="-4"
                  >
                    <Icon as={BsClockHistory} color='secondaryGray.500' h='18px' w='18px' mr="10px" />
                    All Past Events
                  </Button>
                </Link>
              </Box>
            </Flex>
          </Card>
          <Card mt="8">
            <Box px="4">
              <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
                All Upcoming Events
              </Text>
              <Text color={textColorSecondary} fontSize='md' me='26px' mb='18px'>
                To make your experience even better we have created a list of upcoming payments for you subscriptions!
              </Text>
              <Flex pb="25px">
                <IconBox
                  w='46px'
                  h='46px'
                  mr="5px"
                  icon={<ChainLogo width='46px' height='46px' chain={chain} />}
                />
                <Select
                  id='chain'
                  value={chain}
                  onChange={e => setChain(e.target.value==="ftm" ? "ftm":(e.target.value==="tbnb" ? "tbnb":"mumbai"))}
                  variant={"filled"}
                  mt="1"
                  w="fit-content"
                >
                  {allChains.map(chain => {
                    return(
                      <option key={uuidv4()} value={chain.ticker}>{chain.name}</option>
                      )
                    })}
                </Select>
              </Flex>
            </Box>
            {tableData ? (
              <EventsTable tableData={tableData}  />
            ):(
              <Center mx="auto" flexDirection={"column"} my="8">
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='brand.500'
                  size="xl"
                  mx="auto"
                />
                <Text textAlign={"center"}>Loading Data . . .</Text>
              </Center>
            )}
          </Card>
          <Grid
            mt="8"
            gap={{ base: '20px', xl: '20px' }}
            gridArea={"1"}
            display={"grid"}
          >
            <EventsCalendar />
          </Grid>
        </>
      ):(
        <NoUser />
      )}
    </Box>
  );
};

export default AllUpcomingEventsPage;