import {
	Box,
	Grid,
	Image,
	SimpleGrid,
	Icon,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import Conversion from './components/Conversion';
import UserActivity from './components/UserActivity';
import UserReportsTable from './components/UserReportsTable'; 

// Assets
import FakeLineGraph from 'assets/img/users/FakeLineGraph.png';
import { MdPerson } from 'react-icons/md';
import { useEffect, useMemo, useState } from 'react';
import { BananaPay } from 'bananapay-types';
import { chainToHex, createLinkForCustomerOverview, createLinkForDashboard, createLinkForPaymentLinkOverview, createLinkForSubscriptionPaymentLinks, displayTime, getToast, hexToChain, useQuery } from 'helpers/formatters';
import { useApps } from 'contexts/AppsContext';
import { useUser } from 'contexts/UserContext';
import { useHistory } from 'react-router-dom';
import { useStats } from 'contexts/StatsContext';
import { links } from 'helpers/links';
import Loading from 'components/loading/Loading';
import { AiOutlineEye } from 'react-icons/ai';
import { BiDollar } from 'react-icons/bi';
import { useEvents } from 'contexts/EventsContext';
import EventsTableLink from './components/EventsTableLink';
import { getTokenDetailsForChain, getTokenFromAddressChain } from 'variables/tokens';
import BreadCrumpStrip from 'components/breadcrumb/Breadcrumb';


type RowObj = {
	address: string; 
	chain: string;
	status: boolean;
  token: [string, string, number, boolean];
	date: string;
	id: string;
  actions:string;
	[x: string]: any;
};

type RowObjEvents = {
  type: string;
  chain: string;
  date: string; 
  address: string;
  amount: string;
  confirmed: string;
  actions: string;
};

export default function LinkDetails() {
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const [isLoaded, setIsLoaded] = useState(false);
	const [customers, setCustomers] = useState<BananaPay.Customer[] | null>([]);
	const [paymentLink, setPaymentLink] = useState<BananaPay.PaymentLink | null>(null);
	const [tableData, setTableData] = useState<RowObj[]>([]);
	const [tableDataEvents, setTableDataEvents] = useState<RowObjEvents[]>([]);
	const [allEvents, setAllEvents] = useState<BananaPay.Event.BaseEvent[]>([]);

	const query = useQuery();
	const { getAppDetails, apps } = useApps();
	const { user } = useUser();
	const { getEventsForPaymentLink, events, getSubscriptionPlan } = useEvents();
	const history = useHistory();
	const toast = useToast();
	const { getCustomersForLinkId, getPaymentLinksByIds } = useStats();

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	const totalRevenue = () => {
		// calculate how many payments have been made
		let totalSuccessfulPayments = 0;	
		customers.forEach((customer) => {
			const successfulPayments = customer.events.event_count.payment.successful;
			totalSuccessfulPayments += successfulPayments;
		});
	
		// get pricing plan of this link
		const plan = app.paymentOptions[paymentLink.line_items[0].id];

		// if usd
		if(plan.useUsdValue) {
			return {
				title: "Total Revenue",
				value: `$${(totalSuccessfulPayments*plan.usdValue).toFixed(2)}`,
				plan: plan
			}
		}else{
			return {
				title: "Total Payments",
				value: totalSuccessfulPayments,
				plan: plan
			}
		}
	}

	const getJoinDate = (d: Date) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
	}

	useEffect(() => {
		if(customers && app && events && paymentLink) {
			// get all events for this payment link
			setAllEvents(getEventsForPaymentLink(customers, paymentLink));

			const data: RowObj[] = customers.map((x) => {
				const plan = app?.paymentOptions[x.subscription.plan_id[x.subscription.plan_id.length-1]];
				const token = x.subscription.token[x.subscription.token.length-1];
				let paymentAmount = plan.useUsdValue ? plan.usdValue : 0;
				if(!plan.useUsdValue) {
					plan.paymentTokens.map(_token => _token.token.address[x.chain].toLowerCase() === token.address[x.chain].toLowerCase() ? paymentAmount = _token.amount : null)
				}

				// declare additional fields to display data in table for values unique to this link
				const additionalFields: { [x: string]: any } = {}
				
				// add custom fields values to table
				x.custom_fields[x.events.subscribed_events[x.events.subscribed_events.length-1]].map(field => {
					additionalFields[field.key] = field.value;
				});
				// add email and others to table
				additionalFields["email"] = x.email;
				additionalFields["phone"] = x.phone;
				additionalFields["promotions"] = x.consent_collection[x.consent_collection.length-1].promotions.accepted ? "Accepted" : "Declined";
				additionalFields["terms-of-service"] = x.consent_collection[x.consent_collection.length-1].terms_of_service.accepted ? "Accepted" : "Declined";

				return {
					address: x.address,
					chain: hexToChain(x.chain).charAt(0).toUpperCase() + hexToChain(x.chain).slice(1),
					status: x.subscription.subscribed,
					date: getJoinDate(new Date(x.subscription.subscribed_at[x.subscription.subscribed_at.length-1] * 1000)),
					actions: `/#${createLinkForCustomerOverview(app.subscription_id, x.id)}`,
					token: [token.logo, `${token.ticker}/${displayTime(plan.paymentInterval, false)}`, paymentAmount, plan.useUsdValue],
					email: x.email,
					username: x.name,
					id: x.id,
					...additionalFields
				}
			});
			setTableData(data);
		}
	}, [customers, app, events, paymentLink]);

	useEffect(() => {
		if(allEvents.length>0) {
			const data: RowObjEvents[] = allEvents.map((x) => {
				let amountString = "-";
				
				if(x.name !== "Unsubscribed" && x.name !== "SubscriptionCanceled" && x.name !== "ExtensionCall") {
					const event = x as BananaPay.Event.Payment.Successful;
					const paymentToken = getTokenFromAddressChain(chainToHex(x.chainId), event.paymentToken);
					const tokenDecimals = paymentToken?.decimals || 18;
					const tokenTicker = paymentToken?.ticker || "tokens";
					const amount = Number(event.paymentAmount) / (10**tokenDecimals); // @ts-ignore
					amountString = `${amount || "-"} ${tokenTicker}`;
				}
			
				return{
					type: x.name,
					address: x.subscriber,
					chain: hexToChain(chainToHex(x.chainId)).charAt(0).toUpperCase() + hexToChain(chainToHex(x.chainId)).slice(1),
					confirmed: x.confirmed ? "Yes" : "No",
					date: getJoinDate(new Date(x.updatedAt.seconds*1000)),
					actions: `/#${links.eventDetails}?eventId=${x.id}`,
					amount: amountString
				}
			});
			setTableDataEvents(data);
		}
	}, [allEvents]);

	useEffect(() => {
		console.log(tableDataEvents);
	}, [tableDataEvents])

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;
	
	const fetchCustomers = async(linkId: string) => {
		const res = await getCustomersForLinkId(app.subscription_id, linkId);
		setCustomers(res);
	}

	const fetchLink = async(linkId: string) => {
		const res = await getPaymentLinksByIds(app.subscription_id, [linkId]);
		setPaymentLink(res.paymentLinks[0]);
	}

  useEffect(() => {
		if(user){
			const linkId = query.get("linkId");
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(!linkId) {
				console.log("INVALID")
				//redirect("Invalid Link ID!", "Something went wrong, please try again or contact support.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
        (async function () {
          // get payment links
					await fetchCustomers(linkId);
					await fetchLink(linkId);
        })();
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const linkId = query.get("linkId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.linkDetails}_subscriptionId=${subscriptionId}_linkId=${linkId}` : `${links.signInCentered}?to=${links.linkDetails}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user, query]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<BreadCrumpStrip
				additional={{mb: "4"}}
				links={[{
					href: `/#${createLinkForDashboard(app?.subscription_id)}`,
					name: "Dashboard"
				},{
					href: `/#${createLinkForSubscriptionPaymentLinks(app?.subscription_id)}`,
					name: "All Payment Links"
				}, {
					href: `/`,
					name: "Payment Link Details"
				}]}
			/>
			{paymentLink ? (
				<Box>
					<SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='20px' mb='20px'>
						<MiniStatistics
							startContent={
								<IconBox
								w='56px'
								h='56px'
								bg={boxBg}
								icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
								/>
							}
							name={"Total Subscribers"}
							value={customers?.length || 0}
							/>
						<MiniStatistics
							startContent={
								<IconBox
								w='56px'
								h='56px'
								bg={boxBg}
								icon={<Icon w='32px' h='32px' as={AiOutlineEye} color={brandColor} />}
								/>
							}
							endContent={
								<Text
								color={textColorSecondary}
									fontWeight='500'
									fontSize={{
										base: 'xs'
									}}
									me='10px'
									mt='4px'>
								</Text>
							}
							name="Views"
							value={paymentLink.views}
						/>
						<MiniStatistics
							startContent={
								<IconBox
								w='56px'
								h='56px'
								bg={boxBg}
								icon={<Icon w='32px' h='32px' as={BiDollar} color={brandColor} />}
								/>
							}
							name='Pricing Plan'
							value={totalRevenue().plan.name}
						/>
						<MiniStatistics
							endContent={<Image src={FakeLineGraph} />}
							name={totalRevenue().title}
							value={totalRevenue().value}
						/>
					</SimpleGrid>
					<Grid
						w='100%'
						display={{ base: 'flex', md: 'grid' }}
						flexDirection={{ base: 'column', md: 'unset' }}
						templateColumns={{ md: '1fr 2.6fr' }}
						templateRows={{ base: 'repeat(2, 1fr)', md: '1fr' }}>
						<Box me={{ base: '0px', md: '20px' }} mb={{ base: '20px', md: '0px' }} gridArea='1 / 1 / 2 / 2'>
							<Conversion mb='20px' customers={customers?.length} views={paymentLink.views} />
							<UserActivity customers={customers} />
						</Box>
						<Card gridArea={{ base: '2 / 1 / 3 / 2', md: '1 / 2 / 2 / 3' }} mb={{ base: '20px', md: '0px' }}>
							<Box px="3" mt="4px">
								<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='2px'>
									All Subscribers
								</Text>
								<Text color={textColorSecondary} fontSize='md' me='26px' mb='24px'>
									All Subscribers that have subscribed through this payment link. Keep in mind that all this subscribers are subscribed on the <b>same</b> pricing plan, that is <b>{totalRevenue().plan.name}</b>, they also have the <b>same metadata</b> (unless changed via API), that has been inherited from the payment link metadata.
								</Text>
							</Box>
							<UserReportsTable tableData={tableData} paymentLink={paymentLink} />
						</Card>
					</Grid>

					<Box mt="8">
						<Card>
							<Box px="3" mt="4px">
								<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='2px'>
									All Events
								</Text>
								<Text color={textColorSecondary} fontSize='md' me='26px' mb='24px'>
									All Events that occurred for customers that subscribed through this payment link.
								</Text>
							</Box>
							<EventsTableLink tableData={tableDataEvents} />
						</Card>
					</Box>
				</Box>
			):(
				<Loading text="Loading Payment Link Data..." />
			)}
		</Box>
	);
}
