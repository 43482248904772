import { BananaPay } from "bananapay-types";


export type OptionalParamProps = {
  visible: boolean;
  label: string;  // what users see - a general description (nickname,...)
  isEditable: boolean;
  valueType: "string" | "number";
  data: {
    image?: string;  // images like profile pictures
    value?: number | string; // value that is passed as optional param
    valueNickname?: string; // used to easier name tha non-human readable params
  }[];  // if more than one - visible in a dropdown
}

export const inputTypes = ["email", "string", "number", "date", "phone", "url", "address"] as const;
export type InputTypes = typeof inputTypes[number];

export type CollectData = {
  name: string;
  type: InputTypes;
  isRequired: boolean;
}

export type RedirectUrlProps = {
  url: string;
  target: "_blank" | "_parent" | "_self" | "_top";
}

export type WidgetUrlProps = {
  appAddress: string;
  chain: Chain;
  widgetType: WidgetType;
  planId: number;
  emailRequired: boolean;
  redirectUrl?: RedirectUrlProps;
  optionalParam?: OptionalParamProps[];
  collectData?: CollectData[];
}

export type UrlLocation = "_blank" | "_self"

export type WidgetType = "subscribe" | "cancel" | "restore";

export interface AppsPageProps {
  selectedTab: number
}

export type Notifications = {
  subscribed: boolean,
  unsubscribed: boolean,
  paymentFailed: boolean,
  paymentExecuted: boolean,
}

export type Tag = { name: string; id: number };

export type Tags = Tag[];

export type Chain = "0x13881" | "0xfa2" | "0x61";

export type EventTypes = "Subscribed" | "Unsubscribed" | "PaymentExecuted" | "PaymentFailed" | "SubscriptionCanceled" | "IntervalsGiven";

export type AllUpcomingEvents = {
  [chain: string]: {
    [appAddress: string]: UpcomingEvent[]
  };
}

export const supportedChains = () => [{
  chain: "0x13881",
  name: "Mumbai",
  ticker: "MATIC"
},
{
  chain: "0x61",
  name: "BNB Testnet",
  ticker: "BNB"
},
{
  chain: "0xfa2",
  name: "Fantom",
  ticker: "FTM"
}];

export type EventLinkType = "all" | "successful" | "failed" | "canceled" | "subscribed" | "unsubscribed" | "intervals-given" | "restored";

export type TimeStampNumber = {
  timestamp: string;
  value: number;
}

export type Subscriber = {
  subscribedAt: string; 
  paymentsExecuted: any[];  // array of payments
  isSubscribed: boolean;
  paymentOption: BananaPay.App.PaymentOption | null;
  token: BananaPay.Token,
  address: string;
  chain: Chain
}

export type TimeStampSubs = {
  timestamp: number,
  subscribers: Subscriber[]
}

export type Balances = {
  address: string,
  chain: string,
  erc20: {
    token_address: string;
    name: string;
    symbol: string;
    logo?: string | undefined;
    thumbnail?: string | undefined;
    decimals: number;
    balance: string;
  }[],
  native: {
    balance: string,
    price: string
  }
}

export type UpcomingEventsData = {
  upcomingEvents: UpcomingEvent[],
  estimatedUnsubscribeDate: number
};

export type EventsForUser = {
  [userAddress: string]: {
    pastEvents: any[];
    upcomingEvents: UpcomingEventsData
  }
};

export type TimeStampEvents = {
  timestamp: number,
  eventsInDay: number,
}

export type TimeStampPlans = {
  timestamp: number,
  paymentPlans: PlanData[]
}

export type PlanData = {
  plan: BananaPay.App.PaymentOption | null; // null is for all the plans that are undefined (users subscriptions doesn't comply with any plan)
  activeSubscribers: Subscriber[],
  totalSubscribers: Subscriber[]
};

export type SalesPerDay = {
  timestamp: number,
  sales: {
    totalSalesNative: number;
    totalSalesUsd: number;
  },
}

export interface ChannelData extends BananaPay.Analytics {
  allEvents: any[];
}

export type PaymentTokenStats = {
  paymentToken: BananaPay.Token,
  activeSubscribers: number,
  totalSubscribers: number
}


export type StatsChain = Chain & "allChains";

export type Stats = {
  success: boolean,
  data: ChannelData,
}

export type SubscriptionPlanRes = { 
  isSubscribed: boolean; 
  plan: BananaPay.App.PaymentOption | null;
  planId: number | null;
  nextSeconds: number | null;
  paymentToken: BananaPay.App.PaymentToken | null
}

export type UpcomingEvents = UpcomingEvent[];

export type UpcomingEvent = {
  name: "Unsubscribed" | "PaymentExecuted" | "PaymentFailed",
  channel: string,
  subscriber: string,
  paymentAmount: string, // amount of tokens to be transfered
  amount: string, // value set in subscribe() same as amount in Subscribe event, $ value
  paymentToken: string,
  paymentInterval: string,
  useUsdValue: boolean,
  chainId: number,
  updatedAt: {
    seconds: number
  }
}

export type NotificationSettings = BananaPay.ExtendedUser.Notifications;