// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card';
import { barChartDataDailyTraffic, barChartOptionsDailyTraffic } from 'variables/charts';
import LineChart from 'components/charts/LineChart';

// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { ChannelData } from 'helpers/types';
import { getEventsForDate } from 'helpers/formatters';



export default function DailyEvents(props: { stats: ChannelData; [x: string]: any }) {
	const { stats, ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const [chartOptions, setChartOptions] = useState<any>(null);
	const [chartData, setChartData] = useState<{name: string; data: number[]}[]>(null);

	/*const getChange = () => {
		if(data){
			const today = data[0].data[data[0].data.length-1]?.subscribers;
			const yesterday = data[0].data[data[0].data.length-2]?.subscribers;
			const change  = Math.round(((today-yesterday)/yesterday)*100);
			console.log(change, today, yesterday)
			return `${change || "-"}%`;
		}
		return "-%";
	}*/

	/*					color={info.getValue()[1] === '+' ? 'green.500' : 'red.500'}
					fontSize='sm'
					fontWeight='600'>
					{info.getValue()[1]}{info.getValue()[0]}%*/

	const getTotalEvents = () => {
		if(stats){
			const total = stats.allEvents.length;
			return `${total}`;
		}
		return "-";
	}

	const getXaxsis = () => {
		let longestArray: any = null;
		let maxLength = 0;

		Object.values(stats.total_sales).forEach((arr) => {
			if (arr.length > maxLength) {
				maxLength = arr.length;
				longestArray = arr;
			}
		});

		return {longestArray, maxLength};
	}


	useEffect(() => {
		if(stats) {
			let d = new Date();
			const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
			const xaxis: string[] = [];
			const data: {name: string; data: number[]}[] = [
				{
					name: "Successful Payments",
					data: []
				},
				{
					name: "Failed Payments",
					data: []
				},
				{
					name: "Subscribed",
					data: []
				},
				{
					name: "Unsubscribed",
					data: []
				},
				{
					name: "Subscription Cancled",
					data: []
				}
			];

			let l = Math.abs(getXaxsis().maxLength);
			console.log("length ", l);
			[...Array(l).keys()].map((x, i) => {
				const allEvents = getEventsForDate(stats.allEvents, d);
				let subscribedEvents = 0;
				let unsubscribedEvents = 0;
				let paymentSuccessfulEvents = 0;
				let paymentFailedEvents = 0;
				let subscriptionCanceled = 0;
				allEvents.map((e) => {
					switch(e.name){
						case "Subscribed": 
							subscribedEvents++;
							break;
						case "Unsubscribed": 
							unsubscribedEvents++;
							break;
						case "PaymentExecuted": 
							paymentSuccessfulEvents++;
							break;
						case "PaymentFailed": 
							paymentFailedEvents++;
							break;
						case "SubscriptionCanceled": 
							subscriptionCanceled++;
							break;
					}
				});

				const xaxsisLabel = `${d.getDate()}/${d.getMonth()+1}`;

				xaxis.push(xaxsisLabel);
				data[0].data.push(paymentSuccessfulEvents);
				data[1].data.push(paymentFailedEvents);
				data[2].data.push(subscribedEvents);
				data[3].data.push(unsubscribedEvents);
				data[4].data.push(subscriptionCanceled);

				d.setDate(d.getDate()-1);
			});

			// need to reverse
			data[0].data.reverse();
			data[1].data.reverse();
			data[2].data.reverse();
			data[3].data.reverse();
			data[4].data.reverse();
			xaxis.reverse();

			setChartData(data);
			setChartOptions({
				chart: {
					toolbar: {
						show: false
					},
					dropShadow: {
						enabled: true,
						top: 13,
						left: 0,
						blur: 10,
						opacity: 0.1,
						color: '#4318FF'
					}
				},
				colors: [ '#11047A', '#5E37FF', '#6AD2FF', '#E1E9F8', '#5E3AFF' ],
				markers: {
					size: 0,
					colors: 'white',
					strokeColors: '#7551FF',
					strokeWidth: 3,
					strokeOpacity: 0.9,
					strokeDashArray: 0,
					fillOpacity: 1,
					// discrete: [],
					shape: 'circle',
					radius: 2,
					offsetX: 0,
					offsetY: 0,
					showNullDataPoints: true
				},
				tooltip: {
					theme: 'dark'
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth',
					type: 'line'
				},
				xaxis: {
					type: 'numeric',
					categories: xaxis,
					labels: {
						style: {
							colors: '#A3AED0',
							fontSize: '12px',
							fontWeight: '500'
						}
					},
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false
					}
				},
				yaxis: {
					show: false
				},
				legend: {
					show: false
				},
				grid: {
					show: false,
					column: {
						color: [ '#11047A', '#5E37FF', '#6AD2FF', '#E1E9F8', '#5E3AFF' ],
						opacity: 0.5
					}
				},
				color: [ '#11047A', '#5E37FF', '#6AD2FF', '#E1E9F8', '#5E3AFF' ]
			});
		}
	}, [stats]);

	return (
		<Card alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex justify='space-between' align='start' px='10px' pt='5px' w='100%'>
				<Flex flexDirection='column' align='start' me='20px'>
					<Flex w='100%'>
						<Text color='secondaryGray.600' me='auto' fontSize='sm' fontWeight='500'>
							Daily Events
						</Text>
					</Flex>

					<Flex align='end'>
						<Text color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
							{getTotalEvents()}
						</Text>
						<Text ms='6px' color='secondaryGray.600' fontSize='sm' fontWeight='500'>
							Total Events
						</Text>
					</Flex>
				</Flex>
				{/*<Flex align='center'>
					<Icon as={RiArrowUpSFill} color='green.500' />
					<Text color='green.500' fontSize='sm' fontWeight='700'>
						{getChange()} since yesterday
					</Text>
				</Flex>*/}
			</Flex>
			<Box h='240px' mt='auto' w='100%'>
				{chartData && chartOptions ? (
					<LineChart chartData={chartData} chartOptions={chartOptions} />
				):null}
			</Box>
		</Card>
	);
}
