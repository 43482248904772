import { As, Box, Button, Icon, Image, Link, Text, useColorModeValue } from "@chakra-ui/react";
import Card from 'components/card/Card';
import { link } from "fs";
import { FiExternalLink } from "react-icons/fi";

const DocsBox = (props: { title: string, text: string, link: string, img?: any, buttonText?: string; isExternal?: boolean; icon?: As<any>; }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.500', 'gray.300');

  const { title, text, link, img, isExternal=true, buttonText, icon=FiExternalLink } = props;

  return(
    <Card>
      <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
        {title}
      </Text>
      {img}
      <Text>
        {text}
      </Text>
      <Link mt="2" isExternal={isExternal} href={link} variant='no-hover' p='0px !important'>
        <Button textColor={textColorPrimary}>
          <Icon as={icon} color={textColorPrimary} h='18px' w='18px' mr="10px" />
          {buttonText || "Docs"}
        </Button>
      </Link>
    </Card>
  );
};

export default DocsBox;