import React, { useState, ChangeEvent } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Input,
  Text,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

// Define the type for the custom object
type CustomObject = {
  [key: string]: string;
};

const MetadataInput = (props: { customObject: CustomObject; setCustomObject: (newObj: CustomObject) => void; additionalText?: string }) => {

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

  const { additionalText, customObject, setCustomObject } = props;

  const [propertyName, setPropertyName] = useState<string>('');
  const [propertyValue, setPropertyValue] = useState<string>('');
  const [editingKey, setEditingKey] = useState<string | null>(null);

  const handleAddProperty = () => {
    if (propertyName && propertyValue) {
      const prev = {...customObject};
      prev[propertyName] = propertyValue;
      setCustomObject(prev);
      setPropertyName('');
      setPropertyValue('');
    }
  };

  const handleRemoveProperty = (key: string) => {
    const prev = {...customObject};
    delete prev[key];
    setCustomObject(prev);
  };

  const handleEditProperty = (key: string) => {
    setEditingKey(key);
  };

  const handleSaveProperty = (key: string) => {
    if (editingKey !== null && propertyName && propertyValue) {
      const prev = {...customObject};
      prev[propertyName] = propertyValue;
      setCustomObject(prev);
      setPropertyName('');
      setPropertyValue('');
      setEditingKey(null);
    };
  };

  const handlePropertyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPropertyName(e.target.value);
  };

  const handlePropertyValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPropertyValue(e.target.value);
  };

  return (
    <>
    <FormLabel
      display='flex'
      ms='10px'
      fontSize='sm'
      color={textColorPrimary}
      fontWeight='bold'
      _hover={{ cursor: 'pointer' }}
    >
      Metadata
    </FormLabel>
    {additionalText ? (
      <Text fontSize={"sm"} mt="-2" ms="9px">{additionalText}</Text>
    ):null}
    <Box px={"4"} mt="4">
      <Box px="10px" mb="4">
        <FormLabel
          display='flex'
          fontSize='sm'
          color={textColorPrimary}
          fontWeight='bold'
          _hover={{ cursor: 'pointer' }}
        >
          Properties
        </FormLabel>
        {Object.entries(customObject).map(([key, value]) => (
          <Flex key={key}>
            {editingKey === key ? (
              <>
                <InputField
                  id='name'
                  label="New Property Name"
                  value={propertyName || key}
                  onChange={handlePropertyNameChange}
                  placeholder="Enter new property name"
                  mr="4"
                />
                <Box mx="2"></Box>
                <InputField
                  id='name'
                  label="New Property Value"
                  value={propertyValue || value}
                  onChange={handlePropertyValueChange}
                  placeholder="Enter new property value"
                  mr="4"
                />
                <Button onClick={() => handleSaveProperty(key)} colorScheme="brand" mt="8" ml="4">
                  Save
                </Button>
              </>
            ) : (
              <>
                <Text mr="6" mt="0.5" fontSize={"md"}>{key}</Text>
                <Text mr="6" fontSize={"md"} fontWeight={"extrabold"}>:</Text>
                <Text mt="0.5" fontSize={"md"}>{value}</Text>

                <Button onClick={() => handleEditProperty(key)} mr="3" ml="6" size={"sm"}>
                  Edit
                </Button>
                <Button onClick={() => handleRemoveProperty(key)} colorScheme="brand" size={"sm"}>
                  Remove
                </Button>
              </>
            )}
          </Flex>
        ))}
        {Object.entries(customObject).length<=0 ? (
          <Text>No properties added yet.</Text>
        ):null}
      </Box>
      <Flex>
        <Box width={"100%"} mr="4">
          <InputField
            id='name'
            label="Add Property Name"
            value={propertyName}
            onChange={handlePropertyNameChange}
            placeholder="Enter property name"
            width={"100%"}
            mr="4"
          />
        </Box>
        <Box width={"100%"}>
          <InputField
            id='name'
            label="Add Property value"
            value={propertyValue}
            onChange={handlePropertyValueChange}
            placeholder="Enter property value"
            width={"100%"}
          />
        </Box>
      </Flex>
      <Button onClick={handleAddProperty} variant="brand">
        Add Property
      </Button>
    </Box>
    </>
  );
};

export default MetadataInput;
