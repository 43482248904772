import { Box, Button, Center, Link, Spinner, Text, useColorModeValue, useToast } from "@chakra-ui/react"
import { BananaPay } from "bananapay-types";
import ApiKeyDrawer from "components/apiKeys/ApiKeyDrawer";
import ApiKeysTable from "components/apiKeys/ApiKeysTable";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import Card from 'components/card/Card';
import { useApps } from "contexts/AppsContext";
import { useUser } from "contexts/UserContext";
import { createLinkForDashboard, getToast, useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";


type RowObj = {
  name: string;
  app: string;
	status: boolean;
	actions: [string, boolean];
  delete: string;
	more: string;
};

const SubscriptionApiKeys = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keys, setKeys] = useState<BananaPay.ExtendedUser.ApiKeys>({});

  const query = useQuery();
	const history = useHistory();
	const { user, subscriptionApiKeys, getAllApiKeysForSubscription } = useUser();
	const { getAppDetails, apps } = useApps();
	const toast = useToast();

	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

  const createTableData = () => {
    const arr: RowObj[] = Object.entries(keys).map(([hashed_key, { active, subscription_id, name }]) => ({
      name,
      app: getAppDetails(subscription_id)?.name || "Unknown",
      status: active,
      actions: [hashed_key, active],
      delete: hashed_key,
      more: ""
    }));
    return arr;
  };

  const onCreate = (name: string, newKey: BananaPay.ExtendedUser.ApiKey) => {
    const _keys = {...keys};
    _keys[name] = newKey;
    setKeys(_keys);
  };

  const displayNoKeys = () => {
    if(isLoading){
			return (
        <Center mx="auto" flexDirection={"column"} my="8">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='brand.500'
            size="xl"
            mx="auto"
          />
          <Text textAlign={"center"}>Loading your API Keys . . .</Text>
        </Center>
      );
    }else{
      return <Text>No API Keys created.</Text>;
    }
  };

  useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
        (async function () {
          setIsLoading(true);
          await getAllApiKeysForSubscription(app.subscription_id, app.publisher.uid);
          setIsLoading(false);
        })();
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.subscriptionApiKeys}_subscriptionId=${subscriptionId}` : `${links.signInCentered}?to=${links.subscriptionApiKeys}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user]);

  useEffect(() => {
    if(subscriptionApiKeys) setKeys(subscriptionApiKeys);
  }, [subscriptionApiKeys]);

  return(
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
		{app ? (
      <>
        <BreadCrumpStrip
          additional={{mb: "4"}}
          links={[{
            href: `/#${createLinkForDashboard(app.subscription_id)}`,
            name: "Dashboard"
          },{
            href: `/`,
            name: "Api Keys"
          }]}
        />
        <Card>
          <Text fontSize='3xl' fontWeight='900' color={textColor}>
            API Keys
          </Text>
          <Text fontSize='xl' fontWeight='500' color='secondaryGray.600' mb='2'>
            Create your BananaPay API Keys to be able to access BananaPay APIs and integrate BananaPay into your application. Only the subscription owner can create API Keys.
          </Text>
          {createTableData().length>0 ? (
            <ApiKeysTable
              tableData={createTableData()}
            />
          ): displayNoKeys() }
          {Object.keys(subscriptionApiKeys || {})?.length>=10 ? (
            <>
              <Button
                variant={"darkBrand"}
                width="min-content"
                mt="3"
              >
                API Key Limit Reached
              </Button>
              <Text fontSize='sm' color='secondaryGray.600' mt='1.5' ml="1.5">
                *You can create up to 10 API Keys
              </Text>
            </>
          ):(
            <Button
              variant={"darkBrand"}
              width="min-content"
              mt="3"
              onClick={() => setIsOpen(true)}
            >
              Create API Key
            </Button>
          )}
        </Card>

        <ApiKeyDrawer
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          apiKeys={subscriptionApiKeys}
          onCreate={onCreate}
          selectedSubscriptionId={app.subscription_id}
        />
      </>
		):(
			<Center mx="auto" flexDirection={"column"} my="8">
				<Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='brand.500'
					size="xl"
					mx="auto"
				/>
				<Text textAlign={"center"}>Loading Subscription . . .</Text>
			</Center>
		)}
		</Box>
  );
};

export default SubscriptionApiKeys;