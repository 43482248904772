import ChainLogo from "components/chain/ChainLogo";
import { Chain } from "./types";

export const allChains: {
  name: string;
  ticker: string;
  currency: string;
  id: Chain;
  numberId: number;
  logo: JSX.Element;
  blockExplorer: string;
}[] = [
  {
    name: "Mumbai",
    ticker: "mumbai",
    currency: "MATIC",
    id: "0x13881",
    numberId: 80001,
    logo: <ChainLogo chain="matic" />,
    blockExplorer: "https://mumbai.polygonscan.com/"
  },
  {
    name: "BNB Testnet",
    ticker: "tbnb",
    currency: "tBNB",
    id: "0x61",
    numberId: 97,
    logo: <ChainLogo chain="bnb" />,
    blockExplorer: "https://testnet.bscscan.com/"
  },
  {
    name: "Fantom Testnet",
    ticker: "ftm",
    currency: "FTM",
    id: "0xfa2",
    numberId: 4002,
    logo: <ChainLogo chain="FTM" />,
    blockExplorer: "https://testnet.ftmscan.com/"
  }
];

export const DEFAULT_CHAIN = allChains[0];

export const getChain = (
  chain: "0x13881"|"0x61"|"0xfa2"|"tbnb"|"ftm"|"mumbai"|80001|4002|97
) => {
  switch(chain) {
    // mumbai - 0
    case "0x13881": return allChains[0];
    case "mumbai": return allChains[0];
    case 80001: return allChains[0];

    // bnb testnet - 1
    case "0x61": return allChains[1];
    case "tbnb": return allChains[1];
    case 97: return allChains[1];

    // fantom testnet - 2
    case "0xfa2": return allChains[2];
    case "ftm": return allChains[2];
    case 4002: return allChains[2];
  } 
}