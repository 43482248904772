// Chakra imports
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Icon, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { BananaPay } from 'bananapay-types';
// Custom components
import Card from 'components/card/Card';
import { getEllipsisTxt, getToast } from 'helpers/formatters';
import { FiCopy } from 'react-icons/fi';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function CustomerDataDetails(props: { customer: BananaPay.Customer, [x: string]: any }) {
	const { customer, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = "secondaryGray.600";

	const toast = useToast();

	const displayMetadata = () => {
		return Object.entries(customer.metadata[customer.metadata.length-1]).map(([key, value], index) => {
			return(
				<Box mb="2" key={`${key}${value}+${index}`}>
					<Text color={textColorSecondary} fontWeight="500">{key}:</Text>
					<Text color={textColor} fontWeight="500" mt="-1">{value}</Text>
				</Box>
			)
		});
	}

	const displayFields = () => {
		const lastSubscribed = customer.events.subscribed_events[customer.events.subscribed_events.length-1];
		return customer.custom_fields[lastSubscribed].map((val, index) => {
			return(
				<Box mb="2" key={`${val.key}${val.value}+${index}`}>
					<Text color={textColorSecondary} fontWeight="500">{val.key}: ({val.type})</Text>
					<Text color={textColor} fontWeight="500" mt="-1">{val.value}</Text>
				</Box>
			)
		});
	}

	const getJoinDate = (d: Date) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
	}

	return (
		<Card px="-4" py='30px' w='100%' {...rest} height={"100%"}>
			<Text color={textColor} fontSize='2xl' fontWeight='800' mb='6' mx='auto'>
				Customer Data
			</Text>

			<Flex px="8" direction={"column"} gap="5px">
				<Flex justifyContent={"space-between"}>
					<Text color={textColorSecondary} fontWeight="500">Subscribed At: </Text>
					<Text color={textColor} fontWeight="500" mt="-1">{getJoinDate(new Date(customer.subscription.subscribed_at[customer.subscription.subscribed_at.length-1]*1000))}</Text>
				</Flex>

				<Flex justifyContent={"space-between"}>
					<Text color={textColorSecondary} fontWeight="500">Email: </Text>
					<Text color={textColor} fontWeight="500" mt="-1">{customer.email}</Text>
				</Flex>

				<Flex justifyContent={"space-between"}>
					<Text color={textColorSecondary} fontWeight="500">Name: </Text>
					<Text color={textColor} fontWeight="500" mt="-1">{customer.name || "not set"}</Text>
				</Flex>

				<Flex justifyContent={"space-between"}>
					<Text color={textColorSecondary} fontWeight="500">Description: </Text>
					<Text color={textColor} fontWeight="500" mt="-1">{customer.description || "not set"}</Text>
				</Flex>

				<Box>
					<Text color={textColorSecondary} fontWeight="500">Phone: </Text>
					{customer.phone ? (
						<PhoneInput
							disabled={true}
							onChange={() => {}}
							value={customer.phone}
						/>
					):(
						<Text color={textColor} fontWeight="500" mt="-1">not set</Text>
					)}
				</Box>

				{customer.consent_collection.length>0 ? (
					<>
						<Flex justifyContent={"space-between"}>
							<Text color={textColorSecondary} fontWeight="500">Terms Of Service: </Text>
							<Text color={textColor} fontWeight="500" mt="-1">{customer.consent_collection[customer.consent_collection.length-1].terms_of_service.accepted ? "Accepted" : "Declined"}</Text>
						</Flex>
						<Flex justifyContent={"space-between"}>
							<Text color={textColorSecondary} fontWeight="500">Promotions: </Text>
							<Text color={textColor} fontWeight="500" mt="-1">{customer.consent_collection[customer.consent_collection.length-1].promotions.accepted ? "Accepted" : "Declined"}</Text>
						</Flex>
					</>
				):null}

				<Flex justifyContent={"space-between"}>
					<Text color={textColorSecondary} fontWeight="500">Customer ID: </Text>
					<Flex>
						<Icon
							as={FiCopy}
							onClick={() => {
								navigator.clipboard.writeText(customer.id)
								toast(getToast("success", "Copied!", "Copied ID."))
							}}
							width="12px"
							height="12px"
							mr="1.5"
							color={textColor}
						/>
						<Text color={textColor} fontWeight="500" mt="-1">
							{getEllipsisTxt(customer.id, 6)}
						</Text>
					</Flex>
				</Flex>
			</Flex>

			<Accordion allowToggle allowMultiple outline={"none"} mb="6" px="4" mt="8">
				{customer.custom_fields ? (
					<AccordionItem>
						<h2>
							<AccordionButton>
							<Text fontWeight={"bold"} fontSize="md">Custom Fields</Text>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>       
							<Box>
								{displayFields()}
							</Box>
						</AccordionPanel>
					</AccordionItem>
				):null}
				{customer.metadata ? (
					<AccordionItem>
						<h2>
							<AccordionButton>
							<Text fontWeight={"bold"} fontSize="md">Metadata</Text>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>       
							<Box>
								{displayMetadata()}
							</Box>
						</AccordionPanel>
					</AccordionItem>
				):null}
			</Accordion>
		</Card>
	);
}
