import { Badge, Box, Button, Flex, Icon, Image, Link, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { Chain } from "helpers/types";
import { getTokensForChain, tokens } from "variables/tokens";
import { v4 as uuidv4 } from "uuid";
import { FiExternalLink } from "react-icons/fi";
import { createLinkForExplorer } from "helpers/formatters";
import { BananaPay } from "bananapay-types";


const TokenList = (props: { useUsdValue: boolean; setToken: (newToken:BananaPay.Token) => void; searchTerm: string; selectedTokens:BananaPay.Token[] | null }) => {
  const { setToken, searchTerm, selectedTokens, useUsdValue } = props;
  const bgColor = useColorModeValue("gray.200", "navy.800");

  const getTokensToFilter = () => {
    const tokensForChain = tokens;
    const tokensToReturn = tokensForChain.filter((token) => {
      return !selectedTokens.some((selectedToken) => {
        return token === selectedToken;
      });
    });
    return tokensToReturn;
  }

  return(
    <Box>
      {getTokensToFilter().filter(val => {
        if(searchTerm.length>0) {
          if(val.name.toLowerCase().includes(searchTerm.toLowerCase()) || val.ticker.toLowerCase().includes(searchTerm.toLowerCase()) || val.ticker.toLowerCase().includes(searchTerm.toLowerCase())) {
            if(useUsdValue) {
              if(val.priceFeed === useUsdValue) {
                return val;
              }
            }else{
              return val;
            } 
          }
        }else{
          if(useUsdValue) {
            if(val.priceFeed === useUsdValue) {
              return val;
            }
          }else{
            return val;
          } 
        }
      }).map(token => (
        <Box key={uuidv4()} p="2" rounded={"3xl"} _hover={{backgroundColor: bgColor}}>
          <Flex justifyContent="space-between">
            <Flex onClick={() => setToken(token)} w="100%" cursor={"pointer"}>
              <Image src={token.logo} alt="Token Logo" rounded={"full"} w="10" h="10" mr="3" />
              <Box>
                <Text fontSize={"md"} mb="0" fontWeight="500">{token.ticker}</Text>
                <Text fontSize={"sm"} mt="-1" fontWeight="300">{token.name}</Text>
              </Box>
            </Flex>
            <Flex justifyContent={"row"}>
              {token.priceFeed ? (
                <Tooltip label="This token can be used in payments fixed to USD">
                  <Badge colorScheme='purple' h={"min-content"} my="auto" mr="1">USD</Badge>
                </Tooltip>
              ):null}
              {/*<Link href={createLinkForExplorer(chain, "address", token.address)} target={"_blank"}>
                <Button>
                  <Flex flexDirection={"column"}>
                    <Icon mx="auto" as={FiExternalLink} h="5" w="5" />
                  </Flex>
                </Button>
              </Link>*/}
            </Flex>
          </Flex>
        </Box>
      ))}
    </Box>
  );
};

export default TokenList;