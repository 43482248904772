import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Link, Text, useColorModeValue } from "@chakra-ui/react"
import { links } from "helpers/links";

const Integrate = () => {

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return(
    <Box>
      <Accordion allowToggle outline={"none"}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Text color={textColorPrimary} fontWeight='medium' fontSize='lg'>
                How to Integrate - BananaPay APIs?
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontSize={"md"} mt="1.5">You can easily <b>get</b>, <b>update</b>, <b>search</b>, <b>delete</b> or <b>create</b> customers with BananaPay APIs. For more information, examples and tutorials please take a look at BananaPay Docs <Link href={links.docsLink}>here</Link>.</Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default Integrate;