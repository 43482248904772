import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Icon, IconButton, Input, InputGroup, InputLeftElement, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import QRCode from "components/QRCode/QRCode";
import { HSeparator } from "components/separator/Separator";
import { IoQrCode } from "react-icons/io5";
import { useState } from "react";
import { generateUnsubscribeTxLink, getToast } from "helpers/formatters";
import { FaBellSlash } from "react-icons/fa";
import Card from 'components/card/Card';
import { useUser } from "contexts/UserContext";
import { ethers } from "ethers";
import { getChainContractAddress, getContractAbi } from "helpers/web3";
import { GoBrowser } from "react-icons/go";
import { HiTrendingUp } from "react-icons/hi";
import TokenList from "./components/TokenList";
import AddCustomToken from "./components/AddCustomToken";
import { Chain } from "helpers/types";
import { BiCustomize } from "react-icons/bi";
import { SearchIcon } from "@chakra-ui/icons";
import { BananaPay } from "bananapay-types";

const drawerSizes = {
  sm: '350px',
  md: "350px",
  lg: "550px"
};

const TokensDrawer = (props: {
  isOpen: boolean;
  useUsdValue: boolean;
  selectedTokens: BananaPay.Token[] | null;
  close: () => void;
  onSelect: (token: BananaPay.Token) => void;
}) => {
  const { isOpen, selectedTokens, onSelect, close, useUsdValue } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const drawerBg = useColorModeValue('white', 'navy.800');
	const searchIconColor = useColorModeValue('gray.700', 'white');

  const [tabState, setTabState] = useState<"list"|"custom">("list");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const setToken = (newToken: BananaPay.Token) => {
    console.log("new");
    onSelect(newToken);
    close();
  }

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">Select Payment Token</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">Select a payment token in which you want to receive payments.</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          <Tabs variant='soft-rounded' colorScheme='brandTabs'>
            <Box zIndex={"3"} bgColor={"white"} position={"sticky"} top="0">
              <TabList px={{ base: '10px', lg: '30px' }} overflowX={{ sm: 'scroll', lg: 'unset' }}>
                <Flex justify={{ base: 'start', md: 'center' }} w='100%'>
                  <Tab
                    pb='0px'
                    flexDirection='column'
                    onClick={function() {
                      setTabState('list');
                    }}
                    me='20px'
                    bg='unset'
                    _selected={{
                      bg: 'none'
                    }}
                    _focus={{ border: 'none' }}
                    minW='max-content'>
                    <Flex align='center'>
                      <Icon color={textColor} as={HiTrendingUp} w='20px' h='20px' me='8px' />
                      <Text color={textColor} fontSize='md' fontWeight='500' me='12px'>
                        Popular Tokens
                      </Text>
                    </Flex>
                    <Box
                      height='4px'
                      w='100%'
                      transition='0.1s linear'
                      bg={tabState === 'list' ? 'brand.500' : 'transparent'}
                      mt='15px'
                      borderRadius='30px'
                    />
                  </Tab>
                  {/*<Tab
                    onClick={function() {
                      setTabState('custom');
                    }}
                    pb='0px'
                    bg='unset'
                    _selected={{
                      bg: 'none'
                    }}
                    _focus={{ border: 'none' }}
                    minW='max-content'
                    flexDirection='column'>
                    <Flex align='center'>
                      <Icon color={textColor} as={BiCustomize} w='20px' h='20px' me='8px' />
                      <Text color={textColor} fontSize='md' fontWeight='500' me='12px'>
                        Custom Token
                      </Text>
                    </Flex>
                    <Box
                      height='4px'
                      w='100%'
                      transition='0.1s linear'
                      bg={tabState === 'custom' ? 'brand.500' : 'transparent'}
                      mt='15px'
                      borderRadius='30px'
                    />
                  </Tab>*/}
                </Flex>
              </TabList>
              
              <HSeparator mb='10px' bg={paleGray} mt='0px' />
              
              {tabState==="list" ? (
                <Box my="2">
                  <InputGroup
                    _placeholder={{ color: 'secondaryGray.600' }}
                    me={{ base: '10px', md: '20px' }}
                  >
                    <InputLeftElement
                      children={
                        <IconButton
                          aria-label='search'
                          bg='inherit'
                          borderRadius='inherit'
                          _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent'
                          }}
                          _focus={{
                            boxShadow: 'none'
                          }}
                          disabled={true}
                          icon={<SearchIcon color={searchIconColor} w='15px' h='15px' />}
                        />
                      }
                    />
                    <Input
                      w={{
                        base: '100px',
                        md: '270px',
                        lg: '530px',
                        xl: '660px',
                        '2xl': '860px',
                        '3xl': '860px'
                      }}
                      maxW='100%'
                      variant='search'
                      fontSize='sm'
                      fontWeight='500'
                      _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                      placeholder='Search tokens'
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                  <HSeparator bg={paleGray} mt='2' />
                </Box>
              ):null}

            </Box>

            <TabPanels>
              <TabPanel px='0px'>
                <Flex direction={"column"}>
                  <TokenList selectedTokens={selectedTokens} searchTerm={searchTerm} useUsdValue={useUsdValue} setToken={setToken} />
                </Flex>
              </TabPanel>
              {/*<TabPanel px='0px'>
                <Flex px="4" direction={"column"}>
                  <AddCustomToken chain={chain} setToken={setToken} />
                </Flex>
                    </TabPanel>*/}
            </TabPanels>
          </Tabs>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="brand" onClick={close}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default TokensDrawer;