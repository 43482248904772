import { Box, Button, Flex, Link, Text } from "@chakra-ui/react"
import { useEvents } from "contexts/EventsContext";
import { createLinkForApp, createLinkForDashboard, secondesToDate } from "helpers/formatters";
import { useEffect, useState } from "react";
import Card from 'components/card/Card';
import NFT from "components/card/NFT";
import Subscription from "components/card/Subscription";
import { BananaPay } from "bananapay-types";


const SubscriptionBox = (props: { appData: BananaPay.App; isOwner: boolean; }) => {

  const { appData, isOwner } = props;
  
  const { getUpcomingEvents } = useEvents();

  const [estimatedDate, setEstimatedDate] = useState<string>("-");
  
  useEffect(() => {
    (async () => {
      const _events = await getUpcomingEvents(appData.subscription_id);
      if(_events) {
        console.log(_events.upcomingEvents[_events?.upcomingEvents?.length-1]?.updatedAt?.seconds);
        setEstimatedDate(secondesToDate(_events.upcomingEvents[_events?.upcomingEvents?.length-1]?.updatedAt?.seconds));
      }
    })();
  }, []);

  return(
    <Subscription
      verified={appData.verified}
      image={appData.logo}
      name={appData.name}
      description={appData.description}
      isOwner={isOwner}
      chains={Object.entries(appData.receivers).map(([index, receiver]) => receiver.chain)}
      subscriptionId={appData.subscription_id}
      estimatedDate={isOwner ? null : estimatedDate}
    />
  );
};

export default SubscriptionBox;