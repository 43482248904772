// @ts-nocheck

import { Box, Button, Center, Flex, FormLabel, Link, SimpleGrid, Spinner, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import Card from 'components/card/Card';
import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';
import { useState } from "react";
import { BananaPay } from "bananapay-types";
import SwitchField from 'components/fields/SwitchField';
import { links } from "helpers/links";
import { useApps } from "contexts/AppsContext";
import MetadataInput from "components/fields/MetadataField";
import { CopyBlock, dracula } from "react-code-blocks";
import { INTEGRATE_WEBHOOK_CODE_JS } from "helpers/codeBlocks";
import { getToast, isValidUrl } from "helpers/formatters";


const CreateEndpoint = (props: { subscriptionId: string, close: () => void }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

  const { subscriptionId, close } = props;

  const toast = useToast();
  const { saveWebhook } = useApps();

  const [isMetadata, setIsMetadata] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [webhook, setWebhook] = useState<BananaPay.Webhook>({
    id: "",
    object: "webhook_endpoint",
    api_version: "v1",
    subscription_id: subscriptionId,
    created: Math.floor(Date.now() / 1000), // Current Unix timestamp in seconds
    description: "",
    name: "",
    enabled_events: [],
    livemode: true,
    metadata: {},
    status: "enabled",
    url: "",
    secret: "",
  });

  const handleEventsOnChange = (event: BananaPay.Webhook.WebhookTypes) => {
    if(webhook.enabled_events.includes(event)) {
      // remove event from array
      const removedArray = webhook.enabled_events.filter(_event => _event !== event);
      const prev = {...webhook};
      prev.enabled_events = removedArray;
      setWebhook(prev);
    }else{
      // push event into array
      const prev = {...webhook};
      prev.enabled_events.push(event);
      setWebhook(prev);
    }
  };

  const validateEnabledEvents = (enabledEvents: BananaPay.Webhook.WebhookTypes[]) => {
    // Check if the array has at least one element
    if (enabledEvents.length === 0) {
      throw new Error("Must have at least 1 enabled event.");
    }
  
    // Check if all elements in the array are unique
    const uniqueSet = new Set(enabledEvents);
    if(uniqueSet.size !== enabledEvents.length) {
      throw new Error("Enabled events can't have duplicates.");
    };
  };

  const validateName = (name: string, limit: number) => {
    if(name.length<=1 || name.length>limit || !isEnglishAlphabet(name)) {
      throw new Error("Name must have less than 11 and more than 1 characters, all must be alphabetical.");
    }
  };

  const handleSaveWebhook = async() => {
    try{
      setIsLoading(true);
      if(!isValidUrl(webhook.url)) throw new Error("Invalid URL");
      if(webhook.description.length>50) throw new Error("Description must be shorter than 50 characters.");
      validateName(webhook.name);
      validateEnabledEvents(webhook.enabled_events);
      const data = {...webhook};
      if(!isMetadata) data.metadata = {};
      const response = await saveWebhook(data);
      setWebhook(response);
      setIsLoading(false);
    }catch(err){
      setIsLoading(false);
      toast(getToast("info", "info!", err.message));
      throw err;
    }
  }

  return(
    <Card mt="8">
      <Text fontSize='2xl' fontWeight='900' mb="3" color={textColorPrimary}>
        Create Webhook Endpoint
      </Text>
      {!isLoading ? (
        <>
          <Box>
            <InputField
              mb="0.5"
              id='name'
              placeholder='eg. Main'
              label="Endpoint Name"
              value={webhook.name}
              onChange={(e: any) => setWebhook({...webhook, name: e.target.value})}
            />
            <Text mb="4" fontSize={"sm"} ms="10px">*One word (less than 10 characters), only English alphabet.</Text>
            <TextField
              h='146px'
              mb='4'
              id='Description'
              placeholder='Short description for this endpoint'
              label='Subscription Description'
              value={webhook.description}
              onChange={(e: any) => setWebhook({...webhook, description: e.target.value})}
            />
            <InputField
              mb='6'
              id='url'
              placeholder='eg. https://mydomain.com/...'
              label="Endpoint URL"
              value={webhook.url}
              onChange={(e: any) => setWebhook({...webhook, url: e.target.value})}
            />
            <FormLabel
              display='flex'
              ms='10px'
              fontSize='sm'
              color={textColorPrimary}
              fontWeight='bold'
              _hover={{ cursor: 'pointer' }}
            >
              Enabled Events
            </FormLabel>
            <SimpleGrid columns={{ base: 1, md: 2 }} px="4">
              <SwitchField isChecked={webhook.enabled_events.includes("subscription.subscribed")} onChange={() => handleEventsOnChange("subscription.subscribed")} reversed={true} fontSize='sm' mb='10px' id='1' label='Subscribed' />
              <SwitchField isChecked={webhook.enabled_events.includes("subscription.unsubscribed")} onChange={() => handleEventsOnChange("subscription.unsubscribed")} reversed={true} fontSize='sm' mb='10px' id='2' label='Unsubscribed' />
              <SwitchField isChecked={webhook.enabled_events.includes("payment.successful")} onChange={() => handleEventsOnChange("payment.successful")} reversed={true} fontSize='sm' mb='10px' id='3' label='Payment Successful' />
              <SwitchField isChecked={webhook.enabled_events.includes("payment.failed")} onChange={() => handleEventsOnChange("payment.failed")} reversed={true} fontSize='sm' mb='10px' id='4' label='Payment Failed' />
              <SwitchField isChecked={webhook.enabled_events.includes("subscription.canceled")} onChange={() => handleEventsOnChange("subscription.canceled")} reversed={true} fontSize='sm' mb='10px' id='6' label='Subscription Canceled' />
              <SwitchField isChecked={webhook.enabled_events.includes("subscription.restored")} onChange={() => handleEventsOnChange("subscription.restored")} reversed={true} fontSize='sm' mb='10px' id='7' label='Subscription Restored' />
              <SwitchField isChecked={webhook.enabled_events.includes("low_balance_allowance")} onChange={() => handleEventsOnChange("low_balance_allowance")} reversed={true} fontSize='sm' mb='10px' id='5' label='Balance is Low' />
              <SwitchField isChecked={webhook.enabled_events.includes("intervals_given")} onChange={() => handleEventsOnChange("intervals_given")} reversed={true} fontSize='sm' mb='10px' id='5' label='Intervals Given' />
            </SimpleGrid>
            <Text px="4" fontSize={"sm"} mb="8">*Do not see an event you need? Request more webhook events and contact support at {links.supportEmail}. Thank you!</Text>
            <Box px="4">
              <SwitchField isChecked={isMetadata} onChange={() => setIsMetadata(!isMetadata)} reversed={true} fontSize='sm' mb='10px' id='5' label='Enable Custom Metadata' />
            </Box>
            <Text px="4" fontSize={"sm"} mb="2">*Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format.</Text>
            {isMetadata ? (
              <MetadataInput
                customObject={webhook.metadata}
                setCustomObject={newObj => setWebhook({...webhook, metadata: newObj })}
              />
            ):null}
          </Box>
          {webhook.secret.length>0 ? (
            <Box mt="8">
              <Text fontSize={"lg"} fontWeight="medium">Webhook Secret:</Text>
              <Text>{webhook.secret}</Text>

              <Text mt="3" fontSize={"lg"} fontWeight="medium">How to integrate?</Text>
              <Box>
                <CopyBlock
                  language={"js"}
                  text={INTEGRATE_WEBHOOK_CODE_JS(webhook.secret)}
                  showLineNumbers={true}
                  wrapLines={true}
                  theme={{
                    ...dracula,
                  }}
                  codeBlock
                />
              </Box>
              <Text fontSize={"sm"} mt="1.5">Or take a look into BananaPay Docs <Link href={links.docsLink}>here</Link>.</Text>
            </Box>
          ):null}
          <Flex mt="8" flexDirection={"row"} justifyContent="end">
            <Button
              onClick={close}
              mr="3"
            >
              Cancel
            </Button>
            <Button
              variant={"brand"}
              onClick={handleSaveWebhook}
            >
              Save
            </Button>
          </Flex>
        </>
      ):(
        <Center mx="auto" flexDirection={"column"} my="8">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='brand.500'
            size="xl"
            mx="auto"
          />
          <Text textAlign={"center"}>Saving, please wait . . .</Text>
        </Center>
      )}
    </Card>
  );
};

export default CreateEndpoint;