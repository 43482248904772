// Chakra imports
import { AvatarGroup, Avatar, Box, Button, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import ChainTag from 'components/chain/ChainTag';
import { useApps } from 'contexts/AppsContext';
import { useUser } from 'contexts/UserContext';
import { chainToNumber } from 'helpers/formatters';
import { Chain } from 'helpers/types';
// Assets
import { useEffect, useState } from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { MdVerified } from 'react-icons/md';

export default function NFT(props: {
	image: string;
	name: string;
	author: string;
	bidders: string[];
	download: string;
	verified?: boolean;
	currentbid?: string | number;
	chains?: Chain[];
	subscriptionId?: string;
	size?: string
}) {
	const { image, name, author, bidders, download, currentbid, chains, size, subscriptionId, verified } = props;
	const [ like, setLike ] = useState(false);
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');

	const { likeApp, getAppDetails, apps } = useApps();
	const { user } = useUser();

	const handleLikeClick = async() => {
		try{
			await likeApp(subscriptionId);
			setLike(!like);
		}catch(err) {
			
		}
	}

	const getIsLiked = () => {
		if(subscriptionId && user) {
			console.log(getAppDetails(subscriptionId))
			const isLiked = getAppDetails(subscriptionId)?.likes?.[user.uid] ? true:false;
			setLike(isLiked);
		}else{
			setLike(false);
		}
	}

	useEffect(() => {
		if(apps) {
			getIsLiked();
		}
	}, [apps, user])

	return (
		<Card p='20px'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					<Image
						src={image}
						w={size ? size :{ base: '100%', '3xl': '100%' } }
						h={size ? size : { base: '100%', '3xl': '100%' } }
						borderRadius='20px'
					/>
					<Button
						position='absolute'
						bg='white'
						_hover={{ bg: 'whiteAlpha.900' }}
						_active={{ bg: 'white' }}
						_focus={{ bg: 'white' }}
						p='0px !important'
						top='14px'
						right='14px'
						borderRadius='50%'
						minW='36px'
						h='36px'
						disabled={like}
						onClick={handleLikeClick}>
						<Icon
							transition='0.2s linear'
							w='20px'
							h='20px'
							as={like ? IoHeart : IoHeartOutline}
							color='brand.500'
						/>
					</Button>
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%'>
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{name}
								{verified ? <Icon as={MdVerified} h='34px' w='34px' color='blue.500' mt='12px' /> : null}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'
								noOfLines={3}
							>
								{author}
							</Text>
						</Flex>
						<AvatarGroup
							max={3}
							color={textColorBid}
							size='sm'
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}
							fontSize='12px'>
							{chains.map((chain, key) => <Avatar key={key} src={"assets/img/chain/avalanche"} />)}
						</AvatarGroup>
					</Flex>
					<Flex
						justify='space-between'
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mt='20px'>
						{currentbid ? (
							<Text fontWeight='700' fontSize='sm' color={textColorBid}>
								Current Bid: {currentbid}
							</Text>
						):null}
						<Link
							href={download}
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}>
							<Button
								variant='darkBrand'
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								mt="5px"
								px='24px'
								py='5px'
							>
								More
							</Button>
						</Link>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
