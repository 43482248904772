import React from 'react';
import { ILogoProps } from './types';

const CoinbaseLogo: React.FC<ILogoProps> = ({ width = '120', height = '160' }) => {
    return (
        <svg
            fill="none"
            height={height}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2272.000000 1704.000000"
        >
            <g
                transform="translate(150.000000,1800.000000) scale(0.200000,-0.20000)"
                fill="#ffffff"
                stroke="none"
            >
                <path
                    d="M4605 9995 c-11 -2 -116 -16 -233 -30 -267 -32 -469 -71 -698 -134
                    -947 -261 -1781 -782 -2427 -1516 -457 -520 -779 -1074 -993 -1710 -108 -323
                    -189 -683 -234 -1045 -16 -131 -24 -760 -12 -956 24 -378 120 -842 254 -1233
                    312 -908 867 -1690 1637 -2305 678 -541 1502 -895 2389 -1026 199 -29 391 -40
                    712 -40 321 0 513 11 712 40 887 131 1711 485 2389 1026 580 463 1015 992
                    1365 1659 182 348 357 847 438 1255 75 377 96 591 96 1020 0 423 -21 646 -96
                    1020 -119 600 -372 1207 -719 1730 -428 645 -976 1169 -1632 1561 -148 88
                    -541 282 -693 342 -341 135 -656 222 -1012 283 -304 51 -353 55 -798 59 -234
                    2 -434 2 -445 0z m955 -1518 c772 -134 1426 -475 1960 -1023 291 -299 471
                    -556 650 -928 175 -362 293 -777 334 -1167 l6 -56 -143 -6 c-78 -4 -478 -7
                    -888 -7 l-747 0 -22 98 c-99 433 -365 821 -730 1064 -221 147 -472 246 -722
                    283 -125 19 -382 19 -515 0 -481 -68 -921 -351 -1203 -772 -187 -280 -275
                    -557 -287 -899 -9 -274 20 -450 122 -719 65 -172 208 -396 344 -538 196 -204
                    390 -339 617 -429 231 -93 413 -128 664 -129 374 -2 705 104 1025 327 81 57
                    311 282 377 369 125 167 237 404 297 627 l36 138 745 0 c410 0 809 -3 888 -7
                    l143 -6 -6 -56 c-15 -126 -58 -364 -90 -493 -99 -397 -267 -776 -498 -1123
                    -188 -282 -507 -618 -793 -833 -376 -283 -817 -494 -1264 -606 -311 -77 -397
                    -88 -779 -93 -435 -7 -590 8 -918 88 -975 237 -1805 888 -2281 1789 -141 267
                    -264 608 -327 910 -53 251 -57 295 -62 638 -4 218 -1 371 6 444 47 446 172
                    861 380 1263 218 422 552 825 942 1139 101 81 360 254 484 323 357 198 770
                    336 1175 392 80 11 156 23 170 25 14 2 192 3 395 1 340 -3 382 -5 515 -28z"
                />
            </g>
        </svg>
    );
};

export default CoinbaseLogo;
