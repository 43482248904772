import { Box, Image, SimpleGrid, Text, Tooltip, useColorModeValue } from "@chakra-ui/react"
import { BananaPay } from "bananapay-types";
import { displayTime } from "helpers/formatters";


const PricingPlanCard = (props: { paymentOption: BananaPay.App.PaymentOption }) => {

  const { paymentOption } = props;

	const textColorSecondary = 'gray.500';
	const textColor = useColorModeValue('secondaryGray.900', 'white');

  const displayAmount = (paymentOption: BananaPay.App.PaymentOption): string => {
		if(paymentOption.useUsdValue) return `$${paymentOption.usdValue}`;
    const tickerAmounts = new Map();
    paymentOption.paymentTokens.map(paymentToken => {
      const tokenTicker = paymentToken?.token?.ticker;
      const amount = paymentToken.amount;
      const existingAmount = tickerAmounts.get(tokenTicker) ?? 0;
      tickerAmounts.set(tokenTicker, existingAmount + amount);
    });
    return [...tickerAmounts.entries()]
      .map(([ticker, amount]) => `${amount} ${ticker}`)
      .join(', ');
	}


	const displayTokens = (paymentOption: BananaPay.App.PaymentOption) => {
		return paymentOption.paymentTokens?.map((paymentToken) => {
			return {
				img: paymentToken?.token?.logo,
				ticker: paymentToken?.token?.ticker,
				name: paymentToken?.token?.name
			}
		});
	}

  return(
    <Box>
      <Text textAlign='start' w='100%' color={textColor} fontSize='40px' fontWeight='bold'>
        {displayAmount(paymentOption)}
        <Text as='span' color='secondaryGray.600' fontSize='40px' fontWeight='bold'>
          /{displayTime(paymentOption.paymentInterval)}
        </Text>
      </Text>
      
      <Text color={textColorSecondary} fontWeight='extrabold' fontSize='lg' mb="0.5">Accepted Tokens:</Text>
      <SimpleGrid gap="10px" columns={8}>
        {displayTokens(paymentOption)?.map((val, index) => {
          return(
            <Box key={index}>
              <Tooltip label={`${val.name} (${val.ticker})`} aria-label='A tooltip'>
                <Image
                  rounded={"full"}
                  src={val.img}
                  alt={val.ticker}
                  width={"10"}
                  height={"10"}
                />
              </Tooltip>
            </Box>
          )
        })}
      </SimpleGrid>
    </Box>
  );
};

export default PricingPlanCard;