// Chakra imports
import { Box, Flex, Select, Text, useColorModeValue } from '@chakra-ui/react';
import { BananaPay } from 'bananapay-types';
import Card from 'components/card/Card';
// Custom components
import BarChart from 'components/charts/BarChart';
import { getLastFiveMondays } from 'helpers/formatters';


export default function UserActivity(props: { customers: BananaPay.Customer[], [x: string]: any }) {
	const { customers, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const countSubscribersBetweenTimestamps = () => {
		const result = [];
		const secondsNow = Math.floor(Date.now() / 1000);
		const timestamps = [...getLastFiveMondays().timestamps, secondsNow];

		for (let i = 0; i < timestamps.length - 1; i++) {
			const startTimestamp = timestamps[i];
			const endTimestamp = timestamps[i + 1];
			
			// Filter the objects whose subscribed_at timestamps fall between the start and end timestamps
			const subscribersBetweenTimestamps = customers.filter((obj) => {
				const subscribedAtArray = obj.subscription.subscribed_at;
				return subscribedAtArray.some((timestamp) => timestamp >= startTimestamp && timestamp < endTimestamp);
			});

			// Count the number of subscribers for this time interval and add it to the result array
			result.push(subscribersBetweenTimestamps.length);
		}

		return result;
	}

	console.log(countSubscribersBetweenTimestamps());

	const chartData = [
		{
			name: 'New Subscribers',
			data: countSubscribersBetweenTimestamps()
		}
	]

	const chartOptions = {
		chart: {
			stacked: true,
			toolbar: {
				show: false
			}
		},
		tooltip: {
			style: {
				fontSize: '12px'
			},
			onDatasetHover: {
				style: {
					fontSize: '12px'
				}
			},
			theme: 'dark'
		},
		xaxis: {
			categories: [...getLastFiveMondays().dates],
			show: false,
			labels: {
				show: true,
				style: {
					colors: '#A3AED0',
					fontSize: '14px',
					fontWeight: '500'
				}
			},
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false
			}
		},
		yaxis: {
			show: true,
			color: 'black',
			labels: {
				show: true,
				style: {
					colors: '#A3AED0',
					fontSize: '14px',
					fontWeight: '500'
				}
			}
		},
	
		grid: {
			borderColor: 'rgba(163, 174, 208, 0.3)',
			show: true,
			yaxis: {
				lines: {
					show: true,
					opacity: 0.5
				}
			},
			row: {
				opacity: 0.5
			},
			xaxis: {
				lines: {
					show: false
				}
			}
		},
		fill: {
			type: 'solid',
			colors: [ '#5E37FF' ]
		},
		legend: {
			show: false
		},
		colors: [ '#5E37FF' ],
		dataLabels: {
			enabled: false
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				columnWidth: '30px'
			}
		}
	}

	return (
		<Card alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex align='center' w='100%' px='15px' py='10px'>
				<Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
					New Subscribers
				</Text>
				<Select
					id='user_type'
					w='unset'
					variant='transparent'
					display='flex'
					alignItems='center'
					defaultValue='Weekly'>
					<option value='Weekly'>Weekly</option>
				</Select>
			</Flex>

			<Text textAlign={"left"} color='secondaryGray.600' fontSize='sm' fontWeight='500' maxW='250px' mx='auto' mb='10px' mt="-2.5">
				Number of New Subscribers each Week
			</Text>

			<Box h='240px' mt='auto'>
				{!chartData[0].data.every((num) => num === 0) ? (
					<BarChart 
						chartData={chartData}
						chartOptions={chartOptions}
					/>
				):(
					<Text textAlign={"left"} color={textColor} fontSize='lg' fontWeight='500' maxW='250px' mx='auto' mb='10px' mt="85px">
						No Subscribers in last 5 weeks.
					</Text>
				)}
			</Box>
		</Card>
	);
}
