import { useEffect } from "react";
// Chakra imports
import { Badge, List, ListItem, ListIcon, Text, Button, useColorModeValue, useToast, Box, Icon, Flex, Center, Stack, Link } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import { BsCheck2Circle, BsCheckCircle, BsCircleFill } from 'react-icons/bs';
import { useState } from "react";
import { useEvents } from 'contexts/EventsContext';
import { chainToHex, chainToNumber, createWidgetLink, getToast } from 'helpers/formatters';
import { FiCopy } from 'react-icons/fi';
import { HSeparator } from 'components/separator/Separator';
import { useApps } from "contexts/AppsContext";
import { BananaPay } from "bananapay-types";

export default function PricingPlan(props: {
	title: string;
	desc: string;
	button: string;
	price: JSX.Element | string;
	details: string;
	benefits?: string[];
  plan: BananaPay.App.PaymentOption;
  name: string;
	subscriptionId: string;
	planId: number;
	isSubscribed?: boolean
	isAdmin?: boolean;
	isPublic?: boolean;
	highlighted?: boolean;
	subscribeLink: string;
}) {

	const { title, desc, button, price, details, benefits, highlighted, plan, name, subscriptionId, isAdmin, isPublic, planId, subscribeLink } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.500', 'gray.300');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubscribedToThis, setIsSubscribedToThis] = useState<boolean>(false);
	const { getIsUserSubscribedToApp } = useEvents();
	const toast = useToast();
	const { getAppDetails } = useApps();
	const { getSubscriptionPlan } = useEvents();

	const handleOpen = () => {
		const isSubscribed = getIsUserSubscribedToApp(subscriptionId);
		if(!isSubscribed) setIsOpen(true);
		else toast(getToast("info", "Already subscribed!", `You are already subscribed to ${name}!`));
	}

	useEffect(() => {
		const app = getAppDetails(subscriptionId);
		const subscriptionPlan = getSubscriptionPlan(app);
		if(subscriptionPlan.planId === planId && subscriptionPlan.isSubscribed) setIsSubscribedToThis(true);
		else setIsSubscribedToThis(false);
	}, [planId, subscriptionId]);

	return (
    <>
		<Card
			p='20px'
			pb='45px'
			pt={highlighted ? '60px' : '30px'}
			w="100%"
			alignItems='flex-start'
			justifyContent='flex-start'
			overflow='unset !important'>
			<Badge
				display={highlighted ? 'block' : 'none'}
				w='max-content'
				position='absolute'
				fontSize='sm'
				color='orange.500'
				bg='orange.100'
				fontWeight='bold'
				textTransform='unset'
				left='50%'
				borderRadius='70px'
				transform='translate(-50%,-250%)'>
				Most popular plan
			</Badge>
			<Text fontSize='30px' color={textColor} fontWeight='700'>
				{title}
			</Text>
			<Text mb='15px' fontSize='md' color='secondaryGray.600' fontWeight='500'>
				{desc}
			</Text>
			{price}
			{!isSubscribedToThis ? (
				<Link w="100%" href={subscribeLink} isExternal mt="15px">
					<Button w='100%' variant={highlighted ? 'brand' : 'lightBrand'} mb='0px'>
						{button}
					</Button>
				</Link>
			):(
				<Button w='100%' variant={highlighted ? 'brand' : 'lightBrand'} mb='0px' mt="15px">
					<Icon as={BsCheckCircle} h='18px' w='18px' mr="10px" />
					Current Subscription Plan
				</Button>
			)}
			{/*<Text fontSize='md' color='secondaryGray.600' fontWeight='500'>
				{details}
			</Text>
			<List spacing={3} justifyContent='flex-start'>
				{benefits.map((benefit, index) => (
					<ListItem
						key={index}
						display='flex'
						textAlign='start'
						fontSize='md'
						fontWeight='500'
						color={textColor}
						alignItems='center'
						lineHeight='100%'
						mt='30px !important'>
						<ListIcon w='10px' h='10px' as={BsCircleFill} my='auto' color={textColor} />
						{benefit}
					</ListItem>
				))}{' '}
				</List>*/}
			{isAdmin ? (
				<>
					<HSeparator mb="6" mt="10" />
					<Center flexDirection={"row"} backgroundColor={"brand.100"} textColor={"brand.400"} rounded={"full"} px="4" py="2" w="fit-content" mx="auto">
						<Text fontWeight={"800"}>{isPublic ? "Public" : "Hidden"}</Text>
					</Center>
				</>
			):null}
			{isAdmin ? (
				<Box mt="5" textAlign={"center"} px="auto">
					<Text color={textColorPrimary} fontWeight="extrabold" fontSize={"xl"} mb="1.5">Widget Integration Link</Text>
					<Text color={textColorSecondary} fontSize={"md"}>Integrate BananaPay into your website with this widget link. Each subscription plan has a different link. See docs for examples.</Text>
					<Button
						w="100%"
						onClick={function() {
							navigator.clipboard.writeText(createWidgetLink(subscriptionId, "subscribe", planId));
							toast(getToast("success", "Copied!", "Subscribe widget link copied!"));
						}}
						mt="2"
					>
						<Icon as={FiCopy} color={textColorPrimary} h='18px' w='18px' mr="10px" />
						Copy Subscribe Widget Link
					</Button>
				</Box>
			):null}
		</Card>
    </>
	);
}
