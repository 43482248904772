// @ts-nocheck

import { Box } from "@chakra-ui/react";
import ArbitrumLogo from "assets/img/chain/arbitrum";
import AvaxLogo from "assets/img/chain/avalanche";
import BNBLogo from "assets/img/chain/binance";
import CoinbaseLogo from "assets/img/chain/coinbase";
import CronosLogo from "assets/img/chain/cronos";
import ETHLogo from "assets/img/chain/ethereum";
import FTMLogo from "assets/img/chain/fantom";
import OpLogo from "assets/img/chain/optimism";
import PalmLogo from "assets/img/chain/palm";
import MaticLogo from "assets/img/chain/polygon";
import RoninLogo from "assets/img/chain/ronin";
import { chainLogoData } from "assets/img/chain/types";

export type ChainLogoT = "eth"|"bnb"|"ftm"|"avax"|"arbi"|"op"|"coin"|"palm"|"cro"|"ron"|"matic"|"tbnb"|"mumbai";

const Logo = (props: { width?: string, height?: string, chain: ChainLogoT|string }) => {
  const { width, height, chain } = props;

  switch(chain) {
    case "arbi":
      return(
        <ArbitrumLogo width={width} height={height} />
      )
    case "avax":
      return(
        <AvaxLogo width={width} height={height} />
      )
    case "bnb":
      return(
        <BNBLogo width={width} height={height} />
      )
    case "tbnb":
      return(
        <BNBLogo width={width} height={height} />
      )
    case "coin":
      return(
        <CoinbaseLogo width={width} height={height} />
      )
    case "cro":
      return(
        <CronosLogo width={width} height={height} />
      )
    case "eth":
      return(
        <ETHLogo width={width} height={height} />
      )
    case "ftm":
      return(
        <FTMLogo width={width} height={height} />
      )
    case "matic":
      return(
        <MaticLogo width={width} height={height} />
      )
    case "mumbai":
      return(
        <MaticLogo width={width} height={height} />
      )
    case "op":
      return(
        <OpLogo width={width} height={height} />
      )
    case "palm":
      return(
        <PalmLogo width={width} height={height} />
      )
    case "ron":
      return(
        <RoninLogo width={width} height={height} />
      )
    default:
      return(
        <ETHLogo width={width} height={height} />
      )
  }
}

const ChainLogo = (props: { width?: string, height?: string, chain: ChainLogoT|string }) => {
  const { width, height, chain } = props;
  return (
    <Box
      alignItems={"center"}
      borderRadius={"full"}
      boxSizing={"border-box"}
      display={"flex"}
      justifyContent={"center"}
      overflow={"hidden"}
      position={"relative"}
      background={chainLogoData[chain]?.color}
    >
      <Logo
        width={width}
        height={height}
        chain={chain}
      />      
    </Box>
  )
};

export default ChainLogo;