import { useState } from "react"; 
import { Box, Button, Icon, Image, Link, SimpleGrid, Text, Tooltip, useColorModeValue, useToast } from "@chakra-ui/react"
import PricingPlan from "components/Pricing/PricingPlan";
import { v4 as uuidv4 } from "uuid";
import Card from 'components/card/Card';
import { links } from "helpers/links";
import { createLinkForEditApp, createPaymentLinkFromId, createWidgetLink, displayTime, getToast, hexToChain } from "helpers/formatters";
import { FiCopy } from "react-icons/fi";
import { HSeparator } from "components/separator/Separator";
import WidgetLinkDrawer from "components/Drawers/WidgetLinkDrawer";
import { BananaPay } from "bananapay-types";


const Pricing = (props: { app: BananaPay.App }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.500', 'gray.300');

  const { app } = props;
  const toast = useToast();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  
	const displayAmount = (paymentOption: BananaPay.App.PaymentOption): string => {
		if(paymentOption.useUsdValue) return `$${paymentOption.usdValue}`;
    const tickerAmounts = new Map();
    paymentOption.paymentTokens.map(paymentToken => {
      const tokenTicker = paymentToken.token.ticker;
      const amount = paymentToken.amount;
      const existingAmount = tickerAmounts.get(tokenTicker) ?? 0;
      tickerAmounts.set(tokenTicker, existingAmount + amount);
    });
    return [...tickerAmounts.entries()]
      .map(([ticker, amount]) => `${amount} ${ticker}`)
      .join(', ');
	}

	const displayTokens = (paymentOption: BananaPay.App.PaymentOption) => {
		return paymentOption.paymentTokens.map((paymentToken) => {
			return {
				img: paymentToken.token.logo,
				ticker: paymentToken.token.ticker,
				name: paymentToken.token.name
			}
		});
	}

  return (
    <Box mt="16">
      <Text mb="2" fontSize={"2xl"} fontWeight={"extrabold"} color={textColorSecondary}>Pricing Plans</Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, '2xl': 3 }} gap='20px' mb='20px'>
        {app.paymentOptions.map((plan, index) => {
          return(
            <PricingPlan
              key={uuidv4()}
              subscriptionId={app.subscription_id}
              name={app.name}
              plan={plan}
              title={plan.name}
              desc={plan.description}
              button='Subscribe'
              subscribeLink={createPaymentLinkFromId(app.defaultPaymentLinks.subscribe[index])}
              price={
                  <Box>
										<Text textAlign='start' w='100%' color={textColorPrimary} fontSize='40px' fontWeight='bold'>
											{displayAmount(plan)}
											<Text as='span' color='secondaryGray.600' fontSize='40px' fontWeight='bold'>
												/{displayTime(plan.paymentInterval)}
											</Text>
										</Text>
										
                    <Text color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>Accepted Tokens: </Text>
										<SimpleGrid gap="10px" columns={8}>
											{displayTokens(plan).map((val, index) => {
												return(
													<Box>
														<Tooltip label={`${val.name} (${val.ticker})`} aria-label='A tooltip'>
															<Image
                                rounded={"full"}
																src={val.img}
																alt={val.ticker}
																width={"10"}
																height={"10"}
															/>
														</Tooltip>
													</Box>
												)
											})}
										</SimpleGrid>
									</Box>
              }
              planId={index}
              isPublic={!plan.isHidden}
              isAdmin={true}
              details='(Per subscriber per month)'
              benefits={[
                'Sell on your own terms',
                'Website, marketing tools & automations',
                'Bandwidth & storage is included',
                "We'll get you onboarded"
              ]}
            />
          )
        })}
        <Card textAlign={"center"} py="auto">
          <Text mb="2" color={textColorPrimary} fontWeight='extrabold' fontSize='xl'>
            Edit Subscription Plans
          </Text>
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            Add, remove and edit subscription plans.
          </Text>
          <Link href={`/#${createLinkForEditApp(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button
              variant={"brand"}
              mt="2"
              w="min-content"
            >
              Edit
            </Button>
          </Link>
          <HSeparator my="6" />
          <Text mb="2" color={textColorPrimary} fontWeight='extrabold' fontSize='xl'>
            General Integration Link:
          </Text>
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            This widget link allows user to select the subscription plan they want in the widget.
          </Text>
          <Button
            variant={"brand"}
            mt="2"
            w="min-content"
            mx="auto"
          >
            <Icon as={FiCopy} color={"white"} h='18px' w='18px' mr="10px" />
            Copy
          </Button>
          <HSeparator my="6" />
          <Text mb="2" color={textColorPrimary} fontWeight='extrabold' fontSize='xl'>
            Cancel Subscription Link:
          </Text>
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            This widget link allows user cancel subscription.
          </Text>
          <Button
            variant={"brand"}
            mt="2"
            w="min-content"
            mx="auto"
            onClick={function() {
              navigator.clipboard.writeText(createPaymentLinkFromId(app.defaultPaymentLinks.cancel));
              toast(getToast("success", "Copied!", "Cancel subscription widget link copied!"));
            }}
          >
            <Icon as={FiCopy} color={"white"} h='18px' w='18px' mr="10px" />
            Copy Cancel Subscription Link
          </Button>
        </Card>
      </SimpleGrid>
      <Button variant={"darkBrand"} onClick={openDrawer} mt="4">
        Create Custom Widget Link
      </Button>
      <WidgetLinkDrawer
        isOpen={isDrawerOpen}
        close={closeDrawer}
        app={app}
      />
    </Box>
  );
};

export default Pricing;