import { Box, Link, Text, useColorModeValue } from "@chakra-ui/react";
import ChainTag from "components/chain/ChainTag";
import Card from 'components/card/Card';
import SubscriptionStatusTag from "components/other/SubscriptionStatusTag";
import { HSeparator } from "components/separator/Separator";
import { BananaPay } from "bananapay-types";


const AboutPanel = (props: { app: BananaPay.App }) => {
  const { app } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

  return(
    <Box px="8" mx="2" pb="8">
      <Card>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          {app.name}
        </Text>
        <Text color={textColorSecondary} fontSize='md' me='26px' mb='25px'>
          {app.description}
        </Text>
      </Card>
    </Box>
  )
};

export default AboutPanel;