/*!
""

*/
// Chakra imports
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, useColorModeValue, Text, InputGroup, InputRightElement, Icon, useToast, Link } from '@chakra-ui/react';
import { useUser } from 'contexts/UserContext';
import { getToast, useQuery } from 'helpers/formatters';
import { links } from 'helpers/links';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import { useEffect, useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

function SetEmail() {
	// Chakra color mode
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorSecondary = 'gray.400';
	const brandStars = useColorModeValue('brand.500', 'brand.400');

	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);

	const [email, setEmail] = useState<string>("");

	const { user, saveEmail, signOut } = useUser();
	const history = useHistory();
	const toast = useToast();
	const query = useQuery();

	const handleEmailUpdate = async() => {
		try{
			if(email) {
				await saveEmail(email);
				const to = query.get("to");
				toast(getToast("info", "Must be signed in!", "Sign in first, redirecting you to sign in page."));
				const redirectTo = to ? `${links.signInCentered}?to=${to}` : `${links.signInCentered}?to=${links.profileOverview}`;
				console.log(redirectTo);
				history.push(redirectTo);
			}
		}catch(err){
			await signOut();
			toast(getToast("error", "ERROR!", "You need to be logged in recently to be able to change your password!"));
			const to = query.get("to");
			const redirectTo = to ? `${links.signInCentered}?to=${links.setEmailCentered}_to=${to}` : `${links.signInCentered}?to=${links.setEmailCentered}`
			history.push(redirectTo);
		}
	}

	useEffect(() => {
		if(!user) {
			const to = query.get("to");
			toast(getToast("info", "Must be signed in!", "Sign in first, redirecting you to sign in page."));
			const redirectTo = to ? `${links.signInCentered}?to=${links.setEmailCentered}_to=${to}` : `${links.signInCentered}?to=${links.setEmailCentered}`
			history.push(redirectTo);
		}
	}, [user]);

	return (
		<CenteredAuth
			image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
			cardTop={{ base: '140px', md: '24vh' }}
			cardBottom={{ base: '50px', lg: 'auto' }}>
			<Flex
				w='100%'
				maxW='max-content'
				me='auto'
				h='100%'
				alignItems='start'
				justifyContent='center'
				px={{ base: '25px', md: '0px' }}
				flexDirection='column'>
				<Box me='auto' mb='34px'>
					<Heading color={textColor} fontSize={{ base: '3xl', md: '36px' }} mb='16px'>
						Set Email
					</Heading>
					<Text color={textColorSecondary} fontSize='md' w={{ base: '100%', lg: '456px' }} maxW='100%'>
						Set your email to be able to receive notifications about your subscriptions from BananaPay!
					</Text>
				</Box>
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', lg: '456px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}
					align='start'>
					<FormControl>
						<FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
							Email<Text color={brandStars}>*</Text>
						</FormLabel>
						<Input
							value={email}
							onChange={e => setEmail(e.target.value)}
							isRequired={true}
							variant='auth'
							fontSize='sm'
							type='email'
							placeholder='mail@simmmple.com'
							mb='24px'
							size='lg'
						/>
						<Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='15px' onClick={handleEmailUpdate}>
							Set Email
						</Button>
						<Link href={`/#${query.get("to") || links.home}`}>
							<Button fontSize='sm' fontWeight='500' w='100%' h='50' mb='12px'>
								I'll do it later
							</Button>
						</Link>
					</FormControl>
				</Flex>
			</Flex>
		</CenteredAuth>
	);
}

export default SetEmail;
