export interface ILogoProps {
  width?: string;
  height?: string;
}

//"eth"|"bnb"|"ftm"|"avax"|"arbi"|"op"|"coin"|"palm"|"cro"|"ron"|"matic"|"tbnb"|"mumbai";

export const chainLogoData = {
  arbi: {
      name: 'arbitrum',
      color: '#003470',
  },
  ftm: {
      name: 'fantom',
      color: '#0F7FFF',
  },
  avax: {
      name: 'avalanche',
      color: '#DE0000',
  },
  matic: {
      name: 'polygon',
      color: '#9B22FF',
  },
  mumbai: {
      name: 'polygon',
      color: '#9B22FF',
  },
  bnb: {
      name: 'binance',
      color: '#EBBB00',
  },
  tbnb: {
      name: 'binance',
      color: '#EBBB00',
  },
  eth: {
      name: 'ethereum',
      color: '#396993',
  },
  cro: {
      name: 'cronos',
      color: '#041836',
  },
  coin: {
      name: 'coinbase',
      color: '#333dfb',
  },
  ron: {
      name: 'ronin',
      color: '#019EC9',
  },
  op: {
      name: 'optimism',
      color: '#E95557',
  }, 
  palm : {
      name:'palm',
      color:'#6818AA',
  },

};