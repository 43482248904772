import React, { useState, createContext, useContext, useEffect } from "react";
import { firestore, functions } from "../helpers/firebase";
import { query, collection, onSnapshot } from "firebase/firestore";
import { useUser } from "./UserContext";
import { httpsCallable } from "firebase/functions";
import { AllUpcomingEvents, SubscriptionPlanRes, UpcomingEvent, UpcomingEventsData } from "helpers/types";
import { chainToHex, chainToNumber, hasTokenAddress, hexToChain, isEventStuck, isObjectInArray, sortEvents } from "helpers/formatters";
import { useApps } from "./AppsContext";
import { BananaPay } from "bananapay-types";

interface EventsProviderProps {
  children: React.ReactNode
}

interface EventsContextProps {
  events: any;
  getEventsForUser: (chain: "0x13881"|"0x61"|"0xfa2") => any[] | null;
  getEventsForUserAndApp: (subscriptionId: string) => any[] | null;
  getUpcomingEvents: (subscriptionId: string) => Promise<UpcomingEventsData | null>;
  getAllUpcomingEvents: () => Promise<AllUpcomingEvents | null>;
  getAllEventsForUser: () => any[] | null;
  getEventById: (eventId: string) => any | null;
  getIsUserSubscribedToApp: (subscriptionId: string) => boolean;
  getSubscriptionPlan: (app: BananaPay.App, seconds?: number) => SubscriptionPlanRes | null;
  getPricingPlanFromEvent: (eventData: any) => SubscriptionPlanRes | null;
  getEventsForPaymentLink: (customers: BananaPay.Customer[], paymentLink: BananaPay.PaymentLink) => BananaPay.Event.BaseEvent[];
  getEventsForAddressAndApp: (address: string, subscriptionId: string, chain: BananaPay.Chain) => BananaPay.Event.BaseEvent[];
  getAllEventsForApp: (subscriptionId: string) => BananaPay.Event.BaseEvent[];
}

const EventsContext = createContext<EventsContextProps>({
  events: null,
  getEventsForUser: (chain) => [],
  getEventsForUserAndApp: (subscriptionId) => [],
  getUpcomingEvents: (subscriptionId) => new Promise(() => null),
  getAllUpcomingEvents: () => new Promise(null),
  getAllEventsForUser: () => [],
  getEventById: (eventId: string) => null,
  getIsUserSubscribedToApp: (subscriptionId) => false,
  getSubscriptionPlan: (app) => null,
  getPricingPlanFromEvent: (eventData) => null,
  getEventsForPaymentLink: (customers, paymentLink) => [],
  getEventsForAddressAndApp: (address, subscriptionId, chain) => [],
  getAllEventsForApp: (subscriptionId) => []
});

export const EventsProvider = ({ children }: EventsProviderProps) => {

  const [events, setEvents] = useState<any[] | null>(null);
  const [upcoming, setUpcoming] = useState<UpcomingEventsData | null>(null);
  const [allUpcoming, setAllUpcoming] = useState<AllUpcomingEvents | null>(null);

  const { user, isSignedIn } = useUser();
  const { getAppDetails, getAppDetailsFromAddressAndChain } = useApps();

  useEffect(() => {
    if(isSignedIn && user) {
      const q = query(collection(firestore, "moralis/events/Paymentevents"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const _events: any[] = [];
        querySnapshot.forEach((doc) => {
          const documentData = doc.data() as BananaPay.Event.BaseEvent;
          if(!isEventStuck(documentData)) {
            _events.push(documentData);
          }
        });
        // sort events
        _events.sort((a: any, b: any) => {
          if (Number(a.blockTimestamp) - Number(b.blockTimestamp) === 0) {
            // emitted in same block - find true sequence
            if(a.name === "Unsubscribed" || b.name === "Subscribed") {
              // return b first
              return 1;
            }else{
              // return a first
              return -1;
            }
          }
          // emitted in different blocks - OK
          return Number(a.blockTimestamp) - Number(b.blockTimestamp);
        });
        setEvents(_events);
      });

      //Stop realtime updates:
      return () => unsubscribe();
    }
  }, [isSignedIn]);

  const getEventsForUser = (chain: "0x13881"|"0x61"|"0xfa2") => {
    if(isSignedIn && user) {
      if(!events) return null;
      const _chain = chain==="0x13881" ? 80001 : (chain==="0x61" ? 97 : 4002);
      return events.filter((val: any) => {
        if(val.subscriber.toLowerCase() === user.displayName?.toLowerCase() &&
          val.chainId === _chain &&
          !isEventStuck(val as BananaPay.Event.BaseEvent)
        ) {
          return val;
        }
      });
    }else{
      return null;
    }
  };

  const getAllEventsForUser = () => {
    if(isSignedIn && user) {
      if(!events) return null;
      return events.filter((val: any) => {
        if(val.subscriber.toLowerCase() === user.displayName?.toLowerCase() && val.name !== "ExtensionCall" && !isEventStuck(val as BananaPay.Event.BaseEvent)) {
          return val;
        }
      }).reverse();
    }else{
      return null;
    }
  }

  const getEventsForUserAndApp = (subscriptionId: string) => {
    if(isSignedIn && user) {
      if(!events) return null;
      const app = getAppDetails(subscriptionId);
      if(!app) return null;
      return events.filter((val: any) => {
        if(val.subscriber.toLowerCase() === user.displayName?.toLowerCase() &&
          app.receivers[chainToHex(val.chainId)].address.toLowerCase() === val.channel.toLowerCase() &&
          val.name !== "ExtensionCall" &&
          !isEventStuck(val as BananaPay.Event.BaseEvent)
        ) {
          return val;
        }
      });
    }else{
      return null;
    }
  }

  const getEventsForAddressAndApp = (address: string, subscriptionId: string, chain: BananaPay.Chain) => {
    if(!events) return null;
    const app = getAppDetails(subscriptionId);
    if(!app) return null;
    return events.filter((val: any) => {
      if(val.subscriber.toLowerCase() === address.toLowerCase() &&
        app.receivers[chainToHex(val.chainId)].address.toLowerCase() === val.channel.toLowerCase() &&
        val.name !== "ExtensionCall" &&
        chainToHex(val.chainId) === chain &&
        !isEventStuck(val as BananaPay.Event.BaseEvent)
      ) {
        return val;
      }
    });
  }

  const getUpcomingEvents = async(subscriptionId: string): Promise<UpcomingEventsData | null> => {
    // check if there is data in allUpcoming before calling
    if(subscriptionId && !upcoming) {
      const _events = getEventsForUserAndApp(subscriptionId);

      if(_events && user) {
        if(_events.length>0) {
          if(_events[_events.length-1]?.name==="Unsubscribed" ||
            _events[_events.length-1]?.name==="PaymentFailed"
          ) {
            return null;
          }else{
            const upcomingEventsF = httpsCallable(functions, "getUpcomingEvents");
            const params = {
              subscriptionId: subscriptionId,
              userAddress: user.displayName,
              events: _events
            }
        
            let toReturn: UpcomingEventsData | null;

            try{
              const result = await upcomingEventsF(params)
              const data: UpcomingEventsData = result.data as UpcomingEventsData;
              console.log("Upcoming events");
              console.log(data);
              toReturn = data;
              setUpcoming(data);
              return toReturn;
            }catch(err){
              const error: any = err;
              const code = error.code;
              const message = error.message;
              const details = error.details;
              console.log(code, message, details);
              setUpcoming(null);
              return null;
            }
          }
        }else{
          return null;
        }
      }else{
        return null;
      }
    }else{
      return upcoming;
    }
  }

  const getEventById = (eventId: string) => {
    if(events) {
      let event: any = null;
      events.map((x) => {
        if(x.id.toLowerCase() === eventId.toLowerCase()) event = x;
      });
      return event;
    }
    return null;
  }

  const getIsUserSubscribedToApp = (subscriptionId: string) => {
    if(events) {
      const _events = getEventsForUserAndApp(subscriptionId);
      if(!_events) return false;
      if(_events.length === 0) return false;
      const lastEvent = _events[_events?.length-1];
      if(lastEvent.name === "Unsubscribed" || lastEvent.name === "PaymentFailed") return false;
      else return true;
    }
    return false;
  }

  const getAllUpcomingEvents = async() => {
    if(!allUpcoming) {
      const _events = getAllEventsForUser();

      if(_events && user) {
        if(_events.length>0) {
          const upcomingEventsF = httpsCallable(functions, "getAllUpcomingEvents");
          const params = {
            userAddress: user.displayName,
            events: _events
          }
      
          let toReturn: AllUpcomingEvents | null = null;

          try{
            const result = await upcomingEventsF(params)
            const data: any = result.data;
            const upcomingEvents = data.upcomingEvents;
            console.log("All Upcoming events");
            console.log(upcomingEvents);
            toReturn = upcomingEvents;
            setAllUpcoming(upcomingEvents);
            return toReturn;
          }catch(err){
            const error: any = err;
            const code = error.code;
            const message = error.message;
            const details = error.details;
            console.log(code, message, details);
            setAllUpcoming(null);
            return null;
          }
        }
      }else{
        return null;
      }
    }else{
      return allUpcoming;
    }
  }

  const getPaymentPlan = (
    app: BananaPay.App,
    tokenAddress: string,
    paymentAmount: string,
    paymentInterval: string,
    useUsdValue: boolean,
  ) : {
    plan: BananaPay.App.PaymentOption | null;
    planId: number | null;
    paymentToken: BananaPay.App.PaymentToken | null;
  } => {
    const allPlans = app.paymentOptions;
    let planToReturn = null;
    let planId = null;
    let _paymentToken = null;
    
    if(allPlans) {
      allPlans.map((plan, index) => {
        if(plan.useUsdValue === useUsdValue) {
          plan.paymentTokens.map((paymentToken) => {
            let amount = paymentToken.amount * (10**paymentToken.token.decimals);
            if(plan.useUsdValue) {
              amount = Math.round(plan.usdValue * (10**8));
            }
            if(
              hasTokenAddress(paymentToken?.token?.address, tokenAddress?.toLowerCase()) &&
              amount?.toString() === paymentAmount &&
              plan?.paymentInterval?.toString() === paymentInterval
            ) {
              planToReturn = plan;
              planId = index;
              _paymentToken = paymentToken;
            }
          });
        }
      });
    }
  
    return {
      plan: planToReturn,
      planId: planId,
      paymentToken: _paymentToken
    };
  };
  
  const getSubscriptionPlan = (app: BananaPay.App, seconds?: number): SubscriptionPlanRes | null => {
    const defaultReturn: SubscriptionPlanRes = {
      isSubscribed: false,
      plan: null,
      planId: null,
      nextSeconds: null,
      paymentToken: null
    }

    const userEvents = getEventsForUserAndApp(app.subscription_id);
    if(!userEvents) {
      console.log("default return")
      return defaultReturn;
    }
    const lastEvent = userEvents[userEvents.length-1];
    const isSubscribed = (lastEvent?.name === "Unsubscribed" || lastEvent?.name === "PaymentFailed" || !lastEvent) ? false : true;
    let lastSubscribed: any = null;
    userEvents.map((e) => {
      if(e.name === "Subscribed") {
        if(seconds>0) {
          if(e.updatedAt.seconds <= seconds) {
            lastSubscribed = e;
          }
        }else{
          lastSubscribed = e;
        }
      }
    });

    if(lastSubscribed) {
      if(app) {
        // get plan
        console.log(app, lastSubscribed.paymentToken, lastSubscribed.amount, lastSubscribed.paymentInterval, lastSubscribed.useUsdValue==="true" ? true : false)
        const { plan, planId, paymentToken } = getPaymentPlan(app, lastSubscribed.paymentToken, lastSubscribed.amount, lastSubscribed.paymentInterval, lastSubscribed.useUsdValue==="true" ? true : false);

        return {
          isSubscribed: isSubscribed,
          plan: plan,
          planId: planId,
          nextSeconds: lastEvent?.nextPayment,
          paymentToken: paymentToken
        }
      }else{
        return {
          isSubscribed: isSubscribed,
          plan: null,
          planId: null,
          nextSeconds: lastEvent?.nextPayment,
          paymentToken: null
        };
      }
    }else{
      return {
        isSubscribed: isSubscribed,
        plan: null,
        planId: null,
        nextSeconds: null,
        paymentToken: null
      };
    }
  };

  
  const getPricingPlanFromEvent = (event: any): SubscriptionPlanRes | null => {
    /*let useUsdValue = false;
    let toReturn: SubscriptionPlanRes | null = null;
    if(event.eventName === "Subscribed") useUsdValue = event.useUsdvalue;
    else useUsdValue = event.paymentAmount !== event.amount;
    getAppDetails(event.channel, event.chainId)?.paymentOptions.map((option: PaymentOption, index) => {
      if(option.useUsdValue === useUsdValue) {
        const i = option.paymentTokens.findIndex(e => e.token.address.toLowerCase() === event.tokenAddress.toLowerCase());
        if(i > -1) {
          if(useUsdValue) {
            if(option.usdValue * 10**8 === Number(event.amount)) {
              toReturn = {
                plan: option,
                nextSeconds: Number(event.nextPayment) || 0,
                paymentToken: option.paymentTokens[i],
                planId: index,
                isSubscribed: true
              };
            }
          }else{
            const decimals = option.paymentTokens[i].token.decimals;
            if(Number(event.amount) === option.paymentTokens[i].amount * 10**decimals) {
              toReturn = {
                plan: option,
                nextSeconds: Number(event.nextPayment) || 0,
                paymentToken: option.paymentTokens[i],
                planId: index,
                isSubscribed: true
              };
            }
          }
        }
      }
    });
    return toReturn;*/

    const app = getAppDetailsFromAddressAndChain(event.channel, chainToHex(chainToNumber(event.chainId)));
    if(app) return getSubscriptionPlan(app, event?.updatedAt?.seconds);
    else return {
      isSubscribed: null,
      plan: null,
      planId: null,
      nextSeconds: null,
      paymentToken:null
    }
  };

  const getEventsForPaymentLink = (customers: BananaPay.Customer[], paymentLink: BananaPay.PaymentLink) => {
    const eventsToReturn: BananaPay.Event.BaseEvent[] = [];

    customers.forEach(customer => {
      // get all subscried event IDs for this payment link
      const subscribedEventsForLink = customer.events.subscribed_events.filter((event, index) => customer.payment_link[index] === paymentLink.id);
      
      // get all events for customer and subscription
      const allCustomerEvents = sortEvents(getEventsForAddressAndApp(customer.address, paymentLink.subscription_id, customer.chain) as BananaPay.Event.BaseEvent[]);

      // identify events from subscribedEvent till first unsubscribe event
      const events: BananaPay.Event.BaseEvent[] = [];
      let found = false;
      subscribedEventsForLink.forEach(subscribedEvent => {
        allCustomerEvents.forEach(e => {
          if(e.id === subscribedEvent) {
            events.push(e);
            found = true;
          }else{
            if(found) {
              events.push(e);
              if(e.name==="Unsubscribed") {
                found = false;
              }
            }
          };
        });
      });
      eventsToReturn.push(...events);
    });

    return sortEvents(eventsToReturn).reverse();
  }

  const getAllEventsForApp = (subscriptionId: string) => {
    const app = getAppDetails(subscriptionId);
    
    const _events: BananaPay.Event.BaseEvent[] = [];

    for(const _chain in app.receivers) {
      const chain = _chain as BananaPay.Chain;
      const _e = events.filter((val: any) => {
        if(val.channel.toLowerCase() === app.receivers[chain].address.toLowerCase() &&
          chainToHex(val.chainId) === chain &&
          !isEventStuck(val as BananaPay.Event.BaseEvent)
        ) {
          return val;
        }
      });
      _events.push(..._e);
    }

    return sortEvents(_events);
  }
  

  return (
    <EventsContext.Provider
      value={{
        events,
        getEventsForUser,
        getEventsForUserAndApp,
        getUpcomingEvents,
        getAllUpcomingEvents,
        getAllEventsForUser,
        getEventById,
        getIsUserSubscribedToApp,
        getSubscriptionPlan,
        getPricingPlanFromEvent,
        getEventsForPaymentLink,
        getEventsForAddressAndApp,
        getAllEventsForApp
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => {
  return useContext(EventsContext);
}