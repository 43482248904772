// Chakra imports
import { Box, Icon, Image, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import Dropzone from 'views/admin/main/ecommerce/newPlan/components/Dropzone';

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';

export default function ImageInput(props: { type: "Logo"|"Banner", image: string, setImage: (newImage: string) => void }) {
	const { type, image, setImage } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brand = useColorModeValue('brand.500', 'brand.400');

	return (
		<Card p='30px' mb="20px">
			<Text color={textColor} fontSize='xl' fontWeight='700' mb='30px'>
				Subscription {type}
			</Text>
			<Dropzone
				file={image}
				onChange={setImage}
				content={
					<Box>
						{image ? (
							<Image
								src={image}
								h='100px'
								alt="Uploaded file"
								mb="2"
								mx="auto"
							/>
						):(
							<Icon as={MdOutlineCloudUpload} w='80px' h='80px' color={textColor} />
						)}
						<Text
							mx='auto'
							mb='12px'
							fontSize='lg'
							fontWeight='700'
							whiteSpace='pre-wrap'
							color={textColor}>
							Drop your files here, or{' '}
							<Text as='span' fontSize='lg' fontWeight='700' color={brand}>
								browse
							</Text>
						</Text>
						<Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
							PNG, JPG and GIF files are allowed
						</Text>
					</Box>
				}
			/>
		</Card>
	);
}
