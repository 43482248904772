import { Flex, Image, Text } from "@chakra-ui/react";
import { Chain } from "helpers/types";

// Assets
import ftm from "assets/svg/fantom.svg";
import matic from "assets/svg/matic.svg";
import bnb from "assets/svg/bnb.svg";


const ChainTag = (props: { chain: Chain }) => {
  const { chain } = props;

  switch(chain) {
    case "0x13881":
      return(
        <Flex flexDirection={"row"} backgroundColor={"purple.100"} textColor={"purple.700"} rounded={"full"} px="4" py="2" w="fit-content">
          <Image h="6" w="6" mr="3" rounded={"full"} src={matic} alt="Matic Logo" />
          <Text fontWeight={"800"}>Mumbai</Text>
        </Flex>
      );
    case "0x61":
      return(
        <Flex flexDirection={"row"} backgroundColor={"yellow.100"} textColor={"yellow.700"} rounded={"full"} px="4" py="2" w="fit-content">
          <Image h="6" w="6" mr="3" rounded={"full"} src={bnb} alt="BNB Logo" />
          <Text fontWeight={"800"}>BNB Testnet</Text>
        </Flex>
      );
    case "0xfa2":
      return(
        <Flex flexDirection={"row"} backgroundColor={"blue.100"} textColor={"blue.700"} rounded={"full"} px="4" py="2" w="fit-content">
          <Image h="6" w="6" mr="3" rounded={"full"} src={ftm} alt="FTM Logo" />
          <Text fontWeight={"800"}>Fantom</Text>
        </Flex>
      );
    default:
      return(
        <Flex flexDirection={"row"} backgroundColor={"purple.100"} textColor={"purple.700"} rounded={"full"} px="4" py="2" w="fit-content">
          <Image h="6" w="6" mr="3" rounded={"full"} src={matic} alt="Matic Logo" />
          <Text fontWeight={"800"}>Mumbai</Text>
        </Flex>
      );
  }
};

export default ChainTag;