import { useMemo } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Flex, FormLabel, Icon, Select, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { BananaPay } from 'bananapay-types';
import Card from 'components/card/Card';
import ChainLogo from 'components/chain/ChainLogo';
import IconBox from 'components/icons/IconBox';
import { useStats } from 'contexts/StatsContext';
import { useUser } from 'contexts/UserContext';
import { allChains } from 'helpers/chains';
import { createLinkForCustomerOverview, createLinkForDashboard, createLinkForSubscribersOverview, getToast, hexToChain, useQuery } from 'helpers/formatters';
import { useState, useEffect } from "react";
import { BsClipboardData } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import SearchTableUsers from 'views/admin/main/users/overview/components/SearchTableUsersOverivew'; 
import { useApps } from "contexts/AppsContext";
import { links } from "helpers/links";
import MetadataInput from "components/fields/MetadataField";
import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import Integrate from "./components/Integrate";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";


type RowObj = {
	address: string;
	chain: string;
	date: string;
	status: string;
	plan: string
	actions: string;
};

export default function UsersOverview() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

	const { isLoading, getCustomers, getSearchCustomers } = useStats();
	const query = useQuery();
	const history = useHistory();
	const { user } = useUser();
	const toast = useToast();
	const { apps, getAppDetails } = useApps();

	const [customers, setCustomers] = useState<BananaPay.Customer[] | null>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [tableData, setTableData] = useState<RowObj[] | null>(null);
	const [customerInput, setCustomerInput] = useState<Partial<BananaPay.Customer> | null>(null);
	const [searchCustomers, setSearchCustomers] = useState<BananaPay.Customer[] | null | null>(null);
	const [hasMoreSearch, setHasMoreSearch] = useState<boolean>(false);
	const [searchTableData, setSearchTableData] = useState<RowObj[] | null>(null);

	const getJoinDate = (d: Date) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
	}

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, getAppDetails]);

	const getActiveNumber = (_customer: BananaPay.Customer[]) => {
		let active = 0;

		_customer.map(x => {
			if(x.subscription.subscribed) {
				active += 1;
			}
		});

		return active;
	}

	const fetchCustomers = async(startAfter: string) => {
		const res = await getCustomers(app.subscription_id, startAfter);
		if(res) {
			const _customers = res[app.subscription_id];
			setCustomers(_customers.customers);
			setHasMore(_customers.hasMore);
			setIsLoaded(true);
		}else{
			console.log("no res");
		}
	}

	const searchMore = async(startAfter: string) => {
		const searchData = {...customerInput};
		if(customerInput?.subscription?.subscribed === undefined) {
			searchData.subscription.subscribed = false;
		}
		console.log("search data: ", customerInput);
		const res = await getSearchCustomers(app.subscription_id, startAfter, customerInput);
		const _customers = res[app.subscription_id];
		console.log("search customers ", _customers);
		setSearchCustomers(_customers.customers);
		setHasMoreSearch(_customers.hasMore);
		setIsLoaded(true);
	}

	useEffect(() => {
		if(customers && app) {
			const data: RowObj[] = customers.map((x) => {
				return{
					address: x.address,
					chain: hexToChain(x.chain).charAt(0).toUpperCase() + hexToChain(x.chain).slice(1),
					status: x.subscription.subscribed ? "Subscribed" : "Not Subscribed",
					date: getJoinDate(new Date(x.subscription.subscribed_at[x.subscription.subscribed_at.length-1] * 1000)),
					actions: `/#${createLinkForCustomerOverview(app.subscription_id, x.id)}`,
					plan: app?.paymentOptions[x.subscription.plan_id[x.subscription.plan_id.length-1]]?.name
				}
			});
			setTableData(data);
		}
	}, [customers, app]);

	useEffect(() => {
		if(searchCustomers && app) {
			const data: RowObj[] = searchCustomers.map((x) => {
				return{
					address: x.address,
					chain: hexToChain(x.chain).charAt(0).toUpperCase() + hexToChain(x.chain).slice(1),
					status: x.subscription.subscribed ? "Subscribed" : "Not Subscribed",
					date: getJoinDate(new Date(x.subscription.subscribed_at[x.subscription.subscribed_at.length-1] * 1000)),
					actions: "More Details",
					plan: app?.paymentOptions[x.subscription.plan_id[x.subscription.plan_id.length-1]]?.name
				}
			});
			setSearchTableData(data);
		}
	}, [searchCustomers, app]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

  useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
        (async function () {
          // get customers
					await fetchCustomers("");
				})();
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.subscribersOverview}_subscriptionId=${subscriptionId}` : `${links.signInCentered}?to=${links.subscribersOverview}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user]);

	return (
		<Flex direction='column' pt={"75px"}>
			<BreadCrumpStrip
				additional={{mb: "4"}}
				links={[{
					href: `/#${createLinkForDashboard(app?.subscription_id)}`,
					name: "Dashboard"
				},{
					href: `/`,
					name: "All Subscribers"
				}]}
			/>
			<Card px='0px'>
				<Box px="8">
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
						All Subscribers
					</Text>
					<Text color={textColorSecondary} fontSize='md' me='26px' mb='25px'>
						List of all user that are subscribed to your subscription. All data in one place for ease of use & monitoring!
					</Text>
				</Box>
				{customers?.length>0 && !isLoading && tableData && isLoaded ? (
					<SearchTableUsers
						tableData={tableData}
						activeSubscribers={getActiveNumber(customers).toString()}
						hasMore={hasMore}
						fetchMore={() => fetchCustomers(customers[customers.length-1].id)}
						placeholder="Find in the table"
						search={false}
					/>
				):(
					isLoading || !isLoaded ? (
						<Center mx="auto" flexDirection={"column"} my="8">
							<Spinner
								thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='brand.500'
								size="xl"
								mx="auto"
							/>
							<Text textAlign={"center"}>Loading Data . . .</Text>
						</Center>
					):null
				)}
				{!isLoading && isLoaded && customers?.length===0 ? (
					<Box mb="8" mt="8" mx="auto">
						<Center mb="2">
							<Icon as={BsClipboardData} color={textColorPrimary} fontSize={"7xl"} mx="auto" w="min-content" textAlign={"center"} />
						</Center>
						<Text fontSize={"3xl"} fontWeight="900" color={textColorPrimary} textAlign="center">No Subscribers Yet</Text>
						<Text fontSize={"lg"} fontWeight="300" color={textColorPrimary} textAlign="center">Your subscription does not have any subscribers left. Your first subscriber awaits - embrace the journey, inspire the world!</Text>
					</Box>
				):null}
			</Card>

			<Card mt="10" px="0px">
				<Accordion allowToggle outline={"none"}>
					<AccordionItem>
						<h2>
							<AccordionButton>
								<Text color={textColorPrimary} fontWeight='medium' fontSize='lg' mx="4">
									Advanced Search
								</Text>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>
							<Box px="8">
								<Text color={textColorSecondary} fontSize='md' me='26px' mb='25px' mt="3">
									Here you can search <u>all</u> your customers in one place by any field. You can also do this with BananaPay API.
								</Text>
							</Box>
							<Box px="8">
								<InputField
									mb="4"
									id='name'
									placeholder='0x...'
									label="Customer Address"
									value={customerInput?.address}
									onChange={(e: any) => setCustomerInput({...customerInput, address: e.target.value})}
								/>
								<FormLabel
									display='flex'
									ms='10px'
									fontSize='sm'
									color={textColorPrimary}
									fontWeight='bold'
									_hover={{ cursor: 'pointer' }}
								>
									Chain
								</FormLabel>
								<Select mb="4" value={customerInput?.chain || "1"} onChange={e => setCustomerInput({...customerInput, chain: e.target.value as BananaPay.Chain})}>
									{[...allChains].map(chain => {
										return(
											<option key={chain.id} value={chain.id}>{chain.name}</option>
										)
									})}
								</Select>
								<InputField
									mb='6'
									id='url'
									placeholder='eg. https://mydomain.com/...'
									label="Customer Email"
									value={customerInput?.email}
									onChange={(e: any) => setCustomerInput({...customerInput, email: e.target.value})}
								/>
								<TextField
									h='146px'
									mb='4'
									id='Description'
									placeholder='Description'
									label='Customer Description'
									value={customerInput?.description}
									onChange={(e: any) => setCustomerInput({...customerInput, description: e.target.value})}
								/>
								<FormLabel
									display='flex'
									ms='10px'
									fontSize='sm'
									color={textColorPrimary}
									fontWeight='bold'
									_hover={{ cursor: 'pointer' }}
								>
									Is Subscribed
								</FormLabel>
								<SwitchField
									isChecked={customerInput?.subscription?.subscribed}
									onChange={() => {
										setCustomerInput({
											...customerInput,
											subscription: {
												...customerInput?.subscription,
												subscribed: !customerInput?.subscription?.subscribed,
											},
										});
									}}
									reversed={true}
									fontSize='sm'
									mb='6'
									id='1'
									label='Subscribed'
								/>
								<Button
									variant={"darkBrand"}
									onClick={() => searchMore("")}
								>
									Search
								</Button>
							</Box>
							<Box>
								{searchCustomers?.length>0 && !isLoading && searchTableData && isLoaded ? (
									<Box>
										<Text px="8" color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='4' mb='2'>
											Result
										</Text>
										<SearchTableUsers
											tableData={searchTableData}
											activeSubscribers={getActiveNumber(searchCustomers).toString()}
											hasMore={hasMoreSearch}
											fetchMore={() => searchMore(searchCustomers[searchCustomers.length-1].id)}
											placeholder="Find in the table"
											search={true}
										/>
									</Box>
								):(
									isLoading || !isLoaded ? (
										<Center mx="auto" flexDirection={"column"} my="8">
											<Spinner
												thickness='4px'
												speed='0.65s'
												emptyColor='gray.200'
												color='brand.500'
												size="xl"
												mx="auto"
											/>
											<Text textAlign={"center"}>Searching . . .</Text>
										</Center>
									):null
								)}
								{!isLoading && isLoaded && searchCustomers?.length===0 ? (
									<Box mb="8" mt="8" mx="auto">
										<Center mb="2">
											<Icon as={BsClipboardData} color={textColorPrimary} fontSize={"7xl"} mx="auto" w="min-content" textAlign={"center"} />
										</Center>
										<Text fontSize={"3xl"} fontWeight="900" color={textColorPrimary} textAlign="center">No Results Found</Text>
										<Text fontSize={"lg"} fontWeight="300" color={textColorPrimary} textAlign="center">No subscriber matched your search parameters.</Text>
									</Box>
								):null}
							</Box>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Card>

			<Card mt="6" px="4">
				<Integrate />
			</Card>
		</Flex>
	);
}
