import { useState } from "react";

// Chakra imports
import { Center, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';

import Card from 'components/card/Card';

import { useUser } from 'contexts/UserContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { chainToHex, chainToNumber, formatNumber, strToColor } from 'helpers/formatters';
import { useEvents } from 'contexts/EventsContext';
import { useApps } from 'contexts/AppsContext';
import EventCalendar from 'components/calendar/EventCalendar';

type CalendarDataObj = {
	title: string,
	borderColor: "transparent",
	start: string,
	end: string,
	backgroundColor: string,
	className: "info" | "success" | "warning" | "error", // info success warning error
}

const EventsCalendar = () => {

  const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { getAllUpcomingEvents, events, getSubscriptionPlan } = useEvents();
	const { getAppDetailsFromAddressAndChain, apps } = useApps();

	const [calendarData, setCalendarData] = useState<CalendarDataObj[]>(null);
	const [currentMonth, setCurrentMonth] = useState<string>(null);

  const changeCurrentMonth = (plus: number) => {
		const date = new Date();
		const d = new Date(date.setMonth(date.getMonth()+plus));
		setCurrentMonth(`${d.getFullYear()}-${("0" + d.getMonth()+1).slice(-2)}-${("0" + d.getDate()).slice(-2)}`);
	}

	const getCurrentMonth = () => {
		const d = new Date();
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		return `${months[d.getMonth()]} ${d.getFullYear()}`;
	}

	useEffect(() => {
		if(events && apps && !calendarData){
			(async () => {
				const allUpcomingEvents = await getAllUpcomingEvents();
				const data: CalendarDataObj[] = [];

				for(const chain in allUpcomingEvents) {
					for(const appAddress in allUpcomingEvents[chain]) {
						const upcomingEvents = allUpcomingEvents[chain][appAddress];
						const appDetails = getAppDetailsFromAddressAndChain(appAddress, chainToHex(chainToNumber(chain)));

						upcomingEvents.map((upcomingEvent) => {
							const date = new Date(upcomingEvent.updatedAt.seconds*1000);
							const className = upcomingEvent.name === "Unsubscribed" ? "error" : (upcomingEvent.name === "PaymentFailed" ? "info" : "success");
							const token = getSubscriptionPlan(appDetails)?.paymentToken?.token;
							const tokenTicker = token?.ticker || "Tokens";
							const decimals = token?.decimals || 18;
							const amount = Number(upcomingEvent.paymentAmount) / (10**decimals);
							const secondStr = upcomingEvent.name === "Unsubscribed" ? "Unsubscribed" : (upcomingEvent.name === "PaymentFailed" ? "Failed" : `${formatNumber(amount, 3)} ${tokenTicker}`);
							data.push({
								title: `${appDetails?.name || "Unknown"} - ${secondStr}`,
								start: `${date.getFullYear()}-${("0" + date.getMonth()+1).slice(-2)}-${("0" + date.getDate()).slice(-2)}`,
								backgroundColor: strToColor(appDetails?.name || "Unknown"),
								borderColor: "transparent",
								end: `${date.getFullYear()}-${("0" + date.getMonth()+1).slice(-2)}-${("0" + date.getDate()).slice(-2)}`,
								className: className
							});
						})
					}
				}

				setCalendarData(data);
			})();
		}
	}, [events, apps]);

  useEffect(() => {
    console.log(currentMonth, calendarData);
  }, [currentMonth, calendarData]);

  useEffect(() => {
		changeCurrentMonth(0);
	}, []);

  return(
    <Card gridArea='1' minH='700px'>
      <Text fontSize='2xl' fontWeight='700' color={textColor}>
        Calendar
      </Text>
      <Text fontSize='md' fontWeight='500' color='secondaryGray.600' mb='30px'>
        {getCurrentMonth()}
      </Text>
      {calendarData && currentMonth ? (
        <EventCalendar initialDate={currentMonth} calendarData={calendarData} />
      ):(
        <Center mx="auto" flexDirection={"column"}>
          <Spinner
            thickness='10px'
            speed='0.65s'
            emptyColor='gray.200'
            color='brand.500'
            size="xl"
            mx="auto"
          />
          <Text textAlign={"center"}>Loading Calendar. . .</Text>
        </Center>
      )}
    </Card>
  );
};

export default EventsCalendar;