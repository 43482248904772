import React, { useState, createContext, useContext, useEffect } from "react";
import { app, functions } from "../helpers/firebase";
import { useToast } from '@chakra-ui/react'
import { chainToHex, chainToNumber, checkIfAppOk, getToast, useQuery } from "../helpers/formatters";
import { httpsCallable } from "firebase/functions";
import { useUser } from "./UserContext";
import { ChannelData, Stats } from "helpers/types";
import { useApps } from "./AppsContext";
import { BananaPay } from "bananapay-types";

interface StatsProviderProps {
  children: React.ReactNode
}

interface CustomersRes {
  hasMore: boolean;
  customers: BananaPay.Customer[];
}

interface PaymentLinksRes {
  hasMore: boolean;
  paymentLinks: BananaPay.PaymentLink[];
}

interface ResponseObj {
  success: boolean;
  data: ChannelData | null;
}

interface StatsContextProps {
  subscriptionId: string | null,
  isLoading: boolean,
  customers: { [subscriptionId: string]: CustomersRes } | null,
  searchCustomers: { [subscriptionId: string]: CustomersRes } | null,
  paymentLinks: { [subscriptionId: string]: PaymentLinksRes } | null,
  getCustomers: (subscriptionId: string, startAfter: string) => Promise<{ [subscriptionId: string]: CustomersRes } | null>,
  getPaymentLinks: (subscriptionId: string, startAfter: string) => Promise<{ [subscriptionId: string]: PaymentLinksRes } | null>,
  getPaymentLinksByIds: (subscriptionId: string, ids: string[]) => Promise<PaymentLinksRes | null>,
  getSearchCustomers: (subscriptionId: string, startAfter: string, data: Partial<BananaPay.Customer>) => Promise<{ [subscriptionId: string]: CustomersRes } | null>,
  getCustomersForLinkId: (subscriptionId: string, linkId: string) => Promise<BananaPay.Customer[]>,
  getCustomerById: (subscriptionId: string, customerId: string) => Promise<BananaPay.Customer>,
  getStats: (subscriptionId: string) => Promise<ChannelData | null>,
  refresh: () => void,
}

const StatsContext = createContext<StatsContextProps>({
  subscriptionId: null,
  isLoading: false,
  customers: null,
  searchCustomers: null,
  paymentLinks: null,
  getCustomers: (subscriptionId, startAfter) => new Promise(() => null),
  getPaymentLinks: (startAfter) => new Promise(() => null),
  getPaymentLinksByIds: (subscriptionId, ids) => new Promise(() => null),
  getSearchCustomers: (subscriptionId, startAfter, data) => new Promise(() => null),
  getCustomersForLinkId: (subscriptionId, linkId) => new Promise(() => null),
  getCustomerById: (subscriptionId, customerId) => new Promise(() => null),
  getStats: (subscriptionId) => new Promise(() => null),
  refresh: () => {},
});


export const StatsProvider = ({ children }: StatsProviderProps) => {
  const [stats, setStats] = useState<{ [subscriptionId: string]: ChannelData } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState<{ [subscriptionId: string]: CustomersRes } | null>(null);
  const [searchCustomers, setSearchCustomers] = useState<{ [subscriptionId: string]: CustomersRes } | null>(null);
  const [paymentLinks, setPaymentLinks] = useState<{ [subscriptionId: string]: PaymentLinksRes } | null>(null);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);

  const { getAppDetails } = useApps();
  const { user } = useUser();

  const toast = useToast();

  const getStats = async(subscriptionId: string) => {
    const app = getAppDetails(subscriptionId);
    if(!app) {
      toast(getToast("warning", "Sorry Not Found!", "This subscription was not found."));
      return null;
    }
    if(app.publisher.address.toLowerCase() === user.displayName.toLowerCase()) {
      if(stats) {
        if(stats[subscriptionId]) {
          // already fetched no need to do it again - return
          return stats[subscriptionId];
        }
      }
      try{
        setIsLoading(true);
        const params = {
          subscriptionId: app.subscription_id
        }
        const channelData = httpsCallable(functions, "getChannelData");              
        const { data } = await channelData(params) as { data: ResponseObj };
        console.log(data);
        const allPastEvents = [];
        // Iterate through the events for each user
        for (const chain in data.data.events_for_users) {
          for (const userId in data.data.events_for_users[chain as BananaPay.Analytics.ChainType]) {
            const userEvents = data.data.events_for_users[chain as BananaPay.Analytics.ChainType][userId];
            if (userEvents.pastEvents && Array.isArray(userEvents.pastEvents)) {
              allPastEvents.push(...userEvents.pastEvents);
            }
          }
        }      
        data.data.allEvents = allPastEvents;
        const statsData: ChannelData = data.data;
        console.log("Result");
        console.log(data);
        setSubscriptionId(app.subscription_id);
        const prev = {...stats};
        prev[subscriptionId] = statsData;
        setStats(prev);
        setIsLoading(false);
        return prev[subscriptionId];
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        setStats(null);
        toast(getToast("error", "Error!", message));
        setIsLoading(false);
        return null;
      }
    }else{
      toast(getToast("warning", "Not Allowed!", "Sorry you do not have permission to see this data."));
      return null;
    }
  }

  const refresh = () => {

  }

  const getCustomers = async(subscriptionId: string, startAfter: string) => {
    if(customers) {
      if(customers[subscriptionId]){
        if(customers[subscriptionId].customers[customers[subscriptionId].customers.length-1].id !== startAfter) return customers;
      }
    }
    try{
      setIsLoading(true);
      
      // call cloud function
      const getAllCustomersF = httpsCallable(functions, "getAllCustomers");
      
      const params: any = {
        subscriptionId: subscriptionId,
        limit: 50,
      };
      if(startAfter.length>0) {
        params["startAfter"] = startAfter;
      }

      console.log("params: ", params);

      const result = await getAllCustomersF(params);
      const data: CustomersRes = result.data as CustomersRes;

      console.log(data);

      // setCustomers
      const prev = {...customers};
      if(prev) {
        if(prev[subscriptionId]) {
          const customersBefore = customers[subscriptionId].customers;
          prev[subscriptionId].customers = [...customersBefore, ...data.customers];
          prev[subscriptionId].hasMore = data.hasMore;
        }else{
          prev[subscriptionId] = data;
        }
      }else{
        prev[subscriptionId] = data;
      }
      setCustomers(prev)
      setIsLoading(false);
      
      // return
      return prev;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      setStats(null);
      toast(getToast("error", "Something went wrong.", message));
      setIsLoading(false);
    }
  }

  const getPaymentLinks = async(subscriptionId: string, startAfter: string) => {
    if(paymentLinks) {
      if(paymentLinks[subscriptionId]){
        if(paymentLinks[subscriptionId].paymentLinks[paymentLinks[subscriptionId].paymentLinks.length-1].id !== startAfter) return paymentLinks;
      }
    }
    try{
      setIsLoading(true);
      
      // call cloud function
      const getAllPaymentLinksF = httpsCallable(functions, "getAllPaymentLinks");
      
      const params: any = {
        subscriptionId: subscriptionId,
        limit: 50,
      };
      if(startAfter.length>0) {
        params["startAfter"] = startAfter;
      }

      const result = await getAllPaymentLinksF(params);
      const data: PaymentLinksRes = result.data as PaymentLinksRes;

      console.log(data);

      // set payment links
      const prev = {...paymentLinks};
      if(prev) {
        if(prev[subscriptionId]) {
          const linksBefore = paymentLinks[subscriptionId].paymentLinks;
          prev[subscriptionId].paymentLinks = [...linksBefore, ...data.paymentLinks];
          prev[subscriptionId].hasMore = data.hasMore;
        }else{
          prev[subscriptionId] = data;
        }
      }else{
        prev[subscriptionId] = data;
      }
      setPaymentLinks(prev)
      setIsLoading(false);
      
      // return
      return prev;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      setStats(null);
      toast(getToast("error", "Something went wrong.", message));
      setIsLoading(false);
    }
  }

  const getPaymentLinksByIds = async(subscriptionId: string, ids: string[]) => {
    // check if they already exist and if they do return else fetch, set and then return
    if(paymentLinks) {
      if(paymentLinks[subscriptionId]) {
        if(ids.every((id) => paymentLinks[subscriptionId].paymentLinks.some((obj) => obj.id === id))) {
          return {
            paymentLinks: paymentLinks[subscriptionId].paymentLinks.filter((obj) => ids.includes(obj.id)),
            hasMore: false
          };
        }
      }
    }
    try{
      setIsLoading(true);
      
      // call cloud function
      const getPaymentLinksByIdsF = httpsCallable(functions, "getPaymentLinksByIds");
      
      console.log(ids)

      const params: any = {
        ids: ids
      }

      const result = await getPaymentLinksByIdsF(params);
      const data: PaymentLinksRes = result.data as PaymentLinksRes;

      console.log(data);

      // setCustomers
      const prev = {...paymentLinks};
      if(prev) {
        if(prev[subscriptionId]) {
          const linksBefore = paymentLinks[subscriptionId].paymentLinks;
          prev[subscriptionId].paymentLinks = [...linksBefore, ...data.paymentLinks];
          prev[subscriptionId].hasMore = data.hasMore;
        }else{
          prev[subscriptionId] = data;
        }
      }else{
        prev[subscriptionId] = data;
      }
      setPaymentLinks(prev)
      setIsLoading(false);
      
      // return
      return data;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      setStats(null);
      toast(getToast("error", "Something went wrong.", message));
      setIsLoading(false);
    }
  }

  const getSearchCustomers = async(subscriptionId: string, startAfter: string, searchData: Partial<BananaPay.Customer>) => {
    try{
      setIsLoading(true);
      
      // call cloud function
      const searchAllCustomersF = httpsCallable(functions, "searchAllCustomers");
      
      const params: any = {
        subscriptionId: subscriptionId,
        limit: 50,
        obj: searchData
      };
      if(startAfter.length>0) {
        params["startAfter"] = startAfter;
      }

      console.log("params: ", params);

      const result = await searchAllCustomersF(params);
      const data: CustomersRes = result.data as CustomersRes;

      console.log("data: ", data);

      // setCustomers
      const prev = {...customers};
      if(prev) {
        if(prev[subscriptionId]) {
          const customersBefore = customers[subscriptionId].customers;
          prev[subscriptionId].customers = [...customersBefore, ...data.customers];
          prev[subscriptionId].hasMore = data.hasMore;
        }else{
          prev[subscriptionId] = data;
        }
      }else{
        prev[subscriptionId] = data;
      }
      setCustomers(prev)
      setIsLoading(false);
      
      // return
      return prev;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      setStats(null);
      toast(getToast("error", "Something went wrong.", message));
      setIsLoading(false);
    }
  }

  const getCustomerById = async(subscriptionId: string, customerId: string) => {
    try{
      setIsLoading(true);
      
      // call cloud function
      const getCustomerByIdF = httpsCallable(functions, "getCustomerById");
      
      const params: any = {
        subscriptionId: subscriptionId,
        customerId: customerId
      }

      const result = await getCustomerByIdF(params);
      const data: any = result.data;
      const _customer = data.customer as BananaPay.Customer;

      return _customer;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      setStats(null);
      toast(getToast("error", "Something went wrong.", message));
      setIsLoading(false);
    }
  }

  const getCustomersForLinkId = async(subscriptionId: string, linkId: string) => {
    try{
      setIsLoading(true);
      
      // call cloud function
      const getAllCustomersForLinkF = httpsCallable(functions, "getAllCustomersForLink");
      
      const params: any = {
        subscriptionId: subscriptionId,
        linkId: linkId
      }


      const result = await getAllCustomersForLinkF(params);
      const data: any = result.data;
      const _customers = data.customers as BananaPay.Customer[];

      console.log("customer data data: ", _customers, result, data);

      return _customers;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      setStats(null);
      toast(getToast("error", "Something went wrong.", message));
      setIsLoading(false);
    }
  }

  return (
    <StatsContext.Provider
      value={{
        subscriptionId,
        isLoading,
        customers,
        searchCustomers,
        paymentLinks,
        getCustomers,
        getPaymentLinks,
        getPaymentLinksByIds,
        getSearchCustomers,
        getCustomersForLinkId,
        getStats,
        refresh,
        getCustomerById
      }}
    >
      {children}
    </StatsContext.Provider>
  );
};

export const useStats = () => {
  return useContext(StatsContext);
}