import { Box, Button, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
import ChainTag from "components/chain/ChainTag";
import Card from 'components/card/Card';
import SubscriptionStatusTag from "components/other/SubscriptionStatusTag";
import { HSeparator } from "components/separator/Separator";
import { useUser } from "contexts/UserContext";
import { links } from "helpers/links";
import { MdEdit } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { IoAnalyticsSharp } from "react-icons/io5";
import { createLinkForDashboard, hexToChain } from "helpers/formatters";
import { BananaPay } from "bananapay-types";


const AdminPanel = (props: { app: BananaPay.App }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';
	const textColorWarning = 'red.500';
  const { app } = props;
  const { user } = useUser();

  const isOwner = () => user?.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

  return(
    <Box px="8" mx="2" pb="8">
      <Card>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          {app.name}'s Admin Panel
        </Text>
        <Text color={textColorSecondary} fontSize='md' me='26px' mb='25px'>
          {app.name}'s admin panel, operate your subscription with ease!
        </Text>
      </Card>

      <Card mt="20px">
        {isOwner() ? (
          <Box>
            <Box>
              <Text color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
                Admin Panel:
              </Text>
              <Link href={`/#${createLinkForDashboard(app.subscription_id)}`} variant='no-hover' p='0px !important'>
                <Button textColor={textColorPrimary}>
                  <Icon as={BsFillPersonFill} color={textColorPrimary} h='18px' w='18px' mr="10px" />
                  Admin
                </Button>
              </Link>
            </Box>
          </Box>
        ):(
          <Box>
            <Text textAlign={"center"} color={textColorWarning} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>Where do you think you are going?</Text>
            <Text textAlign={"center"} color={textColorPrimary} fontSize='md' me='26px' mb='25px'>You do not have access to the {app.name}'s Admin Panel.</Text>
          </Box>
        )}
      </Card>
    </Box>
  )
};

export default AdminPanel;