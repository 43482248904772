import { Box, Button, Flex, Icon, Image, Input, Text, Tooltip } from "@chakra-ui/react";
import { BananaPay } from "bananapay-types";
import TokensDrawer from "components/Drawers/TokensDrawer";
import React, { useState } from"react";
import { FiChevronDown } from "react-icons/fi";
import { TbTrash } from "react-icons/tb";
import { getTokenDetails, getTokenDetailsForChain, getTokensForChain } from "variables/tokens";


const SelectToken = (props: { token: BananaPay.Token, selectedTokens: BananaPay.Token[], amount: number, useUsdValue: boolean, length: number, onSelect: (token: BananaPay.Token) => void, valueChange: (e: string) => void, remove: () => void }) => {

  const { token, amount, useUsdValue, selectedTokens, length, onSelect, valueChange, remove } = props;

  const [isTokenDrawerOpen, setIsTokenDrawerOpen] = useState(false);
  
  const [currentToken, setCurrentToken] = useState<BananaPay.Token | null>(null);

  const handleOnSelect = (newToken: BananaPay.Token) => {
    onSelect(newToken);
    setCurrentToken(newToken);
  }

  const getTokenMin = (): string => {
    const tokenData = getTokenDetails(token);
    const tokenMin = tokenData?.minTokenAmount || "0";
    return tokenMin.toString();
  }

  return(
    <>
      <Flex flexDirection={"row"}>
        {!useUsdValue ? (
          <Tooltip label={`Minimum amount is ${getTokenMin()} ${token.ticker}`} isDisabled={currentToken && amount < Number(getTokenMin()) && amount !== undefined ? true : false}>
            <Input
              mb='0px'
              id='token-amount'
              placeholder='eg. 5.99'
              w="95%"
              height="16"
              mr="5"
              rounded={"2xl"}
              value={amount}
              type="number"
              borderColor={currentToken && amount < Number(getTokenMin()) && amount !== undefined ? "#E2E8F0" : "red.500"}
              onChange={(e: any) => valueChange(e.target.value)}
            />
          </Tooltip>
        ):null}
        <Flex w={"100%"} h="16" border="1px" borderColor={"gray.200"} rounded="2xl" p="2" justifyContent={"space-between"}>
          {token.logo ? (
            <>
              <Flex>
                <Image src={token.logo} rounded="full" h="12" w="12" mr="2" />
                <Box>
                  <Text fontSize={"md"} mt="0.5" fontWeight="500">{token.ticker}</Text>
                  <Text fontSize={"sm"} mt="-1" fontWeight="300">{token.name}</Text>
                </Box>
              </Flex>
            </>
          ):(
            <>
              <Text fontSize={"lg"} my="auto" ml="4" fontWeight="400">Select Token</Text>
            </>
          )}
          <Flex flexDirection={"row"}>
            <Button h="100%" onClick={() => setIsTokenDrawerOpen(true)}>
              <Icon as={FiChevronDown} h="12" />
            </Button>
            {length>1 ? (
              <Button onClick={remove}>
                <TbTrash />
              </Button>
            ):null}
          </Flex>
        </Flex>
      </Flex>

      <TokensDrawer
        isOpen={isTokenDrawerOpen}
        selectedTokens={selectedTokens}
        useUsdValue={useUsdValue}
        close={() => setIsTokenDrawerOpen(false)}
        onSelect={handleOnSelect}
      />
    </>    
  );
};

export default SelectToken;