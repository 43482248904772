// Chakra imports
import { Button, Flex,   Image,   Text,   useColorModeValue, useToast } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
// Assets
import { useEffect, useState } from "react";
import { getToast } from "helpers/formatters";


function Dropzone(props: {content:JSX.Element|string, file: string, onChange: (fileString: string) => void, w?: string, h?: string, minH?: string, pt?: string, pb?: string, [x:string]:any} ) {
  const { content, onChange, file, w="100%", h="max-content", minH="130px", pt="80px !important", pb="105px !important", ...rest } = props;
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("gray.300", "whiteAlpha.100");
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
  const toast = useToast();

  useEffect(() => {
    if(acceptedFiles.length>0) {
      (async function get() {
        try{
          const file = acceptedFiles[0];
          const a = await file.arrayBuffer();
          const base64String = btoa(String.fromCharCode(...new Uint8Array(a)));
          console.log(base64String);
          const reader = new FileReader()
          if(file) {
            reader.addEventListener("load", () => {
              onChange((reader.result)?.toString() || "");
            });
            reader.readAsDataURL(file)
          }
        }catch(err){
          toast(getToast("warning", "File size too large!", "Try uploading smaller file."));
        }
      })();
    }
  }, [acceptedFiles]);
  
  return (
    <>
      <Flex
        align='center'
        justify='center'
        bg={bg}
        border='1px dashed'
        borderColor={borderColor}
        borderRadius='16px'
        w={w}
        maxW='100%'
        h={h}
        minH={minH}
        cursor='pointer'
        {...getRootProps({ className: "dropzone" })}
        pt={pt}
        pb={pb}
        {...rest}>
        {acceptedFiles.length === 0 ? (<input {...getInputProps() } /> ):null}
        <Button variant='no-effects'>{content}</Button>
      </Flex>
      {file ? (
        <>
        <Text>
          Uploaded successfully!
        </Text>
        </>
      ):(
        <Text>
          Nothing Uploaded! Upload something above!
        </Text>
      )}
    </>
  );
}

export default Dropzone;
