/*eslint-disable*/

import { Box, Flex, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { links } from 'helpers/links';
import { ReactComponent as Banana1 } from 'assets/svg/banana1.svg';
import { ReactComponent as Banana2 } from 'assets/svg/banana2.svg';


export default function Footer() {

	const randomNum = (min: number, max: number) => {
		return Math.floor(Math.random() * (max - min + 1) + min)
	}
	const classes = ["rotate10", "rotate20", "rotate60", "rotate90", "rotate100"]

	const textColor = useColorModeValue('gray.400', 'white');
	return (
		<Flex
			zIndex='3'
			flexDirection={{
				base: 'column',
				xl: 'row'
			}}
			alignItems={{
				base: 'center',
				xl: 'start'
			}}
			justifyContent='space-between'
			px={{ base: '30px', md: '50px' }}
			pb='30px'>
			<Text
				color={textColor}
				textAlign={{
					base: 'center',
					xl: 'start'
				}}
				mb={{ base: '20px', xl: '0px' }}>
				{' '}
				&copy; {new Date().getFullYear()}
				<Text as='span' fontWeight='500' ms='4px'>
					BananaPay. All Rights Reserved. Made with love by
					<Link mx='3px' color={textColor} href={links.home} target='_blank' fontWeight='700'>
						BananaPay Team!
					</Link>
				</Text>
			</Text>
			<List display='flex'>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link fontWeight='500' color={textColor} href={links.supportEmail}>
						Support
					</Link>
				</ListItem>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link fontWeight='500' color={textColor} href={links.license}>
						License
					</Link>
				</ListItem>
				<ListItem
					me={{
						base: '20px',
						md: '44px'
					}}>
					<Link fontWeight='500' color={textColor} href={links.termsOfUse}>
						Terms of Use
					</Link>
				</ListItem>
				<ListItem>
					<Link fontWeight='500' color={textColor} href={links.blog}>
						Blog
					</Link>
				</ListItem>
			</List>
		</Flex>
	);
}
