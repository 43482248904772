import { Box, Button, Flex, Icon, IconButton, Image, Select, SimpleGrid, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';
import Dropzone from "./Dropzone";
import { MdOutlineCloudUpload } from "react-icons/md";
import Card from 'components/card/Card';
import { chainToNumber, getToast } from "helpers/formatters";
import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import SwitchField from 'components/fields/SwitchField';
import SelectToken from "./SelectToken";
import { BiPlus } from "react-icons/bi";
import { getTokensForChain, tokens } from "variables/tokens";
import { useApps } from "contexts/AppsContext";
import { BananaPay } from "bananapay-types";

const TIMEFRAMES = [
  {
    val: 60,
    name: "Minutes"
  },
  {
    val: 3600,
    name: "Hour"
  },
  {
    val: 86400,
    name: "Day"
  },
  {
    val: 604800,
    name: "Week"
  },
  {
    val: 2592000,
    name: "Month (30 days)"
  },
  {
    val: 31536000,
    name: "Year"
  },
]

const PricingPanel = (props: { receivers: { [key in BananaPay.Chain]: BananaPay.App.Receiver}; subscriptionId?: string; remove?: boolean; pricingPlans: BananaPay.App.PaymentOption[], setPricingPlans: (newPricingPlans: BananaPay.App.PaymentOption[]) => void }) => {
  const { receivers, subscriptionId, remove=false, pricingPlans, setPricingPlans } = props;

  const bgColor = useColorModeValue("white", "#0B1437");
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brand = useColorModeValue('brand.500', 'brand.400');
  const toast = useToast();

  const { getAppDetails } = useApps();

  const [timeframe, setTimeframe] = useState<number>(3600);

  const addNewPricingPlan = () => {
    const newPlans = [...pricingPlans];
    if(newPlans.length<12) {
      newPlans.push({
        name: "",
        description: "",
        useUsdValue: false,
        usdValue: undefined,
        paymentTokens: [{
          token: {
            name: "",
            ticker: "",
            address: {
              "0x13881": "",
              "0x61": "",
              "0xfa2": ""
            },
            logo: "",
            decimals: 18
          },
          amount: undefined,
        }],
        paymentInterval: undefined,
        isHidden: false
      });
      setPricingPlans(newPlans);
    }else{
      toast(getToast("info", "Info!", "Currently you can have 12 pricing plans max!"));
    }
  }

  const canDeleteOrEditPlan = (n: number) => {
    if(remove) return true; // creating subscription page
    const app = getAppDetails(subscriptionId);
    return app?.paymentOptions?.length <= n;
  }

  const canDeleteOrEditPaymentToken = (n: number, i: number) => {
    if(remove) return true; // creating subscription page
    const app = getAppDetails(subscriptionId);
    console.log("app: ", app);
    if(!app) return false;
    console.log("Data:", app, i);
    if(pricingPlans.length > app.paymentOptions.length) {
      return true;
    }else{
      // editing existing plan
      return app.paymentOptions[n].paymentTokens.length <= i;
    }
  }

  const removePricingPlan = (n: number) => {
    const newPlans = [...pricingPlans];
    if(newPlans.length!==1) {
      if(canDeleteOrEditPlan(n)) {
        newPlans.splice(n, 1)
      }else{
        newPlans[n].isHidden = !newPlans[n].isHidden;
      }
      setPricingPlans(newPlans);
    }else{
      toast(getToast("info", "Info!", "Must have at least 1 pricing plan!"));
    }
  }

  const handleChange = (index: number, key: "name"|"description"|"address"|"ticker"|"tokenName"|"logo"|"amount"|"paymentInterval"|"useUsdValue"|"usdValue", e: any) => {
    const _pricingPlans = [...pricingPlans];
    if(key === "name" || key === "description") {
      _pricingPlans[index][key] = e;
    }else if(key ==="paymentInterval") {
      if(canDeleteOrEditPlan(index)) {
        const newValue = parseFloat(e);
        const valueToSet = isNaN(newValue) ? undefined : newValue;
        _pricingPlans[index][key] = valueToSet * timeframe;
      }else{
        toast(getToast("info", "Info", "Cannot edit existing plans. Create a new plan."));
      }
    }else if(key === "useUsdValue") {
      if(canDeleteOrEditPlan(index)) {  
        _pricingPlans[index][key] = e;
      }else{
        toast(getToast("info", "Info", "Cannot edit existing plans. Create a new plan."));
      }
    }else if(key === "usdValue") {
      if(canDeleteOrEditPlan(index)) {
        const newValue = parseFloat(e);
        const valueToSet = isNaN(newValue) ? undefined : newValue;
        _pricingPlans[index][key] = valueToSet;
      }else{
        toast(getToast("info", "Info", "Cannot edit existing plans. Create a new plan."));
      }
    }
    setPricingPlans(_pricingPlans);
  }

  const handleTokenChange = (token: BananaPay.Token, i: number, index: number) => {
    if(canDeleteOrEditPaymentToken(index, i)) {
      const _pricingPlans = [...pricingPlans];
      if(_pricingPlans[index].paymentTokens.length===0) {
        _pricingPlans[index].paymentTokens.push({
          token: token,
          amount: undefined
        });
      }else{
        _pricingPlans[index].paymentTokens[i].token = token;
      }
      setPricingPlans(_pricingPlans);
    }else{
      toast(getToast("info", "Info", "Cannot edit existing plans. Create a new plan."));
    }
  }

  const handleTokenAmountChange = (val: number, i: number, index: number) => {
    if(canDeleteOrEditPaymentToken(index, i)) {
      const _pricingPlans = [...pricingPlans];
      _pricingPlans[index].paymentTokens[i].amount = val;
      setPricingPlans(_pricingPlans);
    }else{
      toast(getToast("info", "Info", "Cannot edit existing plans. Create a new plan."));
    }
  }

  const addNewPaymentToken = (index: number) => {
    const allTokens = tokens.length;
    if(pricingPlans[index].paymentTokens.length === allTokens) {
      toast(getToast("info", "All tokens added!", "You cannot add more tokens!"));
    }else{
      const _pricingPlans = [...pricingPlans];
      _pricingPlans[index].paymentTokens.push({
        token: {
          name: "",
          ticker: "",
          address: {
            "0x13881": "",
            "0x61": "",
            "0xfa2": ""
          },
          logo: "",
          decimals: 18
        },
        amount: undefined
      });
      setPricingPlans(_pricingPlans);
    }
  }

  const removePaymentToken = (i: number, index: number) => {
    if(canDeleteOrEditPaymentToken(index, i)) {
      const _pricingPlans = [...pricingPlans];
      _pricingPlans[index].paymentTokens.splice(i, 1);
      setPricingPlans(_pricingPlans);
    }else{
      toast(getToast("info", "Info", "Cannot edit existing plans. Create a new plan."));
    }
  }

  const getSelectedTokens = (index:  number) => {
    const tokensToReturn: BananaPay.Token[] = [];
    pricingPlans[index].paymentTokens.map((paymentToken) => tokensToReturn.push(paymentToken.token));
    return tokensToReturn;
  }

  useEffect(() => {
    console.log(pricingPlans)
  }, [pricingPlans])

  return(
    <>
    <Box>
      {pricingPlans?.map((plan, n) => {
        return(
          <Card bgColor={bgColor} mb="4" key={n}>
            <Text fontSize={"xl"} fontWeight="bold" pb="2" pl="2">Pricing Plan #{n+1}</Text>
            {plan.isHidden ? (
              <Flex mt="2" mb="4">
                <Flex mr="4" flexDirection={"row"} backgroundColor={"brand.100"} textColor={"brand.400"} rounded={"full"} px="4" py="2" w="fit-content">
                  <Text fontWeight={"800"}>{plan.isHidden ? "Hidden" : "Shown"}</Text>
                </Flex>
                <Text pt="2" fontSize={"md"} fontWeight={"300"}>Users won't be able to subscribe to this plan!</Text>
              </Flex>
            ):null}
            <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px' p="1" mb="1.5" rounded={"2xl"}>
              <Stack direction='column' gap='20px'>
                <InputField
                  mb='0px'
                  id='name'
                  placeholder='eg. PRO'
                  label="Pricing Plan Name"
                  value={plan.name}
                  onChange={(e: any) => handleChange(n, "name", e.target.value)}
                />
                <SwitchField
                  mx="auto"
                  isChecked={plan.useUsdValue}
                  onChange={() => handleChange(n, "useUsdValue", !plan.useUsdValue)}
                  reversed={true}
                  fontSize='sm'
                  mb='5px'
                  id='4'
                  label="Make payments in fixed USD value"
                />
                {plan.useUsdValue ? (
                  <Box mb="2">
                    <InputField
                      mb='10px'
                      id='token-amount'
                      placeholder='eg. 5.99'
                      label="Payment Value in USD"
                      type="number"
                      min={1.5}
                      value={plan.usdValue ?? ""}
                      onChange={(e: any) => handleChange(n, "usdValue", e.target.value)}
                    />
                  </Box>
                ):null}
              </Stack>
              <Stack direction='column' gap='20px'>
                <TextField
                  mb='0px'
                  id='description'
                  placeholder='eg. Spotify'
                  label="Pricing Plan Description"
                  value={plan.description ?? ""}
                  onChange={(e: any) => handleChange(n, "description", e.target.value)}
                />
                <Flex>
                  <InputField
                    mb='0px'
                    w="100%"
                    id='payment-interval'
                    placeholder='eg. 600'
                    label="Payment Interval"
                    type="number"
                    value={plan.paymentInterval ? plan.paymentInterval/timeframe : ""}
                    onChange={(e: any) => handleChange(n, "paymentInterval", e.target.value)}
                  />
                  <Select fontSize='md' mt="7" ml="1" variant="outline" h="44px" rounded={"2xl"} defaultValue={timeframe} width='unset' fontWeight='700' onChange={(e: any) => setTimeframe(e.target.value)}>
                    {TIMEFRAMES.map((timeframe) => {
                      return(
                        <option key={timeframe.val} value={timeframe.val}>{timeframe.name}</option>
                      )
                    })}
                  </Select>
                </Flex>
              </Stack>
            </SimpleGrid>
            <Box p="1">
              <Text mb="0.5" fontSize='sm' fontWeight='bold'>
                Select Available Payment Token(s):
              </Text>
              <SimpleGrid columns={plan.useUsdValue ? 2 : 1} gap='15px' mb='10px'>
                {plan.paymentTokens.map((paymentToken, i) => {
                  return (
                    <SelectToken
                      key={i}
                      token={paymentToken.token}
                      amount={paymentToken.amount}
                      useUsdValue={plan.useUsdValue}
                      selectedTokens={getSelectedTokens(n)}
                      length={plan.paymentTokens.length}
                      valueChange={(val: string) => handleTokenAmountChange(parseFloat(val), plan.paymentTokens.length-1, n)}
                      onSelect={(token: BananaPay.Token) => handleTokenChange(token, plan.paymentTokens.length-1, n)}
                      remove={() => removePaymentToken(plan.paymentTokens.length-1, n)}
                    />
                  )
                })}
              </SimpleGrid>
              <Button onClick={() => addNewPaymentToken(n)}>
                <BiPlus />
              </Button>
            </Box>
            <Button mt="4" variant={"action"} onClick={() => removePricingPlan(n)}>
              {canDeleteOrEditPlan(n) ? "Remove" : (plan.isHidden ? "Show" : "Hide")}
            </Button>
          </Card>
        )
        })}

      <Button w="100%" mt="8" mb="6" variant={"brand"} onClick={addNewPricingPlan}>
        Add New Plan
      </Button>
    </Box>

    </>
  );
};

export default PricingPanel;