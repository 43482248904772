import { Box, Flex, Link, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import DocsBox from "components/docs/DocsBox";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { EventLinkType } from "helpers/types";
import { useState } from "react";
import { BiHelpCircle } from "react-icons/bi";
import { BsShop } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { TbBooks } from "react-icons/tb";
import { v4 as uuidv4 } from "uuid";

const SearchHeader = (props: { upcoming: boolean; searchTerm: string; setSearchTerm: (newTerm: string) => void; }) => {

  const { upcoming, searchTerm, setSearchTerm } = props;

  const textColorSecondary = useColorModeValue('gray.500', 'gray.300');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.500', 'white');

  const getLink = (type: EventLinkType) => {
    switch(type) {
      case "all":
        return `/#${links.profileDashboard}?type=${upcoming ? `u_${type}` : type}`;
      case "successful":
        return `/#${links.profileDashboard}?type=${upcoming ? `u_${type}` : type}`;
      case "failed":
        return `/#${links.profileDashboard}?type=${upcoming ? `u_${type}` : type}`;
      case "canceled":
        return `/#${links.profileDashboard}?type=${upcoming ? `u_${type}` : type}`;
      case "subscribed":
        return `/#${links.profileDashboard}?type=${upcoming ? `u_${type}` : type}`;
      case "unsubscribed":
        return `/#${links.profileDashboard}?type=${upcoming ? `u_${type}` : type}`;
    }
  }

  return(
    <Box>
      <Flex direction='column'>
        <Flex
          mb='20px'
          justifyContent='space-between'
          direction={{ base: 'column', lg: 'row' }}
          align={{ base: 'start', lg: 'center' }}
        >
          <Box pr="20px" w="100%">
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              w="100%"
            />
          </Box>
          <Flex
            align='center'
            mx="20px"
            ms={{ base: '24px', lg: '0px' }}
            mt={{ base: '20px', lg: '0px' }}>
            <Link
              color={textColorBrand}
              fontWeight='500'
              me={{ base: '34px', lg: '44px' }}
              href={getLink("all")}>
              All
            </Link>
            <Link
              color={textColorBrand}
              fontWeight='500'
              me={{ base: '34px', lg: '44px' }}
              href={getLink("successful")}>
              Successful
            </Link>
            <Link
              color={textColorBrand}
              fontWeight='500'
              me={{ base: '34px', lg: '44px' }}
              href={getLink("failed")}>
              Failed
            </Link>
            {!upcoming ? (
              <Link
                color={textColorBrand}
                fontWeight='500'
                me={{ base: '34px', lg: '44px' }}
                href={getLink("subscribed")}>
                Subscribed
              </Link>
            ):null}
            <Link
              color={textColorBrand}
              fontWeight='500'
              me={{ base: '34px', lg: '44px' }}
              href={getLink("unsubscribed")}>
              Unsubscribed
            </Link>
            {!upcoming ? (
              <Link color={textColorBrand} fontWeight='500' href={getLink("canceled")}>
                Canceled
              </Link>
            ):null}
          </Flex>
        </Flex>

        {searchTerm.length !== 0 ? (
          <>
            <Text mt='20px' mb='20px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
              Search Results for: <i>{searchTerm}</i>
            </Text>
          </>
        ):null}
      </Flex>
    </Box>
  );
};

export default SearchHeader;