import { useState, useMemo, useEffect } from "react";
import { Box, Button, Center, Flex, Select, Spinner, Text, useColorModeValue, useToast } from "@chakra-ui/react"
import { capitalizeFirstLetter, getToast, isValidUUID, useQuery } from "helpers/formatters";
import { useHistory } from "react-router-dom";
import { useApps } from "contexts/AppsContext";
import { useUser } from "contexts/UserContext";
import { BananaPay } from "bananapay-types";
import { links } from "helpers/links";
import Card from 'components/card/Card';
import { useStats } from "contexts/StatsContext";
import LinksTable from "./LinksTable";
import InputField from 'components/fields/InputField';


type RowObj = {
	type: string;
	layout: string
	status: string;
	plan: string;
	views: number;
	url: string;
	id: string;
	phone: string;
	email: string;
	after: string;
	customFields: number;
	customText: string;
	tosCollection: string;
	promotionsCollection: string;
	actions: string;
};

const GetById = (props: { subscriptionId: string }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

  const { subscriptionId } = props;

  const query = useQuery();
	const history = useHistory();
	const { user } = useUser();
	const { getAppDetails, apps } = useApps();
	const toast = useToast();
	const { isLoading, getPaymentLinksByIds } = useStats();

	const [isLoaded, setIsLoaded] = useState<boolean>(true);
	const [searchId, setSearchId] = useState<string>("");
	const [paymentLink, setPaymentLink] = useState<BananaPay.PaymentLink | null>(null);

  const fetchLink = async() => {
    if(isValidUUID(searchId)) {
      setIsLoaded(false);
      const res = await getPaymentLinksByIds(subscriptionId, [searchId]);
      if(res?.paymentLinks?.length > 0) {
        setPaymentLink(res.paymentLinks[0]);
      }else{
        toast(getToast("info", "No Payment Link Found.", "We couldn't find any payment link for this ID."));
      }
      setIsLoaded(true);
    }else{
      toast(getToast("warning", "Invalid ID.", "This ID seems to be invalid, try some other ID or contact support."));
    }
  }

  return(
		<Box>
			<Card px="4">
				<Box>
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
						Fetch Payment Link By ID
					</Text>
					<Text color={textColorSecondary} fontSize='md' me='26px' mb='18px'>
						If you only need to fetch one Payment Link you can do it here. Just past  the ID and click Search.
					</Text>
				</Box>
        <Box>
          <InputField
            mb="3"
            id='name'
            placeholder='eg. Main'
            label="Payment Link ID"
            value={searchId}
            onChange={(e: any) => setSearchId(e.target.value)}
          />
          <Button
            variant={"darkBrand"}
            onClick={fetchLink}
          >
            Fetch
          </Button>
        </Box>
				{paymentLink ? (
          <Box px="1.5" mt="5">
            <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px' mb='4px'>
						  Result
					  </Text>
            {paymentLink.id}
          </Box>
				):null}
        {!isLoaded ? (
          <Center mx="auto" flexDirection={"column"} my="8">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='brand.500'
              size="xl"
              mx="auto"
            />
            <Text textAlign={"center"}>Searching, hang on ...</Text>
          </Center>
        ):null}
			</Card>
		</Box>
  );
};

export default GetById;