// Chakra imports
import { Box, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { EventIcon, getIconColor } from 'helpers/componentHelpers';
import { createLinkForEvent, getEventName } from 'helpers/formatters';

// Assets
import { FiExternalLink } from 'react-icons/fi';


export default function Event(props: {
	event: any;
	ranking: string;
	[x: string]: any;
}) {
	const { event, ranking, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const brandColor = useColorModeValue('brand.500', 'white');
	const bg = useColorModeValue('white', 'navy.700');
	return (
		<Card bg={bg} {...rest} p='14px'>
			<Flex align='center' direction={{ base: 'column', md: 'row' }}>
				<Box borderRadius='10px' me='20px' h="80px" w="80px" display={"flex"} alignItems="center" pl="23px" bg={getIconColor(event.name)}>
					<EventIcon eventName={event.name} />
				</Box>
				<Box mt={{ base: '10px', md: '0' }}>
					<Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px'>
						{getEventName(event.name)}
					</Text>
					<Text fontWeight='500' color={textColorSecondary} fontSize='sm' me='4px'>
						Event #{ranking} •{' '}
						<Link fontWeight='500' color={brandColor} href={`/#${createLinkForEvent(event, false)}`} fontSize='sm'>
							See details
						</Link>
					</Text>
				</Box>
				<Link href={`/#${createLinkForEvent(event, false)}`} variant='no-hover' me='16px' ms='auto' p='0px !important'>
					<Icon as={FiExternalLink} color='secondaryGray.500' h='18px' w='18px' />
				</Link>
			</Flex>
		</Card>
	);
}
