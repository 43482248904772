/* eslint-disable */

import { Box, Button, Center, Flex, Link, Spinner, Table, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useColorModeValue } from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import { useUser } from 'contexts/UserContext';

// Custom components
import * as React from 'react';
import { useState } from 'react';

type RowObj = {
  name: string;
  app: string;
	status: boolean;
	actions: [string, boolean];
  delete: string;
	more: string;
};

const columnHelper = createColumnHelper<RowObj>();

const ApiKeysTable = (props: { tableData: RowObj[] }) => {
	const { tableData } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('navy.700', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue("brand.500", "brand.400");
  
  const { changeApiKeyState, deleteApiKey } = useUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeApiKeyState = async(name: string, newState: boolean) => {
    setIsLoading(true);
    await changeApiKeyState(name, newState);
    setIsLoading(false);
  };

  const handleDeleteApiKeyState = async(name: string) => {
    setIsLoading(true);
    await deleteApiKey(name);
    setIsLoading(false);
  };

	let defaultData = tableData;
	const columns = [
		columnHelper.accessor('name', {
			id: 'name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NAME
				</Text>
			),
			cell: (info: any) => (
        <Flex>
          <Text color={textColor} fontSize='md' pl="2" fontWeight='500'>
            {info.getValue()}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('app', {
			id: 'app',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SUBSCRIPTION
				</Text>
			),
			cell: (info: any) => (
        <Flex>
          <Text color={textColor} fontSize='md' pl="2" fontWeight='500'>
            {info.getValue()}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('status', {
			id: 'status',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					STATUS
				</Text>
			),
			cell: (info) => ( 
        <Flex>
          <Tooltip label={info.getValue() ? "This API Key can be used to make API calls. Click the button to change the state of this key." : "This API cannot be used to make API calls. Click the button to change the state of this key."}>
            <Tag size={"lg"} variant='solid' colorScheme={info.getValue() ? "green": "red"}>
              {info.getValue() ? "Active" : "Inactive"}
            </Tag>
          </Tooltip>
        </Flex>
			)
		}),
		columnHelper.accessor('actions', {
			id: 'actions',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ACTIONS
				</Text>
			),
			cell: (info) => (
				<Flex>
          <Button onClick={() => handleChangeApiKeyState(info.getValue()[0], !info.getValue()[1])}>
            {info.getValue()[1] ? "Deactivate" : "Reactivate"}
          </Button>
        </Flex>
			)
		}),
		columnHelper.accessor('delete', {
			id: 'delete',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					DELETE
				</Text>
			),
			cell: (info) => (
				<Flex>
          <Button variant="brand" onClick={() => handleDeleteApiKeyState(info.getValue())}>
            Delete
          </Button>
        </Flex>
			)
		}),
		columnHelper.accessor('more', {
			id: 'more',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					MORE
				</Text>
			),
			cell: (info) => (
        <Link href={info.getValue()}>
          <Text
            cursor='pointer'
            color={brandColor}
            textDecoration='underline'
            fontSize='md'
            fontWeight='500'
          >
            More
          </Text>
        </Link>
			)
		})
	];
	const [ data, setData ] = React.useState(() => [ ...defaultData ]);
	const table = useReactTable({
		data: tableData,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true,
		enableColumnFilters: true
	});
	
	return (
		<Flex direction='column' w='100%' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Box>
				{!isLoading ? (
          <Table variant='simple' color='gray.500' mt='12px'>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        borderColor={borderColor}
                        cursor='pointer'>
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.slice(0, 11).map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor={borderColor}
                          mt='20px !important'
                          py='18px !important'>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ):(
          <Center mx="auto" flexDirection={"column"}>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='brand.500'
              size="xl"
              mx="auto"
            />
            <Text textAlign={"center"}>Working on it . . .</Text>
          </Center>
        )}
			</Box>
		</Flex>
	);
};

export default ApiKeysTable;
