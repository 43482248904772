import { useEffect, useState, useMemo } from "react";
import {
	Avatar,
	Box,
	Flex,
	Grid,
	Image,
	SimpleGrid,
	Icon,
	Text,
	Select,
	FormLabel,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import CustomerDataDetails from './components/CustomerDataDetails';
import CustomerEventsTable from './components/CustomerEventsTable'; 
import Blockies from "react-blockies";

// Assets
import Usa from 'assets/img/users/usa.png';
import FakeLineGraph from 'assets/img/users/FakeLineGraph.png';
import { MdPerson, MdThumbUp } from 'react-icons/md';
import { BananaPay } from "bananapay-types";
import { chainToHex, createLinkForDashboard, createLinkForSubscribersOverview, getEllipsisTxt, getToast, hexToChain, useQuery } from "helpers/formatters";
import { useApps } from "contexts/AppsContext";
import { useUser } from "contexts/UserContext";
import { useEvents } from "contexts/EventsContext";
import { useHistory } from "react-router-dom";
import { useStats } from "contexts/StatsContext";
import { getTokenFromAddressChain } from "variables/tokens";
import { links } from "helpers/links";
import Loading from "components/loading/Loading";
import { FiCopy } from "react-icons/fi";
import ChainLogo from "components/chain/ChainLogo";
import { BsBell, BsBellSlash, BsCalendar3 } from "react-icons/bs";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";

type RowObjEvents = {
  type: string;
  chain: string;
  date: string; 
  amount: string;
  confirmed: string;
  actions: string;
};

export default function CustomerDetails() {
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const [isLoaded, setIsLoaded] = useState(false);
	const [customer, setCustomer] = useState<BananaPay.Customer | null>(null);
	const [tableDataEvents, setTableDataEvents] = useState<RowObjEvents[]>([]);
	const [allEvents, setAllEvents] = useState<BananaPay.Event.BaseEvent[]>([]);

	const query = useQuery();
	const { getAppDetails, apps } = useApps();
	const { user } = useUser();
	const { getEventsForAddressAndApp, events } = useEvents();
	const history = useHistory();
	const toast = useToast();
	const { getCustomerById } = useStats();

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	const totalRevenue = () => {
		return "$12.00";
	}

	const getJoinDate = (d: Date) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
	}

	useEffect(() => {
		if(allEvents.length>0) {
			const data: RowObjEvents[] = allEvents.map((x) => {
				let amountString = "-";
				
				if(x.name !== "Unsubscribed" && x.name !== "SubscriptionCanceled" && x.name !== "ExtensionCall") {
					const event = x as BananaPay.Event.Payment.Successful;
					const paymentToken = getTokenFromAddressChain(chainToHex(x.chainId), event.paymentToken || "");
					const tokenDecimals = paymentToken?.decimals || 18;
					const tokenTicker = paymentToken?.ticker || "tokens";
					const amount = Number(event.paymentAmount) / (10**tokenDecimals); // @ts-ignore
					amountString = `${amount || "-"} ${tokenTicker}`;
				}
			
				return{
					type: x.name,
					chain: hexToChain(chainToHex(x.chainId)).charAt(0).toUpperCase() + hexToChain(chainToHex(x.chainId)).slice(1),
					confirmed: x.confirmed ? "Yes" : "No",
					date: getJoinDate(new Date(x.updatedAt.seconds*1000)),
					actions: `/#${links.eventDetails}?eventId=${x.id}`,
					amount: amountString
				}
			});
			setTableDataEvents(data);
		}
	}, [allEvents]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;
	
	const fetchCustomers = async(customerId: string) => {
		const res = await getCustomerById(app.subscription_id, customerId);
		setCustomer(res);
	}

	useEffect(() => {
		if(customer && events && app) {
			const _events = getEventsForAddressAndApp(customer.address, app.subscription_id, customer.chain).reverse();
			setAllEvents(_events);
		}
	}, [customer, events, app]);

  useEffect(() => {
		if(user){
			const customerId = query.get("customerId");
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(!customerId) {
				console.log("INVALID")
				//redirect("Invalid Link ID!", "Something went wrong, please try again or contact support.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
        (async function () {
          // get payment links
					await fetchCustomers(customerId);
        })();
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const customerId = query.get("customerId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.subscriberDetails}_subscriptionId=${subscriptionId}_customerId=${customerId}` : `${links.signInCentered}?to=${links.subscriberDetails}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user, query]);

	return (
		<Box pt={"75px"}>
			<BreadCrumpStrip
				additional={{mb: "4"}}
				links={[{
					href: `/#${createLinkForDashboard(app.subscription_id)}`,
					name: "Dashboard"
				},{
					href: `/#${createLinkForSubscribersOverview(app.subscription_id)}`,
					name: "All Subscribers"
				}, {
					href: `/`,
					name: "Customer Details"
				}]}
			/>
			{customer ? (
				<>
					<SimpleGrid columns={{ base: 1, md: 2, xl: 5 }} gap='20px' mb='20px'>
						<MiniStatistics
							startContent={
								<Blockies
									seed={customer.address}
									size={10}
									scale={5}
									className="blockie"
								/>
							}
							endContent={
								<IconBox
									w='56px'
									h='56px'
									onClick={() => {
										navigator.clipboard.writeText(customer.address)
										toast(getToast("success", "Copied!", "Copied Address."))
									}}
									icon={<Icon w='32px' h='32px' as={FiCopy} color={textColorSecondary} />}
								/>
							}
							name='Address'
							value={getEllipsisTxt(customer.address, 4)}
						/>
						<MiniStatistics
							startContent={
								<ChainLogo
									chain={hexToChain(customer.chain)}
									width="56px"
									height="56px"
								/>
							}
							name='Chain'
							value={hexToChain(customer.chain).replace(/^./, (firstLetter) => firstLetter.toUpperCase())}
						/>
						<MiniStatistics
							endContent={
								<IconBox
									w='56px'
									h='56px'
									bg={boxBg}
									icon={<Icon w='32px' h='32px' as={customer.subscription.subscribed ? BsBell : BsBellSlash} color={brandColor} />}
								/>
							}
							name='Subscription Status'
							value={customer.subscription.subscribed ? "Subscribed" : "Unsubscribed"}
						/>
						<MiniStatistics
							endContent={
								<IconBox
									w='56px'
									h='56px'
									bg={boxBg}
									icon={<Icon w='32px' h='32px' as={BsCalendar3} color={brandColor} />}
								/>
							}
							name='Created'
							value={getJoinDate(new Date(customer.created * 1000))}
						/>
						<MiniStatistics
							endContent={<Image src={FakeLineGraph} />}
							name='Lifetime Revenue'
							value={totalRevenue()}
						/>
					</SimpleGrid>
					<Grid
						w='100%'
						display={{ base: 'flex', md: 'grid' }}
						flexDirection={{ base: 'column', md: 'unset' }}
						templateColumns={{ md: '1fr 2.6fr' }}
						templateRows={{ base: 'repeat(2, 1fr)', md: '1fr' }}>
						<Box me={{ base: '0px', md: '20px' }} mb={{ base: '20px', md: '0px' }}>
							<CustomerDataDetails
								customer={customer}
							/>
						</Box>
						<Card gridArea={{ base: '2 / 1 / 3 / 2', md: '1 / 2 / 2 / 3' }} mb={{ base: '20px', md: '0px' }}>
							<Box px="3" mt="4px">
								<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='2px'>
									All Events
								</Text>
								<Text color={textColorSecondary} fontSize='md' me='26px' mb='24px'>
									All Events for this subscriber.
								</Text>
							</Box>
							<CustomerEventsTable tableData={tableDataEvents}/>
						</Card>
					</Grid>
				</>
			):(
				<Loading text="Loading customer data..." />
			)}
		</Box>
	);
}
