import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useApps } from 'contexts/AppsContext';
import { useEvents } from 'contexts/EventsContext';
import { EventIcon, getIconColor } from 'helpers/componentHelpers';
import { chainToHex, chainToNumber, createLinkForEvent, getEllipsisTxt, getEventName, secondesToDate } from 'helpers/formatters';
import { links } from 'helpers/links';
import { BsClockHistory } from 'react-icons/bs';
import { FaEthereum } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DisplayEvent = (props: { event: any,  isLast: boolean, isUpcoming?: boolean }) => {

  const { event, isLast, isUpcoming } = props;
	// Chakra Color Mode
	const textColor = useColorModeValue('brands.900', 'white');
	const bgItem = useColorModeValue(
		{ bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)' },
		{ bg: 'navy.700', boxShadow: 'unset' }
	);
	const textColorDate = useColorModeValue('secondaryGray.600', 'white');
  const bg = useColorModeValue('white', 'navy.700');

  const { getAppDetailsFromAddressAndChain } = useApps();
  const { getPricingPlanFromEvent } = useEvents();

  return(
    <>
      <Link to={`${createLinkForEvent(event, isUpcoming || false)}`}>
        <Card _hover={bgItem} bg={bg} cursor={"pointer"} px='24px' py='21px' transition='0.2s linear'>
          <Flex direction={{ base: 'column' }} justify='center'>
            <Flex position='relative' align='center'>
              <Box borderRadius='20px' me='16px' h="65px" w="105px" display={"flex"} alignItems="center" pl="18px" bg={getIconColor(event.name)}>
                <EventIcon eventName={event.name} />
                {isUpcoming ? (
                  <Box w="10" h="10" rounded={"full"} ml="6" mt="-2" bg={"purple.500"} pl="2" pt="2">
                    <BsClockHistory fontSize={"1.5rem"} color="white" />
                  </Box>
                ):null}
              </Box>
              <Flex
                direction='column'
                w={{ base: '70%', md: '100%' }}
                me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}>
                <Text
                  color={textColor}
                  fontSize={{
                    base: 'md'
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'
                >
                  {getEventName(event.name)}
                </Text>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: 'sm'
                  }}
                  fontWeight='400'
                  me='14px'>
                  {getAppDetailsFromAddressAndChain(event.channel, chainToHex(chainToNumber(event.chainId)))?.name} {!getAppDetailsFromAddressAndChain(event.channel, chainToHex(chainToNumber(event.chainId)))?.name ? <>{getEllipsisTxt(event.channel, 3)}</> : ""} {getPricingPlanFromEvent(event)?.plan?.name} {isUpcoming ? "UPCOMING" : ""}
                </Text>
              </Flex>
              <Flex me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }} align='center'>
                {/*<Icon as={FaEthereum} color={textColor} width='9px' me='7px' />*/}
                <Text fontWeight='700' fontSize='md' color={textColor}>
                  {event.paymentAmount ? <>{Number(event.paymentAmount) / (10**18)} USDT</>:null}
                </Text>
              </Flex>
              <Text ms='auto' fontWeight='700' fontSize='sm' color={textColorDate}>
                {secondesToDate(event.updatedAt.seconds)}
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Link>
      {!isLast ? (
        <Box>
          I
        </Box>
      ):null}
    </>
  )
};

export default DisplayEvent;