/*!
""

*/

import { useEffect, useMemo, useState } from 'react';

// Chakra imports
import {
	Box,
	Center,
	Flex,
	Spinner,
	Text,
	useToast,
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/nfts/profile/components/Banner';
import { HSeparator } from 'components/separator/Separator';
import { chainToNumber, getToast, useQuery } from 'helpers/formatters';
import { useApps } from 'contexts/AppsContext';
import { useHistory } from 'react-router-dom';
import { links } from 'helpers/links';
import { useUser } from 'contexts/UserContext';
import General from './components/General';
import Pricing from './components/Pricing';
import Integrate from './components/Integrate';
import { BananaPay } from 'bananapay-types';
import Developers from './components/Developers';


export default function AdminDashboard() {
	const query = useQuery();
	const history = useHistory();
	const { user } = useUser();
	const { getAppDetails, apps } = useApps();
	const toast = useToast();

	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

	useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.adminDashboard}_subscriptionId=${subscriptionId}` : `${links.signInCentered}?to=${links.adminDashboard}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user]);


	const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

	const getJoinedString = () => {
		if(app.published) {
			const d = new Date(app.published);
			const m = month[d.getMonth()];
			const y = d.getFullYear();
			return `Joined ${m} ${y}`;
		}else{
			return "";
		}
	}

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
		{app ? (
			<>
        <Box display={{ base: 'block', lg: 'grid' }}>
          <Flex flexDirection='column'>
            <Banner
							verified={app.verified}
              image={app.banner}
              profile={app.logo}
              wallet={app.receivers}
              address={app.website}
              name={app.name}
              isPrivate={!app.displayInMarketplace}
              date={getJoinedString()}
            />
          </Flex>
        </Box>

        <HSeparator />

				<Box px="5">
        	<General app={app} />
					<Developers app={app} />
        	<Pricing app={app} />
        	<Integrate />
				</Box>
      </>
		):(
			<Center mx="auto" flexDirection={"column"} my="8">
				<Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='brand.500'
					size="xl"
					mx="auto"
				/>
				<Text textAlign={"center"}>Loading Subscription . . .</Text>
			</Center>
		)}
		</Box>
	);
}
