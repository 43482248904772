import { Box, Button, Icon, Link, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import Card from 'components/card/Card';
import { MdWebhook } from "react-icons/md";
import { createLinkForAnalytics, createLinkForEditApp, createLinkForSubscriptionApiKeys, createLinkForSubscriptionWebhooks } from "helpers/formatters";
import { createLinkForApp } from "helpers/formatters";
import { AiFillApi } from "react-icons/ai";
import { BananaPay } from "bananapay-types";
import { FaKey } from "react-icons/fa";


const Developers = (props: { app: BananaPay.App }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.500', 'gray.300');

  const { app } = props;

  return (
    <Box mt="8">
      <Text mb="2" fontSize={"2xl"} fontWeight={"extrabold"} color={textColorSecondary}>Developers</Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            API Keys
          </Text>
          <Link href={`/#${createLinkForSubscriptionApiKeys(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={FaKey} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              Manage
            </Button>
          </Link>
        </Card>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            APIs
          </Text>
          <Link href={`/#${createLinkForEditApp(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={AiFillApi} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              Docs
            </Button>
          </Link>
        </Card>
        <Card textAlign={"center"} px="auto">
          <Text mb="2" color={textColorSecondary} fontWeight='extrabold' fontSize='lg'>
            Webhooks
          </Text>
          <Link href={`/#${createLinkForSubscriptionWebhooks(app.subscription_id)}`} variant='no-hover' p='0px !important'>
            <Button textColor={textColorPrimary}>
              <Icon as={MdWebhook} color={textColorPrimary} h='18px' w='18px' mr="10px" />
              Manage
            </Button>
          </Link>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default Developers;