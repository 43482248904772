import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

type ChartProps = {
	chartData: any[];
	chartOptions: any;
};

const LineChart: React.FC<ChartProps> = (props) => {
	const [chartData, setChartData] = useState<any[]>([]);
	const [chartOptions, setChartOptions] = useState<any>({});

	useEffect(() => {
		setChartData(props.chartData);
		setChartOptions(props.chartOptions);
	}, [props.chartData, props.chartOptions]);

	return (
		<ReactApexChart
			options={chartOptions}
			series={chartData}
			type='line'
			width='100%'
			height='100%'
		/>
	);
};

export default LineChart;