import { BananaPay } from "bananapay-types";

export const getTokensForChain = (chain: BananaPay.Chain) => tokens;

export const getTokenFromAddressChain = (chain: BananaPay.Chain, tokenAddress: string): BananaPay.ExtendedToken | null => {
  let token: BananaPay.ExtendedToken | null = null;

  tokens.forEach(t => {
    if(t.address[chain].toLowerCase() === tokenAddress.toLowerCase()) token = t;
  });

  return token;
}

export const getTokenDetailsForChain = (chain: BananaPay.Chain, tokenAddress: string): BananaPay.ExtendedToken | null => {
  const chainTokens = getTokensForChain(chain);
  if (!chainTokens) {
    return null;
  }

  return chainTokens.find((token) => token.address[chain] === tokenAddress);
};

export const getTokenDetails = (token: BananaPay.Token): BananaPay.ExtendedToken | null => {
  return tokens.find((_token) => _token === token);
} 

export const MIN_USD_VALUE = 1.5;

export const tokens: BananaPay.ExtendedToken[] = [
  {
    name: "Tether USD",
    ticker: "USDT",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbEGGFN4OPJ2G2G30JdnMCATPFLwo_t-k8ENfuIG0&s",
    decimals: 18,
    address: {
      "0x13881": "0x37cd9ca44c80726c89958d76ded18dfc5c9f7524",
      "0x61": "0x37cd9ca44c80726c89958d76ded18dfc5c9f7524",
      "0xfa2": "0x37cd9ca44c80726c89958d76ded18dfc5c9f7524",
    },
    priceFeed: false,
    minUsdValue: 1.5,
    minTokenAmount: 1.5
  },
  {
    name: "DAI",
    ticker: "DAI",
    logo: "https://seeklogo.com/images/D/dai-dai-logo-1AFFA0EDE5-seeklogo.com.png",
    decimals: 18,
    address: {
      "0x13881": "0xA0e5f8983130a53F2FfC2854AA9bd06E81CdefBD",
      "0x61": "0xA0e5f8983130a53F2FfC2854AA9bd06E81CdefBD",
      "0xfa2": "0xA0e5f8983130a53F2FfC2854AA9bd06E81CdefBD",
    },
    priceFeed: false,
    minUsdValue: 1.5,
    minTokenAmount: 1.5
  },
  {
    name: "Chainlink",
    ticker: "LINK",
    logo: "https://seeklogo.com/images/C/chainlink-link-logo-CDF7095A43-seeklogo.com.png",
    decimals: 18,
    address: {
      "0x13881": "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
      "0x61": "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06",
      "0xfa2": "0xfaFedb041c0DD4fA2Dc0d87a6B0979Ee6FA7af5F",
    },
    priceFeed: true,
    minUsdValue: 1.5,
    minTokenAmount: 0.25
  },
  {
    name: "Wrapped Bitcoin",
    ticker: "WBTC",
    logo: "https://seeklogo.com/images/W/wrapped-bitcoin-wbtc-logo-A3917F45C9-seeklogo.com.png",
    decimals: 18,
    address: {
      "0x13881": "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
      "0x61": "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
      "0xfa2": "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
    },
    priceFeed: true,
    minUsdValue: 1.5,
    minTokenAmount: 0.00005
  },
];