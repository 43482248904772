import { Box, Center, Flex, Icon, Select, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useApps } from 'contexts/AppsContext';
import { useStats } from 'contexts/StatsContext';
import { allChains } from 'helpers/chains';
import { chainToHex, chainToNumber, createLinkForDashboard, getPaymentToken, getToast, hexToChain, useQuery } from 'helpers/formatters';
import { links } from 'helpers/links';
import { useState, useEffect, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import SearchTableEvents from './components/SearchTableEventsOverivew';
import { v4 as uuidv4 } from "uuid";
import IconBox from 'components/icons/IconBox';
import ChainLogo from 'components/chain/ChainLogo';
import { useEvents } from 'contexts/EventsContext';
import { BsClipboardData } from 'react-icons/bs';
import { BananaPay } from 'bananapay-types';
import BreadCrumpStrip from 'components/breadcrumb/Breadcrumb';
import { useUser } from 'contexts/UserContext';
import { getTokenFromAddressChain } from 'variables/tokens';
import Loading from 'components/loading/Loading';


type RowObj = {
  type: string;
  chain: string;
  date: string; 
  address: string;
  amount: string;
  confirmed: string;
  actions: string;
};

export default function EventsOverview() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

	const { apps, getAppDetails } = useApps();
	const { events, getAllEventsForApp } = useEvents();
	const { user } = useUser();
	const history = useHistory();
	const query = useQuery();
	const toast = useToast();

	const [isLoaded, setIsLoaded] = useState(false);
	const [tableData, setTableData] = useState<RowObj[] | null>(null);
	const [chain, setChain] = useState<"tbnb"|"ftm"|"mumbai">("mumbai");
	const [allEvents, setAllEvents] = useState<BananaPay.Event.BaseEvent[]>([]);

	const getJoinDate = (d: Date) => {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()} - ${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`;
	}

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	useEffect(() => {
		if(allEvents) {
			const data: RowObj[] = allEvents.map((x) => {
				let amountString = "-";
				
				if(x.name !== "Unsubscribed" && x.name !== "SubscriptionCanceled" && x.name !== "ExtensionCall") {
					const event = x as BananaPay.Event.Payment.Successful;
					const paymentToken = getTokenFromAddressChain(chainToHex(x.chainId), event.paymentToken || "");
					const tokenDecimals = paymentToken?.decimals || 18;
					const tokenTicker = paymentToken?.ticker || "tokens";
					const amount = Number(event.paymentAmount) / (10**tokenDecimals);
					amountString = `${amount || "-"} ${tokenTicker}`;
				}
			
				return{
					type: x.name,
					address: x.subscriber,
					chain: hexToChain(chainToHex(x.chainId)).charAt(0).toUpperCase() + hexToChain(chainToHex(x.chainId)).slice(1),
					confirmed: x.confirmed ? "Yes" : "No",
					date: getJoinDate(new Date(x.updatedAt.seconds*1000)),
					actions: `/#${links.eventDetails}?eventId=${x.id}`,
					amount: amountString
				}
			});

			setTableData(data);
		}
	}, [apps, allEvents]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

	const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

  useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.allEventsStats}_subscriptionId=${subscriptionId}` : `${links.signInCentered}?to=${links.allEventsStats}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user, query]);

	useEffect(() => {
		if(events && app) {
			const _events = getAllEventsForApp(app.subscription_id).reverse();
			setAllEvents(_events);
		}
	}, [events, app]);

	return (
		<Flex direction='column' pt={"75px"}>
			<BreadCrumpStrip
				additional={{mb: "4"}}
				links={[{
					href: `/#${createLinkForDashboard(app?.subscription_id)}`,
					name: "Dashboard"
				},{
					href: `/`,
					name: "All Events"
				}]}
			/>
			<Card px='0px'>
				<Box px="8">
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
						All Events
					</Text>
					<Text color={textColorSecondary} fontSize='md' me='26px' mb='15px'>
						List of all payment events that happened for your subscription. All data in one place for ease of use & monitoring!
					</Text>
				</Box>
				{allEvents?.length>0 && isLoaded && tableData ? (
					<SearchTableEvents tableData={tableData}  />
				):(
					!isLoaded ? (
						<Loading text='Loading Events ...' />
					):(
						<Box mb="8" mt="8" mx="auto">
							<Center mb="2">
								<Icon as={BsClipboardData} color={textColorPrimary} fontSize={"7xl"} mx="auto" w="min-content" textAlign={"center"} />
							</Center>
							<Text fontSize={"3xl"} fontWeight="900" color={textColorPrimary} textAlign="center">Not Enough Data</Text>
							<Text fontSize={"lg"} fontWeight="300" color={textColorPrimary} textAlign="center">Your subscription does not have enough data to be displayed. Try getting your first subscriber!</Text>
						</Box>
					)
				)}
			</Card>
		</Flex>
	);
}
