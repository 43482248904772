import { useEffect } from "react";
import { Box, Button, Flex, Icon, Text, Link, toast, useColorModeValue, useToast } from "@chakra-ui/react";
import Resources from "./components/Resources";
import { useUser } from "contexts/UserContext";
import { getToast } from "helpers/formatters";
import { links } from "helpers/links";
import { useHistory } from "react-router-dom";
import Card from 'components/card/Card';
import { BiLogOut } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import NoUser from "./components/NoUser";
import PastEvents from "./components/PastEvents";
import UpcomingEvents from "./components/UpcomingEvents";
import Subscriptions from "./components/Subscriptions";
import { ethers } from "ethers";
import { getChainContractAddress, getContractAbi } from "helpers/web3";
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react";


const ProfileDashboard = () => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const { user, signOut } = useUser();

  const test = async() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(getChainContractAddress("0x13881"), getContractAbi(), provider.getSigner());
    const tx = await contract.checkUpkeep("0x");
    console.log(tx);
  }

  const enabled = useFeatureIsOn("my-test");
  const text = useFeatureValue("my-test-string", "loading");

  return(
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }} px="2">
      {!user?.email && user ? (
        <Flex justifyContent={"space-between"} flexDirection={{ sm: "column", "2sm": "column", lg: "row", base: "row" }} px="4" py="2" backgroundColor={"brand.300"} rounded="2xl" mb="6">
          <Box>
            <Text color="white" textAlign={{ sm: "center", "2sm": "center", lg: "left", base: "left" }} fontSize={"2xl"}  fontWeight={"normal"}>Set your email to receive notifications about your subscriptions!</Text>
          </Box>
          <Box mx={{ sm: "auto", "2sm": "auto", lg: "0", base: "0" }} >
            <Link href={`/#${links.setEmailCentered}`}>
              <Button
                mr="2"
                colorScheme={"brand"}
              >
                <Icon as={IoMdSettings} color='white' h='18px' w='18px' mr="10px" />
                Set Email
              </Button>
            </Link>
          </Box>
        </Flex>
      ):(
        !user?.emailVerified && user ? (
          <Flex justifyContent={"space-between"} flexDirection={{ sm: "column", "2sm": "column", lg: "row", base: "row" }} px="4" py="2" backgroundColor={"brand.300"} rounded="2xl" mb="6">
            <Box>
              <Text color="white" textAlign={{ sm: "center", "2sm": "center", lg: "left", base: "left" }} fontSize={"2xl"}  fontWeight={"normal"}>Verify your email to receive notifications about your subscriptions!</Text>
            </Box>
            <Box mx={{ sm: "auto", "2sm": "auto", lg: "0", base: "0" }}>
              <Link href={`/#${links.verifyEmailCentered}`}>
                <Button
                  mr="2"
                  colorScheme={"brand"}
                >
                  <Icon as={IoMdSettings} color='white' h='18px' w='18px' mr="10px" />
                  Verify Email
                </Button>
              </Link>
            </Box>
          </Flex>
        ):null
      )}
      {user ? (
        <>
          <Card>
            <Flex justifyContent={"space-between"} flexDirection={{ sm: "column", "2sm": "column", lg: "row", base: "row" }}>
              <Box>
                <Text textAlign={{ sm: "center", "2sm": "center", lg: "left", base: "left" }} fontSize={{ sm: "2xl", "2sm": "2xl", lg: "3xl", base: "3xl" }}  fontWeight={"normal"} color={textColorPrimary}>Welcome back <b>{user?.email}</b>! 👋</Text>
              </Box>
              <Box mx={{ sm: "auto", "2sm": "auto", lg: "0", base: "0" }} mt={{ sm: "3", "2sm": "3", lg: "3", base: "0" }}>
                <Link href={`/#${links.profileOverview}`}>
                  <Button
                    mr="2"
                  >
                    <Icon as={IoMdSettings} color='secondaryGray.500' h='18px' w='18px' mr="10px" />
                    Settings
                  </Button>
                </Link>
                <Button
                  onClick={signOut}
                >
                  <Icon as={BiLogOut} color='secondaryGray.500' h='18px' w='18px' mr="10px" />
                  Sign Out
                </Button>
              </Box>
            </Flex>
          </Card>
          {/** maybe put these 2 in a row (flex) */}
          <Card mt="10" pb="8">
            <PastEvents />
          </Card>
          <Card mt="10" pb="8">
            <UpcomingEvents />
          </Card>
          <Subscriptions />
          <Resources />
        </>
      ):(
        <NoUser />
      )}
    </Box>
  );
};

export default ProfileDashboard;