import { Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Icon, IconButton, Image, Input, InputGroup, InputLeftElement, Link, Select, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text, toast, useColorModeValue, useToast } from "@chakra-ui/react";
import QRCode from "components/QRCode/QRCode";
import { HSeparator } from "components/separator/Separator";
import { IoQrCode } from "react-icons/io5";
import { useEffect, useState } from "react";
import { createWidgetLink, generateUnsubscribeTxLink, getToast, isValidUrl, stringToUrlLocationtType, stringToWidgetType, urlLocationTypes, widgetTypes } from "helpers/formatters";
import { FaBellSlash } from "react-icons/fa";
import Card from 'components/card/Card';
import { useUser } from "contexts/UserContext";
import { ethers } from "ethers";
import { getChainContractAddress, getContractAbi } from "helpers/web3";
import { GoBrowser } from "react-icons/go";
import { HiTrendingUp } from "react-icons/hi";
import TokenList from "./components/TokenList";
import AddCustomToken from "./components/AddCustomToken";
import { Chain, CollectData, InputTypes, inputTypes, OptionalParamProps, UrlLocation, WidgetType } from "helpers/types";
import { BiCustomize } from "react-icons/bi";
import { SearchIcon } from "@chakra-ui/icons";
import Dropzone from "views/admin/main/ecommerce/newPlan/components/Dropzone";
import { MdOutlineCloudUpload } from "react-icons/md";
import { BananaPay } from "bananapay-types";

const drawerSizes = {
  sm: '425px',
  md: "425px",
  lg: "1150px"
};

const WidgetLinkDrawer = (props: {
  isOpen: boolean;
  app: BananaPay.App;
  close: () => void;
}) => {
  const { app, isOpen, close } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const drawerBg = useColorModeValue('white', 'navy.800');
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const brand = useColorModeValue('brand.500', 'brand.400');

  const toast = useToast();

  const [widgetType, setWidgetType] = useState<WidgetType>("subscribe");
  const [planId, setPlanId] = useState<number>(0);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);
  const [redirectUrlLocation, setRedirectUrlLocation] = useState<UrlLocation>("_self");
  const [hasParams, setHasParams] = useState<boolean>(false);
  const [optionalParams, setOptionalParams] = useState<OptionalParamProps[]>([]);
  const [hasData, setHasData] = useState<boolean>(false);
  const [collectData, setCollectData] = useState<CollectData[]>([]);
  const [widgetLink, setWidgetLink] = useState<string | undefined>("");

  const toggleParams = () => setHasParams(!hasParams);
  const toggleData = () => setHasData(!hasData);

  const generateLink = () => {
    const params = hasParams ? optionalParams : undefined;
    const redirectUrlData = isValidUrl(redirectUrl) ? { url: redirectUrl, target: redirectUrlLocation } : undefined;
    return createWidgetLink(app.subscription_id, widgetType, planId, redirectUrlData, params);
  }

  const copyLink = () => {
    const _link = generateLink();
    navigator.clipboard.writeText(_link);
    toast(getToast("success", "Copied!", "Your widget link was created successfully!"));
  }

  const addNewParam = () => {
    let _new = [...optionalParams];
    _new.push({
      visible: true,
      label: ``,
      isEditable: false,
      valueType: "string",
      data: [{
        value: "",
        valueNickname: "",
        image: "",
      }]
    });
    setOptionalParams(_new);
  }

  const removeParam = (n: number) => {
    const newParams = [...optionalParams];
    if(newParams.length!==0) {
      newParams.splice(n, 1)
      setOptionalParams(newParams);
    }else{
      toast(getToast("info", "Info!", "Nothing to remove!"));
    }
  }


  const handleIsVisible = (val: boolean, index: number) => {
    let _new: OptionalParamProps[] = optionalParams.map((x, i) => {
      if (i === index) x.visible = val;
      return x;
    });
    setOptionalParams(_new);
  };

  const handleIsEditable = (val: boolean, index: number) => {
    let _new: OptionalParamProps[] = optionalParams.map((x, i) => {
      if (i === index) x.isEditable = val;
      return x;
    });
    setOptionalParams(_new);
  };

  const handleLabel = (val: string, index: number) => {
    let _new: OptionalParamProps[] = optionalParams.map((x, i) => {
      if (i === index) x.label = val;
      return x;
    });
    setOptionalParams(_new);
  };

  const handleValueType = (val: "string" | "number", index: number) => {
    let _new: OptionalParamProps[] = optionalParams.map((x, i) => {
      if (i === index) x.valueType = val;
      return x;
    });
    setOptionalParams(_new);
  };

  const handleValue = (val: string|number, index: number, i: number) => {
    let _new: OptionalParamProps[] = [...optionalParams];
    _new[index].data[i].value = val;
    setOptionalParams(_new);
  }

  const handleNickname = (val: string, index: number, i: number) => {
    let _new: OptionalParamProps[] = [...optionalParams];
    _new[index].data[i].valueNickname = val;
    setOptionalParams(_new);
  }

  const handleImage = (val: string, index: number, i: number) => {
    let _new: OptionalParamProps[] = [...optionalParams];
    _new[index].data[i].image = val;
    setOptionalParams(_new);
  }
  
  const addNewOption = (index: number) => {
    let _new = [...optionalParams];
    _new[index].data.push({
      image: "",
      value: "",
      valueNickname: ""
    });
    setOptionalParams(_new);
  }

  const removeOption = (index: number, i: number) => {
    const newParams = [...optionalParams];
    if(newParams[index].data.length!==0) {
      newParams[index].data.splice(i, 1)
      setOptionalParams(newParams);
    }else{
      toast(getToast("info", "Info!", "Nothing to remove!"));
    }
  }

  const addNewData = () => {
    let _new = [...collectData];
    _new.push({
      name: "",
      type: "string",
      isRequired: true,
    });
    setCollectData(_new);
  }

  const removeData = (n: number) => {
    const newParams = [...collectData];
    if(newParams.length!==0) {
      newParams.splice(n, 1)
      setCollectData(newParams);
    }else{
      toast(getToast("info", "Info!", "Nothing to remove!"));
    }
  }

  const handleDataRequired = (val: boolean, index: number) => {
    let _new: CollectData[] = [...collectData];
    _new[index].isRequired = val;
    setCollectData(_new);
  };

  const handleDataName = (val: string, index: number) => {
    let _new: CollectData[] = [...collectData];
    _new[index].name = val;
    setCollectData(_new);
  }

  const handleDataInputType = (val: InputTypes, index: number) => {
    let _new: CollectData[] = [...collectData];
    _new[index].type = val;
    setCollectData(_new);
  }

  useEffect(() => {
    const _link = generateLink();
    setWidgetLink(_link);
  }, [widgetType, planId, redirectUrl, redirectUrlLocation, optionalParams, hasParams]);

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">Create Widget Link</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">Create your unique widget link that you can use in your projects.</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          <Box>
            <Text>Select Widget Type</Text>
            <Select fontSize='md' mt="1" variant="outline" defaultValue={widgetType} width='unset' fontWeight='700' onChange={e => setWidgetType(stringToWidgetType(e.target.value))}>
              {widgetTypes().map((type) => {
                return(
                  <option key={type.value} value={type.value}>{type.name}</option>
                )
              })}
            </Select>
          </Box>

          {widgetType === "subscribe" ? (
            <Box>
              <Text>Select Subscription Plan:</Text>
              <Select fontSize='md' mt="1" variant="outline" defaultValue={planId} width='unset' fontWeight='700' onChange={e => setPlanId(Number(e.target.value))}>
                {app.paymentOptions.map((plan, index) => {
                  return(
                    <option key={index} value={index}>{plan.name}</option>
                  )
                })}
              </Select>
            </Box>
          ):null}

          <Box>
            <Text>Redirect Url:</Text>
            <Input
              mb='0px'
              id='website'
              placeholder='eg. https//...'
              value={redirectUrl}
              onChange={(e: any) => setRedirectUrl(e.target.value)}
            />
            {redirectUrl?.length>0 && !isValidUrl(redirectUrl) ? (
              <Text>Invalid url. Make sure that the url you input is correct!</Text>
            ):null}
          </Box>

          {isValidUrl(redirectUrl) ? (
            <Box>
              <Text>Select Redirect Location:</Text>
              <Select fontSize='md' mt="1" variant="outline" defaultValue={redirectUrlLocation} width='unset' fontWeight='700' onChange={e => setRedirectUrlLocation(stringToUrlLocationtType(e.target.value))}>
                {urlLocationTypes().map((type) => {
                  return(
                    <option key={type.value} value={type.value}>{type.name}</option>
                  )
                })}
              </Select>
            </Box>
          ):null}

          <Box mb="4">
            {widgetType === "subscribe" ? (
              <>
              <Text>Add Additional Parameters - ADVANCED:</Text>
              <Text>Learn more</Text>
              <Button onClick={toggleParams}>{hasParams ? "Remove Additional Parameters":"Add Additional Parameters"}</Button>
              {hasParams ? (
                <Box>
                  <Text>Add Aditional Parameters:</Text>
                  <Text>All additional parameters are publicly availabe on blockchain - do not put any private data in it.</Text>
                  {optionalParams.map((param, index) => {
                    return (
                      <Box key={index}>
                        <Text><b>#{index+1} Param:</b></Text>
                        <Text>Parameter Label:</Text>
                        <Input
                          mb='0px'
                          id='label'
                          placeholder='eg. Address'
                          value={param.label}
                          onChange={(e: any) => handleLabel((e.target.value).toString(), index)}
                        />
                        <Text>Is the parameter visible on the widget</Text>
                        <Switch checked={param.visible} onChange={(e) => handleIsVisible(e.target.value ? true : false, index)} />
                        <Text>can users edit the parameter</Text>
                        <Switch checked={param.isEditable} onChange={(e) => handleIsEditable(e.target.value ? true : false, index)} />
                        <Text>Option value type</Text>
                        <Flex>
                          <Text>String</Text>
                          <Switch checked={param.valueType === "string" ? true : false} onChange={(e) => handleValueType(e.target.value ? "number" : "string", index)} />
                          <Text>Number</Text>
                        </Flex>
                        {param.data.map((data, i) => {
                          return(
                            <Box key={i}>
                              <Text><b>Option #{i+1}</b></Text>
                              <Flex flexDirection={"row"}>
                                <Box w="49%" mr="2%">
                                  <Text>Option Nickname:</Text>
                                  <Input
                                    mb='0px'
                                    id='label'
                                    placeholder='Your Option Nickname'
                                    value={data.valueNickname}
                                    onChange={(e: any) => handleNickname(e.target.value, index, i)}
                                  />
                                </Box>
                                <Box w="49%">
                                  <Text>Option value:</Text>
                                  <Input
                                    mb='0px'
                                    id='label'
                                    placeholder='Your Option Value'
                                    value={data.value}
                                    onChange={(e: any) => handleValue(e.target.value, index, i)}
                                  />
                                </Box>
                              </Flex>
                              <Text>Option Image:</Text>
                              <Dropzone
                                file={data.image || ""}
                                onChange={(e: string) => handleImage(e, index, i)}
                                h="75px"
                                minH="25px"
                                pb="60px !important"
                                pt="55px !important"
                                content={
                                  <Box p="0">
                                    {data.image ? (
                                      <Image
                                        src={data.image}
                                        h='30px'
                                        alt="Uploaded image"
                                        mb="1.5"
                                        mx="auto"
                                      />
                                    ):(
                                      <Icon as={MdOutlineCloudUpload} w='30px' h='30px' color={textColor} />
                                    )}                <Text
                                      mx='auto'
                                      mb='6px'
                                      fontSize='lg'
                                      fontWeight='700'
                                      whiteSpace='pre-wrap'
                                      color={textColor}>
                                      Drop your files here, or{' '}
                                      <Text as='span' fontSize='lg' fontWeight='700' color={brand}>
                                        browse
                                      </Text>
                                    </Text>
                                    <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                      PNG, JPG and GIF files are allowed
                                    </Text>
                                  </Box>
                                }
                              />
                              <Button onClick={() => removeOption(index, i)}>Remove Option</Button>
                            </Box>
                          )
                        })}
                        <br />
                        <Button onClick={() => addNewOption(index)}>Add New Option</Button>
                        <Button mt="2" variant={"action"} w="100%" onClick={() => removeParam(index)}>
                          Remove
                        </Button>
                      </Box>
                    )
                  })}
                  <Button variant="brand" w="100%" onClick={addNewParam}>Add</Button>
                </Box>
              ):null}
              </>
            ):null}

            {widgetType === "subscribe" ? (
              <>
                <Text>Add Additional Inputs:</Text>
                <Text>Learn more</Text>
                <Button onClick={toggleData}>{hasData ? "Remove Additional Inputs":"Add Additional Inputs"}</Button>
                {hasData ? (
                  <Box>
                    <Text>Add Required Customer Inputs:</Text>
                    <Text>All required customer inputs are private and only visible to you in your dashboard.</Text>
                    {collectData.map((param, index) => {
                      return (
                        <Box key={index}>
                          <Text><b>#{index+1} Input:</b></Text>
                          <Text>Name:</Text>
                          <Input
                            mb='0px'
                            id='name'
                            placeholder='eg. Twitter Username'
                            value={param.name}
                            onChange={(e: any) => handleDataName((e.target.value).toString(), index)}
                          />
                          <Select fontSize='md' mt="1" variant="outline" defaultValue={"string"} width='unset' fontWeight='700' onChange={e => handleDataInputType((e.target.value as InputTypes), index)}>
                            {inputTypes.map((type) => {
                              return(
                                <option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</option>
                              )
                            })}
                          </Select>
                          <Text>Is this input required</Text>
                          <Switch checked={param.isRequired} onChange={(e) => handleDataRequired(e.target.value ? true : false, index)} />
                          <Button mt="2" variant={"action"} w="100%" onClick={() => removeData(index)}>
                            Remove
                          </Button>
                        </Box>
                      )
                    })}
                    <Button variant="brand" w="100%" onClick={addNewData}>Add Input</Button>
                  </Box>
                ):null}
              </>
            ):null}
          </Box>

          <Divider />
          <Button onClick={copyLink} w="100%" variant={"darkBrand"} my="2">Copy Link</Button>
          <Link target={"_blank"} href={widgetLink}>
            <Button w="100%" variant={"darkBrand"}>Preview</Button>
          </Link>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="brand" onClick={close}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default WidgetLinkDrawer;