import { Box, Button, Center, Checkbox, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Icon, IconButton, Input, InputGroup, InputLeftElement, Link, Select, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { BananaPay } from "bananapay-types";
import { useUser } from "contexts/UserContext";
import { getToast, isEnglishAlphabet } from "helpers/formatters";
import { useState } from "react";
import { WarningIcon } from "@chakra-ui/icons";
import { FaCopy } from "react-icons/fa";
import { links } from "helpers/links";
import { useApps } from "contexts/AppsContext";


const drawerSizes = {
  sm: '550px',
  md: "550px",
  lg: "750px"
};

const ApiKeyDrawer = (props: {
  isOpen: boolean;
  apiKeys: BananaPay.ExtendedUser.ApiKeys;
  selectedSubscriptionId: string;
  close: () => void;
  onCreate: (name: string, newKey: BananaPay.ExtendedUser.ApiKey) => void;
}) => {
  const { isOpen, close, apiKeys, onCreate, selectedSubscriptionId } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const bgColor = useColorModeValue('white', 'secondaryGray.900');
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const drawerBg = useColorModeValue('white', 'navy.800');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [subscriptionId, setSubscriptionId] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);

  const { createApiKey, user } = useUser();
  const { getAppsByPublisher } = useApps();
  const toast = useToast();

  const handleCreateApiKey = async() => {
    const _name = name;
    if(_name.length<=1 || _name.length>11 || !isEnglishAlphabet(_name)) {
      return toast(getToast("warning", "Invalid name", "You name must contain between 2 and 11 characters, all must be in English alphabet."));
    }
    setIsLoading(true);
    const id = selectedSubscriptionId.length<=0 ? subscriptionId : selectedSubscriptionId
    console.log("Creating for sub with ID: ", id);
    const key = await createApiKey(_name, id);
    setKey(key);
    setIsLoading(false);
    setName("");
    if(key.length>0) {
      onCreate(_name, {active: true, hashed_key: "", subscription_id: id, name: _name});
    }
  };

  const handleClose = () => {
    if(key) {
      if(checked) close();
    }else{
      close();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(key)
      .then(() => {
        toast(getToast("success", "Copied", "API Key was copied to clipboard - store it securely!"))
      })
      .catch((error) => {
        toast(getToast("error", "Failed to Copy!", error))
      });
  };

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={() => {}}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">Create New API Key</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">Create your new BananaPay API Key.</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          {!user?.emailVerified ? (
            <>
              <Text textAlign={"center"} fontSize={"lg"} fontWeight="400">To create API Keys, it is mandatory to have a verified email address set up.</Text>
              <Link href={user?.email ? `#/${links.verifyEmailCentered}?to=${links.profileOverview}` : `#/${links.setEmailCentered}?to=${links.verifyEmailCentered}_to=${links.profileOverview}`}>
                <Button
                  variant="darkBrand"
                  onClick={handleCreateApiKey}
                  width="100%"
                  isDisabled={isLoading}
                  mt="6"
                  >
                  {user?.email ? "Verify" : "Set"} Email
                </Button>
              </Link>
            </>
          ):(
            <>          
              {!isLoading ? (
                <>
                  <Text fontSize={"sm"} fontWeight="bold">
                    API Key Name
                  </Text>
                  <Input
                    mt="1"
                    mb='4'
                    placeholder='MyApp'
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />

                  {selectedSubscriptionId.length<=0 ? (
                    <>
                    <Text fontSize={"sm"} fontWeight="bold">
                      Select subscription
                    </Text>
                    <Select placeholder="Select subscription" onChange={e => setSubscriptionId(e.target.value)}>
                      {getAppsByPublisher(user?.uid).map(app => {
                        return(
                          <option key={app.subscription_id} value={app.subscription_id}>{app.name}</option>
                        )
                      })}
                    </Select>
                    <Text fontSize={"xs"} fontWeight="light" mb="8">
                      *For better security, each API Key is linked to an individual subscription.
                    </Text>
                    </>
                  ):null}
                </>
              ):(
                <Center mx="auto" flexDirection={"column"} mb="5">
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='brand.500'
                    size="xl"
                    mx="auto"
                  />
                  <Text textAlign={"center"}>Creating . . .</Text>
                </Center>
              )}
              <Button
                variant="darkBrand"
                onClick={handleCreateApiKey}
                width="100%"
                isDisabled={isLoading}
                mb="8"
              >
                Create
              </Button>
              {key.length>0 ? (
                <Box bg="lightpink" border="2px solid red" p={4} borderRadius="2xl">
                  <Flex alignItems="center" mb={2}>
                    <Icon as={WarningIcon} color="red" boxSize={5} mr={2} />
                    <Text fontWeight="bold" color="red" fontSize={"xl"}>
                      Warning! Copy & Store the API Key!
                    </Text>
                  </Flex>
                  <Text color="red" fontSize="md">
                    You must <b>copy and securely store this API Key</b> as you will not be able to access it after you close this window. If you lose it you will need to create a new API Key! If someone gets access to your API Key they will be able to call all your APIs and manage your subscription on your behalf!
                  </Text>
                  <Flex bg={bgColor} mt={4} px={3} py="1.5" borderRadius="xl" justifyContent={"space-between"} alignItems="center">
                    <Text fontWeight="black" color={textColor} fontSize="lg">
                      {key}
                    </Text>
                    <Button size="md" onClick={handleCopy}>
                      <FaCopy />
                    </Button>
                  </Flex>
                </Box>
              ):null}
            </>)}
        </DrawerBody>

        <DrawerFooter justifyContent={"space-between"}>
          {key ? (
            <Box>
              <Checkbox
                variant={"brand"}
                onChange={() => setChecked(!checked)}
                isChecked={checked}
              >
                <Text fontSize={"md"} fontWeight="semibold" textAlign={"left"}>
                  I have copied and securely stored my API Key
                </Text>
              </Checkbox>
            </Box>
          ):null}
          <Button
            variant="brand"
            onClick={handleClose}
            isDisabled={key ? !checked : false}
            >
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ApiKeyDrawer;