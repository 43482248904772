import { Box, Button, Flex, Icon, Link, Tab, TabList, TabPanel, Center, TabPanels, Tabs, Text, useColorModeValue } from "@chakra-ui/react";
import ChainTag from "components/chain/ChainTag";
import Card from 'components/card/Card';
import SubscriptionStatusTag from "components/other/SubscriptionStatusTag";
import { HSeparator } from "components/separator/Separator";
import { useUser } from "contexts/UserContext";
import { useEvents } from "contexts/EventsContext";
import DisplayEvent from "components/event/Event";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import UnsubscribeDrawer from "components/Drawers/UnsubscribeDrawer";
import CancelPaymentDrawer from "components/Drawers/CancelPaymentDrawer";
import { FaHistory } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { MdHistoryEdu } from "react-icons/md";
import { createWidgetLink, useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { ethers } from "ethers";
import { getChainContractAddress, getContractAbi } from "helpers/web3";
import { BananaPay } from "bananapay-types";


const ActivityPanel = (props: { app: BananaPay.App, events: any[] | null }) => {
  const { app, events } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';
	const textColor = useColorModeValue('secondaryGray.900', 'white');
  
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState<boolean>(false);
  const [isCancelOpen, setIsCancelOpen] = useState<boolean>(false);
	const [ tabState, setTabState ] = useState('history');
  const [upcomingEvents, setUpcomingEvents] = useState<any[]>([]);
  const [signInLink, setSignInLink] = useState<string>("");

  const { user } = useUser();
  const query = useQuery();
  const { getUpcomingEvents, getSubscriptionPlan } = useEvents();

  const isSubscribed = () => {
    if(!events) return false;
    if(events.length === 0) return false;
    const lastEvent = events[events?.length-1];
    if(lastEvent.name === "Unsubscribed" || lastEvent.name === "PaymentFailed") return false;
    else return true;
  }

  const getTokenAddress = () => {
    if(!events) return "";
    if(events.length === 0) return "";
    const lastEvent = events[events?.length-1];
    if(lastEvent.name === "Unsubscribed" || lastEvent.name === "PaymentFailed") return "";
    else return lastEvent.paymentToken;
  }

  useEffect(() => {
    if(events) {
      (async () => {
        const _events = await getUpcomingEvents(app.subscription_id);
        setUpcomingEvents(_events.upcomingEvents);
      })();
    }
  }, [events]);

  useEffect(() => {
    const address = query.get("address");
    const chain = query.get("chain");
    const redirectLink = address && chain ? `${links.signInCentered}?to=${links.singleAppPage}_address=${address}_chain=${chain}` : `${links.signInCentered}?to=${links.singleAppPage}`;
    setSignInLink(`/#${redirectLink}`);
  }, [query]);

  return(
    <>
    <Box px="8" mx="2" pb="8">
      <Card>
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
          Your activity with {app.name}
        </Text>
        <Text color={textColorSecondary} fontSize='md' me='26px' mb='25px'>
          All your activity with {app.name} summed up for a simple overview!
        </Text>

        <Flex mb="2">
          <SubscriptionStatusTag isSubscribed={getSubscriptionPlan(app)?.isSubscribed} />
        </Flex>
      </Card>

      <Tabs variant='soft-rounded' colorScheme='brandTabs'>
        <Card mt="16">
          <TabList mx={{ base: '10px', lg: '30px' }} overflowX={{ sm: 'scroll', lg: 'unset' }}>
            <Flex justify={{ base: 'start', md: 'center' }} w='100%'>
              <Tab
                pb='0px'
                flexDirection='column'
                onClick={function() {
                  setTabState('history');
                }}
                me='20px'
                bg='unset'
                _selected={{
                  bg: 'none'
                }}
                _focus={{ border: 'none' }}
                minW='max-content'>
                <Flex align='center'>
                  <Icon color={textColor} as={MdHistoryEdu} w='20px' h='20px' me='8px' />
                  <Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
                    History
                  </Text>
                  <Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
                    {events?.length || "-"}
                  </Text>
                </Flex>
                <Box
                  height='4px'
                  w='100%'
                  transition='0.1s linear'
                  bg={tabState === 'history' ? 'brand.500' : 'transparent'}
                  mt='15px'
                  borderRadius='30px'
                />
              </Tab>
              <Tab
                onClick={function() {
                  setTabState('upcoming');
                }}
                pb='0px'
                me='50px'
                bg='unset'
                _selected={{
                  bg: 'none'
                }}
                _focus={{ border: 'none' }}
                minW='max-content'
                flexDirection='column'>
                <Flex align='center'>
                  <Icon color={textColor} as={BsClockHistory} w='20px' h='20px' me='8px' />
                  <Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
                    Upcoming
                  </Text>
                  <Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
                    {upcomingEvents?.length || "-"}
                  </Text>
                </Flex>
                <Box
                  height='4px'
                  w='100%'
                  transition='0.1s linear'
                  bg={tabState === 'upcoming' ? 'brand.500' : 'transparent'}
                  mt='15px'
                  borderRadius='30px'
                />
              </Tab>
            </Flex>
          </TabList>
        </Card>
        <TabPanels>
					<TabPanel px='0px'>
            <Card mt="1">
              {user ? (
                <Box>
                  {events?.sort((a, b) => b.updatedAt.seconds - a.updatedAt.seconds)?.slice(0, 50)?.map((event: any, n) => {
                    return(
                      <DisplayEvent
                        key={uuidv4()}
                        event={event}
                        isLast={events.length === n+1}
                      />
                    )
                  })}
                  {!events || events?.length === 0 ? (
                    <Box>
                      <Text textAlign={"center"}>You do not have any activity at this subscription!</Text>
                    </Box>
                  ):null}
                  {events && events?.length>50 ? (
                    <Center>
                      <Link mt={"8"} href={`/#${links.allUserEvents}`}>
                        <Button variant={"action"}>
                          See All
                        </Button>
                      </Link>
                    </Center>
                  ):null}
                </Box>
              ):(
                <Box>
                  <Text textAlign={"center"} color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>Sign In</Text>
                  <Text textAlign={"center"} color={textColorPrimary} fontSize='md' me='26px' mb='25px'>You need to be signed in to be able access your activity with {app.name}.</Text>
                  <Center>
                    <Link href={signInLink}>
                      <Button variant={"darkBrand"} w="fit-content">
                        Sign In
                      </Button>
                    </Link>
                  </Center>
                </Box>
              )}
            </Card>
					</TabPanel>
					<TabPanel px='0px'>
            <Card mt="1">
              {user ? (
                <Box>
                  {upcomingEvents?.map((event: any, n) => {
                    return(
                      <DisplayEvent
                        key={uuidv4()}
                        event={event}
                        isLast={upcomingEvents.length === n+1}
                        isUpcoming={true}
                      />
                    )
                  })}
                  {!upcomingEvents || upcomingEvents?.length === 0 ? (
                    <Box>
                      <Text textAlign={"center"}>You do not have any upcoming events at this subscription! You need to be subscribed first!</Text>
                    </Box>
                  ):null}
                </Box>
              ):(
                <Box>
                  <Text textAlign={"center"} color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>Sign In</Text>
                  <Text textAlign={"center"} color={textColorPrimary} fontSize='md' me='26px' mb='25px'>You need to be signed in to be able access your activity with {app.name}.</Text>
                  <Center>
                    <Link href={signInLink}>
                      <Button variant={"darkBrand"} w="fit-content">
                        Sign In
                      </Button>
                    </Link>
                  </Center>
                </Box>
              )}
            </Card>
					</TabPanel>
        </TabPanels>
      </Tabs>
    </Box>

{/*    <UnsubscribeDrawer
      appAddress={app.address}
      chain={app.chain}
      name={app.name}
      isOpen={isUnsubscribeOpen}
      close={() => setIsUnsubscribeOpen(false)}
    />
    <CancelPaymentDrawer
      name={app.name}
      chain={app.chain}
      tokenAddress={getTokenAddress()}
      isOpen={isCancelOpen}
      close={() => setIsCancelOpen(false)}
              />*/}

    </>
  )
};

export default ActivityPanel;