/* eslint-disable */

import { Box, Button, Center, Flex, Icon, Link, Spinner, Table, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useColorModeValue } from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import { BananaPay } from 'bananapay-types';
import { useUser } from 'contexts/UserContext';
import { capitalizeFirstLetter } from 'helpers/formatters';

// Custom components
import * as React from 'react';
import { useState } from 'react';
import { FaCopy } from 'react-icons/fa';

type RowObj = {
  url: string;
  description: string;
	status: BananaPay.Webhook.Status;
	created: string;
	secret: string;
  delete: [() => Promise<void>, () => Promise<void>, string];
	more: () => void;
};

const columnHelper = createColumnHelper<RowObj>();

const EndpointsTable = (props: { tableData: RowObj[] }) => {
	const { tableData } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('navy.700', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue("brand.500", "brand.400");
  
  const [isLoading, setIsLoading] = useState<boolean>(false);

	let defaultData = tableData;
	const columns = [
		columnHelper.accessor('url', {
			id: 'url',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					URL
				</Text>
			),
			cell: (info: any) => (
        <Flex>
          <Text color={textColor} fontSize='md' pl="2" fontWeight='500'>
            {info.getValue()}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('description', {
			id: 'description',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NAME
				</Text>
			),
			cell: (info: any) => (
        <Flex>
          <Text color={textColor} fontSize='md' pl="2" fontWeight='500'>
            {info.getValue()}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('status', {
			id: 'status',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					STATUS
				</Text>
			),
			cell: (info) => ( 
        <Flex>
					<Tag size={"lg"} variant='solid' colorScheme={info.getValue() === "enabled" ? "green": "red"}>
						{capitalizeFirstLetter(info.getValue())}
					</Tag>
        </Flex>
			)
		}),
		columnHelper.accessor('created', {
			id: 'created',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					CREATED
				</Text>
			),
			cell: (info) => (
				<Flex>
					<Text color={textColor} fontSize='md' pl="2" fontWeight='500'>
						{info.getValue()}
					</Text>
        </Flex>
			)
		}),
		/*columnHelper.accessor('secret', {
			id: 'secret',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SECRET
				</Text>
			),
			cell: (info) => (
				<Flex>
					<Button onClick={() => navigator.clipboard.writeText(info.getValue())}>
						<Icon as={FaCopy} h='18px' w='18px' mr="10px" />
						Copy Secret
					</Button>
        </Flex>
			)
		}),*/
		columnHelper.accessor('delete', {
			id: 'delete',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ACTIONS
				</Text>
			),
			cell: (info) => (
				<Flex>
					<Button mr="1" onClick={info.getValue()[0]}>
            {info.getValue()[2] === "enabled" ? "Disable" : "Enable"}
          </Button>
          <Button variant="brand" onClick={info.getValue()[1]}>
            Delete
          </Button>
        </Flex>
			)
		}),
		columnHelper.accessor('more', {
			id: 'more',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					MORE
				</Text>
			),
			cell: (info) => (
				<Flex onClick={info.getValue()}>
					<Text
						cursor='pointer'
						color={brandColor}
						textDecoration='underline'
						fontSize='md'
						fontWeight='500'
						>
						More
					</Text>
				</Flex>
			)
		})
	];
	const [ data, setData ] = React.useState(() => [ ...defaultData ]);
	const table = useReactTable({
		data: tableData,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true,
		enableColumnFilters: true
	});
	
	return (
		<Flex direction='column' w='100%' overflowX={"scroll"}>
			<Box>
				{!isLoading ? (
          <Table variant='simple' color='gray.500' mt='12px'>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        borderColor={borderColor}
                        cursor='pointer'>
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.slice(0, 11).map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor={borderColor}
                          mt='20px !important'
                          py='10px !important'>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ):(
          <Center mx="auto" flexDirection={"column"}>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='brand.500'
              size="xl"
              mx="auto"
            />
            <Text textAlign={"center"}>Working on it . . .</Text>
          </Center>
        )}
			</Box>
		</Flex>
	);
};

export default EndpointsTable;
