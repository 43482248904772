/*!
""

*/
import { useState } from "react";

// Chakra imports
import { Box, Button, Center, Grid, Link, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/main/profile/overview/components/Banner';
import General from 'views/admin/main/profile/overview/components/General';
import Notifications from 'views/admin/main/profile/overview/components/Notifications';
import Projects from 'views/admin/main/profile/overview/components/Projects';
import Storage from 'views/admin/main/profile/overview/components/Storage';
import Upload from 'views/admin/main/profile/overview/components/Upload';
import Card from 'components/card/Card';
import EventsCalendar from "components/event/Calendar";

// Assets
import banner from 'assets/img/auth/banner.png';
import avatar from 'assets/img/avatars/avatar4.png';
import { useUser } from 'contexts/UserContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { chainToHex, chainToNumber, formatNumber, getToast, strToColor } from 'helpers/formatters';
import { links } from 'helpers/links';
import LatestEvents from './components/LatestEvents';
import { useEvents } from 'contexts/EventsContext';
import { useApps } from 'contexts/AppsContext';
import { calendarData } from 'views/admin/main/applications/calendar/variables/calendar';
import EventCalendar from 'components/calendar/EventCalendar';
import Timeline from 'views/admin/main/applications/calendar/components/Timeline';
import ApiKeys from "./components/ApiKeys";

type CalendarDataObj = {
	title: string,
	borderColor: "transparent",
	start: string,
	end: string,
	backgroundColor: string,
	className: "info" | "success" | "warning" | "error", // info success warning error
}

export type TimelineDataObj = {
	title: string,
	time: Date,
}

export default function Overview() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { user } = useUser();
	const history = useHistory();
	const toast = useToast();
	const { getAllEventsForUser, getAllUpcomingEvents, events, getSubscriptionPlan } = useEvents();
	const { getAppsByPublisher, getAppDetailsFromAddressAndChain, apps } = useApps();

	const [calendarData, setCalendarData] = useState<CalendarDataObj[]>(null);
	const [timelineData, setTimelineData] = useState<TimelineDataObj[]>(null);
	const [currentMonth, setCurrentMonth] = useState<string>(null);

	const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

	const getUserCreationTime = () => {
		const d = new Date(user?.metadata?.creationTime);
		if(d) {
			const m = months[d.getMonth()];
			const y = d.getFullYear();
			return `${m} ${y}`;
		}else{
			return "-";
		}
	}

	const handleAddToCalendar = () => {
		console.log("adding");
	}

	const changeCurrentMonth = (plus: number) => {
		const date = new Date();
		const d = new Date(date.setMonth(date.getMonth()+plus));
		setCurrentMonth(`${d.getFullYear()}-${("0" + d.getMonth()+1).slice(-2)}-${("0" + d.getDate()).slice(-2)}`);
	}

	const getCurrentMonth = () => {
		const d = new Date();
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		return `${months[d.getMonth()]} ${d.getFullYear()}`;
	}

	useEffect(() => {
		if(events && apps && !calendarData && !timelineData){
			(async () => {
				const allUpcomingEvents = await getAllUpcomingEvents();
				const data: CalendarDataObj[] = [];
				const timeline: TimelineDataObj[] = [];

				for(const chain in allUpcomingEvents) {
					for(const appAddress in allUpcomingEvents[chain]) {
						const upcomingEvents = allUpcomingEvents[chain][appAddress];
						const appDetails = getAppDetailsFromAddressAndChain(appAddress, chainToHex(chainToNumber(chain)));

						upcomingEvents.map((upcomingEvent) => {
							const date = new Date(upcomingEvent.updatedAt.seconds*1000);
							const className = upcomingEvent.name === "Unsubscribed" ? "error" : (upcomingEvent.name === "PaymentFailed" ? "info" : "success");
							const paymentToken = getSubscriptionPlan(appDetails)?.paymentToken;
							const tokenDecimals = paymentToken?.token?.decimals || 18;
							const tokenTicker = paymentToken?.token?.ticker || "Tokens";
							const amount = Number(upcomingEvent.paymentAmount) / (10**tokenDecimals);
							const secondStr = upcomingEvent.name === "Unsubscribed" ? "Unsubscribed" : (upcomingEvent.name === "PaymentFailed" ? "Failed" : `${formatNumber(amount, 5)} ${tokenTicker} Payment`);
							data.push({
								title: `${appDetails?.name || "Unknown"} ${secondStr}`,
								start: `${date.getFullYear()}-${("0" + date.getMonth()+1).slice(-2)}-${("0" + date.getDate()).slice(-2)}`,
								backgroundColor: strToColor(appDetails?.name || "Unknown"),
								borderColor: "transparent",
								end: `${date.getFullYear()}-${("0" + date.getMonth()+1).slice(-2)}-${("0" + date.getDate()).slice(-2)}`,
								className: className
							});
							timeline.push({
								title: `${appDetails?.name || "Unknown"} - ${secondStr}`,
								time: date
							})
						})
					}
				}

				setTimelineData(timeline);
				setCalendarData(data);
			})();
		}
	}, [events, apps]);

	useEffect(() => {
		changeCurrentMonth(0);
	}, []);

	useEffect(() => {
		if(!user) {
			toast(getToast("info", "Sign in first!", "You must be signed in first."));
			history.push(`${links.signInCentered}?to=${links.profileOverview}`);
		}
	}, [user]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<Banner
				gridArea='1 / 1 / 2 / 2'
				banner={banner}
				avatar={avatar}
				name={user?.email}
				job={user?.displayName}
				created={getAppsByPublisher(user?.uid)?.length.toString() || "0"}
				events={getAllEventsForUser()?.length.toString() || "0"}
				joined={getUserCreationTime()}
			/>
			{/*<Grid
				templateColumns={{
					base: '1fr',
					lg: '1.34fr 1fr 1.62fr'
				}}
				templateRows={{
					base: 'repeat(3, 1fr)',
					lg: '1fr'
				}}
				gap={{ base: '20px', xl: '20px' }}>
				{/*<Banner
					gridArea='1 / 1 / 2 / 2'
					banner={banner}
					avatar={avatar}
					name={user.email}
					job={user.displayName}
					posts='17'
					followers='9.7k'
					following='274'
			/>*/}
				{/*<Storage /*gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} used={25.6} total={50} />
				<Upload
					/*gridArea={{
						base: '3 / 1 / 4 / 2',
						lg: '1 / 3 / 2 / 4'
					}}
					minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
					pe='20px'
					pb={{ base: '100px', lg: '20px' }}
				/>}
			</Grid>*/}
			<Grid
				mb='20px'
				templateColumns={{
					base: '1fr',
					lg: 'repeat(2, 1fr)',
					'2xl': '1.25fr 1.25fr 0.9fr'
				}}
				templateRows={{
					base: '1fr',
					lg: 'repeat(2, 1fr)',
					'2xl': '1fr'
				}}
				gap={{ base: '20px', xl: '20px' }}>
				<Projects />
				<LatestEvents />
				{/*<General gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} minH='365px' pe='20px' />*/}
				<Notifications
					used={25.6}
					total={50}
					gridArea={{
						base: '3 / 1 / 4 / 2',
						lg: '2 / 1 / 3 / 3',
						'2xl': '1 / 3 / 2 / 4'
					}}
				/>
			</Grid>
			{getAppsByPublisher(user?.uid)?.length > 0 ? (
				<ApiKeys show={true} />
			):(
				<ApiKeys show={false} />
			)}
			<Card mt="16" mb="4">
				<Text fontSize='3xl' fontWeight='900' color={textColor}>
					Upcoming Payments
				</Text>
				<Text fontSize='xl' fontWeight='500' color='secondaryGray.600' mb='2'>
					View your upcoming payments and events. You can also add them to your google calendar to stay up to date in real-time.
				</Text>
				{/*<Button
					onClick={handleAddToCalendar}
					w="min-content"
				>
					Add to Google Calendar
				</Button>*/}
			</Card>
			<Grid
				gridTemplateColumns={{ base: '2.4fr 1fr', lg: '1fr 1.83fr' }}
				gap={{ base: '20px', xl: '20px' }}
				display={{ base: 'block', lg: 'grid' }}
			>
				<Box gridArea={"1 / 2 / 2 / 3"} display={"grid"}>
					<EventsCalendar />
				</Box>
				<Box>
					<Timeline data={timelineData} />
				</Box>
			</Grid>
		</Box>
	);
}
