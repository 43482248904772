/*!
""

*/

// Chakra imports
import { Box, Button, Flex, Grid, Link, Text, useColorModeValue, SimpleGrid } from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/nfts/marketplace/components/Banner';
import TableTopCreators from 'views/admin/nfts/marketplace/components/TableTopCreators';
import HistoryItem from 'views/admin/nfts/marketplace/components/HistoryItem';
import NFT from 'components/card/NFT';
import Card from 'components/card/Card';

// Assets
import Nft1 from 'assets/img/nfts/Nft1.png';
import Nft2 from 'assets/img/nfts/Nft2.png';
import Nft3 from 'assets/img/nfts/Nft3.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import tableDataTopCreators from 'views/admin/nfts/marketplace/variables/tableDataTopCreators';
import { useApps } from 'contexts/AppsContext';
import { v4 as uuidv4 } from 'uuid';
import { createLinkForApp } from 'helpers/formatters';
import { SearchBar } from '../collection/components/Search';
import { useEvents } from 'contexts/EventsContext';
import EventRow from './components/EventRow';
import { useUser } from 'contexts/UserContext';
import { links } from 'helpers/links';
import { useEffect, useState } from 'react';

type RowObj = {
	name: string[];
	artworks: number;
	rating: number;  
};

export default function Marketplace() {
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.500', 'white');

	const { apps } = useApps();
	const { getAllEventsForUser } = useEvents();
	const { user } = useUser();

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [tableData, setTableData] = useState<RowObj[]>(null);

	useEffect(() => {
		if(apps) {
			const _apps = apps.sort((a, b) => Object.keys(b.likes).length - Object.keys(a.likes).length);
			const _data: RowObj[] = _apps.map((app) => { return {name: [app.name, app.logo, `/#${createLinkForApp(app.subscription_id)}`], artworks: Object.keys(app.likes).length-1, rating: ((Object.keys(app.likes).length-1)/(Object.keys(_apps[0].likes).length-1))*100 } })
			console.log(_data);
			setTableData(_data);
		}
	}, [apps, user]);

	useEffect(() => {
		console.log(tableData)
	}, [tableData])

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<Grid
				mb='20px'
				gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
				gap={{ base: '20px', xl: '20px' }}
				display={{ base: 'block', xl: 'grid' }}>
				<Flex flexDirection='column' gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
					<Banner />
					<Flex direction='column'>
						<Box mt="35px">
							<SearchBar
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
							/>
						</Box>
						{searchTerm.length===0 ? (
							<>
								<Flex
									mt='30px'
									mb='20px'
									justifyContent='space-between'
									direction={{ base: 'column', md: 'row' }}
									align={{ base: 'start', md: 'center' }}>
									<Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
										Trending Subscriptions
									</Text>
									{/*<Flex
										align='center'
										me='20px'
										ms={{ base: '24px', md: '0px' }}
										mt={{ base: '20px', md: '0px' }}>
										<Link
											color={textColorBrand}
											fontWeight='500'
											me={{ base: '34px', md: '44px' }}
											href='#art'>
											Art
										</Link>
										<Link
											color={textColorBrand}
											fontWeight='500'
											me={{ base: '34px', md: '44px' }}
											href='#music'>
											Music
										</Link>
										<Link
											color={textColorBrand}
											fontWeight='500'
											me={{ base: '34px', md: '44px' }}
											href='#collectibles'>
											Collectibles
										</Link>
										<Link color={textColorBrand} fontWeight='500' href='#sports'>
											Sports
										</Link>
						</Flex>*/}
								</Flex>
								<SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
									{apps?.map((app) => {
										return(
											<NFT
												key={uuidv4()}
												name={app.name}
												author={app.description}
												chains={Object.entries(app.receivers).map(([index, receiver]) => receiver.chain)}
												bidders={[ Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1 ]}
												image={app.logo}
												size={"24"}
												verified={app.verified}
												download={`/#${createLinkForApp(app.subscription_id)}`}
												subscriptionId={app.subscription_id}
											/>
										)
									})}
								</SimpleGrid>
								<Text mt='45px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
									Recently Added
								</Text>
								<SimpleGrid columns={{ base: 1, md: 3 }} gap='20px' mb={{ base: '20px', xl: '0px' }}>
									{apps?.map((app) => {
										// if date added is less than 1 week ago
										return(
											<NFT
												key={uuidv4()}
												name={app.name}
												author={app.description}
												bidders={[ Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1 ]}
												image={app.logo}
												verified={app.verified}
												size={"24"}
												download={`/#${createLinkForApp(app.subscription_id)}`}
												subscriptionId={app.subscription_id}
												chains={Object.entries(app.receivers).map(([index, receiver]) => receiver.chain)}
											/>
										)
									})}
								</SimpleGrid>
							</>
						):(
							<>
								<Text mt='45px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
									Search Results for: <i>{searchTerm}</i>
								</Text>
								<SimpleGrid columns={{ base: 1, md: 3 }} gap='20px' mb={{ base: '20px', xl: '0px' }}>
									{apps?.filter(val => val.name.toLowerCase().includes(searchTerm.toLowerCase()))?.map((app) => {
										// if date added is less than 1 week ago
										return(
											<NFT
												key={uuidv4()}
												name={app.name}
												author={app.description}
												verified={app.verified}
												bidders={[ Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1 ]}
												image={app.logo}
												size={"24"}
												download={`/#${createLinkForApp(app.subscription_id)}`}
												subscriptionId={app.subscription_id}
												chains={Object.entries(app.receivers).map(([index, receiver]) => receiver.chain)}
											/>
										)
									})}
								</SimpleGrid>
							</>
						)}
					</Flex>
				</Flex>

				{/** LATEST EVENTS TABLE  */}
				<Flex flexDirection='column' gridArea={{ xl: '1 / 3 / 2 / 4', '2xl': '1 / 2 / 2 / 3' }}>
					<Card px='0px' mb='20px'>
						{tableData ? (
							<TableTopCreators tableData={tableData} />
						):null}
					</Card>
					<Card p='0px'>
						<Flex
							align={{ sm: 'flex-start', lg: 'center' }}
							justify='space-between'
							w='100%'
							px='22px'
							py='18px'>
							<Text color={textColor} fontSize='xl' fontWeight='600'>
								Your Latest Activity
							</Text>
							<Link href={`/#${links.allEvents}`}>
								<Button variant='action'>See all</Button>
							</Link>
						</Flex>
						{getAllEventsForUser()?.slice(0, 5)?.map((event: any) => {
							return(
								<EventRow
									key={uuidv4()}
									event={event}
									isUpcoming={false}
								/>
							)
						})}
						{!user ? (
							<Flex px="8" pb="8" alignItems={"center"} justifyContent="center" alignSelf={"center"} flexDirection="column">
								<Text fontWeight={"bold"}>
									To see your activity sign in first.
								</Text>
								<Link mt="2" href={`/#${links.signInCentered}?to=${links.allAppsPage}`}>
									<Button variant={"brand"}>
										Sign In
									</Button>
								</Link>
							</Flex>
						):null}
					</Card>
				</Flex>
			</Grid>
		</Box>
	);
}
