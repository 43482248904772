import { Box, Button, Link, Text, useColorModeValue } from "@chakra-ui/react"
import Card from 'components/card/Card';
import { links } from "helpers/links";


const NoUser = () => {

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return(
    <Box mt="20">
      <Card mx="auto" w={{ sm: "80vw", md: "70vw", lg: "50vw", xl: "40vw", "2xl": "35vw", "3xl": "30vw" }}>
        <Text textAlign={"center"} fontSize={"2xl"} fontWeight="black" textColor={textColorPrimary}>Sign in to have the best experience possible!</Text>
        <Link mt="4" href={`/#${links.signInCentered}?to=${links.profileDashboard}`}>
          <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='20px'>
            Sign In
          </Button>
        </Link>
      </Card>
    </Box>
  );
};

export default NoUser;