/* eslint-disable */ 

import { Box, Flex, Button, Icon, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import { MdOutlineCalendarToday } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card'; 
import * as React from 'react';
// Assets
import { useEffect } from "react";
import { ChannelData } from 'helpers/types';
import { getPaymentToken } from 'helpers/formatters';

type RowObj = {
	name: string;
	totalSubscribers: number;
	activeSubscribers: number;
	revenue: string;
	more: string
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function MostVisitedTable(props: { stats: ChannelData }) {
	const { stats } = props;

	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white'); 
	const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const brandColor = useColorModeValue("brand.500", "brand.400");

	const columns = [
		columnHelper.accessor('name', {
			id: 'name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					PLAN NAME
				</Text>
			),
			cell: (info: any) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('totalSubscribers', {
			id: 'totalSubscribers',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TOTAL SUBSCRIBERS
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text> 
			)
		}),
		columnHelper.accessor('activeSubscribers', {
			id: 'activeSubscribers',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ACTIVE SUBSCRIBERS
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='700'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('revenue', {
			id: 'revenue',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					REVENUE per INTERVAL
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='700'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('more', {
			id: 'more',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ACTIONS
				</Text>
			),
			cell: (info) => (
        <Text
          cursor='pointer'
          color={brandColor}
          textDecoration='underline'
          fontSize='md'
          fontWeight='500'
          id={info.getValue()}
				>
          More Info
        </Text>
			)
		}),
	];

	const [ data, setData ] = React.useState<RowObj[]>(null);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});

	useEffect(() => {
		if(stats){
			const _data: RowObj[] = [].concat(...Object.values(stats.payment_plans)).map((planData) => {
				const active = planData.activeSubscribers.length || 0;
				let revenue = "-";

				if(planData?.plan?.useUsdValue) {
					revenue = `$${active * planData?.plan?.usdValue || 0}`;
				}else{
					const activeSubscribers = planData.activeSubscribers;
					const tickerAmounts = new Map();
					activeSubscribers.map((activeSubscriber: any) => {
						const tokenAddress = activeSubscriber.token.address;
						const tokenTicker = activeSubscriber.token.ticker;
						const amount = getPaymentToken(activeSubscriber.paymentOption, tokenAddress[activeSubscriber.chain])?.amount;
						const existingAmount = tickerAmounts.get(tokenTicker) ?? 0;
						tickerAmounts.set(tokenTicker, existingAmount + amount);
					});
					if(active > 0) {
						revenue = [...tickerAmounts.entries()]
						.map(([ticker, amount]) => `${amount} ${ticker}`)
						.join(', ');
					}else{
						revenue = "0";
					}
				}
			
				return {
					name: planData?.plan?.name || "No Plan",
					totalSubscribers: planData.totalSubscribers.length || 0,
					activeSubscribers: active, 
					revenue: revenue,
					more: "/"
				}
			});
			setData(_data);
		}else{
			setData(null);
		}
	}, [stats]);

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex align='center' justify='space-between' w='100%' px='10px' mb='20px'>
					<Text color={textColor} fontSize='lg' fontWeight='700' lineHeight='100%'>
						Most Popular Subscription Plans
					</Text>
					<Button bg={boxBg} fontSize='sm' fontWeight='500' color={textColorSecondary} borderRadius='7px'>
						<Icon as={MdOutlineCalendarToday} color={textColorSecondary} me='4px' />
						This month
					</Button>
				</Flex>
			<Box>
				{data ? (
					<Table variant='simple' color='gray.500'   mt="12px">
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<Th
												key={header.id}
												colSpan={header.colSpan}
												pe='10px'
												borderColor={borderColor}
												cursor='pointer'
												onClick={header.column.getToggleSortingHandler()}>
												<Flex
													justifyContent='space-between'
													align='center'
													fontSize={{ sm: '10px', lg: '12px' }}
													color='gray.400'>
													{flexRender(header.column.columnDef.header, header.getContext())}{{
														asc: '',
														desc: '',
													}[header.column.getIsSorted() as string] ?? null}
												</Flex>
											</Th>
										);
									})}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{table.getRowModel().rows.slice(0, 11).map((row) => {
								return (
									<Tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return (
												<Td
													key={cell.id}
													fontSize={{ sm: '14px' }}
													minW={{ sm: '150px', md: '200px', lg: 'auto' }}
													borderColor='transparent'>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Td>
											);
										})}
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				):null}
			</Box>
		</Card>
	);
} 