import { Box, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import DocsBox from "components/docs/DocsBox";
import { links } from "helpers/links";
import { BiHelpCircle } from "react-icons/bi";
import { BsShop } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { TbBooks } from "react-icons/tb";
import { v4 as uuidv4 } from "uuid";

const RESOURCES = [
  {
    title: "Create",
    text: "Create your own subscriptions and grow your business!",
    link: links.createNewSubscription,
    isExternal: false,
    icon: FiPlus,
    buttonText: "Create",
  },
  {
    title: "Subscribe",
    text: "Looking for a subscription to subscribe to? No worries, take a look at our marketplace!",
    link: links.allAppsPage,
    isExternal: false,
    icon: BsShop,
    buttonText: "Marketplace",
  },
  {
    title: "How it works?",
    text: "Learn how BananaPay works through documentation or video explanation!",
    link: links.aboutDocs,
    isExternal: false,
    icon: TbBooks,
    buttonText: "Docs",
  },
  {
    title: "Need help?",
    text: "Need any help or have any questions? We are happy to assist you!",
    link: links.support,
    isExternal: false,
    icon: BiHelpCircle,
    buttonText: "Support",
  }
];

const Resources = () => {

  const textColorSecondary = useColorModeValue('gray.500', 'gray.300');

  return(
    <Box mt="16">
      <Text ml="2" mb="2" fontSize={"2xl"} fontWeight={"extrabold"} color={textColorSecondary}>Resources</Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, '2xl': 4 }} gap='20px' mb='20px'>
        {RESOURCES.map(x => (
          <DocsBox
            key={uuidv4()}
            title={x.title}
            text={x.text}
            link={`/#${x.link}`}
            buttonText={x.buttonText}
            isExternal={x.isExternal}
            icon={x.icon}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Resources;