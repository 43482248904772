import { Flex, Text } from "@chakra-ui/react";

const SubscriptionStatusTag = (props: { isSubscribed: boolean }) => {
  const { isSubscribed } = props;

  return(
    <Flex flexDirection={"row"} backgroundColor={"brand.100"} textColor={"brand.400"} rounded={"full"} px="4" py="2" w="fit-content">
      <Text fontWeight={"800"}>{isSubscribed ? "SUBSCRIBED" : "NOT SUBSCRIBED"}</Text>
    </Flex>
  );

  /*switch(isSubscribed) {
    case true:
      return(
        <Flex flexDirection={"row"} backgroundColor={"brand.100"} textColor={"brand.700"} rounded={"full"} px="4" py="2" w="fit-content">
          <Image h="6" w="6" mr="3" rounded={"full"} src={matic} alt="Matic Logo" />
          <Text fontWeight={"800"}>SUBSCRIBED</Text>
        </Flex>
      );
    case false:
      return(
        <Flex flexDirection={"row"} backgroundColor={"yellow.100"} textColor={"yellow.700"} rounded={"full"} px="4" py="2" w="fit-content">
          <Image h="6" w="6" mr="3" rounded={"full"} src={bnb} alt="BNB Logo" />
          <Text fontWeight={"800"}>NOT SUBSCRIBED</Text>
        </Flex>
      );
  }*/
};

export default SubscriptionStatusTag;