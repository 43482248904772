import { Box, Button, Icon, Link, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import Card from 'components/card/Card';
import { hexToChain } from "helpers/formatters";
import { MdEdit } from "react-icons/md";
import { IoAnalytics } from "react-icons/io5";
import { links } from "helpers/links";
import { BsFillBellFill, BsFillPersonFill } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import DocsBox from "components/docs/DocsBox";
import { v4 as uuidv4 } from "uuid";

const INTEGARTES = [
  {
    title: "Widget",
    text: "Allow your users and customers to easily subscribe to your subscriptions, integrated anywhere without any prior programming knowledge needed!",
    link: links.widgetDocs
  },
  {
    title: "React Plugin",
    text: "Integrate BananaPay into any react app with only a few lines of code!",
    link: links.reactPluginDocs
  },
  {
    title: "Webhooks",
    text: "Get real-time events and data about your subscriptions!",
    link: links.webhooksDocs
  },
  {
    title: "SDK",
    text: "Explore our powerful SDK and integrate BananaPay with ease!",
    link: links.sdkAndApiDocs
  }
  /*{
    title: "SDK & APIs",
    text: "Easily integrate BananaPay into your application with our SDK and APIs!",
    link: links.sdkAndApiDocs
  },*/
]

const Integrate = () => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.500', 'gray.300');

  return (
    <Box mt="16">
      <Text mb="2" fontSize={"2xl"} fontWeight={"extrabold"} color={textColorSecondary}>Integrate BananaPay</Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, '2xl': 4 }} gap='20px' mb='20px'>
        {INTEGARTES.map(x => (
          <DocsBox
            key={uuidv4()}
            title={x.title}
            text={x.text}
            link={x.link}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Integrate;