import { Box, Button, Center, Flex, Icon, Link, SimpleGrid, Spinner, Text, useColorModeValue } from "@chakra-ui/react"
import DocsBox from "components/docs/DocsBox";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { useEvents } from "contexts/EventsContext";
import { eventLinkToEventName, sortEvents, useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { useEffect, useState } from "react";
import { BiHelpCircle } from "react-icons/bi";
import { BsShop } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { TbBooks } from "react-icons/tb";
import { v4 as uuidv4 } from "uuid";
import SearchHeader from "./SearchHeader";
import Card from 'components/card/Card';
import EventRow from "views/admin/nfts/marketplace/components/EventRow";
import { EventLinkType } from "helpers/types";


const UpcomingEvents = () => {

  const textColorSecondary = useColorModeValue('gray.500', 'gray.300');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const mt = useColorModeValue("10", "4");

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [type, setType] = useState<EventLinkType>("all");
  const [upcomingEvents, setUpcomingEvents] = useState<any[]>([]);

  const { getAllUpcomingEvents, events } = useEvents();

  const query = useQuery();

  useEffect(() => {
    if(query.get("type")?.startsWith("u_")) {
      const part = query.get("type").split("u_")[1];
      const eventType: EventLinkType = ((part || "all") as EventLinkType);
      setType(eventType);
    }
  }, [query]);

  useEffect(() => {
    if(events) {
      (async () => {
        const allUpcomingEvents = await getAllUpcomingEvents();
        const allEvents: any[] = [];
        sortEvents(Object.values(allUpcomingEvents).map(x => {
          if(Object.values(x).length !== 0) allEvents.push(Object.values(x));
        }));
        console.log(allEvents[0][0]);
        console.log(eventLinkToEventName(type, ""), type);
        const _events = allEvents[0][0]?.filter((val: any) => {
          if(val.name === eventLinkToEventName(type, val.name)) {
            if(searchTerm.length>0) {
              let has = false;
              Object.values(val).map((x: any) => {
                if(!has) {
                  x.toString().toLowerCase().includes(searchTerm.toLowerCase()) ? has = true :  has = false;
                }
              });
              if(has) {
                return val;
              }
            }else{
              return val;
            }
          } 
        })?.slice(0, 5);
        setUpcomingEvents(_events);
      })();
    }
  }, [events, type, searchTerm]);

  return(
    <Box>
      <Text ml="2" mb="2" fontSize={"2xl"} fontWeight={"extrabold"} color={textColorSecondary}>Upcoming Events</Text>
      <SearchHeader
        upcoming={true}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />

      {events ? (
        upcomingEvents.length === 0 ? (
          <Card mx="auto">
            <Text fontWeight={"black"} textAlign={"center"}>
              No Upcoming Events Found!
            </Text>
          </Card>
        ):(
          <Box>
            {upcomingEvents.map((event: any) => (
              <EventRow
                key={uuidv4()}
                isUpcoming={true}
                event={event}
              />
            ))}
            <Center>
              <Link mt={mt} href={`/#${links.allUpcomingEvents}`}>
                <Button variant={"action"}>
                  See All
                </Button>
              </Link>
            </Center>
          </Box>
        )
      ):null}

      {events === null ? (
        <Card>
          <Center mx="auto" flexDirection={"column"}>
            <Spinner
              thickness='10px'
              speed='0.65s'
              emptyColor='gray.200'
              color='brand.500'
              size="xl"
              mx="auto"
              />
            <Text textAlign={"center"}>Loading . . .</Text>
          </Center>
        </Card>
      ):null}

      {events?.length === 0 ? (
        <Card mx="auto">
          <Text textAlign={"center"}>
            You do not have any events yet! Subscribe to one of the subscriptions in the marketplace!
          </Text>
          <Center>
            <Link href={`/#${links.allAppsPage}`} mt="3" mx="auto">
              <Button mx="auto">
                <Icon as={BsShop} color={textColorPrimary} h='18px' w='18px' mr="10px"  />
                Marketplace
              </Button>
            </Link>
          </Center>
        </Card>
      ):null}
    </Box>
  );
};

export default UpcomingEvents;