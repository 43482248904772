// Chakra imports
import { Box, Flex, FormControl, FormLabel, Link, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import { BananaPay } from 'bananapay-types';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TagsField from 'components/fields/TagsField';
import { links } from 'helpers/links';
import { Chain } from 'helpers/types';
import PricingPanel from '../../newPlan/components/PricingPanel';

export default function Details(props: {
	receivers: { [key in BananaPay.Chain]: BananaPay.App.Receiver};
	pricingPlans: BananaPay.App.PaymentOption[];
	subscriptionId: string;
	setPricingPlans: (newPlans: BananaPay.App.PaymentOption[]) => void;
}) {
	const { receivers, pricingPlans, subscriptionId, setPricingPlans } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';


	return (
		<FormControl>
			<Card mb='20px'>
				<Flex direction='column' mb='40px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Pricing Plans
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Here you can change pricing plans for your subscription - NOT RECOMMENDED! - This will affect current subscribers - see more here: <Link href={links.docsLink}>docs</Link>.
					</Text>
				</Flex>
				<Box>
					<PricingPanel
						subscriptionId={subscriptionId}
						receivers={receivers}
						pricingPlans={pricingPlans}
						setPricingPlans={setPricingPlans}
					/>					
				</Box>
			</Card>
		</FormControl>
	);
}
