import { useState, useMemo, useEffect } from "react";
import { Box, Button, Center, Flex, Icon, Link, Spinner, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { useApps } from "contexts/AppsContext";
import { useUser } from "contexts/UserContext";
import { createDateString, createLinkForDashboard, createLinkForEditApp, createLinkForSubscriptionWebhooks, getToast, useQuery } from "helpers/formatters";
import { links } from "helpers/links";
import { useHistory } from "react-router-dom";
import { BananaPay } from "bananapay-types";
import Card from 'components/card/Card';
import CreateEndpoint from "./components/CreateEndpoints";
import EndpointsTable from "./components/EndpointsTable";
import EndpointDetails from "./components/Details";
import Integrate from "./components/Integrate";
import { BsClockHistory } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";

type RowObj = {
  url: string;
  description: string;
	status: BananaPay.Webhook.Status;
	created: string;
	secret: string;
  delete: [() => Promise<void>, () => Promise<void>, string];
	more: () => void;
};

const WebhooksPage = () => {

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

  const query = useQuery();
	const history = useHistory();
	const { user } = useUser();
	const { getAppDetails, apps, getAllWebhooks, changeWebhookState, deleteWebhook } = useApps();
	const toast = useToast();

  const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [webhooks, setWebhooks] = useState<BananaPay.Webhook[] | null>(null);
  const [endpoint, setEndpoint] = useState<BananaPay.Webhook | null>(null);

  const handleChangeState = async(webhook: BananaPay.Webhook) => {
    setIsLoading(true);
    const newWebhook = {...webhook};
    if(webhook.status === "enabled") {
      newWebhook.status = "disabled";
    }else{
      newWebhook.status = "enabled";
    }
    await changeWebhookState(newWebhook);
    setIsLoading(false);
  };

  const handleDelete = async(webhook: BananaPay.Webhook) => {
    setIsLoading(true);
    await deleteWebhook(webhook);
    setIsLoading(false);
  };

  const createTableData = (): RowObj[] => {
    return webhooks.map((endpoint): RowObj => {
      return {
        url: endpoint.url,
        created: createDateString(endpoint.created),
        status: endpoint.status,
        secret: endpoint.secret,
        description: endpoint.name,
        more: () => setEndpoint(endpoint),
        delete: [() => handleChangeState(endpoint), () => handleDelete(endpoint), endpoint.status]
      }
    })
  };

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

  const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

  useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot access this subscription.");
				}
        (async function () {
          // get webhook endpoints
          setIsLoading(true);
          const webhooks = await getAllWebhooks(app.subscription_id);
          setWebhooks(webhooks);
          setIsLoading(false);
        })();
			}
		}else{
			const subscriptionId = query.get("subscriptionId");
			const redirectLink = subscriptionId ? `${links.signInCentered}?to=${links.subscriptionWebhooks}_subscriptionId=${subscriptionId}` : `${links.signInCentered}?to=${links.subscriptionWebhooks}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user]);

  return (
		<Box pt={"75px"} px="2">
      <BreadCrumpStrip
        additional={{mb: "4"}}
        links={[{
          href: `/#${createLinkForDashboard(app?.subscription_id)}`,
          name: "Dashboard"
        },{
          href: `/`,
          name: "Webhooks"
        }]}
      />
			{app && !isLoading ? (
        <>
          {!app.webhookSettings.enabled ? (
            <Card>
              <Flex justifyContent={"space-between"} flexDirection={{ sm: "column", "2sm": "column", lg: "row", base: "row" }}>
                <Box>
                  <Text textAlign={{ sm: "center", "2sm": "center", lg: "left", base: "left" }} fontSize={{ sm: "xl", "2sm": "xl", lg: "2xl", base: "2xl" }}  fontWeight={"normal"} color={textColorPrimary}>
                    You need to <b>enable webhooks</b> first in subscription settings
                  </Text>
                </Box>
                <Box mx={{ sm: "auto", "2sm": "auto", lg: "0", base: "0" }} mt={{ sm: "3", "2sm": "3", lg: "3", base: "-4" }}>
                  <Link href={`/#${createLinkForEditApp(app.subscription_id)}`}>
                    <Button
                      mr="2"
                      mt="-4"
                    >
                      <Icon as={MdSettings} color='secondaryGray.500' h='18px' w='18px' mr="10px" />
                      Settings
                    </Button>
                  </Link>
                </Box>
              </Flex>
            </Card>
          ):(
            <Card>
              <Text fontSize='3xl' fontWeight='900' color={textColorPrimary}>
                Webhook Endpoints
              </Text>
              <Text fontSize='xl' fontWeight='500' color='secondaryGray.600' mb='2'>
                Create BananaPay Webhook Endpoints to get data in real-time to your back-end.
              </Text>
              {!webhooks || webhooks.length<=0 ? (
                <Text>No webhooks created</Text>
              ):(
                <EndpointsTable tableData={createTableData()} />
              )}
              {webhooks?.length<10 ? (
                <>
                  <Button
                    variant={"darkBrand"}
                    width="min-content"
                    mt="6"
                    onClick={() => setIsOpen(true)}
                  >
                    Create webhook Endpoint
                  </Button>
                  <Text fontSize='sm' color='secondaryGray.600' mt='1.5' ml="1.5">
                    *You can create up to 10 Webhook Endpoints
                  </Text>
                </>
              ):(
                <Text fontSize='md' color='secondaryGray.600' mt='1.5' ml="1.5">
                  Webhook Endpoint limit reached - you can create up to 10 Webhook Endpoints
                </Text>
              )}
            </Card>
          )}
        </>
      ):(
        <Center mx="auto" flexDirection={"column"} my="8">
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='brand.500'
            size="xl"
            mx="auto"
          />
          <Text textAlign={"center"}>Loading, please wait . . .</Text>
        </Center>
      )}

      {isOpen ? (
        <CreateEndpoint
          subscriptionId={app.subscription_id}
          close={() => setIsOpen(false)}
        />
      ):null}

      {endpoint ? (
        <EndpointDetails
          webhook={endpoint}
          close={() => setEndpoint(null)}
        />
      ):null}

      <Integrate />
    </Box>
  );
};

export default WebhooksPage;