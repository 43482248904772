/*!
""

*/
import { useRef } from 'react';

// Chakra imports
import { Center, Flex, Spinner, Text, useToast } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import { useEvents } from 'contexts/EventsContext';
import { getToast, useQuery, decodeUriToObj, chainToNumber, chainToHex } from 'helpers/formatters';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Banner from 'views/admin/main/account/eventDetails/components/Banner';
import Content from 'views/admin/main/account/eventDetails/components/Content';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { UpcomingEvent } from 'helpers/types';
import { useApps } from 'contexts/AppsContext';
import { useUser } from 'contexts/UserContext';
import { links } from 'helpers/links';
import { BananaPay } from 'bananapay-types';

export default function EventDetails() {

	const history = useHistory();
	const query = useQuery();
	const [event, setEvent] = useState<any>(null);
	const [isUpcoming, setIsUpcoming] = useState(false);
	const printRef = useRef();
	const { events, getEventById } = useEvents();
	const toast = useToast();
	const { getAppDetailsFromAddressAndChain, apps } = useApps();
	const [app, setApp] = useState<BananaPay.App | null>(null);
	const [plan, setPlan] = useState<BananaPay.App.PaymentOption | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { user } = useUser();
	const { getSubscriptionPlan } = useEvents();

	const downloadPdf = async() => {
		try{
			toast(getToast("info", "Downloading!", "Preparing PDF for download, please wait..."));
			const element = printRef.current;
			const canvas = await html2canvas(element);
			const data = canvas.toDataURL('image/png');

			const pdf = new jsPDF();
			const imgProperties = pdf.getImageProperties(data);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

			pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
			pdf.save(`event_bananapay.pdf`);
			toast(getToast("success", "PDF Downloaded!", "PDF was downloaded successfully!"));
		}catch(err){
			const error: any = err;
			toast(getToast("error", "Couldn't download, try again!", error.message));
		}
	}

	const redirectError = () => {
		toast(getToast("error", "Not Found!", "Event you were looking for was not found!"));
		history.goBack();
	}

	const updateEventData = (_event: any) => {
		if(_event) {
			console.log(_event)
			setEvent(_event);
		}else{
			redirectError();
		}
	}

	useEffect(() => {
		const eventId = query.get("eventId");
		const eventType = query.get("type");
		if(user) {
			setIsLoading(true);
			if(events) {
				if(eventId) {
					if(eventType==="u") {
						const _event: UpcomingEvent = decodeUriToObj(eventId);
						updateEventData(_event);
						setIsUpcoming(true);
					}else{
						const _event = getEventById(eventId);
						updateEventData(_event);
						setIsUpcoming(false);
					}
				}else{
					redirectError();
				}
			}
		}else{
			toast(getToast("info", "Sign in first!", "You must be signed in first."));
			history.push(`${links.signInCentered}?to=${links.eventDetails}_eventId=${eventId}_type=${eventType || "a"}`);
		}
	}, [query, events, user]);

	const handleGetPlan = (app: BananaPay.App) => {
		if(app) {
			const data = getSubscriptionPlan(app, event?.updatedAt?.seconds || 0);
			console.log("getting plan", data);
			setPlan(data.plan);
			setIsLoading(false);
		}else{
			console.log("Handle get plan, no APP")
		}
	}

	useEffect(() => {
		if(event) {
			if(isUpcoming) {
				const _app = getAppDetailsFromAddressAndChain(event.channel, chainToHex(chainToNumber(event.chainId)));
				setApp(_app);
			}else{
				const _app = getAppDetailsFromAddressAndChain(event.channel, chainToHex(chainToNumber(event.chainId)));
				setApp(_app);
			}
		}
	}, [event, apps, getAppDetailsFromAddressAndChain, isUpcoming]);

	useEffect(() => {
		if(app) {
			console.log(app);
			handleGetPlan(app);
		}else{
			console.log("no app");
		}
	}, [app]);

	return (
		<div ref={printRef}>
			{event ? (
				<Card mt={{ base: '130px', md: '80px', xl: '80px' }} maxW='920px' mx='auto'>
					<Flex direction='column' width='stretch'>
						<Banner
							event={event}
							download={downloadPdf}
							isUpcoming={isUpcoming}
							app={app}
						/>
						<Content
							app={app}
							plan={plan}
							event={event}
							isUpcoming={isUpcoming}
							isLoading={isLoading}
						/>
					</Flex>
				</Card>
			):(
				<Center mx="auto" flexDirection={"column"} my="8" mt={{ base: '130px', md: '80px', xl: '80px' }}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor='gray.200'
						color='brand.500'
						size="xl"
						mx="auto"
					/>
					<Text textAlign={"center"}>Loading Event Data . . .</Text>
				</Center>
			)}
		</div>
	);
}
