import { Box, Text, SimpleGrid, Button } from "@chakra-ui/react";
import Card from 'components/card/Card';
import DocsBox from "components/docs/DocsBox";
import { links } from "helpers/links";
import { v4 as uuidv4 } from "uuid";

const LINKS = [
  {
    title: "Documentation",
    text: "Read an in-depth explanation of our services!",
    link: links.aboutDocs,
    btnText: "Docs"
  },
  {
    title: "Email Us",
    text: "Fell free to email us with any questions!",
    link: links.supportEmail,
    btnText: "Email Us"
  },
  {
    title: "Blog",
    text: "Read the latest news and updates of BananaPay!",
    link: links.blog,
    btnText: "Blog"
  }, 
  {
    title: "Twitter",
    text: "Follow and connect with us on Twitter!",
    link: links.twitter,
    btnText: "Twitter"
  },
  {
    title: "Discord",
    text: "Join our vibrant community in Discord with 24/7 help!",
    link: links.discord,
    btnText: "Discord"
  },
  {
    title: "Schedule Call",
    text: "Want to integrate BananaPay or have any questions, schedule a call!",
    link: links.call,
    btnText: "Schedule Call"
  },
]

const Learn = () => {
  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card>
        <Text textAlign={"center"} fontSize="3xl" fontWeight={"700"}>Want to learn more about BananaPay?</Text>
        <Text textAlign={"center"} fontSize="xl">Here are some useful links and resources.</Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2, '2xl': 3 }} gap='25px' mb='20px' mt="6">
          {LINKS.map(x => (
            <DocsBox
              key={uuidv4()}
              title={x.title}
              text={x.text}
              link={x.link}
              buttonText={x.btnText}
            />
          ))}
        </SimpleGrid>
      </Card>
    </Box>
  );
};

export default Learn;