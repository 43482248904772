import { Button, Link, Text, useColorModeValue } from "@chakra-ui/react"
import { BananaPay } from "bananapay-types";
import ApiKeyDrawer from "components/apiKeys/ApiKeyDrawer";
import ApiKeysTable from "components/apiKeys/ApiKeysTable";
import Card from 'components/card/Card';
import { useApps } from "contexts/AppsContext";
import { useUser } from "contexts/UserContext";
import { links } from "helpers/links";
import { useEffect } from "react";
import { useState } from "react";

type RowObj = {
  name: string;
  app: string;
	status: boolean;
	actions: [string, boolean];
  delete: string;
	more: string;
};

const ApiKeys = (props: { show: boolean }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const { show } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [keys, setKeys] = useState<BananaPay.ExtendedUser.ApiKeys>({});

  const { apiKeys } = useUser();
  const { getAppDetails } = useApps();

  const createTableData = () => {
    const arr: RowObj[] = Object.entries(keys).map(([hashed_key, { active, subscription_id, name }]) => ({
      name,
      app: getAppDetails(subscription_id)?.name || "Unknown",
      status: active,
      actions: [hashed_key, active],
      delete: hashed_key,
      more: ""
    }));
    return arr;
  };

  const onCreate = (name: string, newKey: BananaPay.ExtendedUser.ApiKey) => {
    const _keys = {...keys};
    _keys[name] = newKey;
    setKeys(_keys);
  }

  useEffect(() => {
    if(apiKeys) setKeys(apiKeys);
  }, [apiKeys]);

  return(
    <>
      <Card mt="16">
        <Text fontSize='3xl' fontWeight='900' color={textColor}>
          API Keys
        </Text>
        {show ? (
          <>
          <Text fontSize='xl' fontWeight='500' color='secondaryGray.600' mb='2'>
            Create your BananaPay API Keys to be able to access BananaPay APIs and integrate BananaPay into your application.
          </Text>
          {createTableData().length>0 ? (
            <ApiKeysTable
              tableData={createTableData()}
            />
          ):(
            <Text>No API Keys created.</Text>
          )}
          {Object.keys(apiKeys || {})?.length>=10 ? (
            <>
              <Button
                variant={"darkBrand"}
                width="min-content"
                mt="3"
              >
                API Key Limit Reached
              </Button>
              <Text fontSize='sm' color='secondaryGray.600' mt='1.5' ml="1.5">
                *You can create up to 10 API Keys
              </Text>
            </>
          ):(
            <Button
              variant={"darkBrand"}
              width="min-content"
              mt="3"
              onClick={() => setIsOpen(true)}
            >
              Create API Key
            </Button>
          )}
          </>
        ):(
          <>
          <Text fontSize='xl' fontWeight='500' color='secondaryGray.600' mb='2'>
            Create your first subscription to be able to get access to API Keys.
          </Text>
          <Link href={`#${links.createNewSubscription}`}>
            <Button
              variant={"darkBrand"}
              width="min-content"
              mt="3"
              >
              Create Subscription
            </Button>
          </Link>
          </>
        )}
      </Card>

      <ApiKeyDrawer
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        apiKeys={apiKeys}
        onCreate={onCreate}
        selectedSubscriptionId={""}
      />
    </>
  );
};

export default ApiKeys;