import { useState, useEffect } from "react";
// Chakra imports
import { Button, Center, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
// Custom components
import SwitchField from 'components/fields/SwitchField';
import Menu from 'components/menu/MainMenu';
import { useUser } from 'contexts/UserContext';
import { NotificationSettings } from "helpers/types";

export default function Notifications(props: { [x: string]: any }) {
	const { ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	const [newNotifications, setNewNotifications] = useState<NotificationSettings | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const { notifications, updateNotificationSettings } = useUser();

	const handleNotifications = async() => {
		setLoading(true);
		await updateNotificationSettings(newNotifications);
		setLoading(false);
	}

	const handleSetNotifications = (key: string) => {
		const _notifications = {...newNotifications};
		_notifications[key as keyof typeof notifications] = !_notifications[key as keyof typeof notifications];
		setNewNotifications(_notifications);
	}	

	const cancelChanges = () => {
		setNewNotifications(notifications);
	}

	useEffect(() => {
		if(notifications) setNewNotifications(notifications);
	}, [notifications]);

	return (
		<Card mb='20px' {...rest}>
			<Flex align='center' w='100%' justify='space-between'>
				<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='4px'>
					Notification Settings
				</Text>
				{/*<Menu />*/}
			</Flex>
			<Text fontSize='md' color={textColorSecondary} mb='30px'>
				Email me when:
			</Text>
			{newNotifications && !loading ? (
				<>
					<SwitchField isChecked={newNotifications["subscribed"]} reversed={true} onChange={() => handleSetNotifications("subscribed")} fontSize='sm' mb='20px' id='1' label='I have Subscribed' />
					<SwitchField isChecked={newNotifications["unsubscribed"]} reversed={true} onChange={() => handleSetNotifications("unsubscribed")} fontSize='sm' mb='20px' id='2' label='I have Unsubscribed' />
					<SwitchField isChecked={newNotifications["payment_executed"]} reversed={true} onChange={() => handleSetNotifications("payment_executed")} fontSize='sm' mb='20px' id='3' label='My Payment was Successful' />
					<SwitchField isChecked={newNotifications["payment_failed"]} reversed={true} onChange={() => handleSetNotifications("payment_failed")} fontSize='sm' mb='20px' id='4' label='My Payment has Failed' />
					<SwitchField isChecked={newNotifications["warning_low"]} reversed={true} onChange={() => handleSetNotifications("warning_low")} fontSize='sm' mb='20px' id='5' label='My Balance is Low' />
					<SwitchField isChecked={newNotifications["subscription_canceled"]} reversed={true} onChange={() => handleSetNotifications("subscription_canceled")} fontSize='sm' mb='20px' id='6' label='My Subscription was Canceled' />
					<SwitchField isChecked={newNotifications["subscription_restored"]} reversed={true} onChange={() => handleSetNotifications("subscription_restored")} fontSize='sm' mb='20px' id='7' label='My Subscription was Restored' />
					
					<Button variant={"brand"} onClick={handleNotifications}>
						Save
					</Button>
					{notifications !== newNotifications ? (
						<Button onClick={cancelChanges}>
							Cancel
						</Button>
					):null}
				</>
			):(
				<Center mx="auto" flexDirection={"column"}>
					<Spinner
						thickness='10px'
						speed='0.65s'
						emptyColor='gray.200'
						color='brand.500'
						size="xl"
						mx="auto"
					/>
					<Text textAlign={"center"}>
						{loading ? "Saving . . ." : "Loading . . ."}
					</Text>
				</Center>
			)}
		</Card>
	);
}
