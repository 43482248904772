// Chakra imports
import { Box, Flex, Image, Link, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import InvoiceTable from 'views/admin/main/account/eventDetails/components/InvoiceTable';
import tableDataInvoice from 'views/admin/main/account/eventDetails/variables/tableDataInvoice';

// Assets
import { HSeparator } from 'components/separator/Separator';
import { chainToHex, createLinkForExplorer, displayTime, formatNumber, getPaymentToken, hexToChain, secondesToDate } from 'helpers/formatters';
import { useEffect, useState } from 'react';
import { useEvents } from 'contexts/EventsContext';

import logoLight from "assets/img/others/logoLight.png";
import logoDark from "assets/img/others/logoDark.png";
import { links } from 'helpers/links';
import { BananaPay } from 'bananapay-types';

type RowObj = {
  type: "Subscribed" | "Unsubscribed" | "PaymentExecuted" | "PaymentFailed" | "SubscriptionCanceled";
	plan: string;
	interval: string;
	amount: string;
	confirmed: string;
	chain: string;
};


export default function Content(props: {
	app: BananaPay.App;
	plan: BananaPay.App.PaymentOption | null;
	isUpcoming: boolean;
	event: any;
	isLoading: boolean;
	[x: string]: any;
}) {
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const logoTextColor = useColorModeValue('rgb(55,65,81)', 'white');
	const bgCard = useColorModeValue('white', 'navy.700');
	const logo = useColorModeValue(logoLight, logoDark);

	const { app, event, plan, isUpcoming, isLoading } = props;

	const [tableData, setTableData] = useState<RowObj[] | null>(null);

	const { getSubscriptionPlan } = useEvents();

	const displayNext = () => {
		if(app) {
			const { nextSeconds, isSubscribed } = getSubscriptionPlan(app);
			if(!isSubscribed) return "Not Subscribed";
			const d = new Date(nextSeconds*1000);
			const m = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			return `Next on ${m[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
		}
	}

	const getToken = () => {
		const tokenIndex = plan?.paymentTokens?.findIndex(e => Object.values(e?.token?.address).includes(event?.paymentToken?.toLowerCase()));
		if(tokenIndex > -1) {
			return plan?.paymentTokens[tokenIndex].token
		}else{
			if(app && event) {
				const { paymentToken } = getSubscriptionPlan(app, event?.updatedAt?.seconds);
				if(paymentToken?.token) return paymentToken.token;
				else return null;
			}
			else return null;
		}
	}

	const displayAmount = () => {
		console.log("PAyment token address, ", event, plan);
		if(plan?.useUsdValue && plan && app) {
			const { paymentToken } = getSubscriptionPlan(app, event?.updatedAt?.seconds);
			return `$${plan.usdValue} in ${paymentToken?.token?.ticker}`
		}else{
			if(plan && app) {
				const { paymentToken } = getSubscriptionPlan(app, event?.updatedAt?.seconds);
				return `${paymentToken?.amount} ${paymentToken?.token?.ticker}`;
			}else{
				return `${event.paymentAmount / (10**(getToken()?.decimals || 18)) || "-"} ${getToken()?.ticker || ""}`;
			}
		}
	}

	useEffect(() => {
		if(!isLoading) {
			const token = getToken();
			setTableData([{
				plan: plan?.name || "Unknown plan",
				type: event.name,
				confirmed: isUpcoming ? "Upcoming" : (event.confirmed ? "Confirmed" : "Pending"),
				chain: hexToChain(chainToHex(event.chainId)).charAt(0).toUpperCase() + hexToChain(chainToHex(event.chainId)).slice(1),
				interval: displayTime(Number(event?.paymentInterval) || plan?.paymentInterval),
				amount: `${event.paymentAmount / (10**(token?.decimals || 18)) || 0} ${token?.ticker || ""}`
			}]);
		}
	}, [app, event, plan, isUpcoming, isLoading]);

	return (
		<Flex direction='column' p={{ base: '10px', md: '60px' }}>
			<Card backgroundRepeat='no-repeat' bg={bgCard} p='30px' mb='30px' mt='-100px'>
				<Flex direction={{ base: 'column', md: 'row' }}>
					<Flex direction='column' me='auto' mb={{ base: '30px', md: '0px' }}>
						<Text w='max-content' mb='8px' fontSize='md' color='secondaryGray.600' fontWeight='400'>
							For Subscription:
						</Text>
						<Text color={textColor} fontSize='xl' fontWeight='700'>
							{plan?.name || "Unknown Plan"}
						</Text>
						<Text
							w='max-content'
							mb='10px'
							fontSize='md'
							color='secondaryGray.600'
							fontWeight='400'
							lineHeight='26px'
						>
							{displayAmount()} per
							<br />
							{displayTime(Number(event?.paymentInterval) || plan?.paymentInterval)}
						</Text>
					</Flex>
					<Flex direction='column'>
						<Text w='max-content' mb='4px' fontSize='md' color='secondaryGray.600' fontWeight='400'>
							Amount
						</Text>
						<Tooltip label={`${event.paymentAmount / (10**(getToken()?.decimals || 18)) || "-"} ${getToken()?.ticker || ""}`} placement="top">
							<Text color={textColor} fontSize='36px' fontWeight='700'>
								{formatNumber((event.paymentAmount || 0) / (10**(getToken()?.decimals || 18))) || "-"} {getToken()?.ticker || ""}
							</Text>
						</Tooltip>
						<Text
							w='max-content'
							mb='10px'
							fontSize='md'
							p='6px 12px'
							bg='linear-gradient(108.54deg, #FF416C 6.56%, #FF4B2B 95.2%)'
							color='white'
							borderRadius='10px'
							fontWeight='700'>
							{displayNext()}
						</Text>
					</Flex>
				</Flex>
			</Card>
			{tableData ? (
				<InvoiceTable tableData={tableData} />
			):null}
			<Box p="2" pt="6">
				{!isUpcoming ? (
					<Text color={textColor} fontSize='lg' fontWeight='400'>
						View transaction in block explorer: 
						<Link fontWeight={"700"} ml="1" href={createLinkForExplorer(event.chainId, "tx", event.transactionHash)} target="_blank">
							view
						</Link>
						.
					</Text>
				):null}
				{event.name==="PaymentFailed" ? (
					<Text color={textColor} fontSize='lg' fontWeight='400'>
						This most likely means you {isUpcoming ? "will ran" : "have run"} out of your {getToken()?.ticker || ""} balance or allowance
						<Link fontWeight={"700"} ml="1" href={`/#${links.failedFix}`} target="_blank">
							learn how to fix it
						</Link>
						.
					</Text>
				):null}
			</Box>
			<Flex mt='70px' direction={{ base: 'column', md: 'row' }}>
				<Box me='auto' mb={{ base: '40px', md: '0px' }}>
					<Text ml="2.5" mt="3" mb="0.5" fontSize={"2xl"} fontWeight={"800"} color={logoTextColor}>Powered By</Text>
					<Image h='75px' w="fit-content" ml="10px" src={logo} alt="BananaPay" />
					{/*<Text fontSize='lg' fontWeight='700' color={textColor}>
						Note
					</Text>
					<Text fontSize='md' fontWeight='400' color='secondaryGray.600' maxW='292px'>
						Hi Anthony, please take a look at the this invoice from September. Let me know if you have any
						questions, thanks.
	</Text>*/}
				</Box>
				<Box>
					<Flex align='center' justifyContent='space-between' mb='12px'>
						<Text textAlign='end' color={textColor} fontSize='lg' fontWeight='400'>
							Amount
						</Text>
						<Tooltip label={`${event.paymentAmount / (10**(getToken()?.decimals || 18)) || "-"} ${getToken()?.ticker || ""}`} placement="top">
							<Text color={textColor} fontSize='lg' fontWeight='700' maxW='292px'>
								{formatNumber(event.paymentAmount / (10**(getToken()?.decimals || 18)), 5) || "-"} {getToken()?.ticker || ""}
							</Text>
						</Tooltip>
					</Flex>
					<Flex align='center' justifyContent='space-between'>
						<Text me='70px' fontWeight='400' textAlign='end' color={textColor} fontSize='lg'>
							Confirmed
						</Text>
						<Text color={textColor} fontSize='lg' fontWeight='700' maxW='292px'>
							{event?.confirmed ? "Yes" : "No"}
						</Text>
					</Flex>
					<HSeparator my='20px' />
					<Flex align='center' justifyContent='space-between'>
						<Text me='70px' fontWeight='400' textAlign='end' color={textColor} fontSize='lg'>
							Amount Paid
						</Text>
						<Box>
							<Text color={textColor} fontSize='lg' fontWeight='700' maxW='292px'>
								{isUpcoming ? (
									"-"
								):(
									`${!isUpcoming && event.name==="PaymentFailed" ? "0" : formatNumber(event.paymentAmount / (10**(getToken()?.decimals || 18)), 5) || "-"} ${getToken()?.ticker || ""}`
								)}
							</Text>
						</Box>
					</Flex>
					{!(event.paymentAmount / (10**(getToken()?.decimals || 18))) ? (
						<Text color={textColor} fontSize='xs' fontWeight='400' maxW='292px' textAlign={"right"}>
							(This event hadn't had any costs)
						</Text>
					):null}
					{isUpcoming ? (
						<Text color={textColor} fontSize='xs' fontWeight='400' maxW='292px' textAlign={"right"}>
							You will be charged automatically at <b>{secondesToDate(event.updatedAt.seconds)}</b>
						</Text>
					):(
						event.name==="PaymentFailed" ? (
							<Text color={textColor} fontSize='xs' fontWeight='400' maxW='292px' textAlign={"right"}>
								Payment has failed, nothing has been payed
							</Text>
						):null
					)}
				</Box>
			</Flex>
		</Flex>
	);
}
