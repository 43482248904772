import React, { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

// Chakra imports
import {
	Box,
	Button,
	Flex,
	Icon,
	Text,
	useColorModeValue,
	SimpleGrid,
	Select,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	useToast,
	Link,
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/nfts/profile/components/Banner';
import NFT from 'components/card/NFT';
import { SearchBar } from 'views/admin/nfts/profile/components/Search';
import { HSeparator } from 'components/separator/Separator';

// Assets
import Nft2 from 'assets/img/nfts/Nft2.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';
import NftBanner3 from 'assets/img/nfts/NftBanner3.png';
import AvatarSimmmple from 'assets/img/avatars/avatarSimmmple.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';

import {
	MdDashboard,
	MdApps,
	MdOutlineCollections,
	MdFormatPaint,
	MdAccessTime,
	MdOutlineLocalOffer
} from 'react-icons/md';
import { IoMdHeartEmpty } from 'react-icons/io';
import { chainToNumber, createPaymentLinkFromId, createWidgetLink, displayTime, getToast, secondesToDate, useQuery } from 'helpers/formatters';
import { useApps } from 'contexts/AppsContext';
import { Redirect, useHistory } from 'react-router-dom';
import { links } from 'helpers/links';
import AboutPanel from './components/panels/AboutPanel';
import { InfoIcon } from '@chakra-ui/icons';
import { IoMailOpen, IoPricetags } from 'react-icons/io5';
import { FiActivity } from 'react-icons/fi';
import { BsFillPersonFill } from 'react-icons/bs';
import PricingPanel from './components/panels/PricingPanel';
import ContactPanel from './components/panels/ContactPanel';
import { useUser } from 'contexts/UserContext';
import AdminPanel from './components/panels/AdminPanel';
import ActivityPanel from './components/panels/ActivityPanel';
import { useEvents } from 'contexts/EventsContext';
import SubscriptionStatusTag from 'components/other/SubscriptionStatusTag';
import { BiBellOff } from 'react-icons/bi';
import { RiRestartLine } from 'react-icons/ri';
import { BananaPay } from 'bananapay-types';

export default function Collection() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	// const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	// const buttonBg = useColorModeValue('transparent', 'navy.800');
	// const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
	// const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.500';

	const [ tabState, setTabState ] = useState('about');
	const [loaded, setLoaded] = useState(false);
	const [isPrivate, setIsPrivate] = useState(false);
	const { getSubscriptionPlan } = useEvents();
	const { getAppDetails, apps } = useApps();
	const query = useQuery();
	const toast = useToast();
	const history = useHistory();
	const { user } = useUser();
	const { getEventsForUserAndApp } = useEvents();

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

	const app: BananaPay.App | null = useMemo(() => {
		const subscription_id: string = query.get("subscriptionId");
		console.log("Subscription ID: ", subscription_id);
		if(subscription_id) {
			if(subscription_id) {
				const _app = getAppDetails(subscription_id);
				if(_app) {
					//console.log(!_app.displayInLibrary && (!user || user.uid.toLowerCase() !== _app.publisher.uid.toLowerCase()))
					//if(!_app.displayInLibrary && (!user || user.uid.toLowerCase() !== _app.publisher.uid.toLowerCase())) setIsPrivate(true);
					return _app;
				}else{
					setLoaded(true);
					return null
				}
			}else{
				setLoaded(true);
				return null
			}
		}else{
			setLoaded(true);
			return null
		}
	}, [query, apps]);

	useEffect(() => {
		if(app===null && loaded && apps) {
			redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
		}
		if(isPrivate) {
			redirect("Subscription is Private!", "We could not display the subscription you were looking for.");
		}
	}, [app, loaded, isPrivate]);

	const isOwner = () => user.uid.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

	const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

	const getJoinedString = () => {
		if(app.published) {
			const d = new Date(app.published);
			const m = month[d.getMonth()];
			const y = d.getFullYear();
			return `Joined ${m} ${y}`;
		}else{
			return "";
		}
	}

	const displayAmount = () => {
		const { plan, paymentToken } = getSubscriptionPlan(app);
		if(plan?.useUsdValue && plan && app) {
			return `$${plan.usdValue} in ${paymentToken?.token?.ticker}`
		}else{
			if(plan && app) {
				return `${paymentToken?.amount} ${paymentToken?.token?.ticker}`;
			}else{
				return `?`;
			}
		}
	}

	const getIsCanceled = () => {
		return getEventsForUserAndApp(app.subscription_id)[getEventsForUserAndApp(app.subscription_id)?.length-1]?.name === "SubscriptionCanceled" ? true : false;
	}

	return (
		<>
		{app ? (
			<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<Box mb='20px' display={{ base: 'block', lg: 'grid' }}>
				<Flex flexDirection='column'>
					<Banner
						verified={app.verified}
						image={app.banner}
						profile={app.logo}
						wallet={app.receivers}
						address={app.website}
						name={app.name}
						isPrivate={!app.displayInMarketplace}
						date={getJoinedString()}
					/>
				</Flex>
			</Box>
			{getSubscriptionPlan(app).isSubscribed ? (
				<Box px="8" mx="2" pb="8">
					<Card>
						<Flex justifyContent={"space-between"}>
							<Flex>
								<SubscriptionStatusTag isSubscribed={getSubscriptionPlan(app).isSubscribed} />
								<Flex ml="4" mt="1">
									<Text mr="4" fontWeight={"extrabold"} fontSize="xl"><b>{getSubscriptionPlan(app)?.plan?.name}</b></Text>
									<Text fontSize="xl" color={textColorSecondary}>{displayAmount()} per {displayTime(getSubscriptionPlan(app)?.plan?.paymentInterval)}</Text>
								</Flex>
							</Flex>
							{!getIsCanceled() ? (
								<Box>
									<Link href={createPaymentLinkFromId(app.defaultPaymentLinks.cancel)} target="_blank">
										<Button>
											<Icon as={BiBellOff} color={textColorPrimary} h='18px' w='18px' mr="10px" />
											Cancel Subscription
										</Button>
									</Link>
								</Box>
							):(
								<Link href={createPaymentLinkFromId(app.defaultPaymentLinks.restore)} target="_blank">
									<Button>
										<Icon as={RiRestartLine} color={textColorPrimary} h='18px' w='18px' mr="10px" />
										Restore Subscription
									</Button>
								</Link>
							)}
						</Flex>
						{getIsCanceled() ? (
							<Box px="1" py="2">
								<Text fontSize="lg" color={textColorSecondary}>Your subscription is <b>canceled</b> (effective: {secondesToDate(Number(getEventsForUserAndApp(app.subscription_id)[getEventsForUserAndApp(app.subscription_id)?.length-1].unsubscribeDate))}).</Text>
							</Box>
						):null}
					</Card>
				</Box>
			):null}
			<Tabs variant='soft-rounded' colorScheme='brandTabs'>
				<TabList mx={{ base: '10px', lg: '30px' }} overflowX={{ sm: 'scroll', lg: 'unset' }}>
					<Flex justify={{ base: 'start', md: 'center' }} w='100%'>
						<Tab
							pb='0px'
							flexDirection='column'
							onClick={function() {
								setTabState('about');
							}}
							me='50px'
							bg='unset'
							_selected={{
								bg: 'none'
							}}
							_focus={{ border: 'none' }}
							minW='max-content'>
							<Flex align='center'>
								<Icon color={textColor} as={InfoIcon} w='20px' h='20px' me='8px' />
								<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
									About
								</Text>
								{/*<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
									0
						</Text>*/}
							</Flex>
							<Box
								height='4px'
								w='100%'
								transition='0.1s linear'
								bg={tabState === 'about' ? 'brand.500' : 'transparent'}
								mt='15px'
								borderRadius='30px'
							/>
						</Tab>
						<Tab
							onClick={function() {
								setTabState('pricing');
							}}
							pb='0px'
							me='50px'
							bg='unset'
							_selected={{
								bg: 'none'
							}}
							_focus={{ border: 'none' }}
							minW='max-content'
							flexDirection='column'>
							<Flex align='center'>
								<Icon color={textColor} as={IoPricetags} w='20px' h='20px' me='8px' />
								<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
									Pricing Plans
								</Text>
								{/*<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
									{app.paymentOptions.length}
						</Text>*/}
							</Flex>
							<Box
								height='4px'
								w='100%'
								transition='0.1s linear'
								bg={tabState === 'pricing' ? 'brand.500' : 'transparent'}
								mt='15px'
								borderRadius='30px'
							/>
						</Tab>
						<Tab
							pb='0px'
							flexDirection='column'
							onClick={function() {
								setTabState('contact');
							}}
							me='50px'
							bg='unset'
							_selected={{
								bg: 'none'
							}}
							_focus={{ border: 'none' }}
							minW='max-content'>
							<Flex align='center'>
								<Icon color={textColor} as={IoMailOpen} w='20px' h='20px' me='8px' />
								<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
									Contact & Details
								</Text>
								{/*<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
									12
						</Text>*/}
							</Flex>
							<Box
								height='4px'
								w='100%'
								transition='0.1s linear'
								bg={tabState === 'contact' ? 'brand.500' : 'transparent'}
								mt='15px'
								borderRadius='30px'
							/>
						</Tab>
						<Tab
							pb='0px'
							flexDirection='column'
							onClick={function() {
								setTabState('activity');
							}}
							me='50px'
							bg='unset'
							_selected={{
								bg: 'none'
							}}
							_focus={{ border: 'none' }}
							minW='max-content'>
							<Flex align='center'>
								<Icon color={textColor} as={FiActivity} w='20px' h='20px' me='8px' />
								<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
									Your Activity
								</Text>
								<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
									{getEventsForUserAndApp(app.subscription_id)?.length}
								</Text>
							</Flex>
							<Box
								height='4px'
								w='100%'
								transition='0.1s linear'
								bg={tabState === 'activity' ? 'brand.500' : 'transparent'}
								mt='15px'
								borderRadius='30px'
							/>
						</Tab>
						{user ? (
							<>
								{isOwner() ? (
									<Tab
										pb='0px'
										flexDirection='column'
										onClick={function() {
											setTabState('admin');
										}}
										me='50px'
										bg='unset'
										_selected={{
											bg: 'none'
										}}
										_focus={{ border: 'none' }}
										minW='max-content'>
										<Flex align='center'>
											<Icon color={textColor} as={BsFillPersonFill} w='20px' h='20px' me='8px' />
											<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
												Admin
											</Text>
											{/*<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
												7
											</Text>*/}
										</Flex>
										<Box
											height='4px'
											w='100%'
											transition='0.1s linear'
											bg={tabState === 'admin' ? 'brand.500' : 'transparent'}
											mt='15px'
											borderRadius='30px'
										/>
									</Tab>
								):null}
							</>
						):null}
					</Flex>
				</TabList>
				<HSeparator mb='30px' bg={paleGray} mt='0px' />
				<TabPanels>
					<TabPanel px='0px'>
						<AboutPanel app={app} />
					</TabPanel>
					<TabPanel px='0px'>
						<PricingPanel app={app} />
					</TabPanel>
					<TabPanel px='0px'>
						<ContactPanel app={app} />
					</TabPanel>
					<TabPanel px='0px'>
						<ActivityPanel
							app={app}
							events={getEventsForUserAndApp(app.subscription_id)}
						/>
					</TabPanel>
					<TabPanel px='0px'>
						<AdminPanel app={app} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
		):null}
		</>
	);
}
