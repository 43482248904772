import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome, MdLibraryBooks, MdLock, MdOutlineShoppingCart } from 'react-icons/md';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
import DashboardsCarInterface from 'views/admin/dashboards/carInterface';
import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

// NFT Imports
import NFTMarketplace from 'views/admin/nfts/marketplace';
import NFTPage from 'views/admin/nfts/page';
import NFTCollection from 'views/admin/nfts/collection';
import NFTProfile from 'views/admin/nfts/profile';
import Admin from "views/admin/nfts/admin";

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountApplications from 'views/admin/main/account/application';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';
import AccountAllCourses from 'views/admin/main/account/courses';
import AccountCoursePage from 'views/admin/main/account/coursePage';

import UserNew from 'views/admin/main/users/newUser';
import UsersOverview from 'views/admin/main/users/overview';
import UsersReports from 'views/admin/main/users/reports';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';
import ProfileDashboard from 'views/admin/main/profile/dashboard';
import AllEventsPage from 'views/admin/main/profile/allEvents';
import AllUpcomingEventsPage from 'views/admin/main/profile/allUpcomingEvents';

import ApplicationsKanban from 'views/admin/main/applications/kanban';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import ApplicationsCalendar from 'views/admin/main/applications/calendar';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceNewPlan from 'views/admin/main/ecommerce/newPlan';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceSubscriptionSettings from 'views/admin/main/ecommerce/settingsSubscription';
import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import UpdatePasswordCentered from 'views/auth/updatePassword/UpdatePasswordCentered';
import UpdatePasswordDefault from 'views/auth/updatePassword/UpdatePasswordDefault';
import LockCentered from 'views/auth/lock/LockCentered';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInCentered from 'views/auth/signIn/SignInCentered';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpCentered from 'views/auth/signUp/SignUpCentered';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationCentered from 'views/auth/verification/VerificationCentered';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import VerifyEmailCentered from 'views/auth/verifyEmail/VerifyEmailCentered';
import VerifyEmailDefault from 'views/auth/verifyEmail/VerifyEmailDefault';
import SetEmailCentered from 'views/auth/setEmail/SetEmailCentered';
import SetEmailDefault from 'views/auth/setEmail/SetEmailDefault';
import EventDetails from 'views/admin/main/account/eventDetails';
import EventsOverview from 'views/admin/main/users/eventsOverview';
import { BsPlus } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { BiStats } from 'react-icons/bi';

// learn
import Learn from 'views/admin/main/learn';
import FailedFix from 'views/admin/main/failedFix';
import SubscriptionApiKeys from 'views/admin/nfts/apiKeys';
import { FaKey } from 'react-icons/fa';
import PaymentLinksPage from 'views/admin/nfts/paymentLinks';
import WebhooksPage from 'views/admin/nfts/webhooks';
import LinkDetails from 'views/admin/main/users/linkDetails';
import CustomerDetails from 'views/admin/main/users/customerDetails';

/**
 * /admin --> sidebar
 * /auth --> navbar (look at layout examples)
 * rename both to be more logical (admin --> stats, auth --> app)
 */


const routes = [
	{
		name: 'Home',
		layout: '/admin',
		path: '/home',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: ProfileDashboard,
		isShown: true
	},
	{
		name: 'Marketplace',
		layout: '/admin',
		path: '/marketplace',
		icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
		component: NFTMarketplace,
		isShown: true
	},
	{
		name: 'Create Subscription',
		layout: '/admin',
		path: '/create',
		icon: <Icon as={FiPlus} width='20px' height='20px' color='inherit' />,
		component: EcommerceNewPlan,
		isShown: true
	},
	{
		name: 'Learn',
		layout: '/admin',
		path: '/learn',
		icon: <Icon as={MdLibraryBooks} width='20px' height='20px' color='inherit' />,
		component: Learn,
		isShown: true
	},
	// // HIDDEN PATHS (on sidebar)
	{
		name: 'Analytics',
		layout: '/admin',
		path: '/subscription/dashboard',
		component: DashboardsDefault,
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'API Keys',
		layout: '/admin',
		path: '/subscription/admin/api-keys',
		component: SubscriptionApiKeys,
		icon: <Icon as={FaKey} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'Payment Link Overview',
		layout: '/admin',
		path: '/subscription/admin/payment-links/details',
		component: LinkDetails,
		icon: <Icon as={FaKey} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'Payment Links',
		layout: '/admin',
		path: '/subscription/admin/payment-links',
		component: PaymentLinksPage,
		icon: <Icon as={FaKey} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'Customer Overview',
		layout: '/admin',
		path: '/subscription/admin/subscribers/details',
		component: CustomerDetails,
		icon: <Icon as={FaKey} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'Subscribers Overview',
		layout: '/admin',
		path: '/subscription/admin/subscribers',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: UsersOverview
	},
	{
		name: 'Webhooks',
		layout: '/admin',
		path: '/subscription/admin/webhooks',
		component: WebhooksPage,
		icon: <Icon as={FaKey} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'Profile',
		layout: '/admin',
		path: '/subscription/profile',
		component: NFTProfile,
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'Admin Dashboard',
		layout: '/admin',
		path: '/subscription/admin',
		component: Admin,
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false
	},
	{
		name: 'Event',
		layout: '/admin',
		path: '/event',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: EventDetails
	},
	{
		name: 'Subscription Settings',
		layout: '/admin',
		path: '/subscription/settings',
		component: EcommerceSubscriptionSettings,
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
	},
	{
		name: 'Events Overview',
		layout: '/admin',
		path: '/subscription/events',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: EventsOverview
	},
	{
		name: 'All Events',
		layout: '/admin',
		path: '/profile/all-events',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: AllEventsPage
	},
	{
		name: 'Upcoming Events',
		layout: '/admin',
		path: '/profile/upcoming-events',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: AllUpcomingEventsPage
	},
	{
		name: 'Set Email',
		layout: '/auth',
		path: '/set-email/',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: SetEmailCentered
	},
	{
		name: 'Verify Email',
		layout: '/auth',
		path: '/verify-email/',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: VerifyEmailCentered
	},
	{
		name: 'Update Password',
		layout: '/auth',
		path: '/update-password/',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: UpdatePasswordCentered
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in/',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: SignInCentered
	},
	{
		name: 'Profile Overview',
		layout: '/admin',
		path: '/profile/overview',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: ProfileOverview
	},
	{
		name: 'Failed Event - Fix',
		layout: '/admin',
		path: '/learn/failed-event',
		icon: <Icon as={BiStats} width='20px' height='20px' color='inherit' />,
		isShown: false,
		component: FailedFix
	},
]

const routes1 = [
	/*singular tabs - look at free version
	{
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: DashboardsDefault,
  },*/
	// --- Dashboards ---
	{
		name: 'Dashboards',
		path: '/dashboards',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: true,
		items: [
			{
				name: 'Main Dashboard',
				layout: '/admin',
				path: '/dashboards/default',
				component: DashboardsDefault
			},
			{
				name: 'Car Interface',
				layout: '/admin',
				path: '/dashboards/car-interface',
				component: DashboardsCarInterface
			},
			{
				name: 'Smart Home',
				layout: '/admin',
				path: '/dashboards/smart-home',
				component: DashboardsSmartHome
			},
			{
				name: 'RTL',
				layout: '/rtl',
				path: '/dashboards/rtl',
				component: DashboardsRTLDefault
			}
		]
	},
	// // --- NFTs ---
	{
		name: 'NFTs',
		path: '/nfts',
		icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
		collapse: true,
		items: [
			{
				name: 'Marketplace',
				layout: '/admin',
				path: '/all-subscriptions',
				component: NFTMarketplace,
				secondary: true
			},
			/*{
				name: 'Collection',
				layout: '/admin',
				path: '/nfts/collection',
				component: NFTCollection,
				secondary: true
			},
			{
				name: 'NFT Page',
				layout: '/admin',
				path: '/nfts/page',
				component: NFTPage,
				secondary: true
			}*/
			{
				name: 'Profile',
				layout: '/admin',
				path: '/nfts/profile',
				component: NFTProfile,
				secondary: true
			},
			{
				name: 'Admin Dashboard',
				layout: '/admin',
				path: '/nfts/admin',
				component: Admin,
				secondary: true
			}
		]
	},
	// // --- Main pages ---
	{
		name: 'Main Pages',
		path: '/main',
		icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
		collapse: true,
		items: [
			{
				name: 'Account',
				path: '/main/account',
				collapse: true,
				items: [
					{
						name: 'Billing',
						layout: '/admin',
						path: '/main/account/billing',
						exact: false,
						component: AccountBilling
					},
					{
						name: 'Application',
						layout: '/admin',
						path: '/main/account/application',
						exact: false,
						component: AccountApplications
					},
					{
						name: 'Invoice',
						layout: '/admin',
						path: '/main/account/invoice',
						exact: false,
						component: AccountInvoice
					},
					{
						name: 'Event',
						layout: '/admin',
						path: '/main/account/event',
						exact: false,
						component: EventDetails
					},
					{
						name: 'Settings',
						layout: '/admin',
						path: '/main/account/settings',
						exact: false,
						component: AccountSettings
					},
					{
						name: 'All Courses',
						layout: '/admin',
						path: '/main/account/all-courses',
						exact: false,
						component: AccountAllCourses
					},
					{
						name: 'Course Page',
						layout: '/admin',
						path: '/main/account/course-page',
						exact: false,
						component: AccountCoursePage
					}
				]
			},
			{
				name: 'Ecommerce',
				path: '/main/users',
				collapse: true,
				items: [
					{
						name: 'New Product',
						layout: '/admin',
						path: '/main/ecommerce/new-prodcut',
						exact: false,
						component: EcommerceNewProduct
					},
					{
						name: 'New Subscription',
						layout: '/admin',
						path: '/main/ecommerce/new-subscription',
						exact: false,
						component: EcommerceNewPlan
					},
					{
						name: 'Product Settings',
						layout: '/admin',
						path: '/main/ecommerce/settings',
						exact: false,
						component: EcommerceProductSettings
					},
					{
						name: 'Subscription Settings',
						layout: '/admin',
						path: '/main/ecommerce/subscription-settings',
						exact: false,
						component: EcommerceSubscriptionSettings
					},
					{
						name: 'Product Page',
						layout: '/admin',
						path: '/main/ecommerce/page-example',
						exact: false,
						component: EcommerceProductPage
					},
					{
						name: 'Order List',
						layout: '/admin',
						path: '/main/ecommerce/order-list',
						exact: false,
						component: EcommerceOrderList
					},
					{
						name: 'Order Details',
						layout: '/admin',
						path: '/main/ecommerce/order-details',
						exact: false,
						component: EcommerceOrderDetails
					},
					{
						name: 'Referrals',
						layout: '/admin',
						path: '/main/ecommerce/referrals',
						exact: false,
						component: EcommerceReferrals
					}
				]
			},
			{
				name: 'Users',
				path: '/main/users',
				collapse: true,
				items: [
					{
						name: 'New User',
						layout: '/admin',
						path: '/main/users/new-user',
						exact: false,
						component: UserNew
					},
					{
						name: 'Subscribers Overview',
						layout: '/admin',
						path: '/main/users/users-overview',
						exact: false,
						component: UsersOverview
					},
					{
						name: 'Events Overview',
						layout: '/admin',
						path: '/main/users/events-overview',
						exact: false,
						component: EventsOverview
					},
					{
						name: 'Users Reports',
						layout: '/admin',
						path: '/main/users/users-reports',
						exact: false,
						component: UsersReports
					}
				]
			},
			{
				name: 'Applications',
				path: '/main/applications',
				collapse: true,
				items: [
					{
						name: 'Kanban',
						layout: '/admin',
						path: '/main/applications/kanban',
						exact: false,
						component: ApplicationsKanban
					},
					{
						name: 'Data Tables',
						layout: '/admin',
						path: '/main/applications/data-tables',
						exact: false,
						component: ApplicationsDataTables
					},
					{
						name: 'Calendar',
						layout: '/admin',
						path: '/main/applications/calendar',
						exact: false,
						component: ApplicationsCalendar
					}
				]
			},
			{
				name: 'Profile',
				path: '/main/profile',
				collapse: true,
				items: [
					{
						name: 'Profile Overview',
						layout: '/admin',
						path: '/main/profile/overview',
						exact: false,
						component: ProfileOverview
					},
					{
						name: 'Home',
						layout: '/admin',
						path: '/main/profile/dashboard',
						exact: false,
						component: ProfileDashboard
					},
					{
						name: 'Profile Settings',
						layout: '/admin',
						path: '/main/profile/settings',
						exact: false,
						component: ProfileSettings
					},
					{
						name: 'News Feed',
						layout: '/admin',
						path: '/main/profile/newsfeed',
						exact: false,
						component: ProfileNewsfeed
					},
					{
						name: 'All Events',
						layout: '/admin',
						path: '/main/profile/all-events',
						exact: false,
						component: AllEventsPage
					},
					{
						name: 'Upcoming Events',
						layout: '/admin',
						path: '/main/profile/upcoming-events',
						exact: false,
						component: AllUpcomingEventsPage
					},
				]
			},
			{
				name: 'Others',
				path: '/main/others',
				collapse: true,
				items: [
					{
						name: 'Notifications',
						layout: '/admin',
						path: '/main/others/notifications',
						exact: false,
						component: OthersNotifications
					},
					{
						name: 'Pricing',
						layout: '/auth',
						path: '/main/others/pricing',
						exact: false,
						component: OthersPricing
					},
					{
						name: '404',
						layout: '/admin',
						path: '/main/others/404',
						exact: false,
						component: OthersError
					},
					{
						name: 'Messages',
						layout: '/admin',
						path: '/main/others/messages',
						exact: false,
						component: Messages
					}
				]
			}
		]
	},
	// --- Authentication ---
	{
		name: 'Authentication',
		path: '/auth',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		collapse: true,
		items: [
			// --- Sign In ---
			{
				name: 'Sign In',
				path: '/sign-in',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/sign-in/default',
						component: SignInDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/sign-in/centered',
						component: SignInCentered
					}
				]
			},
			// --- Sign Up ---
			{
				name: 'Sign Up',
				path: '/sign-up',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/sign-up/default',
						component: SignUpDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/sign-up/centered',
						component: SignUpCentered
					}
				]
			},
			// --- Verification ---
			{
				name: 'Verification',
				path: '/verification',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/verification/default',
						component: VerificationDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/verification/centered',
						component: VerificationCentered
					}
				]
			},
			// --- Lock ---
			{
				name: 'Lock',
				path: '/lock',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/lock/default',
						component: LockDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/lock/centered',
						component: LockCentered
					}
				]
			},
			// --- Forgot Password ---
			{
				name: 'Forgot Password',
				path: '/forgot-password',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/forgot-password/default',
						component: ForgotPasswordDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/forgot-password/centered',
						component: ForgotPasswordCentered
					}
				]
			},
			// --- Update Password ---
			{
				name: 'Update Password',
				path: '/update-password',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/update-password/default',
						component: UpdatePasswordDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/update-password/centered',
						component: UpdatePasswordCentered
					}
				]
			},
			// --- Verify Email ---
			{
				name: 'Verify Email',
				path: '/verify-email',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/verify-email/default',
						component: VerifyEmailDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/verify-email/centered',
						component: VerifyEmailCentered
					}
				]
			},
			// --- Set Email ---
			{
				name: 'Set Email',
				path: '/set-email',
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/set-email/default',
						component: SetEmailDefault
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/set-email/centered',
						component: SetEmailCentered
					}
				]
			}
		]
	}
];

export default routes;