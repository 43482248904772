import { Box, Button, Center, Icon, Link, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import Card from 'components/card/Card';
import { HSeparator } from "components/separator/Separator";
import { useApps } from "contexts/AppsContext";
import { useUser } from "contexts/UserContext";
import { links } from "helpers/links";
import { FiPlus } from "react-icons/fi";
import { useState } from "react";
import SubscriptionBox from "./SubscriptionBox";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react"
import { useEvents } from "contexts/EventsContext";
import { BsShop } from "react-icons/bs";
import { BananaPay } from "bananapay-types";

const Subscriptions = () => {
  const textColorSecondary = useColorModeValue('gray.500', 'gray.300');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const { getAppsByPublisher, apps, getAppDetails } = useApps();
  const { user } = useUser();
  const { getIsUserSubscribedToApp } = useEvents();

  const [subscribedApps, setSubscribedApps] = useState<BananaPay.App[]>([]);

  useEffect(() => {
    if(apps) {
      const subscribed: BananaPay.App[] = [];
      apps.map(app => {
        if(getIsUserSubscribedToApp(app.subscription_id)) {
          subscribed.push(app);
        }
      });
      setSubscribedApps(subscribed);
    }
  }, [apps]);
  
  return(
    <Box mt="8">
      <Text ml="2" mb="2" fontSize={"2xl"} fontWeight={"extrabold"} color={textColorSecondary}>Your Subscriptions</Text>
      <Card>
        <Text mb="3" mt="1" textAlign={"center"} fontSize={"xl"} fontWeight={"bold"} color={textColorSecondary}>All subscriptions that you are subscribed to</Text>
        <Box>
          {subscribedApps.length === 0 ? (
            <Box>
              <Text textAlign={"center"}>You aren't subscribed to any subscriptions yet.<br />Visit marketplace below and find a subscription you like to subscribe.</Text>
              <Center>
                <Link mx="auto" href={`/#${links.allAppsPage}`}>
                  <Button mx="auto" mt="1">
                    <Icon as={BsShop} color={textColorPrimary} h='18px' w='18px' mr="10px" />
                    Marketplace 
                  </Button>
                </Link>
              </Center>
            </Box>
          ):(
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
              {subscribedApps?.map(app => {
                return(
                  <SubscriptionBox
                    key={uuidv4()}
                    appData={app}
                    isOwner={false}
                  />
                )
              })}
            </SimpleGrid>
          )}
        </Box>
        <HSeparator my="8" />
        <Text mb="3" textAlign={"center"} fontSize={"xl"} fontWeight={"bold"} color={textColorSecondary}>All subscriptions you have created</Text>
        <Box>
          {getAppsByPublisher(user?.uid).length === 0 ? (
            <Box pb="4">
              <Text textAlign={"center"}>You haven't created any subscriptions yet.</Text>
              <Center>
                <Link mx="auto" href={`/#${links.createNewSubscription}`}>
                  <Button mx="auto" mt="1">
                    <Icon as={FiPlus} color={textColorPrimary} h='18px' w='18px' mr="10px" />
                    Create 
                  </Button>
                </Link>
              </Center>
            </Box>
          ):(
            <Box>
              <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px' mb="6">
                {getAppsByPublisher(user?.uid)?.map(app => {
                  return(
                    <SubscriptionBox
                      key={uuidv4()}
                      appData={app}
                      isOwner={true}
                    />
                  )
                })}
              </SimpleGrid>
              <Link mt="6" mx="auto" w="100%" href={`/#${links.createNewSubscription}`}>
                <Button w="100%" variant={"brand"}>
                  <Icon as={FiPlus} color={"white"} h='18px' w='18px' mr="10px" />
                  Create New
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default Subscriptions;