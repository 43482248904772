// Chakra imports
import { Flex, Image, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

import logoLight from "../../../assets/img/others/logoLight.png";
import logoDark from "../../../assets/img/others/logoDark.png";

export const SidebarBrand = () => {
	const logo = useColorModeValue(logoLight, logoDark);

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image h='40px' w="fit-content" my='36px' mx="32px" src={logo} alt="BananaPay" />
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
