// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card';
import { barChartDataDailyTraffic, barChartOptionsDailyTraffic } from 'variables/charts';
import LineChart from 'components/charts/LineChart';

// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';


export default function DailyTraffic(props: { data: any; type: "active"|"total"; [x: string]: any }) {
	const { data, type, ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const [chartOptions, setChartOptions] = useState<any>({
		chart: {
			toolbar: {
				show: false
			},
			dropShadow: {
				enabled: true,
				top: 13,
				left: 0,
				blur: 10,
				opacity: 0.1,
				color: '#4318FF'
			}
		},
		colors: [ '#4318FF' ],
		markers: {
			size: 0,
			colors: 'white',
			strokeColors: '#4318FF',
			strokeWidth: 2,
			strokeOpacity: 0.9,
			strokeDashArray: 0,
			fillOpacity: 1,
			// discrete: [],
			shape: 'circle',
			radius: 2,
			offsetX: 0,
			offsetY: 0,
			showNullDataPoints: true
		},
		tooltip: {
			theme: 'dark'
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: 'smooth',
			type: 'gradient'
		},
		xaxis: {
			categories:["aa"],
			labels: {
				style: {
					colors: '#A3AED0',
					fontSize: '14px',
					fontWeight: '500'
				}
			},
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false
			}
		},
		yaxis: {
			show: false
		},
		legend: {
			show: false
		},
		dropShadow: {
			enabled: true,
			top: 0,
			left: 0,
			blur: 3,
			opacity: 0.5
		},
		grid: {
			show: false,
			column: {
				colors: [ 'transparent' ], // takes an array which will be repeated on columns
				opacity: 0.5
			}
		}
	});
	const [chartData, setChartData] = useState<any>();

	const getChange = () => {
		if(data){
			const today = data[0].data[data[0].data.length-1]?.subscribers;
			const yesterday = data[0].data[data[0].data.length-2]?.subscribers;
			const change  = Math.round(((today-yesterday)/yesterday)*100);
			return `${change || "-"}%`;
		}
		return "-%";
	}

	/*					color={info.getValue()[1] === '+' ? 'green.500' : 'red.500'}
					fontSize='sm'
					fontWeight='600'>
					{info.getValue()[1]}{info.getValue()[0]}%*/

	const getActiveSubscribers = () => {
		if(data){
			const today = data[0].data[data[0].data.length-1]?.subscribers;
			return `${today}`;
		}
		return "-";
	}

	useEffect(() => {
		if(data) {
			const dataArr = data[0].data.map((x: any) => x.subscribers);
			setChartData([{
				name: data[0].name,
				data: dataArr
			}]);
			const xaxis = data[0].data?.map((x: any) => {
				const d = new Date(x.timestamp);
				return `${d.getDate()}/${d.getMonth()+1}`;
			});
			setChartOptions({
				chart: {
					toolbar: {
						show: false
					},
					dropShadow: {
						enabled: true,
						top: 13,
						left: 0,
						blur: 10,
						opacity: 0.1,
						color: '#4318FF'
					}
				},
				colors: [ '#4318FF' ],
				markers: {
					size: 0,
					colors: 'white',
					strokeColors: '#4318FF',
					strokeWidth: 12,
					strokeOpacity: 0.9,
					strokeDashArray: 0,
					fillOpacity: 1,
					// discrete: [],
					shape: 'circle',
					radius: 2,
					offsetX: 0,
					offsetY: 0,
					showNullDataPoints: true
				},
				tooltip: {
					theme: 'dark'
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth',
					type: 'gradient'
				},
				xaxis: {
					categories: xaxis,
					labels: {
						style: {
							colors: '#A3AED0',
							fontSize: '14px',
							fontWeight: '500'
						}
					},
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false
					}
				},
				yaxis: {
					show: false
				},
				legend: {
					show: false
				},
				dropShadow: {
					enabled: true,
					top: 0,
					left: 0,
					blur: 3,
					opacity: 0.5
				},
				grid: {
					show: false,
					column: {
						colors: [ 'transparent' ], // takes an array which will be repeated on columns
						opacity: 0.5
					}
				}
			})
		}
	}, [data]);

	return (
		<Card alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex justify='space-between' align='start' px='10px' pt='5px' w='100%'>
				<Flex flexDirection='column' align='start' me='20px'>
					<Flex w='100%'>
						<Text color='secondaryGray.600' me='auto' fontSize='sm' fontWeight='500'>
							Daily {type==="active" ? "Active": "Total"} Subscribers
						</Text>
					</Flex>

					<Flex align='end'>
						<Text color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
							{getActiveSubscribers()}
						</Text>
						<Text ms='6px' color='secondaryGray.600' fontSize='sm' fontWeight='500'>
							{type==="active" ? "Active": "Total"} Subscribers
						</Text>
					</Flex>
				</Flex>
				<Flex align='center'>
					<Icon as={RiArrowUpSFill} color='green.500' />
					<Text color='green.500' fontSize='sm' fontWeight='700'>
						{getChange()} since yesterday
					</Text>
				</Flex>
			</Flex>
			<Box h='240px' mt='auto' w="100%" px="2">
				{/** line or bar chart */}
				{chartData && chartOptions ? (
					<LineChart chartData={chartData} chartOptions={chartOptions} />
				):null}
			</Box>
		</Card>
	);
}
