// @ts-nocheck

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Link, Text } from "@chakra-ui/react"
import { INTEGRATE_WEBHOOK_CODE_JS } from "helpers/codeBlocks";
import { links } from "helpers/links";
import { CopyBlock, dracula } from "react-code-blocks";
import Card from 'components/card/Card';


const Integrate = () => {
  return(
    <Card mt="8">
      <Accordion allowToggle outline={"none"} theme="brand">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                How to Integrate?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box>
              <CopyBlock
                language={"js"}
                text={INTEGRATE_WEBHOOK_CODE_JS("wh_name_1a2b3c...")}
                showLineNumbers={true}
                wrapLines={true}
                theme={dracula}
                codeBlock
              />
            </Box>
            <Text fontSize={"sm"} mt="1.5">Or take a look into BananaPay Docs <Link href={links.docsLink}>here</Link>.</Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default Integrate;