import { Box, Link, Text } from "@chakra-ui/react";
import { links } from "helpers/links";
import SwitchField from 'components/fields/SwitchField';


const AdvancedPanel = (props: {
  isEnabled: boolean;
  setIsEnabled: (newState: boolean) => void;
  showText?: boolean;
}) => {
  const { isEnabled, setIsEnabled, showText=true } = props;

  return(
    <Box>
      {showText ? (
        <Text mb="4">
          BananaPay allows you to get real-time data about users subscriptions to your server and database via <b>BananaPay webhooks</b>. Read more about this in <Link isExternal href={links.docsLink}>docs</Link>.
        </Text>
      ):null}
      <SwitchField
        isChecked={isEnabled}
        reversed={true}
        onChange={() => setIsEnabled(!isEnabled)}
        fontSize='sm'
        mb='10px'
        id='1'
        label='Enable webhooks'
      />
      <Text fontSize={"sm"}>*You can always change this later</Text>
    </Box>
  );
};

export default AdvancedPanel;