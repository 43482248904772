import { Box, Text, SimpleGrid, Button } from "@chakra-ui/react";
import Card from 'components/card/Card';
import DocsBox from "components/docs/DocsBox";
import { links } from "helpers/links";
import { v4 as uuidv4 } from "uuid";

const LINKS = [
  {
    title: "What is token allowance?",
    text: "Do not know what token allowance is? No worries, learn more by reading this short article.",
    link: links.help.tokenAllowance,
    btnText: "Learn"
  },
  {
    title: "Email Us",
    text: "Fell free to email us with any questions or concerns!",
    link: links.supportEmail,
    btnText: "Email Us"
  },
]

const FailedFix = () => {
  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card>
        <Text textAlign={"center"} fontSize="3xl" fontWeight={"700"}>Learn why your payments fail</Text>
        <Text textAlign={"center"} fontSize="xl">Here are some useful links and resources to help you better understand why your payments fail or will fail.</Text>
      </Card>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, '2xl': 3 }} gap='25px' mb='20px' mt="6">
        {LINKS.map(x => (
          <DocsBox
            key={uuidv4()}
            title={x.title}
            text={x.text}
            link={x.link}
            buttonText={x.btnText}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default FailedFix;