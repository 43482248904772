import React from 'react';
import { ILogoProps } from './types';

const BNBLogo: React.FC<ILogoProps> = ({ width = '120', height = '160' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 120 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.168 99.3141C32.9018 95.5573 36.6357 91.7041 40.3695 87.9473C40.4652 88.0436 40.561 88.1399 40.6567 88.2363C46.9755 94.594 53.1986 100.855 59.5174 107.213C59.8046 107.502 59.7089 107.502 60.0919 107.213C66.4107 100.855 72.6337 94.594 78.9526 88.2363C79.0483 88.1399 79.0483 88.0436 79.144 87.9473C82.9736 91.8004 86.7075 95.5573 90.4413 99.3141C90.4413 99.3141 90.3456 99.4104 90.2498 99.5068C80.1972 109.621 70.1445 119.736 60.0919 129.85C59.9004 130.043 59.9004 130.043 59.6132 129.85C49.5605 119.736 39.5078 109.621 29.4552 99.5068C29.2637 99.4104 29.168 99.4104 29.168 99.3141Z"
                fill="white"
            />
            <path
                d="M40.3695 71.9567C36.6357 68.1998 32.9018 64.443 29.168 60.6862C29.168 60.5898 29.2637 60.5898 29.3594 60.4935C39.4121 50.379 49.4648 40.2644 59.5174 30.1499C59.7089 29.9572 59.8046 29.9572 59.9961 30.1499C70.0488 40.2644 80.1014 50.379 90.1541 60.4935C90.2498 60.5898 90.2498 60.5898 90.3456 60.6862C86.6117 64.443 82.8779 68.1998 79.144 71.9567C79.144 71.9567 79.0483 71.8603 78.9526 71.764C72.6337 65.4063 66.4107 59.1449 60.0919 52.7872C59.8046 52.4982 59.9004 52.4982 59.5174 52.7872C53.1986 59.0486 46.8798 65.4063 40.6567 71.6677C40.561 71.764 40.4652 71.8603 40.3695 71.9567Z"
                fill="white"
            />
            <path
                d="M59.7074 91.4152C55.9736 87.6584 52.144 83.8052 48.4102 80.0484C52.144 76.2915 55.9736 72.4384 59.8032 68.5852C63.537 72.342 67.3666 76.1952 71.1004 79.952C67.3666 83.8052 63.537 87.562 59.7074 91.4152Z"
                fill="white"
            />
            <path
                d="M32.4231 79.9521C32.4231 80.0484 32.3274 80.0484 32.3274 80.0484C32.3274 80.1447 32.2316 80.1447 32.2316 80.1447C28.6892 83.7089 25.0511 87.3694 21.5088 90.9336C21.2216 91.2226 21.2216 91.2226 20.9343 90.9336C17.392 87.3694 13.7539 83.7089 10.2115 80.1447C9.92429 79.8557 9.92429 79.8557 10.2115 79.6631C13.7539 76.0989 17.392 72.4384 20.9343 68.8742C21.1258 68.6816 21.1258 68.6816 21.413 68.8742C24.9554 72.4384 28.5935 76.0989 32.1359 79.6631C32.2316 79.7594 32.3274 79.8557 32.4231 79.9521Z"
                fill="white"
            />
            <path
                d="M109.59 79.952C109.59 80.0483 109.494 80.0483 109.494 80.0483C109.494 80.1446 109.398 80.1446 109.398 80.1446C105.856 83.7088 102.218 87.3693 98.6752 90.9335C98.388 91.2225 98.388 91.2225 98.1965 90.9335C94.6542 87.3693 91.0161 83.7088 87.4737 80.1446C87.2822 79.952 87.2822 79.952 87.4737 79.663C91.1118 76.0025 94.6542 72.4383 98.2923 68.7778C98.4837 68.5851 98.5795 68.5851 98.771 68.7778C102.409 72.4383 105.951 76.0025 109.59 79.663C109.398 79.8556 109.494 79.8556 109.59 79.952Z"
                fill="white"
            />
        </svg>
    );
};

export default BNBLogo;
