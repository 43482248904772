/* eslint-disable */

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import ChainLogo from 'components/chain/ChainLogo';
import IconBox from 'components/icons/IconBox';
import { EventIcon, getIconColor } from 'helpers/componentHelpers';
import { getEventName, getShortEventName } from 'helpers/formatters';
// Custom components
import * as React from 'react';

type RowObj = {
  type: "Subscribed" | "Unsubscribed" | "PaymentExecuted" | "PaymentFailed" | "SubscriptionCanceled";
	chain: string;
	plan: string;
	interval: string;
	amount: string;
	confirmed: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function InvoiceTable(props: { tableData: any }) {
	const { tableData } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('navy.700', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	let defaultData = tableData;
	const columns = [
		columnHelper.accessor('type', {
			id: 'type',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					TYPE
				</Text>
			),
			cell: (info: any) => (
        <Flex align='center'>
          <Box
            me="10px"
            rounded={"full"}
          >
            <IconBox
              w='28px'
              h='28px'
              bg={getIconColor(info.getValue())}
              icon={EventIcon({eventName: info.getValue(), size: ".9rem"})}
            />
          </Box>
          <Text
            color={textColor}
            fontSize='md'
            fontWeight='500'
          >
            {getShortEventName(info.getValue())}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('chain', {
			id: 'chain',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					CHAIN
				</Text>
			),
			cell: (info) => ( 
        <Flex>
          <ChainLogo chain={info.getValue().toLowerCase()} width="20px" height="20px" />
          <Text color={textColor} fontSize='md' pl="2" fontWeight='500'>
            {info.getValue()}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('plan', {
			id: 'plan',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					PLAN
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='700'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('interval', {
			id: 'interval',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					INTERVAL
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('amount', {
			id: 'amount',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					AMOUNT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('confirmed', {
			id: 'confirmed',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					STATUS
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
	];
	const [ data, setData ] = React.useState(() => [ ...defaultData ]);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
	return (
		<Flex direction='column' w='100%' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Box>
				<Table variant='simple' color='gray.500' mt='12px'>
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											borderColor={borderColor}
											cursor='pointer'>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.slice(0, 11).map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor={borderColor}
												mt='20px !important'
												py='36px !important'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</Box>
		</Flex>
	);
}
