/*!
""

*/
// Chakra imports
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, useColorModeValue, Text, InputGroup, InputRightElement, Icon, useToast, Link, Spinner, Center } from '@chakra-ui/react';
import { useUser } from 'contexts/UserContext';
import { getToast, useQuery } from 'helpers/formatters';
import { links } from 'helpers/links';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import { useEffect, useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

function VerifyEmail() {
	// Chakra color mode
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorSecondary = 'gray.400';
	const brandStars = useColorModeValue('brand.500', 'brand.400');

	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);

	const [password, setPassword] = useState<string>("");

	const { user, signOut, verifyEmail, getIsVerified } = useUser();
	const history = useHistory();
	const toast = useToast();
	const query = useQuery();

	const [isLoading, setLoading] = useState<boolean>(false);

	const redirectAfterVerified = () => {
		const to = query.get("to");
		const redirectTo = (to || links.profileOverview).replace("_", "?").replaceAll("_", "&");
		console.log(redirectTo);
		history.push(redirectTo);
	}

  const handleVerify = async() => {
    try{
      if(!user?.emailVerified) {
        setLoading(true);
        await verifyEmail();
        let intervalId: any;
        intervalId = setInterval(() => {
          (async function get() {
            let _isVerified = await getIsVerified();
            console.log("is verified: ", _isVerified)
            if(_isVerified) {
              clearInterval(intervalId);
              setLoading(false);
							toast(getToast("success", "Verified!", "Your email has been verified successfully!"));
							redirectAfterVerified();
            };
          })();
        }, 1000);
      }
		}catch(err){
			await signOut();
			setLoading(false);
			toast(getToast("error", "ERROR!", "You need to be logged in recently to be able to change your password!"));
			const to = query.get("to");
			const redirectTo = to ? `${links.signInCentered}?to=${links.verifyEmailCentered}_to=${to}` : `${links.signInCentered}?to=${links.verifyEmailCentered}`;
			history.push(redirectTo);
		}
  }

	useEffect(() => {
		console.log(user);
		if(!user) {
			const to = query.get("to");
			toast(getToast("info", "Must be signed in!", "Sign in first, redirecting you to sign in page."));
			const redirectTo = to ? `${links.signInCentered}?to=${links.verifyEmailCentered}_to=${to}` : `${links.signInCentered}?to=${links.verifyEmailCentered}`;
			history.push(redirectTo);
		}else if(user?.emailVerified) {
			toast(getToast("info", "Already verified!", "Your email is already verified."));
			redirectAfterVerified();
		}else if(!user.email) {
			const to = query.get("to");
			toast(getToast("info", "Set your Email!", "Set your email first."));
			const redirectTo = to ? `${links.setEmailCentered}?to=${links.verifyEmailCentered}_to=${to}` : `${links.setEmailCentered}?to=${links.verifyEmailCentered}`;
			history.push(redirectTo);
		}
		if(user) {
      (async function get() {
        let _isVerified = await getIsVerified();
        if(_isVerified) {
					redirectAfterVerified();
				}
      })();
    }
	}, [user]);

	return (
		<CenteredAuth
			image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
			cardTop={{ base: '140px', md: '24vh' }}
			cardBottom={{ base: '50px', lg: 'auto' }}>
			<Flex
				w='100%'
				maxW='max-content'
				me='auto'
				h='100%'
				alignItems='start'
				justifyContent='center'
				px={{ base: '25px', md: '0px' }}
				flexDirection='column'>
				<Box me='auto' mb='28px'>
					<Heading color={textColor} fontSize={{ base: '3xl', md: '36px' }} mb='16px'>
						Verify Email
					</Heading>
					<Text color={textColorSecondary} fontSize='md' w={{ base: '100%', lg: '456px' }} maxW='100%'>
						Verify your email to start receiving notifications about your subscriptions!
					</Text>
				</Box>
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', lg: '456px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}
					align='start'>
					<FormControl>
						<Button disabled={isLoading} fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='20px' onClick={handleVerify}>
							Send me a verification link
						</Button>
						<Text color={textColorSecondary} textAlign="center" pb="25px" fontSize='md' w={{ base: '100%', lg: '456px' }} maxW='100%'>
							When you get a verification link to <b>{user?.email}</b> follow the instructions there and then come back.
						</Text>
						{isLoading ? (
							<Center mx="auto" flexDirection={"column"}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									emptyColor='gray.200'
									color='brand.500'
									size="xl"
									mx="auto"
								/>
								<Text textAlign={"center"}>Verifying . . .</Text>
							</Center>
						):null}
						<Link href={`/#${query.get("to") || links.home}`}>
							<Button disabled={isLoading} fontSize='sm' fontWeight='500' w='100%' h='50' mb='12px'>
								I'll do it later
							</Button>
						</Link>
					</FormControl>
				</Flex>
			</Flex>
		</CenteredAuth>
	);
}

export default VerifyEmail;
