import React, { useState, createContext, useContext, useEffect } from "react";
import { functions } from "../helpers/firebase";
import { useToast } from '@chakra-ui/react'
import { checkIfAppOk, getToast, isObjectInArray } from "../helpers/formatters";
import { httpsCallable } from "firebase/functions";
import { useUser } from "./UserContext";
import { BananaPay } from "bananapay-types";

interface AppsProviderProps {
  children: React.ReactNode
}
interface AppsContextProps {
  apps: BananaPay.App[] | null,
  refresh: () => void,
  getAppDetails: (subscriptionId: string) => BananaPay.App | null,
  getAppDetailsFromAddressAndChain: (appAddress: string, chain: BananaPay.Chain) => BananaPay.App | null,
  publishNewApp: (app: BananaPay.App) => Promise<boolean>,
  getAppsByPublisher: (uid: string) => BananaPay.App[] | null,
  likeApp: (subscriptionId: string) => Promise<void>,
  editApp: (newApp: BananaPay.App) => Promise<void>,
  deleteApp: (subscriptionId: string) => Promise<void>,
  getTokenMetadata: (chain: BananaPay.Chain, tokenAddress: string) => Promise<any[] | null>,
  createPaymentLink: () => Promise<string>,
  getAllWebhooks: (subscriptionId: string) => Promise<BananaPay.Webhook[] | null>,
  saveWebhook: (newWebhook: BananaPay.Webhook) => Promise<BananaPay.Webhook | null>,
  changeWebhookState: (newWebhook: BananaPay.Webhook) => Promise<void>,
  deleteWebhook: (newWebhook: BananaPay.Webhook) => Promise<void>,
}

const AppsContext = createContext<AppsContextProps>({
  apps: null,
  refresh: () => {},
  getAppDetails: (subscriptionId) => null,
  publishNewApp: (app) => new Promise(() => false),
  getAppsByPublisher: (uid: string) => null,
  likeApp: (subscriptionId) => new Promise(() => {}),
  editApp: (newApp) => new Promise(() => {}),
  deleteApp: (subscriptionId) => new Promise(() => {}),
  getTokenMetadata: (chain, tokenAddress) => new Promise(() => {}),
  getAppDetailsFromAddressAndChain: (appAddress, chain) => null,
  createPaymentLink: () => new Promise(() => ""),
  getAllWebhooks: (subscriptionId) => new Promise(() => null),
  saveWebhook: (newWebhook) => new Promise(() => null),
  changeWebhookState: (newWebhook) => new Promise(() => { }),
  deleteWebhook: (newWebhook) => new Promise(() => { }),
});


export const AppsProvider = ({ children }: AppsProviderProps) => {

  const [apps, setApps] = useState<BananaPay.App[] | null>(null);

  const { user } = useUser();
  const toast = useToast();

  useEffect(() => {
    if(!Array.isArray(apps)) {
      refresh();
    }
  }, []);

  const refresh = async() => {
    try{
      const upcomingEventsF = httpsCallable(functions, "getApps");
      await upcomingEventsF({})
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          const data: any = result.data;
          const apps: BananaPay.App[] = data.apps;
          setApps(apps);
        })
        .catch((error) => {
          // Getting the Error details.
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while fetching apps!", message));
          return null;
          // ...
        });
    }catch(error: any){
      toast(getToast("error", "Error while fetching apps!", error.message));
      throw new Error(error);
    }
  };

  const getAppDetails = (subscriptionId: string) => {
    let appToReturn: BananaPay.App | null = null;
    apps?.map((app) => {
      if(subscriptionId === app.subscription_id) {
        appToReturn = app;
      }
    });
    return appToReturn;
  }

  const getAppDetailsFromAddressAndChain = (appAddress: string, chain: BananaPay.Chain) => {
    let appToReturn: BananaPay.App | null = null;
    apps?.map((app) => {
      if(app.receivers[chain].address.toLowerCase() === appAddress.toLowerCase()) {
        appToReturn = app;
      }
    });
    return appToReturn;
  }

  const publishNewApp = async(app: BananaPay.App) => {
    if(!user) {
      toast(getToast("error", "ERROR!", "Connect your wallet first!"))
      return false;
    };

    //! IMPLEMENT LATER:
    // checks for data
    try{
      toast(getToast("info", "Saving . . .", "Saving your subscription, please wait!"));
      const upcomingEventsF = httpsCallable(functions, "saveNewApp");
      const result = await upcomingEventsF({
        app: app
      })
      const data: any = result.data;
      const success: boolean = data.success;
      toast(getToast("success", "Success!", "Your subscription was saved successfully!"));
      return true;
    }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error while saving subscription!", message));
        return false;
    }
  }

  const getAppsByPublisher = (uid: string) => {
    const usersApps: BananaPay.App[] = [];
    apps?.map((app) => {
      if(app.publisher.uid.toLowerCase() === uid?.toLowerCase()) {
        usersApps.push(app);
      }
    });
    return usersApps;
  };

  const likeApp = async(subscriptionId: string) => {
    if(!user) {
      toast(getToast("error", "Error!", "Sign in first!"))
      throw new Error();
    };

    const hasAlreadyLiked = getAppDetails(subscriptionId).likes[user.uid];

    if(subscriptionId && !hasAlreadyLiked) {
      try{
        toast(getToast("info", "Saving . . .", "Liking your subscription, please wait!"));
        const likeAppFunction = httpsCallable(functions, "likeApp");
        const result = await likeAppFunction({
          subscriptionId: subscriptionId
        })
        const data: any = result.data;
        toast(getToast("success", "Success!", "Subscription liked successfully!"));
        return;
      }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error while liking that subscription!", message));
        throw new Error();
      }
    }else{
      toast(getToast("error", "Error!", "You have already liked this subscription!"))
      throw new Error();
    }
  }

  const editApp = async(newApp: BananaPay.App) => {
    if(checkIfAppOk(newApp) && user && user?.uid === newApp.publisher.uid) {
      try{
        toast(getToast("info", "Saving . . .", "Saving your changes, please wait!"));
        const editAppFunction = httpsCallable(functions, "editApp");
        const result = await editAppFunction({
          newApp: newApp,
        })
        const data: any = result.data;
        toast(getToast("success", "Success!", "Subscription edited successfully!"));
      }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error while editing your subscription!", message));
      }
    }else{
      // wrong input data
      toast(getToast("error", "Error while editing your subscription!", "Wrong input data!"));
    }
  }

  const deleteApp = async(subscriptionId: string) => {
    if(!user) {
      toast(getToast("error", "ERROR!", "Connect your wallet first!"))
      return;
    };

    if(subscriptionId) {
      try{
        toast(getToast("info", "Deleting . . .", "Deleting your subscription, please wait!"));
        const deleteAppFunction = httpsCallable(functions, "deleteApp");
        const result = await deleteAppFunction({
          subscriptionId: subscriptionId
        });
        const data: any = result.data;
        toast(getToast("success", "Success!", "Subscription deleted successfully!"));
        return;
      }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error while deleting that subscription!", message));
      }
    }else{
      toast(getToast("error", "ERROR!", "You have already deleted this subscription!"))
      return;
    }
  }

  const getTokenMetadata = async(chain: BananaPay.Chain, tokenAddress: string) => {
    if(!user) {
      toast(getToast("error", "Error!", "Connect your wallet first!"))
      return;
    };

    if(chain && tokenAddress.length===42 && tokenAddress.startsWith("0x")) {
      try{
        toast(getToast("info", "Adding . . .", "Adding your token, please wait!"));
        const getTokenMetadataF = httpsCallable(functions, "getTokenMetadata");
        const result = await getTokenMetadataF({
          tokenAddress: tokenAddress,
          chain: chain
        });
        const data: any = result.data;
        toast(getToast("success", "Success!", "Token added successfully!"));
        return data.token;
      }catch(err){
        // Getting the Error details.
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Error ocurred while adding your token!", message));
      }
    }else{
      toast(getToast("error", "Error!", "Error ocurred while adding your token!"))
      return;
    }
  }

  const getAllWebhooks = async(subscriptionId: string):Promise<BananaPay.Webhook[] | null> => {
    if(user) {
      if(getAppDetails(subscriptionId)?.publisher.uid === user.uid) {
        try{
          const getAllWebhooksF = httpsCallable(functions, "getAllWebhooks");
          const result = await getAllWebhooksF({
            subscriptionId: subscriptionId,
          });
          const data: any = result.data
          return data.webhookEndpoints as BananaPay.Webhook[];
        }catch(err){
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while getting webhook endpoints!", message));
        }
      }
    }
    return null;
  };

  const saveWebhook = async(newWebhook: BananaPay.Webhook) => {
    if(user) {
      if(getAppDetails(newWebhook.subscription_id)?.publisher.uid === user.uid) {
        try{
          const saveWebhookF = httpsCallable(functions, "saveWebhook");
          const result = await saveWebhookF({
            obj: newWebhook,
          });
          const data: any = result.data;
          toast(getToast("success", "Success!", "webhook endpoint created!"));
          return data.webhookEndpoint as BananaPay.Webhook;
        }catch(err){
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while saving webhook endpoint!", message));
        }
      }
    }
    return null;
  };

  const changeWebhookState = async(webhook: BananaPay.Webhook) => {
    if(user) {
      if(getAppDetails(webhook.subscription_id)?.publisher.uid === user.uid) {
        try{
          const changeWebhookStateF = httpsCallable(functions, "changeWebhookState");
          const result = await changeWebhookStateF({
            obj: webhook,
          });
          const data: any = result.data;
          toast(getToast("success", "Success!", "webhook endpoint state was changed!"));
        }catch(err){
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while changing webhook endpoint!", message));
        }
      }
    }
  }

  const deleteWebhook = async(webhook: BananaPay.Webhook) => {
    if(user) {
      if(getAppDetails(webhook.subscription_id)?.publisher.uid === user.uid) {
        try{
          const deleteWebhookF = httpsCallable(functions, "deleteWebhook");
          const result = await deleteWebhookF({
            id: webhook.id,
            subscriptionId: webhook.subscription_id
          });
          const data: any = result.data;
          toast(getToast("success", "Success!", "webhook endpoint was deleted!"));
        }catch(err){
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Error while deleting webhook endpoint!", message));
        }
      }
    }
  }

  const createPaymentLink = async() => {
    if(!user) {
      toast(getToast("error", "Error!", "Connect your wallet first!"))
      return;
    };

    return "";
  }

  return (
    <AppsContext.Provider
      value={{
        apps,
        refresh,
        getAppDetails,
        publishNewApp,
        getAppsByPublisher,
        likeApp,
        editApp,
        deleteApp,
        getTokenMetadata,
        getAppDetailsFromAddressAndChain,
        createPaymentLink,
        getAllWebhooks,
        saveWebhook,
        changeWebhookState,
        deleteWebhook
      }}
    >
      {children}
    </AppsContext.Provider>
  );
};

export const useApps = () => {
  return useContext(AppsContext);
}