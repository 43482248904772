import { initializeApp } from "firebase/app";
import { getMoralisAuth } from "@moralisweb3/client-firebase-auth-utils";
import { getAuth } from "@firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);
export const moralisAuth = getMoralisAuth(app);
export const auth = getAuth(app);
export const db = getDatabase();
export const firestore = getFirestore();
export const functions = getFunctions();