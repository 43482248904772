/*!
""

*/
// react import
import { useMemo, useState, useEffect } from "react";
// Chakra imports
import { Box, Flex, Image, SimpleGrid, useToast } from '@chakra-ui/react';
import ChairDef from 'assets/img/ecommerce/ChairDef.png';
// Custom components
import Card from 'components/card/Card';
import { useApps } from 'contexts/AppsContext';
import { useUser } from 'contexts/UserContext';
import { chainToNumber, checkIfAppOk, createLinkForDashboard, getToast, useQuery } from 'helpers/formatters';
import { Chain, Notifications, Tags } from 'helpers/types';
import { useHistory } from 'react-router-dom';

import Delete from './components/Delete';
import Details from './components/Details';
import Info from './components/Info';
import Socials from './components/Socials';
import { links } from "helpers/links";
import Advanced from "./components/Advanced";
import ImageInput from "./components/DropzoneCard";
import Marketplace from "./components/Marketplace";
import { DEFAULT_CHAIN } from "helpers/chains";
import { BananaPay } from "bananapay-types";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";

const SubscriptionSettings = () => {

	const query = useQuery();
	const history = useHistory();
	const { user } = useUser();
	const { getAppDetails, apps, editApp, deleteApp } = useApps();
	const toast = useToast();

	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [receiver, setReceiver] = useState<{ [key in BananaPay.Chain]: BananaPay.App.Receiver } | null>(null);
	const [email, setEmail] = useState<string>("");
	const [website, setWebsite] = useState<string>("");
	const [logo, setLogo] = useState<string>("");
	const [banner, setBanner] = useState<string>("");
	const [displayInLibrary, setDisplayInLibrary] = useState<boolean>(true);
	const [apiUrl, setApiUrl] = useState<string>("");
	const [customBody, setCustomBody] = useState<string>("");
	const [isEnabled, setIsEnabled] = useState<boolean>(false);
	const [tags, setTags] = useState<string[]>(["personal"]);
	const [published, setPublished] = useState<Date>();
	const [hasNotifications, setHasNotifications] = useState<Notifications>({
		subscribed: true,
		unsubscribed: true,
		paymentExecuted: true,
		paymentFailed: true
	});
	const [pricingPlans, setPricingPlans] = useState<BananaPay.App.PaymentOption[]>([]);

	const app: BananaPay.App | null = useMemo(() => {
		const subscriptionId = query.get("subscriptionId");

		if(subscriptionId) {
			if(subscriptionId) {
				const _app = getAppDetails(subscriptionId);
				if(_app) {
					//console.log(!_app.displayInLibrary && (!user || user.uid.toLowerCase() !== _app.publisher.uid.toLowerCase()))
					//if(!_app.displayInLibrary && (!user || user.uid.toLowerCase() !== _app.publisher.uid.toLowerCase())) setIsPrivate(true);
					setName(_app.name);
					setEmail(_app.appEmail);
					setWebsite(_app.website);
					setPricingPlans(_app.paymentOptions);
					setBanner(_app.banner);
					setLogo(_app.logo);
					setDescription(_app.description);
					setDisplayInLibrary(_app.displayInMarketplace);
					setReceiver(_app.receivers);
					setPublished(_app.published || new Date());;
					setTags(_app.tags);
					setIsEnabled(_app?.webhookSettings.enabled);
					return _app;
				}else{
					setIsLoaded(true);
					return null
				}
			}else{
				setIsLoaded(true);
				return null
			}
		}else{
			setIsLoaded(true);
			return null
		}
	}, [query, apps]);

	const handleSave = async() => {
		try{
			if(user && user.emailVerified && user.displayName && app) {
				const newApp: BananaPay.App = {
					object: "app",
					subscription_id: app.subscription_id,
					name: name,
					description: description,
					receivers: receiver,
					appEmail: email,
					website: website,
					likes: app.likes,
					displayInMarketplace: displayInLibrary,
					logo: logo,
					banner: banner,
					customPaymentOption: false,
					publisher: {
						uid: user.uid,
						address: user.displayName
					},
					webhookSettings: {
						enabled: isEnabled
					},
					paymentOptions: pricingPlans,
					tags: tags,
					defaultPaymentLinks: app.defaultPaymentLinks,
					published: published,
					verified: app.verified
				}
				console.log(newApp);
				if(checkIfAppOk(newApp)) {
					await editApp(newApp);
				}
			}
		}catch(err){
			const error: any = err;
			console.log(error);
		}
	}

	const handleDelete = async() => {
		try{
			if(user) {
				await deleteApp(app.subscription_id);
				history.goBack();
			}
		}catch(err){
			const error: any = err;
			console.log(error);
		}
	}

	const redirect = (title: string, message: string) => {
		history.push(links.allAppsPage);
		toast(getToast("info", title, message));
	}

	const isOwner = () => user?.uid?.toLowerCase() === app.publisher.uid.toLowerCase() ? true : false;

	useEffect(() => {
		if(user){
			if(app===null && isLoaded && apps) {
				redirect("No Subscription Found!", "We could not find the subscription you were looking for.");
			}
			if(app && user) {
				if(!isOwner()) {
					redirect("Not Your Subscription!", "You cannot edit this subscription.");
				}
			}
		}else{
			const address = query.get("address");
			const chain = query.get("chain");
			const redirectLink = address && chain ? `${links.signInCentered}?to=${links.editSubscription}_address=${address}_chain=${chain}` : `${links.signInCentered}?to=${links.editSubscription}`;
			history.push(redirectLink);
		}
	}, [app, isLoaded, user]);


	return (
		<Box pt={"75px"}>
			<BreadCrumpStrip
				additional={{mb: "4"}}
				links={[{
					href: `/#${createLinkForDashboard(app?.subscription_id)}`,
					name: "Dashboard"
				},{
					href: `/`,
					name: "Edit Subscription"
				}]}
			/>
			<SimpleGrid columns={{ sm: 1, xl: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}
				<Flex direction='column'>
					<ImageInput
						type="Logo"
						image={logo}
						setImage={setLogo}
					/>
					<Info
						name={name}
						setName={setName}
						description={description}
						setDescription={setDescription}
						receivers={receiver}
						setReceivers={setReceiver}
						email={email}
						setEmail={setEmail}
						website={website}
						setWebsite={setWebsite}
						tags={tags}
						setTags={setTags}
					/>
				</Flex>
				{/* Column Right */}
				<Flex direction='column'>
					<ImageInput
						type="Banner"
						image={banner}
						setImage={setBanner}
					/>
					{/*<Socials
						hasNotifications={hasNotifications}
						setHasNotifications={setHasNotifications}
						mb='20px'
	/>*/}
					<Marketplace
						displayInLibrary={displayInLibrary}
						setDisplayInLibrary={setDisplayInLibrary}
					/>
					<Advanced
	          isEnabled={isEnabled}
						setIsEnabled={setIsEnabled}
					/>
				</Flex>
			</SimpleGrid>
			<Details
				subscriptionId={app.subscription_id}
				receivers={receiver}
				pricingPlans={pricingPlans}
				setPricingPlans={setPricingPlans}
			/>
			<Delete
				handleSave={handleSave}
				handleDelete={handleDelete}
			/>
		</Box>
	);
};

export default SubscriptionSettings;
