import { Box, Button, Flex, Input, Select, SimpleGrid, Stack, Text, useToast } from "@chakra-ui/react";
import { BananaPay } from "bananapay-types";
import ChainLogo from "components/chain/ChainLogo";
import InputField from 'components/fields/InputField';
import TagsField from "components/fields/TagsField";
import TextField from 'components/fields/TextField';
import { allChains, DEFAULT_CHAIN, getChain } from "helpers/chains";
import { getToast, stringToChain } from "helpers/formatters";
import { BiPlus } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";

const AboutPanel = (props: {
  name: string;
  setName: (newName: string) => void;
  description: string;
  setDescription: (newDescription: string) => void;
  receivers: { [key in BananaPay.Chain]: BananaPay.App.Receiver};
  setReceivers: (newReceivers: { [key in BananaPay.Chain]: BananaPay.App.Receiver}) => void;
  email: string;
  setEmail: (newEmail: string) => void;
  website: string;
  setWebsite: (newWebsite: string) => void;
  tags: string[];
  setTags: (newTags: string[]) => void;
}) => {
  const { name, setName, description, setDescription, receivers, setReceivers, email, setEmail, website, setWebsite, setTags, tags } = props;

  console.log("receivers", receivers);

  const toast = useToast();

  const handleChange = (value: string, key: "address"|"chain", index: string) => {
    const _receivers = {...receivers};
    if(key === "chain") {
      _receivers[index as BananaPay.Chain][key] = stringToChain(value); 
    }else{
      _receivers[index as BananaPay.Chain][key] = value;
    }
    setReceivers(_receivers);
  }

  const remove = (index: string) => {
    const _receivers = {...receivers};
    _receivers[index as BananaPay.Chain]["address"] = undefined;
    setReceivers(_receivers);
  }

  const add = () => {
    /*if(receivers.length < allChains.length) {
      const _receivers = [...receivers];
      _receivers.push({
        address: "",
        chain: getFreeChain(receivers),
        extension: {
          enabled: false,
          ext_id: ""
        }
      });
      setReceivers(_receivers);
    }else{
      toast(getToast("info", "All Chains used!", "You have already set the receivers on all supported chains!"));
    }*/
  }

  const getFreeChain = (receivers: { [key in BananaPay.Chain]: BananaPay.App.Receiver}) => {
    /*let chainToReturn: BananaPay.Chain = DEFAULT_CHAIN .id;
    allChains.map(chain => {
      receivers.some(obj => {
        if(obj.chain !== chain.id) {
          chainToReturn = chain.id;
        }
      });
    });
    return chainToReturn;*/
  }

  return(
    <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
      <Stack direction='column' gap='20px'>
        <InputField
          mb='0px'
          id='name'
          placeholder='eg. Spotify'
          label="Subscription Name"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <TextField
          h='146px'
          mb='0px'
          id='Description'
          placeholder='Short description about the subscription'
          label='Subscription Description'
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
        />
        <InputField
          mb='0px'
          id='website'
          placeholder='eg. https//...'
          label="Subscription Website (or any other public link)"
          value={website}
          onChange={(e: any) => setWebsite(e.target.value)}
        />
        <InputField
          mb='0px'
          id='email'
          placeholder='eg. test@test.com'
          label="Subscription Email (email that subscribers can contact)"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
      </Stack>
      <Stack direction='column' gap='20px'>
       <Box>
          <Text fontSize={"sm"} fontWeight="bold">
            Payments Recipients:
          </Text>
          <Text fontSize={"sm"}>*Leave empty if none</Text>
          {Object.entries(receivers).map(([index, receiver]) => {
            return(
              <Box key={index} mt="4">
                <Box>
                  <Box mr="1">
                    <Text fontSize={"sm"} fontWeight="bold">
                      Chain
                    </Text>
                    <Flex>
                      <ChainLogo chain={getChain(receiver.chain).ticker} height="25px" width="25px" />
                      <Text pl="1.5" pr="2" fontSize={"lg"} fontWeight={"bold"}>{getChain(receiver.chain).name}</Text>
                    </Flex>
                  </Box>
                  <Box mt="1.5">
                    <Text fontSize={"sm"} fontWeight="bold">
                      Address
                    </Text>
                    <Input
                      mt="1"
                      mb='0px'
                      id='address'
                      width={"100%"}
                      placeholder='eg. 0x123....'
                      value={receiver.address}
                      onChange={(e: any) => handleChange(e.target.value, "address", index)}
                    />
                  </Box>
                </Box>
                {/**receivers.length>1 ? (
                  <Button onClick={() => remove(index)}>
                    <TbTrash />
                  </Button>
                ):null*/}
              </Box>
            )
          })}
        </Box>
      </Stack>
    </SimpleGrid>
  );
};

export default AboutPanel;