// Chakra imports
import { Button, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useApps } from 'contexts/AppsContext';
import { useUser } from 'contexts/UserContext';
import { createLinkForAnalytics, createLinkForDashboard, createLinkForEditApp, hexToChain } from 'helpers/formatters';
import { links } from 'helpers/links';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Project from 'views/admin/main/profile/overview/components/Project';

export default function Projects() {
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';

	const { user } = useUser();
	const { getAppsByPublisher } = useApps();


	return (
		<Card mb={{ base: '0px', '2xl': '20px' }}>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Your Subscriptions:
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				Here you can find more details about your subscriptions. Stay up-tp-date with analytics.
			</Text>
			{getAppsByPublisher(user?.uid)?.map((app, index) => {
				return(
					<Project
						key={uuidv4()}
						mb='20px'
						image={app.logo}
						ranking={(index+1).toString()}
						link={`/#${createLinkForDashboard(app.subscription_id)}`}
						editLink={`/#${createLinkForEditApp(app.subscription_id)}`}
						statisticsLink={`/#${createLinkForAnalytics(app.subscription_id)}`}
						title={app.name}
					/>
				)
			})}

			{getAppsByPublisher(user?.uid)?.length===0 ? (
				<Text color={textColorSecondary}fontWeight='bold' fontSize='2xl' mt='10px' mb='4px' textAlign={"center"}>
					You do not have any subscriptions created.
				</Text>
			):null}

			<Link href={`/#${links.createNewSubscription}`} w="100%">
				<Button mt="10px" variant={"brand"} w="100%">
					Create New
				</Button>
			</Link>

		</Card>
	);
}
