/*!
""

*/

// Chakra imports
import {
	Box,
	Button,
	Flex,
	FormLabel,
	Select,
	SimpleGrid,
	Icon,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
	useToast,
	Center,
	Spinner
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import TagsField from 'components/fields/TagsField';
import Dropzone from 'views/admin/main/ecommerce/newProduct/components/Dropzone';
import React, { useState, useEffect } from 'react';

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';
import AboutPanel from './components/AboutPanel';
import MediaPanel from './components/MediaPanel';
import PricingPanel from './components/PricingPanel';
import { Chain, Notifications, Tags } from 'helpers/types';
import NotificationsPanel from './components/NotificationsPanel';
import AdvancedPanel from './components/AdvancedPanel';
import PublishPanel from './components/PublishPanel';
import { useUser } from 'contexts/UserContext';
import { useHistory } from 'react-router-dom';
import { checkIfAppOk, getToast, hexToChain } from 'helpers/formatters';
import { links } from 'helpers/links';
import { useApps } from 'contexts/AppsContext';
import { DEFAULT_CHAIN } from 'helpers/chains';
import { BananaPay } from 'bananapay-types';

export default function NewPlan() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const [ activeBullets, setActiveBullets ] = useState({
		info: true,
		media: false,
		pricing: false,
		notifications: false,
		advanced: false,
		publish: false
	});

	const productTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const mediaTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const pricingTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const advancedTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const publishTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const size = { sm: '60px', md: '125px', lg: '150px' };
	const boxSize = { sm: '400px', md: '825px', lg: '950px' };

	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [website, setWebsite] = useState<string>("");
	const [logo, setLogo] = useState<string>("");
	const [banner, setBanner] = useState<string>("");
	const [displayInLibrary, setDisplayInLibrary] = useState<boolean>(true);
	const [receiver, setReceiver] = useState<{ [key in BananaPay.Chain]: BananaPay.App.Receiver }>({
		"0x13881": { address: "", chain: "0x13881", extension: { enabled: false, ext_id: "" } },
		"0xfa2": { address: "", chain: "0xfa2", extension: { enabled: false, ext_id: "" } },
		"0x61": { address: "", chain: "0x61", extension: { enabled: false, ext_id: "" } }
	});
		const [isEnabled, setIsEnabled] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tags, setTags] = useState<string[]>(["personal"]);
	const [pricingPlans, setPricingPlans] = useState<BananaPay.App.PaymentOption[]>([{
		name: "",
		description: "",
		useUsdValue: false,
		usdValue: undefined,
		paymentTokens: [{
			token: {
				name: "",
				ticker: "",
				address: {
					"0x13881": "",
					"0x61": "",
					"0xfa2": ""
				},
				logo: "",
				decimals: 18
			},
			amount: undefined,
		}],
		paymentInterval: undefined,
		isHidden: false
	}]);

	
	const { user } = useUser();
	const { publishNewApp, refresh } = useApps();
	const history = useHistory();
	const toast = useToast();

	const handlePublish = async() => {
		try{
			if(user && user.emailVerified && user.displayName && receiver) {
				const app: BananaPay.App = {
					object: "app",
					subscription_id: "",
					name: name,
					description: description,
					receivers: receiver,
					appEmail: email,
					website: website,
					likes: {},
					displayInMarketplace: displayInLibrary,
					logo: logo,
					banner: banner,
					customPaymentOption: false,
					publisher: {
						uid: user.uid,
						address: user.displayName
					},
					webhookSettings: {
						enabled: isEnabled
					},
					paymentOptions: pricingPlans,
					tags: tags,
					defaultPaymentLinks: {
						subscribe: [],
						cancel: "",
						restore: ""
					},
					published: new Date(),
					verified: false
				}
				console.log(app);
				if(checkIfAppOk(app)) {
					setIsLoading(true);
					const success = await publishNewApp(app);
					if(success) {
						history.push(links.profileOverview);
						await refresh();
					}else{
						setIsLoading(false);
					}
				}
			}else{

			}
		}catch(err){
			const error: any = err;
			console.log(error);
		}
	}

	useEffect(() => {
		if(!user) {
			toast(getToast("info", "Sign in first!", "You must be signed in first."));
			history.push(`${links.signInCentered}?to=${links.createNewSubscription}`);
		}else if(!user.emailVerified) {
			toast(getToast("info", "Email not verified!", "Your email must be verified before you can publish subscriptions!"));
			history.push(`${links.verifyEmailCentered}?to=${links.createNewSubscription}`);
		}
	}, [user]);

	return (
		<Flex direction='column' minH='100vh' align='center' pt={{ sm: '125px', lg: '75px' }} position='relative'>
			<Box
				h='45vh'
				bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
				position='absolute'
				w='100%'
				borderRadius='30px'
			/>
			<Tabs
				variant='unstyled'
				mt={{ base: '60px', md: '165px' }}
				zIndex='0'
				display='flex'
				flexDirection='column'>
				<TabList display='flex' alignItems='center' alignSelf='center' justifySelf='center'>
					<Tab
						ref={productTab}
						w={size}
						onClick={() =>
							setActiveBullets({
								info: true,
								media: false,
								pricing: false,
								notifications: false,
								advanced: false,
								publish: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: size,
								height: '3px',
								bg: activeBullets.media ? 'white' : '#8476FF',
								left: { sm: '12px', md: '40px' },
								top: {
									sm: activeBullets.info ? '6px' : '4px',
									md: null
								},
								position: 'absolute',
								bottom: activeBullets.info ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.info ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.info ? 'white' : 'gray.300'}
								fontWeight={activeBullets.info ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								About
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={mediaTab}
						w={size}
						onClick={() =>
							setActiveBullets({
								info: true,
								media: true,
								pricing: false,
								notifications: false,
								advanced: false,
								publish: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: size,
								height: '3px',
								bg: activeBullets.pricing ? 'white' : '#8476FF',
								left: { sm: '12px', md: '28px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.media ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.media ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.media ? 'white' : 'gray.300'}
								fontWeight={activeBullets.media ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Media
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={pricingTab}
						w={size}
						onClick={() =>
							setActiveBullets({
								info: true,
								media: true,
								pricing: true,
								notifications: false,
								advanced: false,
								publish: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: size,
								height: '3px',
								bg: activeBullets.notifications ? 'white' : '#8476FF',
								left: { sm: '12px', md: '28px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.pricing ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.pricing ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.pricing ? 'white' : 'gray.300'}
								fontWeight={activeBullets.pricing ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Pricing Plans
							</Text>
						</Flex>
					</Tab>
					{/*<Tab
						ref={notificationsTab}
						w={size}
						onClick={() =>
							setActiveBullets({
								info: true,
								media: true,
								pricing: true,
								notifications: true,
								advanced: false,
								publish: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: size,
								height: '3px',
								bg: activeBullets.advanced ? 'white' : '#8476FF',
								left: { sm: '12px', md: '28px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.notifications ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.notifications ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.notifications ? 'white' : 'gray.300'}
								fontWeight={activeBullets.notifications ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Notifications
							</Text>
						</Flex>
						</Tab>*/}
					<Tab
						ref={advancedTab}
						w={size}
						onClick={() =>
							setActiveBullets({
								info: true,
								media: true,
								pricing: true,
								notifications: true,
								advanced: true,
								publish: false
							})}>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: size,
								height: '3px',
								bg: activeBullets.publish ? 'white' : '#8476FF',
								left: { sm: '12px', md: '28px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.advanced ? '40px' : '38px',

								transition: 'all .3s ease'
							}}>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.advanced ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.advanced ? 'white' : 'gray.300'}
								fontWeight={activeBullets.advanced ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Advanced
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={publishTab}
						w={size}
						onClick={() =>
							setActiveBullets({
								info: true,
								media: true,
								pricing: true,
								notifications: true,
								advanced: true,
								publish: true
							})}>
						<Flex direction='column' justify='center' align='center' position='relative'>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={activeBullets.publish ? 'white' : '#8476FF'}
								bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={activeBullets.publish ? 'white' : 'gray.300'}
								fontWeight={activeBullets.publish ? 'bold' : 'normal'}
								display={{ sm: 'none', md: 'block' }}>
								Create
							</Text>
						</Flex>
					</Tab>
				</TabList>

				
				<TabPanels mt='24px' mx='auto'>
					<TabPanel w={boxSize} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Subscription Info
							</Text>
							<Flex direction='column' w='100%'>
								<AboutPanel
									name={name}
									setName={setName}
									receivers={receiver}
									setReceivers={setReceiver}
									description={description}
									setDescription={setDescription}
									website={website}
									setWebsite={setWebsite}
									email={email}
									setEmail={setEmail}
									tags={tags}
									setTags={setTags}
								/>
								<Flex justify='space-between' mt='24px'>
									<Button
										variant='darkBrand'
										fontSize='sm'
										borderRadius='16px'
										w={{ base: '128px', md: '148px' }}
										h='46px'
										ms='auto'
										onClick={() => mediaTab.current.click()}>
										Next
									</Button>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={boxSize} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Media
							</Text>
							<MediaPanel
								logo={logo}
								banner={banner}
								setLogo={setLogo}
								setBanner={setBanner}
							/>
							<Flex justify='space-between' mt='24px'>
								<Button
									variant='light'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => productTab.current.click()}>
									Prev
								</Button>
								<Button
									variant='darkBrand'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => pricingTab.current.click()}>
									Next
								</Button>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={boxSize} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Pricing Plans
							</Text>
							<PricingPanel
								receivers={receiver}
								pricingPlans={pricingPlans}
								setPricingPlans={e => setPricingPlans(e)}
								remove={true}
							/>
							<Flex justify='space-between' mt='24px'>
								<Button
									variant='light'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => mediaTab.current.click()}>
									Prev
								</Button>
								<Button
									variant='darkBrand'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => advancedTab.current.click()}>
									Next
								</Button>
							</Flex>
						</Card>
					</TabPanel>
					{/*<TabPanel w={boxSize} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Notifications
							</Text>
							<NotificationsPanel
								hasNotifications={hasNotifications}
								setHasNotifications={setHasNotifications}
							/>
							<Flex justify='space-between' mt='24px'>
								<Button
									variant='light'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => pricingTab.current.click()}>
									Prev
								</Button>
								<Button
									variant='darkBrand'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => advancedTab.current.click()}>
									Next
								</Button>
							</Flex>
						</Card>
						</TabPanel>*/}
					<TabPanel w={boxSize} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Advanced
							</Text>
							<AdvancedPanel
								isEnabled={isEnabled}
								setIsEnabled={setIsEnabled}
							/>
							<Flex justify='space-between' mt='24px'>
								<Button
									variant='light'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => pricingTab.current.click()}>
									Prev
								</Button>
								<Button
									variant='darkBrand'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => publishTab.current.click()}>
									Next
								</Button>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel w={boxSize} p='0px' mx='auto'>
						<Card p='30px'>
							<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
								Create
							</Text>
							{isLoading ? (
								<Center mx="auto" flexDirection={"column"}>
									<Spinner
										thickness='10px'
										speed='0.65s'
										emptyColor='gray.200'
										color='brand.500'
										size="xl"
										mx="auto"
									/>
									<Text textAlign={"center"}>Publishing . . .</Text>
								</Center>
							):(
								<PublishPanel
								isPublic={displayInLibrary}
								setIsPublic={setDisplayInLibrary}
							/>
							)}
							<Flex justify='space-between' mt='24px'>
								<Button
									variant='light'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={() => advancedTab.current.click()}>
									Prev
								</Button>
								<Button
									variant='darkBrand'
									fontSize='sm'
									borderRadius='16px'
									w={{ base: '128px', md: '148px' }}
									h='46px'
									onClick={handlePublish}
								>
									Create!
								</Button>
							</Flex>
						</Card>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>
	);
}
