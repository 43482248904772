export const INTEGRATE_WEBHOOK_CODE_JS = (secret: string) => {
return(
`// server.js

// verifies the webhook signature and payload authenticity
const verifySignature = (payload, secret, receivedSignature) => {
        // generate HMAC of the payload using the secret
        const hmac = createHmac('sha256', secret);
        hmac.update(payload);
        const calculatedSignature = hmac.digest('hex');

        // check if the calculated signature matches the received signature
        return calculatedSignature === receivedSignature;
}

// your webhook endpoint
app.post('/my-webhook', (req, res) => {
        // Process the payload
        const payload = JSON.stringify(req.body); // BananaPay Payload is stored in the body
        const receivedSignature = req.headers['x-bananapay-signature']; // BananaPay signature is sent in a header

        // your webhook secret
        const secret = "${secret}";

        // verify the webhook signature and payload
        if (verifySignature(payload, secret, receivedSignature)) {
                // valid signature (valid webhook payload)
                res.status(200).send(payload);
        }else{
                // invalid signature
                res.status(403).send('Invalid Signature');
        }
});`
);
};