// Chakra imports
import { Button, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import TimelineItem from 'components/dataDisplay/TimelineItem';
import { createLinkForEvent } from 'helpers/formatters';
import { links } from 'helpers/links';
import { TimelineDataObj } from 'views/admin/main/profile/overview';

export default function Default(props: { data?: TimelineDataObj[] | null; [x: string]: any }) {
	const { data, ...rest } = props;
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	return (
		<Card {...rest}>
			<Text fontSize='2xl' fontWeight='700' color={textColor}>
				Timeline
			</Text>
			<Text fontSize='md' fontWeight='500' color='secondaryGray.600' mb='30px'>
				See your upcoming payments in the timeline:
			</Text>

			{data?.slice(0, 5)?.map((item, index) => {
				return(
					<TimelineItem
						mb='16px'
						title={item.title}
						day={("0" + item.time.getDate()).slice(-2)}
						weekday={days[item.time.getDay()]}
						hours={`${item.time.getHours()}:${("0" + item.time.getMinutes()).slice(-2)}`}
						current={index === 0 ? true : false}
					/>
				)
			})}
			{!data || data?.length===0 ? (
				<Text color={textColorSecondary}fontWeight='bold' fontSize='2xl' mt='10px' mb='4px' textAlign={"center"}>
					No upcoming payments.
				</Text>
			):null}

			<Link mt="4" href={`/#${links.allUpcomingEvents}`}>
				<Button w="100%" variant={"brand"}>
					See All
				</Button>
			</Link>
		</Card>
	);
}
