// Chakra imports
import { AvatarGroup, Avatar, Box, Button, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import ChainTag from 'components/chain/ChainTag';
import { useApps } from 'contexts/AppsContext';
import { useUser } from 'contexts/UserContext';
import { chainToNumber, createLinkForApp, createLinkForDashboard } from 'helpers/formatters';
import { Chain } from 'helpers/types';
// Assets
import { useEffect, useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { MdVerified } from 'react-icons/md';

export default function Subscription(props: {
	image: string;
	name: string;
	description: string;
	isOwner: boolean;
	chains: Chain[];
	verified: boolean;
	subscriptionId: string;
	size?: string;
	estimatedDate?: string;
}) {
	const { image, name, description, chains, size, subscriptionId, isOwner, estimatedDate, verified } = props;
	const textColor = useColorModeValue('navy.700', 'white');
  const textColorPrimary = useColorModeValue('white', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');

	return (
		<Card p='20px'>
			<Flex direction={{ base: 'row' }}>
				<Image
					src={image}
					w={"75px"}
					h={"75px"}
					mr="25px"
					borderRadius='10px'
				/>
				<Flex flexDirection='column' justify='space-between' h='100%' pt="2px">
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{name}
								{verified ? <Icon as={MdVerified} h='32px' w='32px' color='blue.500' /> : null}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'
								noOfLines={3}
							>
								{description}
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			{estimatedDate ? (
				<Flex mt="10px" justify={"space-between"}>
					<Text>Estimated Unsubscribe Date:</Text>
					<Text><b>{estimatedDate}</b></Text>
				</Flex>
			):null}
			<Flex
				justify="left"
				align={{
					base: 'center',
					md: 'start',
					lg: 'center',
					xl: 'start',
					'2xl': 'center'
				}}
				direction={{
					base: 'row',
					md: 'column',
					lg: 'row',
					xl: 'column',
					'2xl': 'row'
				}}
				mt='10px'>
				<AvatarGroup
					max={3}
					color={textColorBid}
					size='sm'
					mt={{
						base: '0px',
						md: '10px',
						lg: '0px',
						xl: '10px',
						'2xl': '0px'
					}}
					fontSize='12px'>
					{chains.map((chain, key) => <Avatar key={key} src={"assets/img/chain/avalanche"} />)}
				</AvatarGroup>
				{isOwner ? (
					<Link
						href={`/#${createLinkForDashboard(subscriptionId)}`}
						ml="10px"
						mt={{
							base: '0px',
							md: '10px',
							lg: '0px',
							xl: '10px',
							'2xl': '0px'
						}}>
						<Button
							variant='brand'
							color='white'
							fontSize='sm'
							fontWeight='500'
							borderRadius='70px'
							mt="5px"
							px='24px'
							py='5px'
						>
							<Icon as={BsFillPersonFill} color={textColorPrimary} h='18px' w='18px' mr="10px" />
							Admin Panel
						</Button>
					</Link>
				):null}
				<Link
					href={`/#${createLinkForApp(subscriptionId)}`}
					ml="10px"
					mt={{
						base: '0px',
						md: '10px',
						lg: '0px',
						xl: '10px',
						'2xl': '0px'
					}}>
					<Button
						variant='darkBrand'
						color='white'
						fontSize='sm'
						fontWeight='500'
						borderRadius='70px'
						mt="5px"
						px='24px'
						py='5px'
					>
						More
					</Button>
				</Link>
			</Flex>
		</Card>
	);
}
