// Chakra imports
import { Flex, Icon, Image, Avatar, Text, useToast, useColorModeValue, Link, Box } from '@chakra-ui/react';
import { getEllipsisTxt } from 'helpers/formatters';
import { getChain } from 'helpers/chains';
// Assets
import { MdVerified, MdOutlineContentCopy } from 'react-icons/md';
import { useEffect } from 'react';
import { BananaPay } from 'bananapay-types';

export default function Banner(props: {
	image: string;
	address: string;
	name: string;
	wallet: { [key in BananaPay.Chain]: BananaPay.App.Receiver};
	verified: boolean;
	date: string;
	profile: string;
	isPrivate: boolean;
}) {
	const { image, address, name, wallet, date, profile, isPrivate, verified } = props;
	
	const toast = useToast();
	// Chakra Color Mode
	const borderColor = useColorModeValue('white !important', '#0b1437 !important');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorLink = useColorModeValue('blue.500', 'white');

	return (
		<Flex justifyContent='center' align='center' direction='column' w='100%' pb='60px'>
			<Image
				src={image}
				w={{ base: '100%', '3xl': '100%' }}
				maxH={{ base: '27.5vh', '3xl': '27.5vh' }}
				h={{ base: '140px', md: '100%' }}
				borderRadius='20px'
				objectFit={"cover"}
			/>
			<Avatar
				src={profile}
				h={{ xl: '170px' }}
				w={{ xl: '170px' }}
				border='10px solid'
				mt='-95px'
				mb='20px'
				borderColor={borderColor}
			/>
			<Flex align='center' mb='20px'>
				<Text
					color={textColor}
					fontSize={{ base: '40px', lg: '54px' }}
					fontWeight='700'
					lineHeight='100%'
					me='6px'>
					{name}
				</Text>
				{verified ? <Icon as={MdVerified} h='34px' w='34px' color='blue.500' mt='12px' /> : null}
			</Flex>
			{Object.entries(wallet).map(([key, receiver]) => {
				console.log("Walletz, ", wallet);
				return(
					<Flex
						key={key}
						align='center'
						mb='14px'
						cursor='pointer'
						onClick={function() {
							navigator.clipboard.writeText(receiver.address);
							toast({
								title: `Address copied!`,
								position: 'top',
								status: 'success',
								isClosable: true
							});
						}}>
						<Text color='secondaryGray.600' fontSize='lg' fontWeight='400' mx='4px'>
							{getEllipsisTxt(receiver.address, 4)}
						</Text>
						<Box height={"10px"} width="10px">
							{getChain(receiver.chain).logo}
						</Box>
						<Icon as={MdOutlineContentCopy} h='14px' w='14px' color='secondaryGray.600' mt='6px' />
					</Flex>
				)
			})}
			<Link href={address} target={"_blank"}>
				<Text color={textColorLink} fontSize='lg' fontWeight='500' mx='4px' mb='14px'>
					{address}
				</Text>
			</Link>
			<Text
				color={textColor}
				fontSize='lg'
				fontWeight='500'
				lineHeight='180%'
				w='860px'
				maxW='100%'
				textAlign='center'
			>
				{date}
			</Text>
			{isPrivate ? (
				<Box flexDirection={"row"} backgroundColor={"gray.200"} mt="20px" textColor={"gray.700"} rounded={"full"} px="4" py="2" w="fit-content">
					<Text fontWeight={"800"}>Private</Text>
				</Box>
			):null}
		</Flex>
	);
}
