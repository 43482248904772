import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import { Alert, AlertIcon, ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { UserProvider } from 'contexts/UserContext';
import { AppsProvider } from 'contexts/AppsContext';
import { EventsProvider } from 'contexts/EventsContext';
import { StatsProvider } from 'contexts/StatsContext';
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-FOWxjQvIU7YqEAEi",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});

const App = () => {

	useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

	return (
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<GrowthBookProvider growthbook={growthbook}>
					<UserProvider>
						<AppsProvider>
							<EventsProvider>
								<HashRouter>
									<StatsProvider>
										<Switch>
											<Route path={`/auth`} component={AuthLayout} />
											<Route path={`/admin`} component={AdminLayout} />
											<Route path={`/rtl`} component={RTLLayout} />
											<Redirect from='/' to='/admin' />
										</Switch>
									</StatsProvider>
								</HashRouter>
							</EventsProvider>
						</AppsProvider>
					</UserProvider>
				</GrowthBookProvider>
			</React.StrictMode>
		</ChakraProvider>
	)
}

ReactDOM.render(
	<App />,
	document.getElementById('root')
);
